<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    transition="dialog-transition">
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Información" @cancelar="show = false"/>
      </v-card-title>
      <v-card-text>
        <div class="pt-3">
          <v-row>
            <v-col cols="1">
              <v-icon color="primary" large>mdi-information-outline</v-icon>
            </v-col>
            <v-col class="body-1 font-weight-regular">
              {{ mensaje }}
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="show =false" text>Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  components: {
    ModalTitle
  },
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
      }
    },
    isAdmin() {
      return this.$store.getters.usuario.rol === "ADMIN" ? true : false;
    },
    mensaje() {
      let msj = '';
      if(this.isAdmin) {
        msj = 'Se alcanzó la cantidad máxima de Instalaciones para esta organización, para agregar más modifique este valor en el catálogo de Organizaciones.'
      } else {
        msj = 'Se alcanzó la cantidad máxima de Instalaciones para su organización, por favor contacte al administrador para más información.'
      }
      return msj;
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    cancelar() {
      this.show = false;
    }
  }
}
</script>