<template>
    <v-container fluid>
        <Productos></Productos>
    </v-container>
</template>

<script>
import Productos from "@/components/productos/ProductosTablaComponent";
export default {
    components: {
        Productos
    }
    
}
</script>