<template>
    <v-container fluid>
        <logo @cambiaAnio="reloadData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <v-card color="#0B3F67">
                    <v-card-text>
                        <v-card class="pa-2">
                            <v-list-item>                            
                                <v-list-item-content>
                                    <v-list-item-title class="headline text-center">{{ titulo }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>                                                            
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>            
        </v-row>
        <v-row>
            <v-col cols="3" md="3" lg="2">
                <agregarMedidor 
                    @agregarMedidor="reloadData"  
                    :indicador="indicador"
                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
        </v-row>
        <br>
        <v-row>
            <v-col cols="12" sm="12" md="12" v-for="medidor in medidores" :key="medidor.id">
                <v-card>
                    <v-card-text>
                        <tablaIndicador :data="medidor.historial" :anio="anioConsulta" :valorizable="medidor.id"/>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <agregarConsumo 
                            @agregarHistorial="getConsumo" 
                            :anioConsulta="anioConsulta" 
                            :consumos="medidor.id"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">
                        </agregarConsumo>
                        <modificarConsumo 
                            :tipo="medidor.id" 
                            :anioConsulta="anioConsulta" 
                            @modificarHistorial="getConsumo"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">
                        </modificarConsumo>
                        <agregarEvidencia 
                            :tipo="medidor.id"
                            :anioActual="anioConsulta"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">
                        </agregarEvidencia>
                        <listadoDocs titulo="Evidencias de Residuos" :medidorId="medidor.id" :anioConsulta="anioConsulta" origen="residuos"></listadoDocs>
                        <justificacion :esValorizable="esvalorizables(medidor.id)" :botonIcono="true" :reqEvidencias="true" :tipoDocumento="tipoDocumento(medidor.id)"></justificacion>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import NavParameters from '@/components/utils/NavParameterComponent'
import AgregarMedidor from '@/components/indicadores/ResiduosValorizables/AgregarMedidorResiduoComponent'
import TablaIndicador from '@/components/indicadores/ResiduosValorizables/TablaResiduosComponent'
import AgregarConsumo from '@/components/indicadores/ResiduosValorizables/AgregarHistorialResiduoComponent'
import ModificarConsumo from '@/components/indicadores/ResiduosValorizables/ModificarConsumoResiduosComponent'
import AgregarEvidencia from '@/components/indicadores/ResiduosValorizables/AgregarEvidenciaResiduosComponent'
import ListadoDocs from '@/components/documentos/ListadoDocumentosComponent'
import Justificacion from '@/components/utils/JustificacionComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js"

export default {

    components:{
        NavParameters,
        AgregarMedidor,
        TablaIndicador, 
        AgregarConsumo,
        ModificarConsumo,
        AgregarEvidencia,
        ListadoDocs,
        Logo,
        Justificacion
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    mounted(){
        this.getConsumo();
    },
    computed:{
        indicador() {
            return this.$store.getters.indicadorActual;
        },
        titulo() {
            return "Generación Mensual de Residuos";    
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        }
    },
    data:()=>({
        anios:[],
        medidores: [],
        loading: false,
        consumoAnterior:null,
    }),
    methods:{
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getConsumo(){
            this.loading = false;
            axios.post("/instalaciones/getHistorialConsumoResiduos", null, {params:{
                idInstalacion:this.sucursal.id,
                anio: this.anioConsulta
            }}).then(response =>{
                this.medidores = response.data.medidores;
                this.consumoAnterior = response.data.consumoAnterior;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.error(error);
                this.loadingModal(false);
              }
            })
        },
        reloadData(){
            this.getConsumo();
        },
        esvalorizables(tipo) {
            return (tipo == 0)//0 es valorizable
        },
        tipoDocumento(tipo) {
            let constantes = this.$store.getters.constantes;
            return (tipo == 0) ? constantes.JUSTIF_CONSUMO_VALOR : constantes.JUSTIF_CONSUMO_NO_VALOR;
        }
    }    
}
</script>