<template>
    <v-progress-linear
        :value="avance"
        :color="color"
        height="20"
        rounded>
      <template v-slot="{ value }">
        <strong class="white--text">{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>            
</template>
<script>
export default {
    props: [
        'avance'
    ],
    computed: {        
        color() {
            let avanceFinal = parseInt(this.avance);          
            if(avanceFinal === 100) {
                return 'green darken-2';
            } else {
                if(avanceFinal >= 90 && avanceFinal < 100) {
                    return 'yellow darken-2';
                } else {
                    return 'red darken-1';
                }
            }
        }
    },
}
</script>