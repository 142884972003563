<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent
    max-width="700px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
        <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">
                <v-btn  v-if="botonIcono" large dark icon v-on="{ ...tooltip, ...dialog }" @click="getDataJustificacion()"><v-icon color="#0B3F67">mdi-text-box-plus</v-icon></v-btn>
                <v-btn v-else @click="getDataJustificacion" class="white--text" v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
                    Justificaci&oacute;n
                </v-btn>
            </template>
        {{ tooltipText }}
        </v-tooltip>
    </template>
    <v-card>
        <v-card-title class="pa-0">
            <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-textarea
                label="Justificación"
                v-model="justificacion"
                @input="$v.justificacion.$touch()"
                @blur="$v.justificacion.$touch()"
                :error-messages="justErrors"
                counter="4000"
            ></v-textarea>
            <v-file-input v-if="reqEvidencias"
                multiple
                accept=".png, .jpg, .jpeg"
                show-size 
                counter
                hint="Solo archivos png, jpg o jpeg"
                v-model="archivos"
                :error-messages="docErrors"
                @change="$v.archivos.$touch()"
                @blur="$v.archivos.$touch()"
                :label="'Agregar Evidencia'">
            </v-file-input>
            <Documentos-justificacion v-if="reqEvidencias" :tipoDocumento="tipoDocumento" @reload="getDataJustificacion"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar" color="error">Cancelar</v-btn>
            <v-btn text @click="modificar" color="#0B3F67">Modificar</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@/axios'
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from 'vuelidate/lib/validators'
import DocumentosJustificacion from '@/components/documentos/DocumentosJustificacionComponent'
//validaciones
//Custom validations
const maxFileSize = (files) => {
    if(files.length > 0) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return true;
}
//Cantidad de documentos
const maxFilesCount = (files, vm) => {
    if(files.length > 0) {
        let maximo = vm.docsMaximos;
        let total = files.length + vm.cantDocs;
        return total <= maximo;
    }
    return true;
}
export default {
    components: {
        ModalTitle,
        DocumentosJustificacion
    },
    props:{ 
        titulo: {
            type: String,
            default: 'Justificación',
            required: false
        }, 
        tooltipText: {
            type: String,
            default: 'Justificación',
            required: false
        },
        botonIcono: {
            type: Boolean,
            default: false,
            required: false
        },
        esValorizable: {
            type: Boolean,
            default: false,
            required: false
        },
        reqEvidencias: {
            type: Boolean,
            default: false,
            required: false
        },
        tipoDocumento: {
            type: Number,
            default: 0,
            required: false
        },
    },
    computed: {
        justErrors() {
            const errors = []
            if (!this.$v.justificacion.$dirty) return errors            
            !this.$v.justificacion.required && errors.push('La justificación es requerida.')            
            !this.$v.justificacion.maxLength && errors.push('No debe exceder el tamaño permitido.')  
            return errors
        },
        docErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.maxFilesCount && errors.push(`Máximo de evidencias excedido, (máximo ${this.docsMaximos} evidencias, evidencias cargadas previamente ${this.cantDocs}).`)
            !this.$v.archivos.maxFileSize && errors.push(`El tamaño total debe ser menor a ${this.fileSize} MB.`)
            return errors
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
        docsMaximos() {
            return this.$store.getters.constantes.CANTIDAD_MAXIMA_ARCHIVOS;
        },
    },
    data: () => ({
        justificacion: null,
        dialog: false,
        archivos: [],
        cantDocs: 0
    }),
    validations:{
        justificacion: {
            required,
            maxLength: maxLength(4000)
        },
        archivos: {
            maxFileSize,
            maxFilesCount
        }
    },
    methods: {
        cancelar() {
            this.justificacion = null;
            this.$v.justificacion.$reset()
            this.loadingModal(false);
            this.dialog = false
            this.$v.archivos.$reset();
            this.archivos = []
        },
        modificar() {
           this.$v.$touch()
           if(!this.$v.$invalid) {
                this.loadingModal(true)
                let formData = new FormData();
                formData.append('javaBean', this.id);
                this.archivos.forEach(file => {
                    formData.append('evidencias', file);
                });
                formData.append('instalacion', this.instalacion.id);
                formData.append('anio', this.anioConsulta);
                formData.append('indicador', this.indicadorActual);
                formData.append('justificacion', this.justificacion);
                formData.append('parametroActual', this.parametroActual);
                formData.append('esValorizable', this.esValorizable);
                formData.append('tipoDocumento', this.tipoDocumento);
                axios.post('/instalaciones/agregarJustificacion/', formData).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Justificación agregada', color:'success'});
                    this.cancelar()
                }).catch(err => {
                    if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                    } else {
                        if (error.request && error.request.status === 415) {
                            this.$store.dispatch('noti', {mensaje: err.response.data, color: 'error'});
                            this.loading = false;
                        } else {  
                            this.$store.dispatch('noti',{mensaje: 'Error al agregar la justificación', color:'error'});
                        }
                    }
                }).finally(() => {
                    this.loadingModal(false)
                })
           }
        },
        getDataJustificacion() {
            this.loadingModal(true)
            axios.get(`/instalaciones/getDataJustificacion/${this.instalacion.id}/${this.anioConsulta}/${this.indicadorActual}/${this.parametroActual}/`, { params: {
                esValorizable: this.esValorizable,
                tipoDocumento: this.tipoDocumento
            }})
            .then(response => {
                this.justificacion = response.data.justificacion;
                this.cantDocs = response.data.cantDocs;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.loadingModal(false)
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        }
    }
}
</script>
