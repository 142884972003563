<template>
    <v-dialog
        v-model="dialog"
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Usuario" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Nombre"
                            v-model="usuario.nombre"
                            :error-messages="nameErrors"
                            @input="$v.usuario.nombre.$touch()"
                            @blur="$v.usuario.nombre.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Apellido"
                            v-model="usuario.apellido"
                            :error-messages="apelErrors"
                            @input="$v.usuario.apellido.$touch()"
                            @blur="$v.usuario.apellido.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Correo"
                            v-model="usuario.correo"
                            :error-messages="emailErrors"
                            @blur="$v.usuario.correo.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Teléfono"
                            v-model="usuario.telefono"
                            :error-messages="telefonoErrors"
                            @input="$v.usuario.telefono.$touch()"
                            @blur="$v.usuario.telefono.$touch()"
                            required
                            v-mask="telMask"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Cédula"
                            v-model="usuario.cedula"
                            v-mask="mask"
                            hint="9 dígitos, sin cero al inicio ni guiones"
                            @input="$v.usuario.cedula.$touch()"
                            @blur="$v.usuario.cedula.$touch()"
                            :error-messages="cedErrors"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="isNotAdmin" class="pt-0 pb-0">
                        <v-text-field                            
                            label="Puesto de Trabajo"
                            v-model="usuario.puestoTrabajo"
                        ></v-text-field>
                    </v-col>                    
                    <v-col :cols="colsSize" v-if="isNotAdmin" class="pt-0 pb-0">
                        <v-select                            
                            :items="roles"
                            v-model="rol"
                            label="Rol"               
                            :error-messages="rolErrors"             
                            @change="$v.rol.$touch()"
                            @blur="$v.rol.$touch()"
                            required
                        ></v-select>
                    </v-col>                  
                    <v-col cols="6" v-if="isNotAdmin" class="pt-0 pb-0">
                        <v-select 
                            :items="organizaciones"
                            item-text="nombre"
                            item-value="id"
                            v-model="organizacion"
                            label="Organización"
                            :error-messages="orgErrors"             
                            @change="$v.organizacion.$touch()"
                            @blur="$v.organizacion.$touch()"
                            required
                        ></v-select>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12 " v-if="!isNotAdmin" >
                        <v-select                            
                            :items="roles"
                            v-model="rol"
                            label="Rol"               
                            :error-messages="rolErrors"             
                            @change="$v.rol.$touch()"
                            @blur="$v.rol.$touch()"
                            required
                        ></v-select>
                    </v-col>  
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click.native="agregarUsuario">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import { mask } from "vue-the-mask";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, email, requiredIf, minLength } from "vuelidate/lib/validators";

export default {
    mounted() {
        this.getDataAgregarUsuario();
    },
    components: {
      ModalTitle
    },
    directives: {
        mask,
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    computed: {
        colsSize () {
            return this.superAdmin ? 6 : 12;
        },
        superAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN'
        },
        isNotAdmin() {
            return this.rol !== "Administrador" ? true : false;
        },
        nameErrors () {
            const errors = []
            if (!this.$v.usuario.nombre.$dirty) return errors            
            !this.$v.usuario.nombre.required && errors.push('El nombre es requerido.')            
            return errors
        },
        cedErrors() {
            const errors = []
            if (!this.$v.usuario.cedula.$dirty) return errors            
            !this.$v.usuario.cedula.minLength && errors.push('Tamaño ivalido.')            
            return errors            
        },
        apelErrors () {
            const errors = []
            if (!this.$v.usuario.apellido.$dirty) return errors            
            !this.$v.usuario.apellido.required && errors.push('El apellido es requerido.')            
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.usuario.correo.$dirty) return errors
            !this.$v.usuario.correo.email && errors.push('Debe ser un correo valido.')
            !this.$v.usuario.correo.required && errors.push('El correo es requerido.')
            !this.$v.usuario.correo.uniqueEmail && errors.push('Este correo eléctronico ya se encuentra en uso.')
            return errors
        },
        telefonoErrors () {
            const errors = []
            if (!this.$v.usuario.telefono.$dirty) return errors            
            !this.$v.usuario.telefono.required && errors.push('El teléfono es requerido.')
            !this.$v.usuario.telefono.minLength && errors.push('Tamaño invalido.')
            return errors
        },
        rolErrors () {
            const errors = []
            if (!this.$v.rol.$dirty) return errors
            !this.$v.rol.required && errors.push('El rol es requerido')
            return errors
        },
        orgErrors () {
            const errors = []
            if (!this.$v.organizacion.$dirty) return errors
            !this.$v.organizacion.required && errors.push('La Organización es requerida')
            return errors
        },
        orga() {
            return this.$store.getters.organizacion;
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        mask: '#-####-####',
        telMask: '####-####',
        usuario: {
            nombre: '',
            apellido: '',
            correo: '',
            cedula: '',
            puestoTrabajo: '',  
            telefono: '',         
        },
        rol: '',
        organizacion: null,
        roles: [],
        organizaciones: []
    }),
    validations: {
        usuario: {
            nombre: {
                required,               
            },
            apellido: {
                required,               
            },
            cedula: {
                minLength: minLength(11)
            },
            correo: {
                required,
                email,
                uniqueEmail(email) {
                    if (email === '') return true
                    return new Promise((resolve, reject) => {
                        axios.get("/usuario/verificarEmail/" + email).then(response => {
                            resolve(response.data);
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            reject(error);
                          }
                        })
                    })
                }
            },
            telefono: { required, minLength: minLength(9) }
        },
        rol: {
            required
        },
        organizacion: {
            required: requiredIf(function(){
                return this.isNotAdmin
            }) 
        }
    },
    methods: {
        getDataAgregarUsuario() {
            let nombreUsuario = this.$store.getters.usuario.nombreUsuario;
            axios.get("/usuario/getDataAgregar/"+nombreUsuario
            ).then(response => {
                this.roles = response.data.roles;
                this.organizaciones = response.data.organizaciones;
                if(!this.superAdmin) {
                    this.organizacion = response.data.organizacion.id;
                }
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error en la carga de datos: " + error);
              }
            })
        },
        cancelar() {
            this.usuario = {};
            this.organizacion = '';
            this.rol = '';
            this.$v.usuario.$reset();
            this.$v.organizacion.$reset();
            this.$v.rol.$reset();
            this.loading = false;
            this.dialog = !this.dialog;
        },
        agregarUsuario() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);          
                if(!this.superAdmin) {
                    this.organizacion = this.orga.id;
                }
                axios.post("/usuario/agregarUsuario", this.usuario, { params: {
                    orgId: this.organizacion,
                    rol: this.rol
                }}).then(response => {                    
                    this.$emit('recargaTabla');
                    this.$store.dispatch('noti',{mensaje: 'Usuario agregado', color:'success'});  
                    this.loadingModal(false);              
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar el usuario: " + error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el usuario', color:'error'});
                    this.loadingModal(false);
                    this.cancelar();
                  }
                })
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>