<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                small
                exact-active-class="indigo"
                class="ma-2"
                v-on="{ ...tooltip, ...on }"
                @click="getDataModificar()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Actividad" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                        label="Tipo de Actividad"
                        v-model="accion.tipoActividad"
                        :error-messages="tipoErrors"             
                        @change="$v.accion.tipoActividad.$touch()"
                        @blur="$v.accion.tipoActividad.$touch()"
                        counter="100"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                        label="Descripcion"
                        v-model="accion.descripcion"
                        :error-messages="descErrors"
                        @change="$v.accion.descripcion.$touch()"
                        @blur="$v.accion.descripcion.$touch()"
                        counter="500"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col  cols="12" md="12">
                        <v-text-field
                        label="Cantidad de Actividades"
                        v-model="accion.cantidadActividades"
                        :error-messages="cantErrors"
                        @change="$v.accion.cantidadActividades.$touch()"
                        @blur="$v.accion.cantidadActividades.$touch()"
                        required 
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-select
                            :items="tipoAccion"
                            item-text="nombre"
                            item-value="valor"
                            v-model="accion.tipoAccion"
                            :error-messages="tipoAccionErrors"
                            @change="$v.accion.tipoAccion.$touch()"
                            @blur="$v.accion.tipoAccion.$touch()"
                            required
                            label="Tipo de Accion"
                        ></v-select>
                    </v-col>                    
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarAccion">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import axios from "@/axios.js";
import { mask } from "vue-the-mask"
import { required, integer } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import moment from "moment";

export default {
    directives: {
        mask
    },
    components: {
      ModalTitle
    },
    props: ["id"],
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    computed:{
        fechaMoment() {
            return this.fechaActividad ? moment(this.fechaActividad).format("DD/MM/YYYY"): "";
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.accion.tipoActividad.$dirty) return errors
            !this.$v.accion.tipoActividad.required && errors.push('Campo requerido')
            !this.$v.accion.tipoActividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.accion.descripcion.$dirty) return errors
            !this.$v.accion.descripcion.required && errors.push('Campo requerido')
            !this.$v.accion.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.accion.cantidadActividades.$dirty) return errors
            !this.$v.accion.cantidadActividades.required && errors.push('Campo requerido')
            !this.$v.accion.cantidadActividades.integer && errors.push('Debe digitar solo números')
            return errors
        },
        tipoAccionErrors (){
            const errors = []
            if(!this.$v.accion.tipoAccion.$dirty) return errors
            !this.$v.accion.tipoAccion.required && errors.push('Campo requerido')
            return errors
        },    
        tipoOrden() {
            return this.accion.tipoAccion === 1 ? 'primer' : 'segundo'
        }              
    },
    data : ()=>({
        loading: false,
        dialog: false,
        menu: false, 
        accion: {
            tipoActividad:'',
            descripcion:'',
            cantidadActividades:'',
            fechaActividad: '',
            tipoAccion:''
        },
        fechaActividad: null,
        archivos:[],
        tipoAccion: [
            {nombre:'PRIMER', valor: 1},              
            {nombre:'SEGUNDO', valor: 2},
        ], 
    }),
    validations: {
        accion: {
            tipoActividad: {
                required
            },
            descripcion: {
                required
            },
            cantidadActividades: {
                required,
                integer
            },
            tipoAccion:{
                required
            }
        },
    },    
    methods:{
        getDataModificar(){
            let idAccion = this.id;
            axios.get("/accionesPoS/getDataModificar/"+idAccion).then(response=>{
                this.accion = response.data.accion;
                this.fechaActividad = this.dateFormat(response.data.accion.fechaActividad);
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
              }
            })
        },
        modificarAccion(){
            this.$v.$touch()
            if(!this.$v.$invalid){
                this.loading = true;
                delete this.accion.tipoAccionString;
                axios.put("/accionesPoS/modificar/",this.accion, {params:{
                    fechaActividad: this.fechaActividad,
                    idUsuario: this.usuario.id                
                }}).then(response=>{
                    this.$emit("reload");
                    this.$emit('reloadAn');
                    this.$store.dispatch("noti", {
                        mensaje: "Acción de " + this.tipoOrden + ' orden modificada',
                        color: "success"
                    });    
                    this.cancelar();            
                }).catch(error=>{
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch("noti", {mensaje: "Ocurrió un error no se modificó la acción",color: "error"});
                    console.log(error);
                    this.cancelar();
                  }
                })
            }
        },
        dateFormat(fecha) {
            return new Date(fecha).toISOString().substr(0, 10);
        },
        cancelar(){
            this.accion = {};
            this.fechaActividad = ''
            this.dialog = false;
            this.loading = false;
            this.$v.accion.$reset();
        }
    }
}
</script>

