<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent 
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn large dark icon v-on="{ ...tooltip, ...dialog }"><v-icon color="#0B3F67">mdi-gas-station-off</v-icon></v-btn>                 
        </template>
        Consumo Cero
      </v-tooltip>                    
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Reportar Consumo Cero" @cancelar="cancelar"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="pt-4">
          ¿Desea reportar el combustible {{ medidor.medidor }} del {{ anioConsulta }}, sin consumo?
          Precaución: Esta acción actualiza todos los registros del {{ anioConsulta }} a cero.
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelar" color="error">Cancelar</v-btn>
        <v-btn text @click="consumoCero" color="#0B3F67">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from '@/axios'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  props: [
    'medidor'
  ],
  components: {
    ModalTitle
  },
  computed: {
    usuario(){
      return this.$store.getters.usuario;
    },
    anioConsulta() {
      return this. $store.getters.anioConsulta;
    },
    indicador() {
      return this.$store.getters.indicadorActual;
    },
    instalacion() {
      return this.$store.getters.sucursal;
    },
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    cancelar() {
      this.dialog = !this.dialog
    },
    consumoCero() {
      this.$store.dispatch('loadingPage', true);
      axios.post('/combustibles/consumoCero', null, {params: {
        medidor: this.medidor.id,
        usuario: this.usuario.id,
        instalacion: this.instalacion.id,        
        anio: this.anioConsulta
      }}).then(response => {
        this.$store.dispatch('noti',{mensaje: 'Consumo cero aplicado', color:'success'});
        this.$store.dispatch('loadingPage', false);
        this.$emit('consumoCero');
        this.cancelar();
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          this.$store.dispatch('noti',{mensaje: 'Error al aplicar consumo cero', color:'error'});
          this.$store.dispatch('loadingPage', false);
          this.cancelar();
        }
      })
    }
  }
}
</script>