var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.educacion,"hide-default-footer":"","item-key":"id","search":_vm.search,"no-data-text":"No tiene Educación Externa para este periodo"},scopedSlots:_vm._u([{key:"item.fechaActividad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaActividad))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarEducacionExtCompesacion',{attrs:{"educacionExt":item},on:{"reload":_vm.listarEducaActividadesCompesacion}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idEducacion":item.id,"origen":_vm.origen,"titulo":_vm.titulo,"tooltipText":"Agregar Evidencias"},on:{"reload":_vm.listarEducaActividadesCompesacion}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarEducacion(item.id)}}},on),[_vm._v(" mdi-trash-can-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar Evidenciass")])])]}},{key:"item.evidencia",fn:function(ref){
var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idEducacion":item.id,"idActividad":item.id,"origen":"EducacionExterna","titulo":_vm.titulo}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }