<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="520px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon 
                        medium
                        v-on="{ ...tooltip, ...dialog }" 
                        color="#0B3F67"
                        class="mr-2">
                        {{icon}}
                    </v-icon>                    
                </template>
                {{ tooltipText }}
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-file-input 
                    multiple
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter
                    v-model="archivos"
                    :error-messages="docErrors"
                    @change="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()"
                    :label="'Agregar Evidencia'">
                </v-file-input> 
                <v-file-input 
                    v-if="tipo == 2 && anio >= 2024"
                    multiple
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter
                    v-model="archivosUso"
                    :error-messages="docUsoErrors"
                    @change="$v.archivosUso.$touch()"
                    @blur="$v.archivosUso.$touch()"
                    :label="'Agregar Evidencia Uso de producto'">
                </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="argregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}
export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    props: [
        'titulo',
        'tooltipText',
        'tipo',
        'icon',
        'id',
        'anio'
    ],
    computed: {
      url() {
        switch(this.tipo) {
          case 1:
            return '/productoContaAtmosf/agregarEvidencias/'
          case 2:
            return '/comprasSostenibles/agregarEvidenciasProdSost/'
        }
      },
      sucursal() {
          return this.$store.getters.sucursal;
      },
      indicadorActual() {
          return this.$store.getters.indicadorActual;
      },
      usuario(){
          return this.$store.getters.usuario;
      },
      docErrors () {
          const errors = []
          if (!this.$v.archivos.$dirty) return errors            
          !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a 1 MB.')        
          return errors
      },
      docUsoErrors () {
          const errors = []
          if (!this.$v.archivosUso.$dirty) return errors            
          !this.$v.archivosUso.maxFileSize && errors.push('El tamaño total debe ser menor a 1 MB.')        
          return errors
      },
    },
    data: () => ({
        dialog : false,
        loading: false,
        archivos: null,
        archivosUso: null
    }),
    validations: {
        archivos: {
            maxFileSize
        },
        archivosUso: {
            maxFileSize
        }
    },
    methods: {
        argregarEvidencias() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('javaBean', this.id);
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                this.archivosUso.forEach(file => {
                    formData.append('archivosUso', file);
                });
                formData.append('usuario', this.usuario.id)
                formData.append('anio', this.anio)
                axios.post(this.url, formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Evidencia agregada ', color:'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Error en la carga de documentos', color:'error'}); 
                      this.cancelar();
                    }
                  }
                });
            }
        },
        cancelar() {
            this.archivos = null;
            this.$v.archivos.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>