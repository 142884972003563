<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="getDataNota()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Notas" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="hogar.nombre"
                            label="Hogar"
                            disabled
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Parámetro"
                            v-model="historial.parametro"
                            disabled>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Nota Obtenida"
                            v-model="historial.notaObtenida"
                            :error-messages="notasErrors"             
                            @change="$v.historial.notaObtenida.$touch()"
                            @blur="$v.historial.notaObtenida.$touch()"
                            required>
                        </v-text-field>  
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarHistorial">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, decimal, minValue, maxValue } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        notasErrors() {
            const errors = []
            if (!this.$v.historial.notaObtenida.$dirty) return errors            
            !this.$v.historial.notaObtenida.required && errors.push('La nota es requerida.')
            !this.$v.historial.notaObtenida.decimal && errors.push('Solo debe digitar números.')
            !this.$v.historial.notaObtenida.minValue && errors.push('La nota debe ser mayor a 0.')
            !this.$v.historial.notaObtenida.maxValue && errors.push('La nota debe ser menor o igual a 100.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        historial: {
            id: '',
            parametro: '',
            notaObtenida: '',
        },
    }),
    props: [
        'id',
        'hogar'
    ],
    validations: {
        historial: {
            notaObtenida: { required, decimal, minValue: minValue(0), maxValue: maxValue(100) },
        },
    },
    methods: {
        cancelar() {
            this.historial = {};
            this.dialog = false;
            this.loading = false;
            this.$v.historial.$reset();
        },
        getDataNota() {
            this.cargando = true;
            axios.get("/estrellas/getNotaHogar/" + this.id)
            .then(response => {
                this.historial = response.data.historial;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        modificarHistorial() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.historial.id = this.id;
                formData.append("historialS", JSON.stringify(this.historial));
                axios.post("/estrellas/modificarNotaHogar", formData)
                .then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Nota modificada", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al modificar la nota " + error);
                    this.$store.dispatch("noti", { 
                        mensaje: "Ocurrió un error no se modificó la nota",
                        color: "error"
                    });
                    this.cancelar();
                  }
                });
            }
        }
    }
};
</script>
