<template>
    <v-dialog
        v-model="dialog"
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">                        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                                    
                    <v-icon v-if="tipoAccion === 'Agregar'" color="#0B3F67" v-on="{...tooltip, ...dialog }">{{ icono }}</v-icon>
                    <v-icon v-if="tipoAccion === 'Modificar'" @click="setPlan" color="#0B3F67" v-on="{...tooltip, ...dialog }">{{ icono }}</v-icon>
                    <v-icon v-if="detalle" color="#0B3F67" @click="setPlan" v-on="{...tooltip, ...dialog }">{{ icono }}</v-icon>
                </template>
                {{ tipoAccion }}  Plan
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle :titulo="tipoAccion" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-textarea
                            outlined
                            :label="labelAccion"
                            v-model="plan.descripcion"
                            counter="200"
                            :disabled="detalle"
                            :error-messages="descErrors"
                            @click="$v.plan.descripcion.$touch()"
                            @blur="$v.plan.descripcion.$touch()"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-textarea
                            outlined
                            label="Objetivo"
                            v-model="plan.objetivo"
                            counter="200"
                            :disabled="detalle"
                            :error-messages="objErrors"
                            @click="$v.plan.objetivo.$touch()"
                            @blur="$v.plan.objetivo.$touch()"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-textarea
                            outlined
                            label="Meta"
                            v-model="plan.meta"
                            counter="200"
                            :disabled="detalle"
                            :error-messages="metaErrors"
                            @click="$v.plan.meta.$touch()"
                            @blur="$v.plan.meta.$touch()"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Indicador"
                            v-model="plan.indicador"
                            counter="200"
                            :disabled="detalle"
                            :error-messages="indcErrors"
                            @click="$v.plan.indicador.$touch()"
                            @blur="$v.plan.indicador.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0"  v-if="anioConsulta < 2022">
                        <v-text-field
                            label="Responsable"
                            v-model="plan.responsable"
                            counter="100"
                            :disabled="detalle"                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Plazo de Ejecución"
                            v-model="plan.plazo"
                            counter="100"
                            :disabled="detalle"
                            :error-messages="plazoErrors"
                            @click="$v.plan.plazo.$touch()"
                            @blur="$v.plan.plazo.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Presupuesto"
                            v-model="plan.presupuesto"
                            counter="100"
                            :disabled="detalle"
                            :error-messages="presErrors"
                            @click="$v.plan.presupuesto.$touch()"
                            @blur="$v.plan.presupuesto.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0" v-if="anioConsulta < 2022">
                        <v-textarea
                            outlined
                            label="Oportunidad para la Organización"
                            v-model="plan.oportunidad"
                            counter="200"
                            :disabled="detalle"
                            :error-messages="oportErrors"
                            @click="$v.plan.oportunidad.$touch()"
                            @blur="$v.plan.oportunidad.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!detalle" @click="cancelar" text color="error">Cancelar</v-btn>
                <v-btn v-if="tipoAccion === 'Agregar'" @click="agregarPlan" text color="#0B3F67">Agregar</v-btn>
                <v-btn v-if="tipoAccion === 'Modificar'" @click="modificarPlan" text color="#0B3F67">Modificar</v-btn>
                <v-btn v-if="detalle" @click="cancelar" text color="#0B3F67">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, decimal, requiredIf } from "vuelidate/lib/validators";
import axios from '@/axios.js';

export default {
    props: [
        'detalle',
        'tipoAccion',
        'planProp',
        'instalacion',
        'usuario', 
        'icono'
    ],
    components: {
      ModalTitle
    },
    computed: {
        labelAccion() {
            return this.anioConsulta > 2021 ? 'Descripción de la acción' : 'Descripción del Plan'
        },
        descErrors() {
            const errors = []
            if (!this.$v.plan.descripcion.$dirty) return errors            
            !this.$v.plan.descripcion.required && errors.push('La descripción es requerida.')
            !this.$v.plan.descripcion.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        objErrors() {
            const errors = []
            if (!this.$v.plan.objetivo.$dirty) return errors            
            !this.$v.plan.objetivo.required && errors.push('El objetivo es requerido.')
            !this.$v.plan.objetivo.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        metaErrors() {
            const errors = []
            if (!this.$v.plan.meta.$dirty) return errors            
            !this.$v.plan.meta.required && errors.push('La meta es requerida.')
            !this.$v.plan.meta.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        indcErrors() {
            const errors = []
            if (!this.$v.plan.indicador.$dirty) return errors            
            !this.$v.plan.indicador.required && errors.push('El indicador es requerido.')
            !this.$v.plan.indicador.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        plazoErrors() {
            const errors = []
            if (!this.$v.plan.plazo.$dirty) return errors            
            !this.$v.plan.plazo.required && errors.push('El plazo es requerido.')
            !this.$v.plan.plazo.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        presErrors() {
            const errors = []
            if (!this.$v.plan.presupuesto.$dirty) return errors            
            !this.$v.plan.presupuesto.required && errors.push('El presupuesto es requerido.')
            !this.$v.plan.presupuesto.decimal && errors.push('Deben ser solo números.')
            return errors
        },
        oportErrors() {
            const errors = []
            if (!this.$v.plan.oportunidad.$dirty) return errors            
            !this.$v.plan.oportunidad.required && errors.push('Dato requerido.')
            !this.$v.plan.oportunidad.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        dialog: false,
        plan: {
            descripcion: null,
            objetivo: null,
            meta: null,
            indicador: null,
            responsable: null,
            plazo: null,
            presupuesto: null,
            oportunidad: null
        }
    }),
    validations: {
        plan: {
            descripcion: {
                required,
                maxLength: maxLength(200)
            },
            objetivo: {
                required,
                maxLength: maxLength(200)
            },
            meta: {
                required,
                maxLength: maxLength(200)
            },
            indicador: {
                required,
                maxLength: maxLength(100)
            },            
            plazo: {
                required,
                maxLength: maxLength(100)
            },
            presupuesto: {
                required,
                decimal
            },
            oportunidad: {
                required: requiredIf(function() { 
                    return this.anioConsulta < 2022;
                }),
                maxLength: maxLength(200)
            }
        }
    },
    methods: {
        setPlan() {
            this.plan.descripcion = this.planProp.descripcion;
            this.plan.objetivo = this.planProp.objetivo;
            this.plan.meta = this.planProp.meta;
            this.plan.indicador = this.planProp.indicador;
            this.plan.responsable = this.planProp.responsable;
            this.plan.plazo = this.planProp.plazo;
            this.plan.presupuesto = this.planProp.presupuesto;
            this.plan.oportunidad = this.planProp.oportunidad;
        },
        agregarPlan() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.post('/adaptacion/agregarPlan', this.plan, {params: {
                    evento: this.planProp.idEvento,
                    inst: this.instalacion.id,
                    usuario: this.usuario.id,
                    anio: this.anioConsulta
                }}).then(response => {
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Plan agregado', color: 'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.loadingModal(false);
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el plan de acción', color: 'error'});
                  }
                })
            }
        },
        modificarPlan() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.put('/adaptacion/modificarPlan', null, {params: {
                    newPlan: JSON.stringify(this.plan),
                    modPlan: this.planProp.idPlan,
                    inst: this.instalacion.id,
                    usuario: this.usuario.id
                }}).then(response => {
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Plan modificado', color: 'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el plan de acción', color: 'error'});
                    this.$emit('reload');
                  }
                })
            }
        },
        cancelar() {
            this.plan = {};
            this.$v.plan.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>