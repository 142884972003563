<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                    small
                    exact-active-class="indigo"
                    class="ma-2"
                    v-on="{ ...tooltip, ...on }"
                    @click="getDataModificar()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Educación Externa" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12 ">
                        <v-textarea
                            rows="3"
                            label="Actividad de Compensación"
                            counter="500"
                            v-model="educacionExt.actividad"
                            :error-messages="actErrors"             
                            @change="$v.educacionExt.actividad.$touch()"
                            @blur="$v.educacionExt.actividad.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12 ">
                        <v-textarea
                            rows="3"
                            label="Educación Externa"
                            counter="500"
                            v-model="educacionExt.actividadEducacion"
                            :error-messages="eduErrors"             
                            @change="$v.educacionExt.actividadEducacion.$touch()"
                            @blur="$v.educacionExt.actividadEducacion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Cantidad de Beneficiados"
                            v-model="educacionExt.cant_beneficiados"
                            v-mask="mask"
                            hint="Ingresar solo números"
                            :error-messages="cantErrors"             
                            @change="$v.educacionExt.cant_beneficiados.$touch()"
                            @blur="$v.educacionExt.cant_beneficiados.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificareducacionExt">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import { required, integer, maxLength } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    computed: {
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        actErrors () {
            const errors = []
            if (!this.$v.educacionExt.actividad.$dirty) return errors
            !this.$v.educacionExt.actividad.required && errors.push('Campo requerido')
            !this.$v.educacionExt.actividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        eduErrors () {
            const errors = []
            if (!this.$v.educacionExt.actividadEducacion.$dirty) return errors
            !this.$v.educacionExt.actividadEducacion.required && errors.push('Campo requerido')
            !this.$v.educacionExt.actividadEducacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.educacionExt.cant_beneficiados.$dirty) return errors
            !this.$v.educacionExt.cant_beneficiados.required && errors.push('Campo requerido')
            !this.$v.educacionExt.cant_beneficiados.integer && errors.push('Solo ingrese números')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this. $store.getters.sucursal;
        }
    },
    data: () => ({
        dialog: false,
        menu: false,
        loading: false,
        mask: '####',
        educacionExt: {
            id:0,
            actividad: '',
            actividadEducacion: '',
            cant_beneficiados: 0
        },
        fechaActividad: new Date().toISOString().substr(0, 10),
        archivos:[],
    }),
    validations: {
        educacionExt: {
            actividad: {
                maxLength: maxLength(500),
                required
            },
            actividadEducacion: {
                maxLength: maxLength(500),
                required
            },
            cant_beneficiados: {
                required,
                integer
            },
        }
    },
    methods: {
        getDataModificar(){
            this.educacionExt.id = this.$attrs.educacionExt.id;
            this.educacionExt.actividad = this.$attrs.educacionExt.actividad;
            this.educacionExt.actividadEducacion = this.$attrs.educacionExt.actividadEducacion;
            this.educacionExt.cant_beneficiados = this.$attrs.educacionExt.cant_beneficiados;
            this.fechaActividad = new Date(this.$attrs.educacionExt.fechaActividad).toISOString().substr(0, 10);
        },
        modificareducacionExt() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('id', parseInt(this.educacionExt.id));
                formData.append('actividad', this.educacionExt.actividad);
                formData.append('actividadEducacion', this.educacionExt.actividadEducacion);
                formData.append('cant_beneficiados', this.educacionExt.cant_beneficiados);
                formData.append('fechaActividad', this.fechaActividad);
                formData.append('idInstalacion', this.instalacion.id);
                formData.append('idUsuario', this.usuario.id);
                axios.post("/actividadCompesacion/modificarEducacionExt", formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Educación externa modificada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la educación externa', color:'error'});  
                    this.cancelar();     
                  }       
                })
            }
        },
        cancelar() {
            this.educacionExt = {};
            this.fechaActividad = '';
            this.archivos=[];
            this.loading = false;
            this.$v.educacionExt.$reset();       
            this.dialog = false;
        }
    },
}
</script>