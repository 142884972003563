<template>
    <v-container fluid style="background-color: rgb(11, 63, 103)">
        <v-card raised>      
            <!-- Titulo Principal -->
            <v-card height="100%" outlined>
                <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                    Reducción en la Generación de Residuos <br>
                    AÑO {{periodoAnterior}} respecto al AÑO {{periodoActual}}                  
                </v-card-text>
            </v-card>        
            <!-- Primer encabezado -->
            <v-row no-gutters align="stretch" align-content="stretch">
                <v-col cols="12" md="6" align-self="center" justify-self="center">
                    <v-card height="100%">
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                        Datos Requeridos
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)"> 
                            Equivalencias
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>                      
            <!-- Encabezado de las columnas -->
            <v-row no-gutters align="stretch" align-content="stretch">
                <v-col cols="6" align-self="stretch">
                    <v-row no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    <p>Tipo de Residuo Valorizable</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="8" align-self="stretch">
                            <v-row no-gutters align="stretch" align-content="stretch">
                                <v-col cols="12" md="12">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Generacion KG
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="stretch" align-content="stretch">
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Anterior <br>
                                            
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Actual <br>
                                            
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Reduccion <br>
                                            
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col :cols="cols" align-self="stretch">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center font-weight-black">
                            <p>Contenedor Industrial</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="3" align-self="stretch" v-if="showDisposicion">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center font-weight-black">
                            <p>Disposición final</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!--Fila detalle de residuos valorizables-->
            <v-row class="altura" v-for="data in valorizables" :key="data.nombre"  no-gutters align="stretch" align-content="stretch">
                <v-col cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    {{data.nombre}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="8" align-self="stretch">
                             <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center">
                                            {{data.consumoAnterior}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center">
                                            {{data.consumoActual}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center">
                                            <div :class="numberColor(data.ahorroConsumo)">{{ numberFormat(data.ahorroConsumo) }}</div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col  cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col :cols="colsBig" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.contenedorIndustrial)">{{ numberFormat(data.contenedorIndustrial) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" align-self="stretch" v-if="showDisposicion">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                   <div> <strong>Disposición:</strong> {{ data.disposicion }}</div>
                                   <div> <strong>Gestor:</strong> {{ data.gestor }} </div>
                                   <div> <Disposicion-final :residuo="data" @reload="getDataReduccion()"/> </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!--Fila detalle de residuos NO valorizables-->
            <v-row class="altura" v-for="data in noValorizables" :key="data.nombre"  no-gutters align="stretch" align-content="stretch">
                <v-col cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    {{data.nombre}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="8" align-self="stretch">
                             <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center">
                                            {{data.consumoAnterior}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center">
                                            {{data.consumoActual}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="4" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center">
                                            <div :class="numberColor(data.ahorroConsumo)">{{ numberFormat(data.ahorroConsumo) }}</div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col  cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col :cols="colsBig" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                   <div :class="numberColor(data.contenedorIndustrial)"> {{ numberFormat(data.contenedorIndustrial) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" align-self="stretch" v-if="showDisposicion">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                   <div> <strong>Disposición:</strong> {{ data.disposicion }}</div>
                                   <div> <strong>Gestor:</strong> {{ data.gestor }} </div>
                                   <div> <Disposicion-final :residuo="data" @reload="getDataReduccion()"/> </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-divider></v-divider>
        <v-card>
            <v-row no-gutters align="stretch" align-content="stretch" >
                <v-col cols='12' md="3">
                    <Justificacion 
                        v-if="!necesitaJustificacion" 
                        :botonIcono="true" 
                        titulo="Justificación Residuos Valorizables" 
                        tooltipText="Justificación Residuos Valorizables"
                        :esValorizable="true"
                        :reqEvidencias="true" :tipoDocumento="constantes.JUSTIF_REDUCCION_VALOR"
                    />
                    <div v-else :class="{'pt-3':!errores, 'pl-3':!errores}">
                        <v-alert v-if="!errores"
                            dense
                            text
                            type="success">
                            Reducci&oacute;n Alcanzada, Residuos Valorizables mayor o igual a 1%    
                        </v-alert>
                    </div>
                    <Justificacion 
                        v-if="!justificacionNoValorizables && anioConsulta > 2021"
                        :botonIcono="true" 
                        titulo="Justificación Residuos No Valorizables" 
                        tooltipText="Justificación Residuos No Valorizables"
                        :esValorizable="false"
                        :reqEvidencias="true" :tipoDocumento="constantes.JUSTIF_REDUCCION_NO_VALOR"
                    />
                    <div v-else :class="{'pt-3':!errores, 'pl-3':!errores}">
                        <v-alert v-if="!errores"
                            dense
                            text
                            type="success">
                            Reducci&oacute;n Alcanzada, Residuos No Valorizables mayor o igual a 1%    
                        </v-alert>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>

import axios from "@/axios.js";
import Justificacion from "@/components/utils/JustificacionComponent";
import DisposicionFinal from "@/components/parametros/GestiondeResiduos/DisposicionComponent";

export default {
    components: {
        Justificacion,
        DisposicionFinal
    },
    mounted(){
        this.getDataReduccion()
    },
     computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        tituloIndicador() {
            if(this.esValorizable)
                return "Residuos Valorizable";
            else
                return "Residuos No Valorizables";
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        cols() {
            return this.anioConsulta < 2022 ? 6 : 3;
        },
        colsBig() {
            return this.anioConsulta < 2022 ? 12 : 6;
        },
        showDisposicion() {
            return this.anioConsulta > 2021;
        }
     },
        created() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
        },
        data:()=>({
            valorizables: [],
            noValorizables: [],             
            periodoActual: null,
            periodoAnterior: null, 
            contenedorIndustrial : null,
            necesitaJustificacion: false, 
            justificacion: '',
            justificacionNoValorizables: false,
            errores: false
        }), 
        methods:{
            getDataReduccion() {
                let instalacion = this.instalacion.id ;            
                axios.get("/instalaciones/getDataReduccionResiduos/" + instalacion + "/" +this.anioConsulta)
                .then(response => {
                    this.valorizables = response.data.valorizables;
                    this.noValorizables = response.data.noValorizables;
                    this.periodoActual = response.data.periodoActual;
                    this.periodoAnterior = response.data.periodoAnterior;
                    this.necesitaJustificacion = response.data.necesitaJustificacion;
                    this.justificacionNoValorizables = response.data.justificacionNoValorizables;
                    this.loadingModal(false);
                    this.errores = false
                }).catch(error => {
                    this.errores = true
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al optener los datos " + error);   
                    this.loadingModal(false);   
                  }          
                })
            },
            reduccion(anterior, actual){
                return  (parseFloat(anterior) - parseFloat(actual)).toFixed(2);
            },
            contenedor(anterior, actual){
                let contenedor = ((parseFloat(anterior) - parseFloat(actual))/450).toFixed(2);
                return contenedor;
            },
            loadingModal(modal) {                        
                this.$store.dispatch('loadingPage', modal);
            },
            getJustificacion(){
                this.loading = true;
                axios.get("/instalaciones/obtenerJustificacion/"+this.indicadorActual+'/'+this.instalacion.id+'/'+this.anioConsulta+'/'+2 ).then(response =>{
                    this.justificacion = response.data.justificacion;
                    if(this.justificacion == 'null'){
                        this.justificacion = 'Agregue una justificación.'
                    }
                    this.errores = false
                }).catch(error => {
                    this.errores = true
                    if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                    } else {
                        console.log(error);
                        this.loading = false; 
                    }
                })
            },
            numberFormat(number) {
                return number.toLocaleString('es-CR');
            },
            numberColor(number) {
                if(number > 0) {
                    return 'green--text text--darken-1 font-weight-bold';
                }
                if(number < 0) {
                    return 'red--text text--lighten-1 font-weight-bold';
                } else {
                    return 'font-weight-bold';
                }
            }
        }
}
</script>
<style scoped>
    .altura {
        height: 100%;
    }    
</style>