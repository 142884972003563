<template>
  <v-card @click="enviarCatgoria">
    <v-img
      :src="logo"
    ></v-img>
    <v-card-text>
      <div class="text-center subtitle-1">Categoria</div>
      <div class="text-center body-1">{{ categoria.nombre }}</div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: [
    'categoria'
  ],
  computed: {
    logo() {
      return require('@/assets/' + this.categoria.logo)
    }
  },
  methods: {
    enviarCatgoria() {
      this.$emit('selectCategoria', this.categoria);
    }
  },
}
</script>