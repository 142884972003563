<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal titulo="Cuestionario Requisitos Legales"/>
            </v-col> 
        </v-row>
        <v-row>
            <v-col align-self="center">
                <v-btn href="http://apps.grupoice.com/PBAECC/recursos.xhtml" target="_blank" class="white--text" color="#0B3F67">Descargar Cuestionario</v-btn>
            </v-col>
            <v-col cols="3" align-self="center">                
                <Agregar :archivos="tieneArchivos" @reload="getData"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
                <v-text-field    
                    prepend-icon="mdi-magnify"                
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <Tabla @reload="getData" :search="search" :items="items"/>
    </v-container>
</template>
<script>

import Agregar from '@/components/parametros/InscripcionLegal/AgregarCuestionarioComponent'
import Tabla from '@/components/parametros/InscripcionLegal/TablaCuestionarioComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'

export default {
    components: {        
        TituloPrincipal,
        NavParameters,
        Logo,
        Tabla,
        Agregar
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed: {
        tieneArchivos() {
            return this.items.length ? true : false;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        }
    },
    mounted() {
        this.getData();
    },
    data: () => ({
        search: null,
        items: []
    }),
    methods: {
        getData() {
            axios.get('/instalaciones/listarCuestionario/' + this.instalacion.id + '/' + this.anioConsulta)
            .then(response => {
                this.items = response.data;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.error(error);
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>