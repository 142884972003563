<template>
  <v-toolbar color="#0B3F67" class="font-weight-medium white--text">
    <v-avatar class="mr-3" height="40" width="105" tile>
      <img src="@/assets/iconos/logoszurqui/blancoT25_logo_zurqui.png" contain alt="zurqui"/> 
    </v-avatar>
    <v-toolbar-title class="subtitle-1 white--text">{{ titulo }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-icon medium @click="cancelar" color="white">mdi-window-close</v-icon>
  </v-toolbar>
</template>
<script>
export default {
  props: [
    'titulo'
  ],
  methods: {
    cancelar() {
      this.$emit('cancelar')
    }
  }
}
</script>