<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <TituloPrincipal :titulo="'Categorias'"></TituloPrincipal>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="6" align-self="center">
        <crear-categoria @reload="getCategorias"/>
      </v-col>
      <v-col sm="12" md="4" offset-md="2">
        <v-text-field
          label="Buscar"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="categorias"
          class="elevation-1"
          item-key="id"
          :search="search"
          no-results-text="No hay datos"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon:'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageText: 'Registros por página',
            itemsPerPageAllText: 'Todos',
            pageCount: 12,                       
            showCurrentPage: true,                            
          }">
          <template v-slot:item.action="{ item }">
            <v-layout justify-center>
              <configurar-categoria :categoria="item" :anios="aniosParticipacion"/>    
            </v-layout>
          </template>
          <template v-slot:item.logo="{ item }">
            <v-layout justify-center>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-avatar class="mr-3" size="40" tile v-on="on">
                    <img :src="logoSrc(item.logo)" contain alt="zurqui"/>
                  </v-avatar>
                </template>
                {{ item.logo }}
              </v-tooltip>
            </v-layout>
          </template>
          <template v-slot:item.parametros="{ item }">
            <v-layout justify-center>
              <parametros-categoria :categoria="item" :anios="aniosParticipacion"/>
            </v-layout>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ConfigurarCategoria from '@/components/categorias/ConfigurarCategoriaComponent'
import ParametrosCategoria from '@/components/categorias/ParametrosCatComponent'
import CrearCategoria from '@/components/categorias/AgregarCategoriaComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import axios from '@/axios'
export default {
  components: { 
    CrearCategoria, 
    TituloPrincipal, 
    ParametrosCategoria, 
    ConfigurarCategoria 
  },
  created() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
  },
  mounted() {
    this.getCategorias();
  },
  computed: {
    aniosParticipacion() {
      let inicio = 2018
      let final = new Date().getFullYear() + 1
      let anios = []
      for (let index = inicio; index <= final; index++) {
        anios.push(index);
      }
      return anios
    },
  },
  data: () => ({
    categorias: [],
    search: null,
    headers: [
      {
        text: 'Acciones',
        value: 'action',
        align: 'center',
        width: '10%',
        sortable: false
      },
      {
        text: 'Nombre',
        value: 'nombre',
        align: 'center',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Logo',
        value: 'logo',
        align: 'center',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Parámetros',
        value: 'parametros',
        align: 'center',
        sortable: true,
        filterable: true,
      },
    ]
  }),
  methods: {
    getCategorias() {
      this.loadingModal(true);
      axios.get("/categoria/listarCategorias").then(response => {
        this.categorias = response.data;   
        this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
        console.log(error);
        this.loadingModal(false);
        }
      })
    },
    logoSrc(logo) {
      return require("@/assets/" + logo);
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  },
}
</script>