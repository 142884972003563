var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.rolUsuario != 'CEO' && 
              _vm.rolUsuario != 'GERENTE' && 
              _vm.rolUsuario != 'SUPERVISOR' &&
              _vm.tipo <= 9)?_c('v-row',[_c('v-col',[(!_vm.estrellaVerde && 
                      _vm.rolUsuario != 'CEO' && 
                      _vm.rolUsuario != 'GERENTE' && 
                      _vm.rolUsuario != 'SUPERVISOR' &&
                      _vm.tipo <= 9)?_c(_vm.botonAgregar,{tag:"component",attrs:{"titulo":'Agregar ' + _vm.titulo,"tipo":_vm.tipo},on:{"recargaTabla":_vm.listaEstrellas}}):_vm._e()],1)],1):_vm._e(),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[(_vm.estrellaVerde)?_c('v-sheet',{staticClass:"v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center",attrs:{"height":_vm.alturaHoja(),"color":"#0B3F67","elevation":"12","max-width":"calc(100% - 32px)"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_vm._v(_vm._s(_vm.tituloTabla))])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.cargando,"multi-sort":"","search":_vm.search,"headers":_vm.headers,"items":_vm.items,"no-data-text":"Sin datos","no-results-text":"Dato no encontrado","footer-props":{
                        showFirstLastPage: true,
                        firstIcon:'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageAllText: 'Todos',
                        showCurrentPage: true,
                        showFirstLastPage: true
                    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && 
                                  _vm.rolUsuario != 'GERENTE' && 
                                  _vm.rolUsuario != 'SUPERVISOR')?_c(_vm.botonModificar,{tag:"component",attrs:{"titulo":_vm.titulo,"tipo":_vm.tipo,"id":item.id},on:{"recargaTabla":_vm.listaEstrellas}}):_vm._e(),(_vm.rolUsuario != 'CEO' &&
                                  _vm.rolUsuario != 'GERENTE' &&
                                  _vm.rolUsuario != 'SUPERVISOR' &&
                                  _vm.tipo != 9)?_c('AgregarEvidencia',{attrs:{"idActividad":item.id,"origen":'estrellas',"tipo":_vm.tipo,"tooltipText":"Agregar Evidencias","titulo":'Agregar Evidencias'}}):_vm._e(),(_vm.tipo <= 9)?_c('Eliminar',{attrs:{"requestData":_vm.requestDataFormat(item),"requestUrl":_vm.urlEliminar},on:{"reload":_vm.reloadData}}):_vm._e()]}},{key:"item.categoria",fn:function(ref){
                                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tipo === 3 ? item.categoriaCadena : item.categoriaComunidad)+" ")]}},{key:"item.fecha",fn:function(ref){
                                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tipo === 2 ? _vm.dateFormat(item.fechaEjecucion) : _vm.tipo === 3 || _vm.tipo === 4 ? _vm.dateFormat(item.fechaActividad) : _vm.tipo === 5 || _vm.tipo === 6 ? _vm.dateFormat(item.fechaCuantificacion) : _vm.dateFormat(item.fecha))+" ")]}},{key:"item.evidencias",fn:function(ref){
                                  var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"medidorId":_vm.tipo,"titulo":'Evidencias',"origen":'estrellas'}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }