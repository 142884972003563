<template>
    <v-dialog
        v-model="dialog"   
        scrollable     
        persistent
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                    
                    <v-btn large dark icon v-on="{ ...tooltip, ...dialog }"><v-icon color="primary">mdi-table-row-plus-before</v-icon></v-btn>
                </template>
                Agregar Consumo
            </v-tooltip>  
        </template>
        <v-card :loading="cargando">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Consumo" @cancelar="cancelar"/>
            </v-card-title>           
            <v-divider></v-divider>            
            <v-card-text>
                <v-row>
                    <v-col cols="12">                    
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date"
                                    label="Mes y Año del consumo"
                                    prepend-inner-icon="mdi-calendar-month"
                                    hint="Seleccione el año y mes del registro"
                                    :error-messages="dateErrors"             
                                    @change="$v.date.$touch()"
                                    @blur="$v.date.$touch()"
                                    required
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" reactive :min="fechaMinima" :max="fechaMaxima" :allowed-dates="mesesDisponibles" locale="es-CR" type="month" @input="menu = false"></v-date-picker>
                        </v-menu>
                        <v-text-field
                            label="Consumo"
                            v-model="historial.consumo"
                            :error-messages="consumoErrors"             
                            @change="analizaConsumo"
                            @blur="$v.historial.consumo.$touch()"
                            required
                        ></v-text-field>
                        <v-text-field
                            label="Costo"
                            v-model="historial.costo"
                            hint="Agregar monto total de la factura"
                            :error-messages="costoErrors"             
                            @change="$v.historial.costo.$touch()"
                            @blur="$v.historial.costo.$touch()"
                            required
                        ></v-text-field>
                        <v-text-field
                            label="Eficiencia"
                            v-model="historial.indiceEficiencia"
                            hint="Ingresar dato de eficiencia relacionado e este indicador"
                            :error-messages="eficErrors"             
                            @change="$v.historial.indiceEficiencia.$touch()"
                            @blur="$v.historial.indiceEficiencia.$touch()"
                            required
                        ></v-text-field>
                        <v-file-input
                            accept=".png, .jpeg, .jpg"
                            v-if="!indicCombustible && !indicResiduos"
                            label="Adjuntar Recibo"
                            v-model="recibo"
                            show-size
                            :error-messages="recibErrors"
                            @change="$v.recibo.$touch()"
                            @blur="$v.recibo.$touch()"
                            counter>
                        </v-file-input>
                        <v-textarea v-if="alerta"
                            label="Justificación"
                            v-model="historial.justificacion"
                            outlined
                            shaped
                            :error-messages="justifErrors"             
                            @change="$v.historial.justificacion.$touch()"
                            @blur="$v.historial.justificacion.$touch()"
                            required>
                        </v-textarea>                        
                        <v-alert type="info" :value="alerta">                            
                            Este consumo excede el <strong> promedio del año anterior,</strong> 
                            por favor ingrese una justificación. 
                            <br> <strong> Consumo promedio año anterior {{consumoAnterior}} </strong>
                            <br> <strong> Excedente {{excedente}}% </strong>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="cargando" @click="cancelar()" color="error">Cancelar</v-btn>
                <v-btn text :loading="cargando" @click="agregarHistorial()" color="#0B3F67">Agregar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}

export default {
    props: [
        'medidor',
        'anioConsulta',
        'consumos',
        'consumoAnterior'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        fechaMinima() {
            return this.anioConsulta + '-' + '01';
        },
        fechaMaxima() {
            return this.anioConsulta + '-' + '12';
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        indicCombustible() {
            return this.indicadorActual === this.constantes.indicadorCombustibles ? true : false;
        },
        indicResiduos() {
            return this.indicadorActual === this.constantes.indicadorResiduos ? true : false;
        },        
        constantes() {
            return this.$store.getters.constantes;
        },
        consumoErrors () {
            const errors = []
            if (!this.$v.historial.consumo.$dirty) return errors
            !this.$v.historial.consumo.required && errors.push('Dato requerido')
            !this.$v.historial.consumo.decimal && errors.push('Solo ingrese números')
            return errors
        },
        costoErrors () {
            const errors = []
            if (!this.$v.historial.costo.$dirty) return errors
            !this.$v.historial.costo.required && errors.push('Dato requerido')
            !this.$v.historial.costo.decimal && errors.push('Solo ingrese números')
            return errors
        },
        dateErrors () {
            const errors = []
            if (!this.$v.date.$dirty) return errors
            !this.$v.date.required && errors.push('Dato requerido')
            return errors
        },
        justifErrors () {
            const errors = []
            if (!this.$v.historial.justificacion.$dirty) return errors
            !this.$v.historial.justificacion.required && errors.push('Dato requerido')
            return errors
        },
        eficErrors () {
            const errors = []
            if (!this.$v.historial.indiceEficiencia.$dirty) return errors
            !this.$v.historial.indiceEficiencia.required && errors.push('Dato requerido')
            !this.$v.historial.indiceEficiencia.decimal && errors.push('Solo ingrese números')
            return errors
        },
        recibErrors () {
            const errors = []
            if (!this.$v.recibo.$dirty) return errors
            !this.$v.recibo.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        historial: {
            mes: '',
            anio: '',
            consumo: '',
            costo: '',
            indiceEficiencia: '',
            justificacion: ''
        },
        recibo: null,
        date: '',
        modal: false,
        menu: false,
        cargando: false,
        alerta: false,        
        excedente: null
    }),
    validations: {
        historial: {
            consumo: { required, decimal },
            costo: { required, decimal },
            indiceEficiencia: { required, decimal },
            justificacion: { required: requiredIf(function(){
        	        return this.alerta
                })
            },
        },
        date: { required },
        recibo: { maxFileSize }
    },
    methods: {
        agregarHistorial() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.cargando = true;
                let fecha = this.date.split("-");
                this.historial.mes = fecha[1];
                this.historial.anio = fecha[0];
                let formData = new FormData();
                formData.append('recibo', this.recibo);
                formData.append('idMedidor', this.medidor.id);
                formData.append('indicador', this.$store.getters.indicadorActual);
                formData.append('usuario', this.$store.getters.usuario.id);
                formData.append('instalacion', this.$store.getters.sucursal.id);
                formData.append('consumo', JSON.stringify(this.historial));
                axios.post("/instalaciones/agregarConsumo", formData)
                .then(response => {
                    this.$store.dispatch('noti',{
                        mensaje: 'Consumo agregado', 
                        color:'success'
                    });
                    this.$emit('agregarHistorial');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se agregó el consumo', color:'error'}); 
                      this.cargando = false;
                    }
                  }
                })
            }
        },        
        cancelar() {
            this.historial = {};
            this.date = '';
            this.recibo = null;
            this.$v.historial.$reset();
            this.$v.date.$reset();
            this.$v.recibo.$reset();
            this.alerta = false;
            this.excedente = null;
            this.dialog = false;
            this.cargando = false;
        },
        mesesDisponibles(val) {
            return this.consumos.includes(parseInt(val.split('-')[1]));
        },
        analizaConsumo() {
            this.$v.historial.consumo.$touch();            
            let excedenteActual = this.historial.consumo - this.consumoAnterior;                        
            let data = this.consumoAnterior !== 0 ? ((parseFloat(excedenteActual) / parseFloat(this.consumoAnterior)) * 100) : 0;                                    
            this.excedente = Number.isInteger(data) ? data : data.toPrecision(2);            
            if((this.consumoAnterior != null || this.consumoAnterior != 0) && this.excedente >= 1){
                this.alerta = true;
            } else {
                this.alerta = false;
            }     
        }
    },
}
</script>