<template>
    <v-container fluid style="background-color: rgb(11, 63, 103)">
        <v-card raised>      
            <!-- Titulo Principal -->
            <v-card height="100%" outlined>
                <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                    Reducción en la Generación de {{ tituloIndicador }} <br>
                    AÑO {{ anioConsulta - 1 }} respecto al AÑO {{ anioConsulta }}                    
                </v-card-text>
            </v-card>        
            <!-- Contaminantes Atmosfericos --> 
            <v-row no-gutters align="stretch" align-content="stretch">
                <v-col cols="12" md="3" align-self="center" justify-self="center">
                    <v-card height="100%">
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                            Producto / Actividad
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3" align-self="center" justify-self="center">
                    <v-card height="100%">
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                        Consumo Anterior
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                            Consumo Actual
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                            Ahorro
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>                   
            <v-row v-for="data in datos" :key="data.nombre" no-gutters align="stretch" align-content="stretch">
                <v-col cols="3" align-self="stretch">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center">
                            {{ data.nombre }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="3" align-self="stretch">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center">
                            {{ data.consumoAnterior }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="3" align-self="stretch">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center">
                            {{ data.consumoActual }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="3" align-self="stretch">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center">
                            {{ `${data.porcentajeReduccion}%`}}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-divider></v-divider> 
        <v-card>
        <v-row no-gutters align="stretch" align-content="stretch" >
            <v-col cols='12' md="3">
                <Justificacion 
                    v-if="necesitaJustificacion" 
                    :botonIcono="true" 
                    titulo="Justificación Reducción" 
                    tooltipText="Justificación Reducción Gases Refrigerantes"
                    :reqEvidencias="true"
                    :tipoDocumento="tipoDocumento"
                />
                <div v-else :class="{'pt-3':!errores, 'pl-3':!errores}">
                    <v-alert v-if="!errores"
                        dense
                        text
                        type="success">
                        Reducci&oacute;n Alcanzada, mayor o igual a 1%    
                    </v-alert>
                </div>
            </v-col>
        </v-row>
        </v-card>
    </v-container>
</template>
<script>

import axios from "@/axios.js";
import Justificacion from "@/components/utils/JustificacionComponent";

export default {
    components: {
        Justificacion
    },
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        tituloIndicador() {
            return "Gases Refrigerantes"
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        tipoDocumento() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            let tipoDocumento = null;
            switch(indicador) {
                case constantes.indicadorEnergia:
                    tipoDocumento = constantes.JUSTIF_CONSUMO_ELECT;
                    break;
                case constantes.indicadorAgua:                                    
                    tipoDocumento = constantes.JUSTIF_CONSUMO_AGUA;
                    break;
                case constantes.indicadorCombustibles:
                    tipoDocumento = constantes.JUSTIF_CONSUMO_COMB;
                    break;
                case constantes.indicadorResiduos:
                    tipoDocumento = constantes.JUSTIF_CONSUMO_PAPEL;
                    break;
            }
            return tipoDocumento;
        }
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    props: [        
        'data'        
    ],
    mounted() {
        this.getDataReduccion(); 
    },
    data: () => ({               
        datos: [],
        necesitaJustificacion: false,
        justificacion: '',
        errores: false
    }),
    methods: {        
        getDataReduccion() {
            let instalacion = this.instalacion.id ;            
            axios.get("/historialConta/getDataReduccion/" + instalacion + "/"+this.anioConsulta)
            .then(response => {
                this.datos = response.data.historialConsumo;
                this.necesitaJustificacion = response.data.necesitaJustificacion;
                this.getJustificacion();
                this.loadingModal(false);
                this.errores = false
            }).catch(error => {
                this.errores = true
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al optener los datos " + error);        
                this.loadingModal(false);        
              }
            })
        },
        porcentajeAhorro(anterior, actual){
            let ahorro;
            let consumoTotal = anterior + actual;
            ahorro = consumoTotal != 0 ? 100 * ((anterior -  actual) / consumoTotal ) : 0;
            return ahorro > 0 ? ahorro.toPrecision(4) : 0;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getJustificacion(){
            this.loading = true;
            axios.get("/instalaciones/obtenerJustificacion/"+this.indicadorActual+'/'+this.instalacion.id+'/'+this.anioConsulta+'/'+7 ).then(response =>{
                this.justificacion = response.data.justificacion;
                if(this.justificacion == 'null'){
                   this.justificacion = 'Agregue una justificación.'
                }
                this.errores = false
            }).catch(error => {
                this.errores = true
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false; 
              }
            })
        },
    },
}
</script>
<style scoped>
    .altura {
        height: 100%;
    }    
</style>