<template>
    <v-data-table
        :headers="headers"
        :items="acciones"
        :search="buscar"
        hide-default-footer
        item-key="id"
        no-data-text="No hay Acciones para este periodo">

        <template v-slot:item.fechaActividad="{ item }">
            {{ dateFormat(item.fechaActividad) }}
        </template>
        <template v-slot:item.action="{ item }">
            <ModificarAccionReduccion 
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" 
                @reload="listarAccionReduccion" 
                v-bind:accion1="item" />
            <AgregarEvidencia
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                @reload="listarAccionReduccion"
                :idAccion="item.id"
                :origen="origen"
                tooltipText="Agregar Evidencias"
                :titulo="titulo"
            ></AgregarEvidencia>
            <v-tooltip top>
                <template v-slot:activator="{ on }" v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">                                        
                    <v-icon v-on="on" color="#0B3F67" @click="borrarAccion(item.id)">mdi-trash-can-outline</v-icon>
                </template>
                Eliminar
            </v-tooltip>            
        </template>
        <template v-slot:item.evidencia="{ item }">
            <ListadosDocs 
                :idActividad="item.id" 
                :idAccion="item.id" 
                origen="accionReduccion" 
                :titulo="titulo">
            </ListadosDocs>
        </template>
    </v-data-table>
</template>
<script>

import moment from "moment"
import ModificarAccionReduccion from "@/components/parametros/AccionReduccion/ModificarAccionReduccionComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import axios from "@/axios.js";

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
        ModificarAccionReduccion,
        AgregarEvidencia,
        ListadosDocs
    },
    props: [
        'headers',
        'acciones',
        'buscar'
    ],
    mounted() {
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
    },
    data: () => ({
        titulo: "Documento(s) de Evidencia",
        origen: "accionReduccion"
    }),
    methods: {
        dateFormat(fecha) {
            return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        borrarAccion(id) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar la acción de reducción y control?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    this.cargando = true;
                    axios.delete("/accionReduccion/borrarAccion/" + id, {params: {idUsuario: this.usuario.id, parametroActual: this.parametroActual}}).then(response => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Acción de reducción eliminada",
                            color: "success"
                        });
                        this.cargando = false;
                        this.$emit('reload');
                        })
                        .catch(error => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Ocurrió un error no se eliminó la acción de reducción",
                            color: "error"
                        });
                        console.log(error);
                        this.cargando = false;
                        });
                }
            })
        },
        listarAccionReduccion(){
            this.$emit('reload');
        },
    },
}
</script>