var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('TituloPrincipal',{attrs:{"titulo":'Categorias'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6","align-self":"center"}},[_c('crear-categoria',{on:{"reload":_vm.getCategorias}})],1),_c('v-col',{attrs:{"sm":"12","md":"4","offset-md":"2"}},[_c('v-text-field',{attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.categorias,"item-key":"id","search":_vm.search,"no-results-text":"No hay datos","footer-props":{
          showFirstLastPage: true,
          firstIcon:'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          itemsPerPageText: 'Registros por página',
          itemsPerPageAllText: 'Todos',
          pageCount: 12,                       
          showCurrentPage: true,                            
        }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('configurar-categoria',{attrs:{"categoria":item,"anios":_vm.aniosParticipacion}})],1)]}},{key:"item.logo",fn:function(ref){
        var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-3",attrs:{"size":"40","tile":""}},on),[_c('img',{attrs:{"src":_vm.logoSrc(item.logo),"contain":"","alt":"zurqui"}})])]}}],null,true)},[_vm._v(" "+_vm._s(item.logo)+" ")])],1)]}},{key:"item.parametros",fn:function(ref){
        var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('parametros-categoria',{attrs:{"categoria":item,"anios":_vm.aniosParticipacion}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }