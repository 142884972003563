var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"no-results-text":"No hay registros","footer-props":{
        showFirstLastPage: true,
        firstIcon:'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
        itemsPerPageText: 'Registros por página',
        itemsPerPageAllText: 'Todos',
        pageCount: 12,                       
        showCurrentPage: true,                            
    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref ){
    var item = ref.item;
return [_c('modificarPlanta',{attrs:{"id":item.id},on:{"reload":_vm.reload}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarAccion(item.id)}}},on),[_vm._v("mdi-trash-can-outline")])]}}],null,true)},[_vm._v(" Eliminar ")])]}},{key:"item.sistema",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tipoSistema(item.sistema))+" ")]}},{key:"item.propia",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sistemaPropio(item.propia ))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }