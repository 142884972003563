<template>
    <v-container fluid>
        <usuariosTabla></usuariosTabla>
    </v-container>
</template>

<script>

import UsuariosTabla from "@/components/usuarios/UsuariosTablaComponent";

export default {
    components: {
        UsuariosTabla
    },
};
</script>
