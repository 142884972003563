<template>    
    <v-switch :loading="loading" inset @change="activarInstalacion" color="#0B3F67" v-model="estadoActual"></v-switch>    
</template>
<script>

import axios from "@/axios.js";

export default {
    props: [
        'estado',
        'id'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        estadoActual: {
            get() {
                return this.estado;
            },
            set(estado) {
                this.nuevoEstado = estado;
            }
        }
    },
    data: () => ({
        nuevoEstado: null,
        loading: false
    }),
    methods: {
        activarInstalacion() {
            let insState;
            this.loading = true;
            axios.put("/instalaciones/activar/"+this.id, null, { params: {
                insState: this.nuevoEstado,
            }}).then(response => {
                this.loading = false;
                this.$emit('reload');
                this.$store.dispatch('noti',{mensaje: 'Estado modificado', color:'success'});
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el estado', color:'error'});
              }
            })
        }
    }
};
</script>
