<template>
    <v-dialog
        v-model="dialog"        
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">Agregar <v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Tipo de Enfoque" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            label="Nombre"
                            v-model="enfoque.nombre"
                            :error-messages="nameErrors"                                                              
                                @input="$v.enfoque.nombre.$touch()"
                                @blur="$v.enfoque.nombre.$touch()"
                                required                                                                
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-textarea
                            label="Descripción" outlined
                            shaped
                            counter="100"
                            v-model="enfoque.descripcion"
                            hint="Campo Opcional"                                                                                           
                        ></v-textarea>
                    </v-col> -->                      
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn> 
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarEnfoque"> Agregar </v-btn>
            </v-card-actions>
        </v-card>              
    </v-dialog>    
</template>

<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import {required, email} from "vuelidate/lib/validators";
import axios from "@/axios.js";
export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    components: {
      ModalTitle
    },
     computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.enfoque.nombre.$dirty) return errors            
            !this.$v.enfoque.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
     },       
     data: () => ({
        loading: false,
        dialog: false,
        enfoque: {
            nombre: '',
            //descripcion: '',
        }            
    }),
    validations: {
        enfoque: {
            nombre: {
                required,               
            },
        }
    },
    
    methods: 
    {         
        cancelar() {
            this.enfoque = {};
            this.dialog = false;          
            this.$v.enfoque.$reset();          
            this.loading = false;
        },        
        agregarEnfoque() {
            this.$v.$touch();
            if(!this.$v.$invalid) {   
                this.loading = true;             
                axios.post("/enfoque/agregarEnfoque", this.enfoque,                                       
                ).then(response => {
                    this.cancelar();
                    this.$emit('recargaTabla');
                    this.$store.dispatch('noti',{mensaje: 'Tipo de enfoque agregado', color:'success'}); 
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar el Enfoque: " + error);
                    this.cancelar();
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el tipo de enfoque', color:'error'});
                  }
                })
            }
        }
    },    
}
</script>