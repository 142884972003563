<template>
    <v-dialog
        v-model="dialog"        
        persistent
        max-width="625px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                                        
                    <v-btn large dark icon v-on="{ ...tooltip, ...dialog }"><v-icon color="#ffb605">mdi-pencil</v-icon></v-btn>
                </template>
                Modificar Consumo
            </v-tooltip>  
        </template>
        <v-card :loading="cargando">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="nombreGei" @cancelar="cancelar"/>
            </v-card-title>            
            <v-divider></v-divider>            
            <v-card-text>
                <v-row>
                    <v-col cols="12"> 
                        <v-col>
                            <v-select
                                v-model="producto"
                                :items="productos"
                                item-text="producto"
                                item-value="id"
                                label="Producto"
                                @input="$v.producto.$touch()"
                                @blur="$v.producto.$touch()"
                                @change="cambioHistorial"
                                return-object
                                :error-messages="productoError"
                                required
                            ></v-select>
                        </v-col>
                        <v-col>                     
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date"
                                        label="Mes y Año del consumo"
                                        prepend-inner-icon="mdi-calendar-month"
                                        hint="Seleccione el año y mes del registro"
                                        :error-messages="dateErrors"             
                                        @change="$v.date.$touch()"
                                        @blur="$v.date.$touch()"
                                        required
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="date" 
                                    @change="getHistorial" 
                                    :min="minDate" 
                                    :max="maxDate" 
                                    :allowed-dates="mesesDisponibles"
                                    locale="es-CR" 
                                    type="month" 
                                    @input="menu = false">
                                </v-date-picker>                            
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-text-field
                                :label="consumoDetalle"
                                v-model="historial.consumo"
                                :error-messages="consumoErrors"             
                                @change="analizaConsumo"
                                @blur="$v.historial.consumo.$touch()"
                                required
                            ></v-text-field>
                        </v-col>  
                        <v-col>
                            <v-text-field
                                label="Índice de eficiencia"
                                v-model="historial.indiceEficiencia"
                                :error-messages="indiceEficienciaError"             
                                @change="$v.historial.indiceEficiencia.$touch()"
                                @blur="$v.historial.indiceEficiencia.$touch()"
                                required
                            ></v-text-field>
                        </v-col>  
                        <v-col>
                            <v-textarea v-if="alerta || historial.justificacion"
                                label="Justificación"
                                v-model="historial.justificacion"
                                outlined
                                shaped
                                counter="200"
                                :error-messages="justifErrors"             
                                @change="$v.historial.justificacion.$touch()"
                                @blur="$v.historial.justificacion.$touch()"
                                required>
                            </v-textarea>  
                        </v-col>
                        <v-alert type="info" :value="alerta">                            
                            Este consumo excede el <strong> promedio del año anterior,</strong> 
                            por favor ingrese una justificación. 
                            <br> <strong> Consumo promedio año anterior {{consumoAnterior}} </strong>
                            <br> <strong> Excedente {{excedente}}% </strong>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="cargando" text @click="cancelar()" color="error">Cancelar</v-btn>
                <v-btn :loading="cargando" text @click="modificarHistorial()" color="#0B3F67">Modificar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>

import { required, requiredIf, decimal, maxLength } from "vuelidate/lib/validators";
import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'anioConsulta',
        'gei',
        'historiales',
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
        this.getProductosPorGei()
    },     
    components: {
      ModalTitle
    },
    computed: {
        consumoDetalle() {
            return this.producto ? 'Consumo (' + this.producto.presentacion + ')' : 'Consumo';
        },
        sucursal () {
            return this.$store.getters.sucursal;
        },
        usuarioId () {
            return this.$store.getters.usuario.id;
        },
        minDate() {
            let date = this.anioConsulta + '-' + '01';                        
            return date;
        },
        maxDate() {
            let date = this.anioConsulta + '-' + '12';                        
            return date;
        },
        productoError () {
            const errors = []
            if (!this.$v.producto.$dirty) return errors            
            !this.$v.producto.required && errors.push('El producto es requerido.')            
            return errors
        },
        consumoErrors () {
            const errors = []
            if (!this.$v.historial.consumo.$dirty) return errors
            !this.$v.historial.consumo.required && errors.push('Dato requerido')
            !this.$v.historial.consumo.decimal && errors.push('Consumo debe ser de valor numérico')
            return errors
        },
        dateErrors () {
            const errors = []
            if (!this.$v.date.$dirty) return errors
            !this.$v.date.required && errors.push('Dato requerido')
            return errors
        },
        justifErrors () {
            const errors = []
            if (!this.$v.historial.justificacion.$dirty) return errors
            !this.$v.historial.justificacion.required && errors.push('Dato requerido')
            !this.$v.historial.justificacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        indiceEficienciaError () {
            const errors = []
            if (!this.$v.historial.indiceEficiencia.$dirty) return errors
            !this.$v.historial.indiceEficiencia.required && errors.push('Dato requerido')
            !this.$v.historial.indiceEficiencia.decimal && errors.push('Índice dde eficiencia debe ser de valor numérico')

            return errors
        },
        nombreGei() {
            return this.gei === "1" ? "Modificar Consumo  Dióxido de carbono (CO2)" :
                   this.gei === "2" ? "Modificar Consumo  Metano (NH4)" : "Modificar Consumo  Óxido nitroso (N2O)";
        }
    },
    data: () => ({
        dialog: false,
        historial: {
            mes: '',
            anio: '',
            consumo: '',
            justificacion: '',
            indiceEficiencia: '',
        },
        date: '',
        consumoAnterior: 0,
        consumos: [],
        productos: [],
        producto: '',
        modal: false,
        menu: false,
        cargando: false,
        alerta: false,        
        excedente: null,
    }),
    validations: {
        historial: {
            consumo: { required, decimal },
            indiceEficiencia: {required, decimal},
            justificacion: { 
                maxLength: maxLength(200),
                required: requiredIf(
                function(){
        	        return this.alerta
                }) 
            },
        },
        producto: { required },
        date: { required }
    },
    methods: {
        analizaConsumo() {
            this.$v.historial.consumo.$touch();            
            let excedenteActual = this.historial.consumo - this.consumoAnterior;                        
            this.excedente = excedenteActual;       
            if((this.consumoAnterior != null || this.consumoAnterior != 0) && this.excedente >= 1){
                this.alerta = true;
            } else {
                this.alerta = false;
            }     
        },
        getProductosPorGei() {
            axios.get(`/productoContaAtmosf/getProductosGei/${this.sucursal.id}/${this.gei}/${this.anioConsulta}`)
            .then(response => {
                this.productos = response.data.productos;                
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los productos gases refrigerantes: " + error);
              }
            })
        },
        modificarHistorial() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.cargando = true;
                let idProducto = this.producto.id;
                let fecha = this.date.split("-");
                this.historial.mes = fecha[1];
                this.historial.anio = fecha[0];
                let indicador = this.$store.getters.indicadorActual;
                axios.put("/historialConta/modificarConsumo/" + idProducto + "/" + this.usuarioId, this.historial)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Consumo modificado', color:'success'}); 
                    this.$emit('modificarHistorial');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al modificar el consumo " + error);
                    this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se modificó el consumo', color:'error'}); 
                  }
                })
                this.cargando = false;
                this.cancelar();
            }
        },
        getHistorial() {
            this.cargando = true;
            let indicador = this.$store.getters.indicadorActual;
            axios.get("/historialConta/getConsumo/" +this.producto.id + "/" + this.date.split('-')[1] + "/"+this.date.split('-')[0] + "/")
            .then(response => {
                this.historial = response.data.consumo;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al buscar el consumo " + error);
                this.$store.dispatch('noti',{mensaje: 'Error buscando el consumo', color:'error'}); 
                this.cargando = false;
              }
            })        
        },  
        cancelar() {
            this.historial = {};
            this.date = '';
            this.producto = '';
            this.$v.historial.$reset();
            this.$v.producto.$reset();
            this.$v.date.$reset();
            this.dialog = false;
        },
        mesesDisponibles(val) {
            return this.consumos.includes(parseInt(val.split('-')[1]));
        },
        historialIndex() {
            return this.productos.findIndex(p => p.id === this.producto.id);
        },
        cambioHistorial() {
            this.mesesSinConsumo();
            this.consumoAnterior = this.historiales[this.historialIndex()].consumoAnterior;
        },
        mesesSinConsumo() {
            let meseSinCosumos = [];            
            let dat = new Map(Object.entries(this.historiales[this.historialIndex()]));
            dat.forEach(function(val, key) {                                
                if(parseFloat(val) > 0) {                                    
                    switch(key) {
                        case 'enero':
                            meseSinCosumos.push(1);
                            break;
                        case 'febrero':
                            meseSinCosumos.push(2);
                            break;
                        case 'marzo':
                            meseSinCosumos.push(3);
                            break;
                        case 'abril':
                            meseSinCosumos.push(4);
                            break;
                        case 'mayo':
                            meseSinCosumos.push(5);
                            break;
                        case 'junio':
                            meseSinCosumos.push(6);
                            break;
                        case 'julio':
                            meseSinCosumos.push(7);
                            break;
                        case 'agosto':
                            meseSinCosumos.push(8);
                            break;
                        case 'septiembre':
                            meseSinCosumos.push(9);
                            break;
                        case 'octubre':
                            meseSinCosumos.push(10);
                            break;
                        case 'noviembre':
                            meseSinCosumos.push(11);
                            break;
                        case 'diciembre':
                            meseSinCosumos.push(12);
                            break;
                    }
                }
            })
            this.consumos = meseSinCosumos;
        }
    },
}
</script>