<template>
  <v-dialog v-model="dialog" persistent max-width="1000px" transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn @click="getDocumentos" large dark icon v-on="{ ...tooltip, ...dialog }">
            <v-icon color="#0B3F67">mdi-file-multiple</v-icon>
          </v-btn>
        </template>
        Ver Archivos Adjuntos
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Evidencias" @cancelar="cerrar"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="documentos"
          item-key="id"
          no-data-text="No hay documentos adjuntos"
          :footerProps="footer"
          @reload="getDocumentos">
          <template v-slot:item.action="{ item }">
            <ModicafDoc 
              class="mr-2" 
              :documento="item.id"
              :inst="instalacion.id"
              @reload="getDocumentos"/>  
          </template>
          <template v-slot:item.fechaRegistro="{ item }">
              {{ dateFormat(item.fechaRegistro) }}
          </template>
          <template v-slot:item.documentoId="{ item }">      
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="#0B3F67" @click="descargarDocumento(item.documentoId)">mdi-cloud-download-outline</v-icon>
                  </template>
                  Descargar
              </v-tooltip>      
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cerrar" color="error">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ModicafDoc from "@/components/documentos/ModificarDocComponent"
import DocumentosTable from "@/components/utils/DataTableComponent";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
import moment from "moment"
export default {
  components: {
    DocumentosTable,
    ModalTitle,
    ModicafDoc
  },
  props: [
    "url"
  ],
  created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  },  
  computed: {
    constantes() {
      return this.$store.getters.constantes;
    },
    usuario(){
      return this.$store.getters.usuario;
    },
    indicadorActual() {
      return this.$store.getters.indicadorActual;
    },
    instalacion() {
      return this.$store.getters.sucursal;
    },
  },
  data: () => ({
    dialog: false,
    documentos: [],
    headers: [
      { 
        text: "Acciones",
        value: "action",
        align: "center",
        align: 'center',
        width: '20%',
        sortable: false
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
        sortable: true
      },
      {
        text: "Origen",
        value: "origen",
        align: "center",
        sortable: true
      },
      {
        text: "Fecha Registro",
        value: "fechaRegistro",
        align: "center",
        sortable: true
      },
      {
        text: "Archivo",
        align: "center",
        sortable: false,
        value: "documentoId"
      }
    ],
    footer: {
      disableItemsPerPage: true,
      showFirstLastPage: true,
      firstIcon: "mdi-page-first",
      lastIcon: "mdi-page-last",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageText: "Registros por página",
      itemsPerPageAllText: "Todos",
      showCurrentPage: true
    }
  }),
  methods: {
    getDocumentos() {
      this.$store.dispatch("loadingPage", true);
      axios.get(this.url).then(response => {
        this.documentos = response.data.documentos;
        this.$store.dispatch("loadingPage", false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log("Error en la carga de documentos " + error);
          this.$store.dispatch("loadingPage", false);
        }
      });
    },
    descargarDocumento(documentoId) {
      axios({
          url: '/documento/descargar/' + documentoId,
          method: 'GET',
          responseType: 'blob',
      }).then((response) => {
          const nombre = response.headers.filename;
          
          const fileURL = URL.createObjectURL(new Blob([response.data], {
              type: response.data.type
          }))
          
          let fileLink = document.createElement('a');            
          fileLink.href = fileURL;
          fileLink.setAttribute('download', nombre);
          document.body.appendChild(fileLink);            
          fileLink.click();
      });
    },
    dateFormat(fecha) {
      return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
    },
    erroneo(error){
        
    },
    cerrar() {
      this.documentos = [];
      this.dialog = false;
      this.$store.dispatch("loadingPage", false);
    }
  }
};
</script>
