<template>
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-btn icon>
            <v-icon color="white" dark v-on="on">mdi-logout</v-icon>
        </v-btn>            
      </template>
      <v-card>
        <v-card-title class="headline">Desea Cerrar Sesión?</v-card-title>        
        <v-card-text>Cerrar la sesión activa</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="#0B3F67" text @click="cerrarSesion">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>

import axios from 'axios'

export default {
    data: () => ({
        dialog: false
    }),
    methods: {      
        cerrarSesion() {
          let idUsuario = this.$store.getters.usuario.id;
          axios.get(process.env.BASE_URL+"api/user/auth/logout/" + idUsuario).then(response => {
            this.$store.commit('sucursal', {});
            this.$store.commit('usuario', {});
            this.$store.commit('organizacion', {});
            this.$store.commit('modificarToken', null);   
            this.$store.commit('inscripcion', {}); 
            this.$store.commit('fechaInscripcion', {}); 
            this.$store.commit("historialEstrella", {});        
            this.dialog = false;
            this.$router.push('/');
          }).catch(error => {
            console.error(error);
            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error, consulte al administrador', color: 'error'});
          })
        }
    },
}
</script>