<template>
    <v-dialog
        v-model="dialog" 
        persistent
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
          <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">
              <v-btn @click="getData" icon v v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Modificar Medida
          </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Acción de Prevención" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                      <v-text-field
                        label="Nombre Actividad"
                        counter="100"
                        v-model="accion.tipoActividad"
                        :error-messages="tipoErrors"             
                        @change="$v.accion.tipoActividad.$touch()"
                        @blur="$v.accion.tipoActividad.$touch()"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                      <v-textarea
                        rows="3"
                        label="Descripción"
                        counter="500"
                        v-model="accion.descripcion"
                        :error-messages="descErrors"             
                        @change="$v.accion.descripcion.$touch()"
                        @blur="$v.accion.descripcion.$touch()"
                        required
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                        type="number"
                        label="Cantidad de Actividades"
                        v-model="accion.cantidadActividades"
                        :error-messages="cantErrors"             
                        @change="$v.accion.cantidadActividades.$touch()"
                        @blur="$v.accion.cantidadActividades.$touch()"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="fechaMoment"
                                label="Fecha de la Actividad"
                                prepend-inner-icon="mdi-calendar-month"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarAccion">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import moment from "moment"
import { required, maxLength, minValue } from "vuelidate/lib/validators"
import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  props: [
    'idAccion'
  ],
  components: {
      ModalTitle
    },
  created() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
  }, 
  computed: {
    fechaMoment() {
      return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
    },
    tipoErrors () {
      const errors = []
      if (!this.$v.accion.tipoActividad.$dirty) return errors
      !this.$v.accion.tipoActividad.required && errors.push('Campo requerido')
      !this.$v.accion.tipoActividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
      return errors
    },
    descErrors () {
      const errors = []
      if (!this.$v.accion.descripcion.$dirty) return errors
      !this.$v.accion.descripcion.required && errors.push('Campo requerido')
      !this.$v.accion.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
      return errors
    },
    cantErrors () {
      const errors = []
      if (!this.$v.accion.cantidadActividades.$dirty) return errors
      !this.$v.accion.cantidadActividades.minValue && errors.push('Minimo 1 actividad')
      return errors
    },
    indicadorActual() {
      return this.$store.getters.indicadorActual;
    },
    usuario(){
      return this.$store.getters.usuario;
    },
    instalacion() {
      return this. $store.getters.sucursal;
    }
  },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        mask: '####',
        accion: {
            tipoActividad: '',
            descripcion: '',
            cantidadActividades: 0,
            fechaActividad: new Date().toISOString().substr(0, 10),
        },
        fechaActividad: {
          required
        },
    }),
    validations: {
        accion: {
            tipoActividad: {
                maxLength: maxLength(200),
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            cantidadActividades: {
                minValue: minValue(1)
            },
            fechaActividad: {
                required
            },
        },
    },
    methods: {
      getData() {
        this.loadingModal(true);
        axios.get(`/limpieza/getDataLimpieza/${this.idAccion}`)
        .then(response => {
          this.accion = response.data.accion;
          this.fechaActividad = new Date(response.data.fechaActividad).toISOString().substr(0, 10);
        }).catch(err => {
          console.log(err);
          this.$store.dispatch('noti', {mensaje: 'Ocurrió un error al buscar los datos.', color:'error'});  
        }).finally(() => {
          this.loadingModal(false);
        })
      },
      modificarAccion() {
        this.$v.$touch();
        if(!this.$v.$invalid) {
          this.loading = true;
          axios.post("/limpieza/modificarAccion", null, {params: {
            'accion': this.accion.id,
            'tipoActividad': this.accion.tipoActividad,
            'descripcion': this.accion.descripcion,
            'cantidad': this.accion.cantidadActividades,
            'fechaActividad': this.fechaActividad,
            'instalacion': this.instalacion.id,
            'usuario': this.usuario.id
          }})
          .then(response => {
            this.$store.dispatch('noti',{mensaje: 'Medida de limpieza agregada', color:'success'});   
            this.$emit('reload');
            this.cancelar();
          }).catch(error => {
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log(error);
              this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la medida de limpieza', color:'error'});  
              this.cancelar();      
            }      
          })
        }
      },
      cancelar() {
        this.accion = {};
        this.accion.fechaActividad = new Date().toISOString().substr(0, 10);
        this.accion.cantidadActividades = 0;
        this.archivos=[];
        this.$v.accion.$reset();    
        this.dialog = false;
        this.loading = false;
      },
      loadingModal(modal) {                        
        this.$store.dispatch('loadingPage', modal);
      },
    },
}
</script>