<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="listarAcciones" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal titulo="Acciones de Primer o Segundo Orden"/>
                <AccionesPoSTabla ref="acciones" :headers="headers" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import AccionesPoSTabla from "@/components/parametros/AccionesPrimerSegundo/AccionesPoSTablaComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    mounted() {        
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    components:{
        TituloPrincipal,
        AccionesPoSTabla,
        NavParameters,
        Logo
    },
    props: [
        'navParams'
    ],
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        }
    },
    data: ()=>({
         headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                class: "educTable",
            },
            {
                text: "Tipo de Actividad",
                value: "tipoActividad",
                align: "center",
                class: "educTable",
            },
            {
                text: "Descripción de la Actividad",
                value: "descripcion",
                align: "start",
                class: "educTable",
            },
            {
                text: "Fecha de la Actividad",
                value: "fechaActividad",
                align: "center",
                class: "educTable",
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                class: "educTabla",
            }

        ],
    }),
    methods: {
        listarAcciones() {
            this.$refs.acciones.getDataPorAnio();
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>