<template>
  <v-row justify="center">
    <v-col cols="1" align-self="center">
      <span class="headline">{{ estrellasBlancas }}</span>
    </v-col>
    <v-col cols="2" align-self="center" class="pa-0">
      <v-rating background-color="#000000" length="1" readonly></v-rating>
    </v-col>
    <v-col cols="2" align-self="center" class="pa-0" v-if="certificacion > 6">
        <v-rating background-color="#4CAF50" color="#4CAF50" :value="1" length="1" readonly></v-rating>
    </v-col> 
    <v-col cols="2" align-self="center" class="pa-0" v-if="certificacion > 7">
        <v-rating background-color="#9E9E9E" color="#9E9E9E" :value="1" length="1" readonly></v-rating>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: [
    'certificacion',
    'tipo'
  ],
  computed: {
    estrellasBlancas() {
      return this.certificacion > 6 ? 6 : this.certificacion;
    }
  },
  data: () => ({
    rating: ''
  })
}
</script>
