<template>
  <v-container fluid>
    <v-app-bar
      app
      fixed
      extended
      extension-height="15">
      <v-list-item>    
        <v-avatar class="mr-3 mt-2" height="72" width="200" tile>  
          <img src="@/assets/logo_zurquipng.png">     
        </v-avatar>
      </v-list-item> 

      <v-spacer></v-spacer>

      <v-btn 
        color="#0B3F67" 
        text
        @click="$vuetify.goTo('#carrusel', options)">
        Inicio
      </v-btn>

      <v-btn color="#0B3F67" text
        href="https://saludocupacionalpa3.wixsite.com/zurquiv01"
        target="_blank">
        Blog
      </v-btn>

      <v-btn 
        color="#0B3F67" 
        text
        @click="$vuetify.goTo('#contactenos', options)">
        Contáctenos
      </v-btn>

      <login />
    </v-app-bar>
    <v-main>
      <SocialMedia v-sticky="{ zIndex: 5, stickyTop: topSticky}"/>
      <section>
        <div id="carrusel">
          <Carousel/>
        </div>
      </section>
      <section>
        <div id="logoBandera">
          <Logo-bandera/>
        </div>
      </section>
      <v-divider></v-divider>
      <section>
        <div id="logoZurqui">
          <Logo-zurqui/>
        </div>
      </section>
      <v-divider></v-divider>
      <section>
        <div id="contactenos">
          <Contactenos/>
        </div>
      </section>
      <WhatsApp/>
    </v-main>    
    <v-footer 
      app
      color="#0B3F67"
      padless>
      <v-col
        class="text-center white--text"
        cols="12">
      <span>&#169;{{new Date().getFullYear()}} | <strong>EPROM</strong> Costa Rica. Todos los Derechos Reservados.</span>
      </v-col>
    </v-footer>
  </v-container>
</template>
<script>
import VueSticky from 'vue-sticky'
import Login from '@/components/auth/LoginComponent'
import WhatsApp from '@/components/utils/WhatsAppComponent'
import Carousel from '@/components/utils/HomeCarouselComponent'
import Contactenos from '@/components/utils/ContactenosComponent'
import SocialMedia from '@/components/utils/SocialMediaComponent'
import LogoBandera from '@/components/utils/BanderaLogoComponent'
import LogoZurqui from '@/components/utils/LogoZurquiComponent'
export default {
  components: {
    Login,
    Contactenos,
    SocialMedia,
    Carousel,
    WhatsApp,
    LogoBandera,
    LogoZurqui
  },
  directives: {
    'sticky': VueSticky,
  },
  computed: {
    topSticky() {
      switch(this.$mq) {
        case 'xs':
        case 'sm':
          return 83
        case 'md':
          return 170
        default:
          return 170
      }
    }
  },
  data: () => ({
    options: {
      duration: 500,
      easing: 'easeInCubic'
    }
  })
}
</script>