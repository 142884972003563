<template>    
    <v-switch :loading="loading" inset @change="activarOrga" color="#0B3F67" v-model="estadoActual"></v-switch>    
</template>
<script>

import axios from "@/axios.js";

export default {
    props: [
        'cluster',
        'id'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },   
    computed: {
        estadoActual: {
            get() {
                return this.cluster;
            },
            set(cluster) {
                this.nuevoEstado = cluster;
            }
        }
    },
    data: () => ({
        nuevoEstado: null,
        loading: false
    }),
    methods: {
        activarOrga() {
            this.loading = true;
            axios.put("/organizacion/activarCluster/"+this.id, null, { params: {
                newEstado: this.nuevoEstado,
            }}).then(response => {
                this.loading = false;
                this.$store.dispatch('noti',{mensaje: 'Cluster modificado', color:'success'}); 
                this.$emit('reload');
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el cluster', color:'error'});
                this.loading = false;
              }
            })
        }
    }
};
</script>
