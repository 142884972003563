<template>

    <v-dialog
            v-model="dialog"        
            persistent :overlay="true"
            max-width="600px"
            transition="dialog-transition"
        >
            <template v-slot:activator="{ on }">
                <v-btn color="#0B3F67" dark v-on="on">Agregar <v-icon right>mdi-plus-box-outline</v-icon></v-btn>
            </template>
            <v-card :loading="loading">
                <v-card-title class="pa-0">
                  <ModalTitle titulo="Agregar" @cancelar="cancelar"/>
                </v-card-title> 
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field 
                                label="Nombre"
                                v-model="suministros.nombre"
                                :error-messages="nameErrors"                                                              
                                 @input="$v.suministros.nombre.$touch()"
                                 @blur="$v.suministros.nombre.$touch()"
                                 required                                                                
                            ></v-text-field>
                        </v-col>                                           
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn> 
                    <v-btn text :loading="loading" color="#0B3F67" @click="AgregarSuministros"> Agregar </v-btn>
                </v-card-actions>
        </v-card>              
    </v-dialog>    
</template>

<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import {required, email} from "vuelidate/lib/validators";
import axios from "@/axios.js";
export default {
    computed: {
      nameErrors () {
          const errors = []
          if (!this.$v.suministros.nombre.$dirty) return errors            
          !this.$v.suministros.nombre.required && errors.push('El nombre es requerido.')
          return errors
      },
    },       
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
     data: () => ({
        loading: false,
        dialog: false,
        suministros: 
        {
            nombre: '',        
        }            
    }),
    validations: {
        suministros: {
            nombre: {
                required,               
            },
        }
    },
    
    methods: 
    {         
        cancelar() {
            this.suministros = {};
            this.dialog = !this.dialog;           
            this.$v.suministros.$reset();          
            this.loading = false;
        },        
        AgregarSuministros() {
            this.$v.$touch();
            if(!this.$v.$invalid) {  
                this.loading = true;              
                axios.post("/suministros/agregarSuministros", this.suministros,                                       
                ).then(response => {
                    this.cancelar();
                    this.$emit('recargaTabla');
                     this.$store.dispatch('noti',{mensaje: 'Categoría de cadena de suministros agregada', color:'success'}); 
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar el Enfoque: " + error);
                    this.cancelar();
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la categoría de cadena de suministros', color:'error'});
                    this.dialog = !this.dialog;
                  }
                })
            }
        }
    },    
}
</script>