<template>
  <v-container fluid>
    <TituloPrincipal titulo="Asignación de Puntos por Año"/>
    <v-row>
      <v-col cols="12" md="3">
        <v-select
          :items="anios"
          v-model="anio"
          label="Año"
          :error-messages="anioErrors"
          @change="$v.anio.$touch()"
          @blur="$v.anio.$touch()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="categorias"
          item-text="nombre"
          item-value="id"
          v-model="categoria"
          label="Categoría"
          :error-messages="catErrors"
          @change="$v.categoria.$touch()"
          @blur="$v.categoria.$touch()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" align-self="center">
        <v-btn 
          color="#0B3F67" 
          class="white--text"
          @click="buscarPuntaje"
        >Buscar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ListadoPuntaje @modificado="buscarPuntaje" :puntos="puntajes" :categoria="categoria"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ListadoPuntaje from '@/components/puntaje/ListadoPuntajeAnioComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import { required } from 'vuelidate/lib/validators'
import axios from '@/axios'
export default {
  components: {
    TituloPrincipal,
    ListadoPuntaje
  },
  created() {
    axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  },
  mounted() {
    this.getData();
  },
  computed: {
    anioErrors() {
      const errors = [];
      if (!this.$v.anio.$dirty) return errors;
      !this.$v.anio.required &&
        errors.push("El año es requerido.");
      return errors;
    },
    catErrors() {
      const errors = [];
      if (!this.$v.categoria.$dirty) return errors;
      !this.$v.categoria.required &&
        errors.push("La categoria es requerida.");
      return errors;
    },
  },
  data: () => ({
    anios: [],
    anio: null,
    categorias: [],
    categoria: null,
    indicadores: [],
    indicador: null,
    puntajes: []
  }),
  validations: {
    anio: { required },
    categoria: { required }
  },
  methods: {
    getData() {
      axios.get('/manejoAnios/getDataPuntos').then(
        response => {
          this.anios = response.data.anios;
          this.indicadores = response.data.indicadores;
          this.categorias = response.data.categorias;
        }
      ).catch(
        err => {
          console.error(err);
        }
      )
    },
    buscarPuntaje() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.loadingModal(true);
        axios.get(`/manejoAnios/puntajeCategoriaAnio/${this.categoria}/${this.anio}`).then(
          response => {
            this.puntajes = response.data
          }
        ).catch(
          err => {
            console.error(err);
          }
        ).finally(() => {
          this.loadingModal(false);
        })
      }
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    }
  },
}
</script>