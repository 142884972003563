<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="750px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
          <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">
              <v-btn @click="getDataPotabilidad" icon v v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Modificar Constancia
          </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Constancia" @cancelar="limpiar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>                            
                <v-row class="mt-2">                    
                    <v-col cols="5" class="text-right subtitle-1 pb-0" align-self="center">
                        Año que Aplica la Constancia    
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" md="5" class="pt-0 pb-0">                                                                                                    
                        <v-select
                            :items="anios"
                            v-model="potabilidad.anio"
                            label="Año"
                            :error-messages="anioErrors"             
                            @change="verificarAnio"
                            disabled
                            @blur="$v.potabilidad.anio.$touch()"
                        ></v-select>                                           
                    </v-col>                    
                    <v-col cols="12" md="8" offset-md="2" class="pa-0">
                        <v-alert dense type="error" v-model="alert">
                            Ya existe una constancia para este año.
                        </v-alert>     
                    </v-col>
                </v-row>
                <v-row>                    
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        Fuente de Abastecimiento       
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" class="pt-0 pb-0">                                                                                                 
                        <v-radio-group v-model="potabilidad.fuenteAbastecimiento"
                            :error-messages="fuenteErrors"
                            @change="$v.potabilidad.fuenteAbastecimiento.$touch()">
                            <v-radio label="Externo" :value="0"></v-radio>
                            <v-radio label="Interno" :value="1"></v-radio>
                        </v-radio-group>                                                    
                    </v-col>
                </v-row>                                                                                                                                      
                <v-row>                    
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        Ente Emisor de la Constancia
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" md="5">
                        <v-select
                            :items="items"
                            v-model="potabilidad.emisor"
                            label="Ente Emisor"
                            :error-messages="emisorErrors"             
                            @change="$v.potabilidad.emisor.$touch()"
                            @blur="$v.potabilidad.emisor.$touch()"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row align-content="center">
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        El Agua es Potable                                                     
                    </v-col>        
                    <v-divider vertical class="pa-0"></v-divider>                       
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-radio-group v-model="potabilidad.potable" 
                            hint="El usuario ingresar el resultado de la constancia"
                            :error-messages="potableErrors"
                            @change="$v.potabilidad.potable.$touch()">
                            <v-radio label="Si" :value="true"></v-radio>
                            <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group> 
                    </v-col>
                </v-row>                                                     
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="limpiar()" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="modificarConstancia()" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators"
import axios from "@/axios.js";

export default {
    components: {
      ModalTitle
    },
    computed: {
        fuenteErrors () {
            const errors = []
            if (!this.$v.potabilidad.fuenteAbastecimiento.$dirty) return errors            
            !this.$v.potabilidad.fuenteAbastecimiento.required && errors.push('Dato requerido.')
            return errors
        },
        potableErrors () {
            const errors = []
            if (!this.$v.potabilidad.potable.$dirty) return errors
            !this.$v.potabilidad.potable.required && errors.push('Dato requerido')
            return errors
        },
        emisorErrors () {
            const errors = []
            if (!this.$v.potabilidad.emisor.$dirty) return errors
            !this.$v.potabilidad.emisor.required && errors.push('La Estructura Legal es requerida')
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.potabilidad.anio.$dirty) return errors
            !this.$v.potabilidad.anio.required && errors.push('Dato requerido')
            return errors
        },
        usuario(){
            return this.$store.getters.usuario;
        }
    },
    props: [
        'idPotabilidad',
        'anios',
        'aniosConstancia'
    ],
    data: () => ({
        dialog: false,
        archivos: [],
        alert: false,
        potabilidad: {
            fuenteAbastecimiento: '',
            potable: '',        
            emisor: '',
            anio: ''
        },
        items: [
            'Acueductos y Alcantarillados',
            'Municipalidad',
            'Asada',
            'ESPH',
            'Otro'
        ],    
    }),
    validations: {        
        potabilidad: {    
            fuenteAbastecimiento: {
                required
            },
            potable: {
                required
            },
            emisor: {
                required
            },
            anio: {
                required
            }
        },
    },
    methods: {
        modificarConstancia() {
            this.$v.$touch();
            if(!this.$v.$invalid && !this.alert) {
                this.$store.dispatch('loadingPage', true);
                let formData = new FormData();
                let potAgua = this.potabilidad
                delete potAgua.fuenteName
                delete potAgua.potableName
                formData.append('potabilidad', JSON.stringify(potAgua));
                formData.append('usuario', this.usuario.id);
                formData.append('idPotabilidad', this.idPotabilidad);
                axios.post("/indicadorAgua/modificarConstanciaAgua", formData)
                .then(() => {
                    this.$store.dispatch('loadingPage', false);
                    this.$store.dispatch('noti',{mensaje: 'Constancia modificada', color:'success'});
                    this.$emit('reloadTable');
                    this.limpiar();             
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                    } else {
                      this.$store.dispatch('loadingPage', false);
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la constancia', color:'error'});              
                    }
                  }
                })
            }
        },
        verificarAnio() {
            let tieneConst = this.aniosConstancia.includes(this.potabilidad.anio);
            if(tieneConst) {
                this.alert = true;                
            } else {
                this.alert = false;
                this.$v.potabilidad.anio.$touch()
            }
        },
        limpiar() {
            this.$v.potabilidad.$reset();
            this.potabilidad = {};
            this.alert = false;
            this.dialog = false;
        },
        getDataPotabilidad() {
            this.$store.dispatch('loadingPage', true);
            axios.get(`/indicadorAgua/getDataPotabilidad/${this.idPotabilidad}`).then(response => {
                    this.$store.dispatch('loadingPage', false);
                    this.potabilidad = response.data.potabilidad
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                    this.$store.dispatch('loadingPage', false);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('loadingPage', false);
                    } else {
                      this.$store.dispatch('loadingPage', false);          
                    }
                  }
                })
        }
    },
}
</script>