<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getOrganizacion()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Organización" @cancelar="cancelar"/>
            </v-card-title>              
            <v-divider></v-divider>
            <v-card-text>
                <v-container grid-list-xs>
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-text-field                                
                                label="Nombre"
                                v-model="org.nombre"
                                :error-messages="nameErrors"
                                @input="$v.org.nombre.$touch()"
                                @blur="$v.org.nombre.$touch()"
                                counter="100"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Cédula Jurídica"
                                v-model="org.cedulaJur"
                                :error-messages="cedjurErrors"
                                @input="$v.org.cedulaJur.$touch()"
                                @blur="$v.org.cedulaJur.$touch()"
                                required
                                v-mask="cedMask"
                                hint="10 dígitos, sin cero al inicio ni guiones"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Teléfono"
                                v-model="org.telefono"
                                :error-messages="phoneErrors"
                                @input="$v.org.telefono.$touch()"
                                @blur="$v.org.telefono.$touch()"
                                required
                                v-mask="telMask"
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                label="Descripción"
                                v-model="org.descripcion"
                                :error-messages="descripcionErrors"  
                                @input="$v.org.descripcion.$touch()"
                                @blur="$v.org.descripcion.$touch()"
                                counter="200">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select
                                :items="sectores"
                                item-text="nombre"
                                item-value="id"
                                v-model="sector"
                                label="Sector Productivo"
                                :error-messages="sectorErrors"             
                                @change="$v.sector.$touch()"
                                @blur="$v.sector.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-select
                                :items="estructuras"
                                item-text="nombre"
                                item-value="id"
                                v-model="estructura"
                                label="Estructura Legal"
                                :error-messages="estrucErrors"             
                                @change="$v.estructura.$touch()"
                                @blur="$v.estructura.$touch()"
                                required
                            ></v-select>
                        </v-col> 
                        <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-select
                                :items="politicaOperativa"
                                item-text="nombre"
                                item-value="id"
                                v-model="politicaOperativas"
                                label="Política Operativa"
                                :error-messages="politicaErrors"             
                                @change="$v.politicaOperativas.$touch()"
                                @blur="$v.politicaOperativas.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-select
                                :items="enfoques"
                                item-text="nombre"
                                item-value="id"
                                v-model="tipoEnfoque"
                                label="Tipo Enfoque"
                                :error-messages="enfoqueErrors"             
                                @change="$v.politicaOperativas.$touch()"
                                @blur="$v.politicaOperativas.$touch()"
                                required
                            ></v-select>
                        </v-col> -->
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Latitud"
                                :error-messages="latitudErrors"
                                @change="$v.org.latitud.$touch()"
                                @blur="$v.org.latitud.$touch()"
                                v-model="org.latitud" 
                                required
                                decimal>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Longitud"
                                :error-messages="longitudErrors"
                                v-model="org.longitud"
                                @change="$v.org.longitud.$touch()"
                                @blur="$v.org.longitud.$touch()"
                                required
                                decimal>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Correo"
                                v-model="org.correo"
                                :error-messages="correoErrors"
                                @input="$v.org.correo.$touch()"
                                @blur="$v.org.correo.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Ubicación"
                                v-model="org.ubicacion"
                                :error-messages="ubicacionErrors"
                                @input="$v.org.ubicacion.$touch()"
                                @blur="$v.org.ubicacion.$touch()"
                                counter="200"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Límite de Instalaciones"
                                v-model="org.limiteInstalacion"
                                :error-messages="limiteErrors"
                                @input="$v.org.limiteInstalacion.$touch()"
                                @blur="$v.org.limiteInstalacion.$touch()"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-file-input
                                accept=".png, .jpeg, .jpg"
                                label="Logo"
                                show-size
                                clearable
                                :error-messages="logoErrors"
                                @input="$v.logo.$touch()"
                                @blur="$v.logo.$touch()"
                                v-model="logo">
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarOrganizacion" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
import {required, email, decimal, maxLength, minValue} from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
//Custom validations
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}

export default {
    props: [
        'id'
    ],
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.org.nombre.$dirty) return errors            
            !this.$v.org.nombre.required && errors.push('El nombre es requerido.')
            !this.$v.org.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        phoneErrors () {
            const errors = []
            if (!this.$v.org.telefono.$dirty) return errors            
            !this.$v.org.telefono.required && errors.push('El teléfono es requerido.')
            return errors
        },
        cedjurErrors () {
            const errors = []
            if (!this.$v.org.cedulaJur.$dirty) return errors            
            !this.$v.org.cedulaJur.required && errors.push('La cédula Jurídica es requerida.')
            return errors
        },
        sectorErrors () {
            const errors = []
            if (!this.$v.sector.$dirty) return errors
            !this.$v.sector.required && errors.push('El sector es requerido')
            return errors
        },
        limiteErrors () {
          const errors = []
          if (!this.$v.org.limiteInstalacion.$dirty) return errors
          !this.$v.org.limiteInstalacion.required && errors.push('Este valor es requerido')
          !this.$v.org.limiteInstalacion.minValue && errors.push('El valor minimo es 1')
          return errors
        },
        /*estrucErrors () {
            const errors = []
            if (!this.$v.estructura.$dirty) return errors
            !this.$v.estructura.required && errors.push('La Estructura Legal es requerida')
            return errors
        },
         politicaErrors () {
            const errors = []
            if (!this.$v.politicaOperativas.$dirty) return errors
            !this.$v.politicaOperativas.required && errors.push('Política Operativa es requerida')
            return errors
        },
        enfoqueErrors () {
            const errors = []
            if (!this.$v.tipoEnfoque.$dirty) return errors
            !this.$v.tipoEnfoque.required && errors.push('Tipo de Enfoque requerido')
            return errors
        },  */
        ubicacionErrors () {
            const errors = []
            if (!this.$v.org.ubicacion.$dirty) return errors
            !this.$v.org.ubicacion.required && errors.push('Ubicación requerida')
            !this.$v.org.ubicacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        correoErrors () {
            const errors = []
            if (!this.$v.org.correo.$dirty) return errors
            !this.$v.org.correo.required && errors.push('Correo requerido')
            !this.$v.org.correo.email && errors.push('Formato de correo inválido')
            return errors
        },
        logoErrors () {
            const errors = []
            if (!this.$v.logo.$dirty) return errors            
            !this.$v.logo.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        }, 
        latitudErrors () {
            const errors = []
            if (!this.$v.org.latitud.$dirty) return errors            
            !this.$v.org.latitud.required && errors.push('La latitud es requerida.') 
            !this.$v.org.latitud.decimal && errors.push('Deben ser solo números.')           
            return errors
        },
        longitudErrors () {
            const errors = []
            if (!this.$v.org.longitud.$dirty) return errors            
            !this.$v.org.longitud.required && errors.push('La longitud es requerida.')  
            !this.$v.org.longitud.decimal && errors.push('Deben ser solo números.')   
            return errors
        },
        descripcionErrors() {
            const errors = []
            if (!this.$v.org.descripcion.$dirty) return errors            
            !this.$v.org.descripcion.required && errors.push('La descripcion es requerida.')
            !this.$v.org.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        org: {
            nombre: '',
            cedulaJur: '',
            telefono: '',
            correo: '',
            ubicacion: '',
            latitud: '',
            longitud: '',
            descripcion: '',
        },
        logo: null,
        sector: '',
        loading: false,
        sectores: [],
        /*estructuras: [],
        estructura: '',
        politicaOperativa: [],
        politicaOperativas: '',
        enfoques: [],
        tipoEnfoque: '',*/
        telMask: '####-####',
        cedMask: '##########',
    }),
    validations: {
        org: {
            nombre: {
                maxLength: maxLength(100),
                required,                
            },
            telefono: {
                required               
            },
            cedulaJur: {
                required               
            },
            correo: {
                required,
                email
            },
            ubicacion: {
                maxLength: maxLength(200),
                required
            }, 
            latitud:{ required, decimal},
            longitud: { required, decimal},
            descripcion: { 
                maxLength: maxLength(200),
                required 
            },
            limiteInstalacion: {
              required,
              minValue: minValue(1)
            }
        },
        sector: {
            required
        },
        /*estructura: {
            required
        },
        politicaOperativas: {
            required
        },
        tipoEnfoque: {
            required
        },  */     
        logo: {
            maxFileSize
        }
    },
    methods: {
        getOrganizacion() {
            this.loading = true;
            axios.get("/organizacion/getDataModificar/"+this.id).then(response => {
                this.org = response.data.org;
                this.sectores = response.data.sectores;
                this.sector = response.data.sector;
                /*this.estructuras = response.data.estructuras;
                this.enfoques = response.data.enfoques;
                this.estructura = response.data.estructura;
                this.tipoEnfoque = response.data.tipoEnfoque;
                this.politicaOperativa = response.data.politicaOperativa;
                this.politicaOperativas = response.data.politicaOperativas;*/
                this.$emit('reload');
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        
        modificarOrganizacion() {
            this.$v.$touch();
            let orga = this.org;
            //delete orga.nombrePolitica;
            delete orga.nombreSector;
            //delete orga.nombreEstructura;     
            delete orga.cantInstalaciones;     
            if(!this.$v.$invalid) { 
                this.loading = true;
                let formData = new FormData();
                formData.append('logo', this.logo);
                formData.append('idSector', this.sector);
                /*formData.append('idEstruct', this.estructura);
                formData.append('idPolitica', this.politicaOperativas);
                formData.append('idTipoEnfoque', this.tipoEnfoque);*/
                formData.append('org', JSON.stringify(this.org));
                axios.put("/organizacion/modificarOrganizacion/", formData)
                .then(response => {
                    this.loading = false;
                    this.dialog = false;
                    this.$store.dispatch('noti',{mensaje: 'Organización modificada', color:'success'});      
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la organización', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        cancelar() {
            this.org = {};            
            /*this.estructura = '';
            this.$v.estructura.$reset();
            this.$v.politicaOperativas.$reset();*/
            this.sector = '';
            this.$v.org.$reset();
            this.loading = false;
            this.$v.sector.$reset();
            this.dialog = !this.dialog;
        },
    },
}
</script>