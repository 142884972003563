<template>
    <v-card hover>
        <v-sheet class="v-sheet--offset mx-auto pt-" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="white--text headline text-center">Consumo de {{ gei }}</v-list-item-title>
                    <v-list-item-subtitle class="white--text subtitle text-center">{{ anio }}</v-list-item-subtitle> 
                </v-list-item-content>
            </v-list-item>
        </v-sheet> 
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="historial"
                calculate-widths
                disable-filtering
                disable-pagination
                hide-default-footer
                fixed-header>
                <template v-slot:item.total="props">
                    <div class="text-center">{{ totalMedidor(props.item.detalle) }}</div>            
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>

export default {
    props: [
        'historial',        
        'anio',
        'gei',
    ],
    data: () => ({
        headers:[
            {
                text: 'Producto',
                value: 'detalle',
                align: 'center',
                sortable: false
            },
            {
                text: 'Enero',
                value: 'enero',
                align: 'center',
                sortable: false
            },
            {
                text: 'Febrero',
                value: 'febrero',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Marzo',
                value: 'marzo',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Abril',
                value: 'abril',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Mayo',
                value: 'mayo',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Junio',
                value: 'junio',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Julio',
                value: 'julio',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Agosto',
                value: 'agosto',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Septiembre',
                value: 'septiembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Octubre',
                value: 'octubre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Noviembre',
                value: 'noviembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Diciembre',
                value: 'diciembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Total',
                value: 'total',
                align: 'center',
                sortable: true,
                filterable: true,
            },
        ],
    }),
    methods: {
        totalMedidor(nombreMedidor) {            
            let datos = this.historial;
            let total = 0;
            datos.forEach((medidor) => {
                let medidorData = Object.values(medidor);
                if(medidorData.includes(nombreMedidor)) {
                    medidorData.splice(0, 2);
                    medidorData = Array.from(medidorData, item => item || 0);                                        
                    //total = parseFloat(medidorData.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)); 
                    medidorData.forEach(suma);
                    function suma(item, index){
                        if(index > 0 && index < 13){
                            total += item;
                        }
                    };
                }                
            })                        
            return total.toFixed(2);
        },
    },
}
</script>
<style scoped>
    thead th {
      font-size: 15px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>