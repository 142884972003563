<template>
  <v-container fluid>
    <v-row class="fill-height">
      <v-col cols="12">
          <v-card>
            <v-subheader class="text-subtitle-1">{{ indicadorName }} <v-spacer></v-spacer> {{ instalacionName }}</v-subheader>
            <v-divider></v-divider>
            <div v-if="noData" class="pa-2 text-center">
              No hay datos
            </div>
            <v-virtual-scroll
              v-else 
              :items="bitacoras"
              :height="screenHeight"
              item-height="75">
              <template v-slot:default="{ item }">
                <v-list two-line>
                  <v-list-item
                    :key="item.id">
                    <v-list-item-avatar color="grey lighten-2">
                      <v-icon medium color="#0B3F67">{{ item.accion.icono }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Acción {{ item.accion.nombre }}</v-list-item-title>

                      <v-list-item-subtitle>
                        {{ item.descripcion }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <strong> Usuario: </strong> {{ item.usuarioRegistra }} <strong>- Fecha de Registro:</strong> {{ item.fechaDeRegistro }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-virtual-scroll>
            <v-divider></v-divider>
            <v-card-actions class="text-caption">
              <v-col>
                Elementos por Página {{ pagination.numberOfElements }}
              </v-col>
              <v-col>
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.totalPages"
                  total-visible="10"
                  @input="buscarBitacoras(indicador, instalacion)"
                  @next="buscarBitacoras(indicador, instalacion)"
                  @previous="buscarBitacoras(indicador, instalacion)">
                </v-pagination>
              </v-col>
              <v-col justify-content-end>
                Total de Elementos: {{ pagination.totalElements }}
              </v-col>
            </v-card-actions>
          </v-card>
      </v-col>
      <v-col cols="12">

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from '@/axios'
export default {
  props: [
    'indicador',
    'instalacion',
    'instalacionName'
  ],
  computed: {
    length() {
      return this.bitacoras.length
    },
    noData() {
      return this.bitacoras.length === 0
    },
    indicadorName() {
      switch(this.indicador) {
        case 1:
          return 'Indicador Energia Electrica'
        case 2:
          return 'Indicador Agua'
        case 3:
          return 'Indicador Combustibles Fósiles'
        case 4:
          return 'Indicador Cumplimiento Lega'
        case 5:
          return 'Indicador Aguas Residuales'
        case 6:
          return 'Indicador Residups'
        case 7:
          return 'Indicador Gas Refrigerante'
        case 8:
          return 'Indicador Compras Sostenibles' 
        case 9:
          return 'Indicador Adaptación'
        case 10:
          return 'Indicador Compensación'
        default:
          return 'Sin Indicador'
      }
    },
    screenHeight() {
      return Math.trunc((screen.availHeight / 2));
    }
  },
  data: () => ({
    pagination: {
      page: 1,
      totalPages: 0,
      elements: 10,
      numberOfElements: 0,
      totalElements: 0
    },
    bitacoras: [],
  }),
  methods: {
    buscarBitacoras(indicador, instalacion) {
      this.loadingModal(true);
      let currentPage = (this.pagination.page - 1)
      axios.get(`/bitacora/listarBitacoras/${instalacion}/${indicador}/${currentPage}/${this.pagination.elements}` ).then(response => {
        this.bitacoras = response.data.content;
        this.pagination.totalPages = response.data.totalPages
        this.pagination.numberOfElements = response.data.numberOfElements
        this.pagination.totalElements = response.data.totalElements
        this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);
          this.loading = false;
        }
        this.loadingModal(false);
      })
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    }
  }
}
</script>