<template>
    <v-container fluid>
        <logo @cambiaAnio="getProdSustituidos" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Sustitución de Productos"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3" align-self="center">
                <AgregarSustitucion 
                    @reload="getProdSustituidos" 
                    :anios="aniosConsulta"
                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="2">
                <v-text-field    
                    prepend-inner-icon="mdi-magnify"                
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <tituloSimple titulo="Productos Sustituidos"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"                                                                                                                
                            item-key="id"                            
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <ModificarProd 
                                    @reload="getProdSustituidos" 
                                    :producto="item" 
                                    :anios="aniosConsulta"
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                                <Eliminar
                                    requestUrl="/comprasSostenibles/eliminarSustitucionProd"
                                    v-bind:requestData="requestData(item)"
                                    @reload="getProdSustituidos"
                                />
                            </template>
                            <template v-slot:item.id="{ item }">
                                <listadoEvidencias :id="item.id" origen="sustituciones"/>
                            </template>                            
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from '@/axios.js'
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import ModificarProd from '@/components/parametros/ComprasSostenibles/ModificarProdSustituidoComponent'
import ListadoEvidencias from '@/components/parametros/ComprasSostenibles/EvidenciasProdSostComponent'
import AgregarSustitucion from '@/components/parametros/ComprasSostenibles/AgregarSustitucionComponent'
import Eliminar from '@/components/utils/EliminarComponent'

export default {
    components: {
    TituloPrincipal,
    TituloSimple,
    AgregarSustitucion,
    ListadoEvidencias,
    ModificarProd,
    NavParameters,
    Logo,
    Eliminar
},
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        aniosConsulta() {
            return this.$store.getters.aniosConsulta;
        }
        ,
        usuario(){
            return this.$store.getters.usuario;
        },
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted() {
        this.getProdSustituidos();        
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Año Sustitución',
                value: 'anio',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Producto Original',
                value: 'nombre',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Producto Sostenible',
                value: 'productSost',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Evidencias',
                value: 'id',
                align: 'center',
                width: '20%',
                sortable: false
            },
        ],
        items: [],                                                                                                                                              
        search: null,
    }),
    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getProdSustituidos() {
            axios.get("/comprasSostenibles/getProducSustituidos/" + this.instalacion.id)
            .then(response => {
                this.items = response.data;
                setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
              }
            })
        },
        requestData(producto) {
            let formData = new FormData();
            formData.append('producto', producto.id);
            formData.append('usuario', this.usuario.id);
            return formData;
        }
    },
}
</script>