<template>
    <v-container fluid>
        <v-row align="center">
            <v-col cols="12" v-if="!tieneContaminante">
                <SinContaminantes />
            </v-col>
            <v-col cols="12" sm="6" md="3" >
                <AgregarInventario @recargaTabla="listaInventario" v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>   
        <br>   
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-sheet height="80" class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">                   
                        <v-layout fill-height align-center justify-center>                            
                            Gases Refrigerantes
                        </v-layout>                                  
                    </v-sheet>                                        
                    <v-data-table
                        multi-sort    
                        :search="search"        
                        :headers="headers"
                        :items="inventario"
                        class="elevation-1"            
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }">
                        <template v-slot:item.action="{ item }">
                            <ModificarInventario 
                                @reload="listaInventario" 
                                :id="item.id"
                                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                        </template>
                        <template v-slot:item.estadoAnio="{ item }">
                            <ActivarInventario 
                                @reload="listaInventario" 
                                :estado="item.estadoAnio" 
                                :id="item.id"/>
                        </template>
                        <template 
                            v-slot:item.evidencias="{ item }">
                            <AgregarDocs v-if="item.agregarDocs"
                                @reload="listaInventario" 
                                titulo="Agregar Evidencia"
                                tooltipText="Agregar Evidencia"
                                :tipo="1"
                                icon="mdi-folder-upload"
                                :id="item.id"
                                :anio="anioConsulta" />
                            <ListadosDocs v-else
                                :idAccion="item.id" 
                                :medidorId="item.id"
                                :anioConsulta="anioConsulta"
                                :titulo="'Documentos Producto contaminante atmosférico'"
                                origen="contaminanteAtmosferico" 
                            ></ListadosDocs>
                        </template>
                    </v-data-table>                        
                </v-card>
            </v-col>
        </v-row>       
    </v-container>
</template>

<script>
import SinContaminantes from "@/components/parametros/ContaminantesAtmosfericos/SinContaminantesComponent.vue"
import AgregarInventario from "@/components/parametros/ContaminantesAtmosfericos/AgregarInventarioContaComponent"
import ModificarInventario from "@/components/parametros/ContaminantesAtmosfericos/ModificarInventarioContaComponent";
import ActivarInventario from "@/components/parametros/ContaminantesAtmosfericos/ActivarContaAtmosfComponent";
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import AgregarDocs from '@/components/documentos/AgregarEvidenciaAnioComponent'
import axios from "@/axios.js";

export default {
    components: {
        AgregarInventario,
        ModificarInventario,
        ActivarInventario,
        ListadosDocs,
        AgregarDocs,
        SinContaminantes
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    mounted () {
        this.listaInventario();
    },
    data: () => ({
        tieneContaminante: true,
        search: null,
        inventario: [],
        headers:[
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estadoAnio',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Equipo que consume gas refrigerante',
                value: 'producto',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Gas refrigerante que consume',
                value: 'tipoContaminanteNombre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Capacidad',
                value: 'presentacion',
                align: 'center',
                sortable: true,                
            },
            {
                text: 'Tipo de mantenimiento',
                value: 'tipoMantenimiento',
                align: 'center',
                sortable: true,                
            },                           
        ],
        cargando: false
    }),
    computed:{
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
    },
    methods: {
        listaInventario() {
            this.cargando = true;
            this.$store.dispatch('loadingPage', true);
            let inst = this.instalacion.id;
            axios.get(`/productoContaAtmosf/getInventarioInstalacion/${inst}/${this.anioConsulta}`)
            .then(response => {
                this.inventario = response.data.inventario;
                this.tieneContaminante = response.data.cant
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            }).finally(() => {
              this.$store.dispatch('loadingPage', false);
            })
        }
    }
}
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>

