<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal :titulo="tituloIndicador"/>
            </v-col>
        </v-row>
        <TablaResiduo ref="residuosPel" :headers="tableHeaders" :origen="origen"/>
    </v-container>
    
</template>

<script>

import TablaResiduo from "@/components/parametros/GestiondeResiduos/ResiduosTablaComponent";
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent';
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    components:{
        TablaResiduo,
        TituloPrincipal,
        NavParameters,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    mounted(){
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    computed:{
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        origen(){
            return this.$store.getters.constantes.residuosPeligrosos;
        },
        tableHeaders() {
            return this.anioConsulta < 2022 ? this.headers : this.headers2022;
        }
    },
     data: () => ({
        tituloIndicador: "Residuos de Manejo Peligrosos",
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                class: "educTable",
            },
            {
                text: "Nombre del Residuo",
                value: "nombreResiduo",
                align: "center",
                class: "educTable",
            },
            {
                text: "Descripción de la Trazabilidad",
                value: "descripcionTrazabilidad",
                align: "start",
                class: "educTable",
            },,
            {
                text: "Tipo Tratamiento",
                value: "tratamiento",
                align: "center",
                class: "educTable",
            },
            {
                text: "Evidencia",
                value: "evidencia",
                align: "center",
                class: "educTable",
            },
        ],
        headers2022: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                class: "educTable",
            },
            {
                text: "Nombre del Residuo",
                value: "nombreResiduo",
                align: "center",
                class: "educTable",
            },
            {
                text: "Descripción de la Trazabilidad",
                value: "descripcionTrazabilidad",
                align: "start",
                class: "educTable",
            },,
            {
                text: "Disposición Final",
                value: "disposicionFinal",
                align: "start",
                class: "educTable",
            },
            {
                text: "Evidencia",
                value: "evidencia",
                align: "center",
                class: "educTable",
            },
        ],
    }),
    methods:{
        getData() {
            this.$refs.residuosPel.getData();
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
    
}
</script>