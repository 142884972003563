<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn icon @click="open" v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Modificar Parámetro
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Parametro" @cancelar="cancelar"/>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Indicador"
          v-model="item.indicador"
          disabled
        ></v-text-field>
        <v-textarea
          label="Nombre"
          v-model="item.nombre"
          @input="$v.item.nombre.$touch()"
          @blur="$v.item.nombre.$touch()"
          :error-messages="nameErrors"
        ></v-textarea>
        <v-text-field
          label="Puntos"
          v-model="item.puntos"
          type="number"
          @input="$v.item.puntos.$touch()"
          @blur="$v.item.puntos.$touch()"
          :error-messages="puntosErrors"
        ></v-text-field>
        <v-text-field
          label="Cantidad Minima"
          v-model="item.cantidadMinima"
          type="number"
          @input="$v.item.cantidadMinima.$touch()"
          @blur="$v.item.cantidadMinima.$touch()"
          :error-messages="cantidadErrors"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
        <v-btn @click="aceptar" text color="#0B3F67">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, minValue } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  props: [
    'item'
  ],
  components: {
    ModalTitle
  },
  computed : {
    nameErrors () {
      const errors = []
      if (!this.$v.item.nombre.$dirty) return errors            
      !this.$v.item.nombre.required && errors.push('El nombre es requerido.')            
      return errors
    },
    puntosErrors () {
      const errors = []
      if (!this.$v.item.puntos.$dirty) return errors            
      !this.$v.item.puntos.required && errors.push('Los puntos son requeridos.')            
      return errors
    },
    cantidadErrors () {
      const errors = []
      if (!this.$v.item.cantidadMinima.$dirty) return errors            
      !this.$v.item.cantidadMinima.required && errors.push('La cantidad es requeridos.')
      !this.$v.item.cantidadMinima.minValue && errors.push('No debe ser valor cero.')
      return errors
    },
  },
  data: () => ({
    dialog: false,
    backupItem: null
  }),
  validations: {
    item: {
      nombre: { required },
      puntos: { required },
      cantidadMinima: {
        required,
        minValue: minValue(1)
      }
    }
  },
  methods: {
    open() {
      this.backupItem = {...this.item}
    },
    aceptar() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.dialog = !this.dialog;
      }
    },
    cancelar() {
      this.item.nombre = this.backupItem.nombre;
      this.item.puntos = this.backupItem.puntos;
      this.dialog = !this.dialog;
    }
  },
}
</script>