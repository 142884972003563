<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">  
                    <v-btn class="white--text" color="#0B3F67" v-on="{ ...tooltip, ...on }" @click="getDataAccion()">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>                  
                </template>
                <span>Agregar</span>
            </v-tooltip>            
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Acción de Adaptación a Amenaza Climática" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Acción de Adaptación"
                            v-model="accion.nombre"
                            :error-messages="accionErrors"             
                            @change="$v.accion.nombre.$touch()"
                            @blur="$v.accion.nombre.$touch()"
                            counter="200"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            :items="amenazas"
                            item-text="amenazaNombre"
                            item-value="amenazaNombre"
                            v-model="amenazasSelect"
                            multiple
                            :error-messages="amenazaErrors"
                            @change="$v.amenazasSelect.$touch()"
                            @blur="$v.amenazasSelect.$touch()"
                            required
                            label="Amenazas Climáticas"
                        ></v-select>
                    </v-col>
                    <v-col cols="12"> 
                        <v-textarea
                            outlined
                            :error-messages="descErrors"             
                            @change="$v.accion.descripcion.$touch()"
                            @blur="$v.accion.descripcion.$touch()"
                            label="Descripción de la acción"
                            v-model="accion.descripcion"
                            counter="500"
                        ></v-textarea>
                    </v-col>
                    
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregarModificar" text>Aceptar</v-btn>                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators";
import axios from '@/axios.js'

export default {
    props: [
        'instalacion',
        'anioConsulta',
        'usuario'
    ],
    components: {
      ModalTitle
    },
    computed: {
        accionErrors() {
            const errors = []
            if (!this.$v.accion.nombre.$dirty) return errors            
            !this.$v.accion.nombre.required && errors.push('Las acciones son requeridas.')
            !this.$v.accion.nombre.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        descErrors() {
            const errors = []
            if (!this.$v.accion.descripcion.$dirty) return errors            
            !this.$v.accion.descripcion.required && errors.push('Las acciones son requeridas.')
            !this.$v.accion.descripcion.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        amenazaErrors() {
            const errors = []
            if (!this.$v.amenazasSelect.$dirty) return errors            
            !this.$v.amenazasSelect.required && errors.push('Las acciones son requeridas.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        amenazas: [],
        amenazasSelect: [],
        accion: {
            nombre: null,            
            descripcion: null
        }
    }),
    validations: {
        accion: {
            nombre: {
                required,
                maxLength: maxLength(200)     
            },
            descripcion: {
                required,
                maxLength: maxLength(500)     
            },
        },
        amenazasSelect: { required }
    },
    methods: {
        getDataAccion() {
            axios.get(`/adaptacion/getDataAgregarAccionAdaptacion/${this.instalacion.id}/${this.anioConsulta}`).then(response => {
                this.amenazas = response.data
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo agregar la acción del evento', color: 'error'});
                    this.loadingModal(false);
                }
            })
        },
        agregarModificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.post('/adaptacion/agregarAccionAdaptacion', null, {params: {
                    accion: this.accion.nombre,
                    descrip: this.accion.descripcion,
                    inst: this.instalacion.id,
                    usuario: this.usuario.id,
                    anioConsulta: this. anioConsulta,
                    amenazas: this.amenazasSelect.toString()
                }}).then(response => {
                    this.$store.dispatch('noti', {mensaje: 'Acción de evento climático agregado', color: 'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                   this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo agregar la acción del evento', color: 'error'});
                  }
                }).finally(() => {
                    this.loadingModal(false);
                })
            }
        },
        cancelar() {
            this.accion = {
                nombre: null,            
                descripcion: null
            }
            this.amenazas = [],
            this.amenazasSelect = [],
            this.$v.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>