<template>
    <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="5"
        :no-data-text="sinDatos"
        :footer-props="footerProps">
        <template v-slot:item.fechaRegistro="{ item }">
            {{ dateFormat(item.fechaRegistro) }}
        </template>
        <template v-slot:item.documentoId="{ item }">      
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="#0B3F67" @click="descargarDocumento(item.documentoId)">mdi-cloud-download-outline</v-icon>
                </template>
                Descargar
            </v-tooltip>      
        </template>
        <template v-slot:item.action="{ item }">
          <div class="d-flex flex-row">
            <ImageViewer :documentoId="item.documentoId" />
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon 
                        v-on="on" 
                        class="mr-2" 
                        v-if="origen == 'educacionAmbiental' ||
                              origen == 'accionesPoS' || 
                              origen == 'tratamientoParametro' ||
                              origen == 'accionReduccion' ||
                              origen == 'estrellas' ||
                              origen == 'residuosEspeciales' ||
                              origen == 'accionAdaptacion'" 
                        color="#0B3F67" @click="borrarDocumento(item.documentoId)">
                            mdi-trash-can-outline
                    </v-icon>
                </template>
                Eliminar Evidencia
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mr-2" v-if="origen == 'consumo'" color="#0B3F67" @click="borrarDocumentoConsumo(item.documentoId, item.id)">mdi-trash-can-outline</v-icon>
                </template>
                Eliminar Evidencia
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mr-2" v-if=" origen == 'residuos'" color="#0B3F67" @click="borrarDocResiduo(item.documentoId, item.id)">mdi-trash-can-outline</v-icon>
                </template>
                Eliminar Evidencia
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mr-2" v-if=" origen == 'planAccion'" color="#0B3F67" @click="borrarDocPlanAcc(item.documentoId, item.id)">mdi-trash-can-outline</v-icon>
                </template>
                Eliminar Evidencia
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mr-2" v-if=" origen == 'programaCompras'" color="#0B3F67" @click="borrarEvidenciaPrograma(item.documentoId, item.id)">mdi-trash-can-outline</v-icon>
                </template>
                Eliminar Evidencia
            </v-tooltip>
            <ModicafDoc 
                class="mr-2" 
                v-if="origen == 'tratamientoParametro' || 
                      origen == 'vertidoSustancias'|| 
                      origen == 'planAccion' ||
                      origen == 'contaminanteAtmosferico' ||
                      origen == 'actividadCompesacion' ||
                      origen == 'inscripcionLegal' ||
                      origen == 'estrellas' ||
                      origen == 'EducacionExterna' ||
                      origen == 'prevencion' ||
                      origen == 'limpieza'  ||
                      origen == 'programaCompras' ||
                      origen == 'educacionAmbiental' ||
                      origen == 'accionAdaptacion'" 
                :documento="item.id"
                :inst="item.instalacion"
                @reload="refreshTable()"/>     
            <eliminar-evidencia-potable @reload="refreshTable" v-if="origen === 'potable'" :evidencia="item"/>  
          </div>         
        </template>
    </v-data-table>            
</template>
<script>
 
import moment from 'moment'
import axios from '@/axios.js'
import ImageViewer from '@/components/documentos/ImageViewerComponent'
import ModicafDoc from '@/components/documentos/ModificarDocComponent'
import EliminarEvidenciaPotable from '@/components/parametros/Agua/ElimVerAgPotableComponent'

export default {
    components: {
      ModicafDoc,
      EliminarEvidenciaPotable,
      ImageViewer
  },
    props: [
        'headers',
        'data',
        'sinDatos',
        'footerProps',
        'idActividad',//en casos donde sea necesario
        'origen',
        'idMedidor',
        'id'//par usarse con cualquier id
    ],
    computed:{
       constantes() {
          return this.$store.getters.constantes;
        },
        usuario(){
          return this.$store.getters.usuario;
        },
        indicadorActual() {
          return this.$store.getters.indicadorActual;
        },
        instalacion() {
          return this.$store.getters.sucursal;
        },
        indicadorAgua() {
          return this.indicadorActual == this.constantes.indicadorAgua
        },
        anioConsulta() {
          return this.$store.getters.anioConsulta;
        },
    },
    methods: {
        descargarDocumento(documentoId) {
            axios({
                url: '/documento/descargar/' + documentoId,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
        dateFormat(fecha) {
            return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
        },
        borrarDocumento(documentoId){
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar el documento?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    if (this.origen == "educacionAmbiental"){
                        axios.delete("/educacionAmbiental/borrarDocumento/",{params:{
                            idActividad: this.idActividad,
                            idDocumento: documentoId,
                            idUsuario: this.usuario.id
                        }}).then(response=>{
                            this.efectivo('Evidencia eliminada');
                        }).catch(error=>{
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error, 'eliminó la evidencia');
                          }
                        })
                    }else if (this.origen == "accionesPoS"){
                        axios.delete("/accionesPoS/borrarDocumento",{params:{
                            idAccion:this.idActividad,
                            idDocumento:documentoId,
                            idUsuario:this.usuario.id
                        }}).then(reponse=>{
                            this.efectivo('Evidencia eliminada');
                        }).catch(error=>{
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error, 'eliminó la evidencia');
                          }
                        })
                    }else if (this.origen == "tratamientoParametro"){
                        axios.delete("/documento/eliminarDocTratamientoAgua", {params: {
                            documento: documentoId,
                            inst: this.instalacion.id,
                            user: this.usuario.id,
                            anio: this.anioConsulta
                        }}).then(response => {
                            this.efectivo('Evidencia eliminada');
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error, 'eliminó la evidencia');
                          }
                        })
                    } else if (this.origen == "accionReduccion") {
                        axios.delete("/accionReduccion/borrarDocumento", {params: {
                            idAccion:this.idActividad,
                            idDocumento:documentoId,
                            idUsuario:this.usuario.id
                        }}).then(response => {
                            this.efectivo('Evidencia eliminada');
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error, 'eliminó la evidencia');
                          }
                        })
                    } else if (this.origen == "EducacionExterna") {
                        axios.delete("/actividadCompesacion/borrarDocumento", {params: {
                            idEducacion:this.idActividad,
                            idDocumento:documentoId,
                            idUsuario:this.usuario.id
                        }}).then(response => {
                            this.efectivo('Documento eliminado');
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error,'eliminó el documento');
                          }
                        })
                    }  else if (this.origen == 'estrellas') {
                        axios.delete("/estrellas/borrarDocumento", {params: {
                            id:this.idActividad,
                            idDoc: documentoId,
                            idUsuario: this.usuario.id,
                            tipo: this.idMedidor
                        }}).then(response => {
                            this.efectivo('Documento eliminado');
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error,'eliminó el documento');
                          }
                        })
                    }else if(this.origen == 'residuosEspeciales'){
                        axios.delete("/gestionResiduos/borrarDocumento/", {params:{
                            idResiduo: this.idActividad,
                            idDocumento: documentoId,
                            idUsuario: this.usuario.id
                        }}).then(response => {
                            this.efectivo('Se eliminó el documento');
                        }).catch(error =>{
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error, ' logró eliminar el documento');
                          }
                        })
                    } else if (this.origen == "accionAdaptacion"){
                        axios.delete("/adaptacion/borrarDocumento",{params:{
                            idEvento: this.id,
                            idDocumento:documentoId,
                            idUsuario:this.usuario.id
                        }}).then(reponse=>{
                            this.efectivo('Evidencia eliminada');
                        }).catch(error=>{
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            this.erroneo(error, 'eliminó la evidencia');
                          }
                        })
                    }
                }
            });
        },
        borrarDocumentoConsumo(documentoId, evidencia) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar la evidencia?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    axios.delete("/documento/borrarDocumento/",{params:{
                        indicador: this.indicadorActual,
                        idDocumento: documentoId,
                        idUsuario: this.usuario.id,
                        idInstalacion: this.instalacion.id,
                        medidorId: this.idMedidor,
                        evidencia: evidencia
                    }}).then(response=>{
                        this.efectivo('Evidencia eliminada');
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.erroneo(error, 'eliminó la evidencia');
                      }
                    })
                }
            });
        },
        borrarDocResiduo(documentoId, evidencia){
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar el documento?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    axios.delete("/documento/borrarDocResiduos/",{params:{
                        anio: this.anioConsulta,
                        idDoc: documentoId,
                        idUser: this.usuario.id,
                        instalacion: this.instalacion.id,
                        evidenciaId: evidencia
                    }}).then(response=>{
                        this.efectivo('Documento eliminado');
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.erroneo(error, 'eliminó el documento');
                      }
                    })
                }
            });
        },
        borrarDocPlanAcc(documentoId) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar el documento?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                     axios.delete("/adaptacion/borrarDocPlanAccion/",{params:{
                        doc: documentoId,
                        usuario: this.usuario.id,
                        inst: this.instalacion.id,
                        plan: this.id
                    }}).then(response=>{
                        this.efectivo('Documento eliminado');
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.erroneo(error, 'eliminó el documento');
                      }
                    })
                }
            });
        },

        borrarEvidenciaPrograma(documentoId) {
          this.$store.dispatch('confirma', {mensaje: 'Desea eliminar el documento?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                     axios.delete("/comprasSostenibles/eliminarEvidenciasPrograma/",{params:{
                        id: documentoId,
                        usuario: this.usuario.id,
                        inst: this.instalacion.id,
                        programa: this.id
                    }}).then(response=>{
                        this.efectivo('Documento eliminado');
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.erroneo(error, 'eliminó el documento');
                      }
                    })
                }
            });
        },
        efectivo(str) {
            this.$store.dispatch('noti',{mensaje: str, color:'success'});
            this.$store.dispatch('loadingPage', false);
            this.$emit('reload');
        },
        erroneo(error, str) {
            console.log(error);
            this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se ' + str, color:'error'});
            this.$store.dispatch('loadingPage', false);
        },
        refreshTable() {
            this.$emit('reload');
        },
    },
}
</script>