<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getRepresentanteLegal()"
                        >
                        mdi-account-box-outline
                    </v-icon>
                </template>
                <span>Representante Legal</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Datos Representante Legal" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-container grid-list-xs>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field                                
                                label="Nombre"
                                v-model="representante.nombre"
                                :error-messages="nameErrors"
                                @input="$v.representante.nombre.$touch()"
                                @blur="$v.representante.nombre.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field                                
                                label="Cédula física"
                                v-model="representante.cedula"
                                v-mask="cedMask"
                                :error-messages="idErrors"
                                @input="$v.representante.cedula.$touch()"
                                @blur="$v.representante.cedula.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="" md="">
                            <v-text-field                                
                                label="Teléfono"
                                v-model="representante.telefono"
                                v-mask="telMask"
                                :error-messages="phoneErrors"
                                @input="$v.representante.telefono.$touch()"
                                @blur="$v.representante.telefono.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field                                
                                label="Correo"
                                v-model="representante.correo"
                                :error-messages="emailErrors"
                                @input="$v.representante.correo.$touch()"
                                @blur="$v.representante.correo.$touch()"
                                required
                                email
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>  
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text >Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarRepresentante" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import { mask } from "vue-the-mask";
import {required, email} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props:[
        'id'
    ],
    components: {
      ModalTitle
    },
    directives: {
        mask,
    },
    computed:{
        nameErrors () {
            const errors = []
            if (!this.$v.representante.nombre.$dirty) return errors            
            !this.$v.representante.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
        phoneErrors(){
            const errors = []
            if (!this.$v.representante.telefono.$dirty) return errors
            !this.$v.representante.telefono.required && errors.push('El teléfono es requerido.')
            return errors
        },
        emailErrors(){
            const errors = []
            if (!this.$v.representante.correo.$dirty) return errors
            !this.$v.representante.correo.required && errors.push('El correo es requerido.')
            !this.$v.representante.correo.email && errors.push('Formato de correo inválido.')
            return errors
        },
        idErrors(){
            const errors = []
            if(!this.$v.representante.cedula.$dirty) return errors
            !this.$v.representante.cedula.required && errors.push('La cédula es requerida')
            return errors
        }
    },
    data: () =>({
        dialog: false,
        loading: false,
        representante:{
            nombre: '',
            telefono: '',
            cedula:'',
            correo:'',
        },
        telMask: '####-####',
        cedMask: '#-####-####', 
    }),
    validations:{
        representante:{
            nombre:{
                required,
            },
            cedula:{
                required
            },
            telefono:{
                required
            },
            correo:{
                required,
                email
            }
        }
    },
    methods:{

        modificarRepresentante() {
            this.$v.$touch();          
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.post("/repLegal/agregar", this.representante, {params: {
                    idOrg: this.id
                }})
                .then(response => {
                    this.loading = false;
                    this.dialog = false;
                    this.$store.dispatch('noti',{mensaje: 'Representante Legal modificado', color:'success'});      
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el representante legal', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        cancelar(){
            this.representante = {};
            this.dialog = !this.dialog;
            this.loading = false;
            this.$v.representante.$reset();
        },

        getRepresentanteLegal(){
            this.loading=true;
            axios.get("/repLegal/getRepLegal/"+this.id).then(response => {
                if (response.data.repLegal != null){
                    this.representante = response.data.repLegal;
                }
                this.$emit('reload');
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })            
        }
    }

}
</script>