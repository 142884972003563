<template>
    <v-dialog
        v-model="dialog"
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
             <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon small class="mr-2" v-on="{...tooltip, ...on}"
                    @click="getDataEtiqueta()">mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
         <v-card :loading="loading">
             <v-card-title class="pa-0">
               <ModalTitle titulo="Modificar Etiqueta" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Nombre"
                        v-model="etiqueta.nombre"
                        :error-messages="nameErrors"
                        @input="$v.etiqueta.nombre.$touch()"
                        @blur="$v.etiqueta.nombre.$touch()"
                        required>
                        </v-text-field>
                    </v-col>                    
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarEtiqueta">Modificar</v-btn>
            </v-card-actions>
         </v-card>      
    </v-dialog>    
</template>

<script>
import axios from "@/axios.js";
import {required, email} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'idEtiqueta'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.etiqueta.nombre.$dirty) return errors            
            !this.$v.etiqueta.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        etiqueta: {
            nombre: ''   
        },
    }),
    validations: {
        etiqueta: {  
            nombre: {
                required
            },
        }
    },
    methods:{
        getDataEtiqueta() {
            this.loading = true;
            axios.get("/etiquetas/getDataEtiqueta/"+this.idEtiqueta).then(response => {
                this.etiqueta = response.data.etiqueta;
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarEtiqueta() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/etiquetas/modificarEtiqueta/"+this.idEtiqueta, this.etiqueta).then(response => {
                    this.loading = false;
                    this.$store.dispatch('noti',{mensaje: 'Etiqueta modificada', color:'success'});
                    this.dialog = false;
                    this.$emit('reloadEdit');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la etiqueta', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        cancelar() {
            this.etiqueta = {};
            this.dialog = !this.dialog;            
            this.$v.etiqueta.$reset();
            this.loading = false;
        },
    },
}
</script>