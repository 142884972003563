<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent 
            scrollable
            max-width="500px"
            transition="dialog-transition">
            <template v-slot:activator="{ on }">
                <v-btn color="#0B3F67" dark v-on="on">Generar Informe<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
            </template>
            <v-card :loading="loading">
                <v-card-title class="pa-0">
                    <ModalTitle titulo="Generar Informe" @cancelar="cancelar"/>
                </v-card-title> 
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select                            
                                :items="instalaciones"
                                item-text="nombre"
                                item-value="id"
                                v-model="instalacion"
                                label="Instalación"
                                return-object
                                :error-messages="instalacionErrors"             
                                @change="$v.instalacion.$touch()"
                                @blur="$v.instalacion.$touch()"
                                required
                            ></v-select>
                        </v-col>                  
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select 
                                :items="indicadores"
                                item-text="nombre"
                                item-value="id"
                                v-model="indicador"
                                label="Indicador"
                                return-object
                                :error-messages="indicadorErrors"             
                                @change="$v.indicador.$touch()"
                                @blur="$v.indicador.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select 
                                :items="anios"
                                v-model="anio"
                                label="Año"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                    <v-btn text :loading="loading" color="#0B3F67" @click.native="generarInforme">Generar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from '@/axios'
    import { required } from 'vuelidate/lib/validators'
    import ModalTitle from '@/components/utils/ModalTitleComponent'
    import { informeBitacora } from '@/pdf/InformeBitacora'
    import diccionario from '@/imageDictionary.js'
    export default {
        components: {
            ModalTitle
        },
        created() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
        },
        props: [
            'instalaciones',
            'indicadores',
            'anios'
        ],
        computed: {
            instalacionErrors () {
                const errors = []
                if (!this.$v.instalacion.$dirty) return errors
                !this.$v.instalacion.required && errors.push('El instalación es requerido')
                return errors
            },
            indicadorErrors () {
                const errors = []
                if (!this.$v.indicador.$dirty) return errors
                !this.$v.indicador.required && errors.push('El indicador es requerido')
                return errors
            },
        },
        data: () => ({
            bitacoras: [],
            loading: false,
            dialog: false, 
            instalacion: null,
            indicador: null,
            anio: null,
            docDefinition: {
                footer: function(currentPage, pageCount) { 
                    if(currentPage >= 1)
                        return {columns: [
                            { width: '10%', image: diccionario.zurqui, fit: [50, 50], alignment:'center' }
                        ]}; 
                },   
                pageSize: 'B4',
                content: []
            },
        }),
        validations: {
            instalacion: {
                required
            },
            indicador: {
                required
            },
        },
        methods: {
            cancelar() {
                this.instalacion = null
                this.indicador = null
                this.anio = null
                this.loading = false
                this.dialog = false
                this.$v.instalacion.$reset()
                this.$v.indicador.$reset()
                this.loadingModal(false);
            },
            generarInforme() {
                this.loading = true;
                this.loadingModal(true);
                axios.get(`/bitacora/listarBitacorasReporte/`, { params: {
                    instalacion: this.instalacion.id,
                    indicador: this.indicador.id,
                    anio: this.anio
                }} ).then(response => {
                    this.crearDoumento(response.data, this.instalacion, this.indicador);
                }).catch(error => {
                    if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                    } else {
                        console.log(error);
                    }
                    this.cancelar()
                })
            },
            loadingModal(modal) {                        
                this.$store.dispatch('loadingPage', modal);
            },
            crearDoumento(bitacoras, instalacion, indicador) {
                this.docDefinition.content = informeBitacora(bitacoras, instalacion, indicador)
                pdfMake.createPdf(this.docDefinition).download(`Informe bitácora.pdf`);
                this.loadingModal(false);
                this.cancelar()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>