<template>        
    <v-card color="#0B3F67">
        <v-card-text>
            <v-card class="pa-2">
                <v-list-item>                            
                    <v-list-item-content>
                        <v-list-item-title :class="[{'headline': $mq !== 'sm'}, 'text-center']">{{ titulo }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>                                                            
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: [
        'titulo'
    ]
}
</script>