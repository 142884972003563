<template>
    <v-card hover>
        <v-sheet class="v-sheet--offset mx-auto pt-" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="white--text headline text-center"> {{titulo}}  </v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text subtitle text-center">{{ anio }}</v-list-item-subtitle> 
                </v-list-item-content>
            </v-list-item>
        </v-sheet> 
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="data"
                calculate-widths
                disable-filtering
                disable-pagination
                hide-default-footer
                fixed-header>
                <template v-slot:item.detalle="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.detalle"
                        large
                        persistent
                        @save="save(props.item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close">
                        <div>{{ props.item.detalle }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                            Modificar Residuo
                            </div>
                            <v-text-field
                                v-model="props.item.detalle"
                                :rules="[max25chars]"
                                label="Modificar"
                                single-line
                                counter
                                autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.estado="{ item }">
                    <v-layout justify-center>
                        <ActivarResiduo @reload="getDataResiduos" :estado="item.estado" :id="item.id" ref="tabla"/>
                    </v-layout>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>

import axios from "@/axios.js"
import ActivarResiduo from "@/components/indicadores/ResiduosValorizables/ActivarResiduosComponent"

export default {
    props: [
        'data',        
        'anio',
        'valorizable',
        'medidores'
    ],
    components:{
        ActivarResiduo
    },

    computed: {
        titulo(){
            if (this.valorizable == 0){
                return "Generación de Residuos Valorizables (Kg)"
            }else if (this.valorizable == 1){
                return "Generación de Residuos No Valorizables (Kg)"
            }
        }      
    },
    data: () => ({
        headers:[
            {
                text: 'Detalle',
                value: 'detalle',
                align: 'center',
                sortable: false
            },{
                text: 'Estado',
                value: 'estado',
                align: 'center',
                sortable: false
            },
            {
                text: 'Enero',
                value: 'enero',
                align: 'center',
                sortable: false
            },
            {
                text: 'Febrero',
                value: 'febrero',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Marzo',
                value: 'marzo',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Abril',
                value: 'abril',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Mayo',
                value: 'mayo',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Junio',
                value: 'junio',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Julio',
                value: 'julio',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Agosto',
                value: 'agosto',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Septiembre',
                value: 'septiembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Octubre',
                value: 'octubre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Noviembre',
                value: 'noviembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Diciembre',
                value: 'diciembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Total',
                value: 'total',
                align: 'center',
                sortable: true,
                filterable: true,
            },
        ],
        max25chars: v => v.length <= 75 || 'Excede tamaño máximo!',
    }),
    methods: {
        totalMedidor(nombreMedidor) {    
            let datos = this.data;
            let total = 0;
            datos.forEach((medidor) => {
                let medidorData = Object.values(medidor);
                if(medidorData.includes(nombreMedidor)) {
                    medidorData = this.filter_list(medidorData);
                    medidorData.forEach(suma);
                    function suma(item, index){
                        if(index > 0 && index < 13){
                            total += item;
                        }
                    };
                }                
            })                        
            return total.toFixed(2);        
        },
        save (item) {
            this.loadingModal(true);
            axios.post('/gestionResiduos/modificarResiduoValorizable', null, {params: {
                residuo: item.id,
                nombre: item.detalle
            }}).then(() => {
                this.loadingModal(false);
                this.$store.dispatch('noti',{
                    mensaje: 'Residuo modificado', 
                    color:'success'
                });
            }).catch(() => {
                this.loadingModal(false);
                this.$store.dispatch('noti',{
                    mensaje: 'Error al modificar el residuo', 
                    color:'error'
                });
            })
        },
        cancel () {

        },
        open () {

        },
        close () {

        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        filter_list(l) {
            return l.filter(x => typeof x === "number");
        },
        getDataResiduos() {
            this.$refs.tabla.reloadData();
        }
    },
}
</script>
<style scoped>
    thead th {
      font-size: 15px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>