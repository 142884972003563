<template>
    <v-dialog
        v-model="dialog"        
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon small class="mr-2" v-on="{...tooltip, ...on}"
                    @click="getDataSector()">mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>            
        </template>
         <v-card :loading="loading">
             <v-card-title class="pa-0">
               <ModalTitle titulo="Modificar Sector Productivo" @cancelar="cancelar"/>
            </v-card-title>           
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Nombre"
                        v-model="productivo.nombre"
                        :error-messages="nameErrors"
                        @input="$v.productivo.nombre.$touch()"
                        @blur="$v.productivo.nombre.$touch()"
                        required>
                        </v-text-field>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-textarea label="Descripción"
                        v-model="productivo.descripcion"
                        counter="100"
                        hint="Campo Opcional"
                        outlined
                        shaped>
                        </v-textarea>
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarSector">Modificar</v-btn>
            </v-card-actions>
         </v-card>      
    </v-dialog>    
</template>

<script>
import axios from "@/axios.js";
import {required, email} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'idSector'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.productivo.nombre.$dirty) return errors            
            !this.$v.productivo.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        productivo: {
            nombre: '',
            //descripcion: '',    
        },
    }),
    validations: {
        productivo: {  
            nombre: {
                required
            },
        }
    },
    methods:{
        getDataSector() {
            this.loading = true;
            axios.get("/sector/getDataSector/"+this.idSector).then(response => {
                this.productivo = response.data.productivo;
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarSector() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/sector/modificarSector/"+this.idSector, this.productivo).then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Sector productivo modificado', color:'success'});
                    this.$emit('reloadEdit');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el sector productivo', color:'error'});
                    this.cancelar();
                  }
                })
            }
        },
        cancelar() {
            this.productivo = {};
            this.dialog = false;
            this.loading = false;            
            this.$v.productivo.$reset();
        },
    },
}
</script>