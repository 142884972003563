<template>
    <v-sheet class="v-sheet--offset mx-auto pt-" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="white--text headline text-center">{{ titulo }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-sheet>       
</template>
<script>
export default {
    props: [
        'titulo'
    ]
}
</script>