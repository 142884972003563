<template>
  <v-dialog
    v-model="sessionEnd"
    scrollable 
    persistent
    max-width="500px"
    transition="dialog-transition">
  <v-card>
    <v-card-title class="pa-0">
      <ModalTitle titulo="Sesión Finalizada" @cancelar="cerrar"/>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <div class="pa-2 subtitle-1">Su sesión finalizó, por favor vuelva a iniciar sesión.</div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="cerrar" color="#0B3F67">Aceptar</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  components: {
    ModalTitle
  },
  computed: {
    sessionEnd() {
      return this.$store.getters.getSessionDIalog;
    }
  },
  methods: {
    cerrar() {
      this.$store.commit('sucursal', {});
      this.$store.commit('usuario', {});
      this.$store.commit('organizacion', {});
      this.$store.commit('modificarToken', null);   
      this.$store.commit('inscripcion', {}); 
      this.$store.commit('fechaInscripcion', {}); 
      this.$store.commit("historialEstrella", {});  
      this.$store.commit('showSessionDialog', false);
      this.$router.push({ name: 'home' });
    }
  },
}
</script>