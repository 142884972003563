<template>
    <v-dialog v-model="dialog"
            persistent 
            scrollable
            max-width="550px"
            transition="dialog-transition">
            <template v-slot:activator="{ on }">
                <v-btn color="#0B3F67" dark v-on="on">Agregar
                    <v-icon right>mdi-plus-box-outline</v-icon>
                </v-btn>
            </template>
            <v-card :loading="loading">
                <v-card-title class="pa-0">
                  <ModalTitle titulo="Agregar Gas Refrigerante" @cancelar="cancelar"/>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field 
                                v-model="contaminante.producto"
                                label="Equipo que consume gas refrigerante"
                                :error-messages="productoError"
                                hint="Ejemplo: CAMARAS DE REFRIGERACIÓN, REFRIGERADORAS, AIRES ACONDICIONADOS FIJOS, AIRES ACONDICIONADOS DE FLOTILLA VEHICULAR, OTROS"
                                @input="$v.contaminante.producto.$touch()"
                                @blur="$v.contaminante.producto.$touch()"
                                counter="100"
                                required
                            ></v-text-field >
                        </v-col>
                        <v-col cols="12">
                            <v-text-field 
                                v-model="contaminante.presentacion"
                                label="Capacidad"
                                :error-messages="presentacionError"
                                hint="Ejemplo: Botella 100ml, Recarga, Aerosol 300g"
                                @input="$v.contaminante.presentacion.$touch()"
                                @blur="$v.contaminante.presentacion.$touch()"
                                required
                            ></v-text-field >
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="contaminante.tipoContaminante"
                                :items="tipoContaminantes"
                                item-text="nombre"
                                item-value="id"
                                label="Gas refrigerante que consume"
                                @input="$v.contaminante.tipoContaminante.$touch()"
                                @blur="$v.contaminante.tipoContaminante.$touch()"
                                :error-messages="tipoContaminanteError"
                                required
                            ></v-select>
                        </v-col>     
                        <v-col cols="12">
                            <v-select
                                v-model="tipoGei"
                                :items="listaGei"
                                item-text="nombre"
                                item-value="id"
                                label="Tipo de GEI"
                                @input="$v.tipoGei.$touch()"
                                @blur="$v.tipoGei.$touch()"
                                :error-messages="geiError"
                                required
                            ></v-select>
                        </v-col> 
                        <v-col cols="12">
                            <v-text-field
                                v-model="contaminante.tipoMantenimiento"
                                label="Tipo de mantenimiento"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <span>*GEI: Gases Efecto Invernadero</span>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="loading" text color="error" @click="cancelar">Cancelar</v-btn> 
                    <v-btn :loading="loading" text color="#0B3F67" @click="agregarContaminante"> Agregar </v-btn>
                </v-card-actions>
        </v-card>              
    </v-dialog>    
</template>

<script>
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
//Custom validations
const maxFileSize = (file) => {
    let incorrectos = 0;
    file.forEach(f =>
    {
        if (f.size > 1024 * 1024)
            incorrectos = 1
    })
    return incorrectos === 0;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        productoError () {
            const errors = []
            if (!this.$v.contaminante.producto.$dirty) return errors            
            !this.$v.contaminante.producto.required && errors.push('Campo requerido')  
            !this.$v.contaminante.producto.maxLength && errors.push('No debe exceder el tamaño permitido.')           
            return errors
        },
        tipoContaminanteError () {
            const errors = []
            if (!this.$v.contaminante.tipoContaminante.$dirty) return errors            
            !this.$v.contaminante.tipoContaminante.required && errors.push('El tipo de gas refrigerante es requerido.')            
            return errors
        },
        geiError () {
            const errors = []
            if (!this.$v.tipoGei.$dirty) return errors            
            !this.$v.tipoGei.required && errors.push('El tipo de GEI es requerido.')            
            return errors
        },
        presentacionError () {
            const errors = []
            if (!this.$v.contaminante.presentacion.$dirty) return errors            
            !this.$v.contaminante.presentacion.required && errors.push('Campo requerido')            
            return errors
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        contaminante: {
            producto:'',
            tipoContaminante: '',
            presentacion: '',
            tipoMantenimiento: '',
        },
        tipoGei: '',
        tipoContaminantes: [
            {id:1,nombre:'R22'},
            {id:2,nombre:'R23'},
            {id:3,nombre:'R32'},
            {id:4,nombre:'R123'},
            {id:5,nombre:'R124'},
            {id:6,nombre:'R125'},
            {id:7,nombre:'R134a'},
            {id:8,nombre:'R141'},
            {id:9,nombre:'R141b'},
            {id:10,nombre:'R142'},
            {id:11,nombre:'R142b'},
            {id:12,nombre:'R143a'},
            {id:13,nombre:'R152a'},
            {id:14,nombre:'R402a'},
            {id:15,nombre:'R402b'},
            {id:16,nombre:'R404a'},
            {id:17,nombre:'R404b'},
            {id:18,nombre:'R407c'},
            {id:19,nombre:'R410a'},
            {id:20,nombre:'R507'},
            {id:21,nombre:'R508b'},
            {id:22,nombre:'Refrigerantes naturales'},
        ],
        listaGei: [
            {id:1, nombre:'CFC - Clorofluorocarbonos'},
            {id:2, nombre:'HCFC - Hidroclorofluorocarbonos'},
            {id:3, nombre:'HFC - Hidrofluorocarbonos'},
            {id:4, nombre:'PFC - Perfluorocarbonos'},
            {id:5, nombre:'SF6 - Hexafluoruro de azufre'},
            {id:6, nombre:'NF3 - Trifluoruro de nitrógeno'},
        ],
    }),
    validations: {
        contaminante: {
            producto: {
                maxLength: maxLength(100),
                required,
            },
            tipoContaminante: { 
                required,               
            },
            presentacion: {
                required,
            }
        },
        tipoGei: {
            required,
        },
    },
    methods: {
        cancelar() {
            this.contaminante = {};
            this.tipoGei = '';
            this.dialog = !this.dialog;            
            this.$v.contaminante.$reset();
            this.$v.tipoGei.$reset();
            this.loading = false;
        },
        agregarContaminante() {
          this.$v.$touch();
          if(!this.$v.$invalid) {  
            this.loading = true;
            let formData = new FormData();
            formData.append("contaminanteS", JSON.stringify(this.contaminante));
            formData.append("idInstalacion", this.instalacion.id);
            formData.append("idUsuario", this.usuario.id);
            formData.append("gei", this.tipoGei);
            formData.append("anio", this.anioConsulta);
            axios.post("/productoContaAtmosf/agregarContaminante", formData)
            .then(response => {
                this.$emit('recargaTabla');                                                 
                this.$store.dispatch('noti',{mensaje: 'Gas Refrigerante agregado', color:'success'});
                this.cancelar();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                if (error.request && error.request.status === 415) {
                  this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                  this.loading = false;
                } else {
                  this.loading = false;
                  this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el gas refrigerante', color:'error'});
                  this.dialog = !this.dialog;
                }
              }
            }).finally(() => {
              this.loading = false;
            })
          }
        },
    }
}
</script>