<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent
    max-width="350px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-icon class="mr-2" color="#0B3F67" v-on="{ ...dialog, ...tooltip }">mdi-trash-can-outline</v-icon>                      
        </template>
        Eliminar
      </v-tooltip>              
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Eliminar Incripción" @cancelar="cancelar"/>
      </v-card-title> 
      <v-divider></v-divider>
      <v-card-text class="title">
        ¿Desea eliminar esta Incripción?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
        <v-btn @click="eliminar" text color="#0B3F67">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from '@/axios'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  props: [
    'inscripcion'
  ],
  components: {
      ModalTitle
    },
  created() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
  }, 
  computed: {
    usuario(){
      return this.$store.getters.usuario;
    }
  },
  data:() => ({
    dialog: false
  }),
  methods: {
    eliminar() {
      this.$store.dispatch('loadingPage', true);
      axios.post("/instalaciones/eliminarIncripcion/", null, 
      { params: {
        inscripcion: this.inscripcion.id,
        usuario: this.usuario.id
      }}).then(response => {
        this.$store.dispatch('loadingPage', false);
        this.$store.dispatch('noti',{mensaje: 'Inscripción legal eliminada', color:'success'});
        this.$emit('reload');
        this.cancelar();
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          this.$store.dispatch('loadingPage', false);
          this.$store.dispatch('noti',{mensaje: 'No se pudo eliminar la Inscripción legal', color:'error'});                    
          this.cancelar();
        }
      })
    },
    cancelar() {
      this.dialog = !this.dialog
    }
  }
}
</script>