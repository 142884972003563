<template>
    <v-container fluid class="pt-1">
        <!-- <v-row justify="end" class="year">
            <v-col cols="2" offset-md="10" align-self="center" v-if="notAdmin">
                <span class="title">{{ anioConsulta }}</span>
                <AnioSelect :origen="origen" @reload="getItems" />
            </v-col>
        </v-row> -->
        <v-row>
            <v-col cols="12">
                <detalleInstalacion :alert="alert" :indicadores="indicadores" :navParams="navParams"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DetalleInstalacion from "@/components/instalaciones/DetAvanceInstalacionComponent";
import AnioSelect from "@/components/utils/MenuAnioComponent";
import axios from "@/axios.js";

export default {
    components: {
        DetalleInstalacion,
        AnioSelect
    },
    mounted() {
        this.getItems();
    },
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
        axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    },
    computed: {
        constantes() {
            return this.$store.getters.constantes;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        notAdmin() {
            return this.$store.getters.usuario.rol !== "ADMIN" ? true : false;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        origen() {
            return this.constantes.aniosActuales;
        }
    },
    data: () => ({
        indicadores: [],
        alert: false,
        navParams: []
    }),
    methods: {
        getItems() {
            axios.get("/manejoAnios/getItems/" + this.instalacion.id, {
            params: {
                anio: this.anioConsulta
            }}).then(response => {
                this.indicadores = response.data.indicadores;
                if(this.indicadores == null || this.indicadores.length == 0) {
                    this.alert = true;
                }else{
                    this.updateNavParams(this.indicadores);
                    this.alert = false;
                }
                this.$store.dispatch("loadingPage", false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.$store.dispatch("loadingPage", false);
                console.log(error);
              }
            });
        },
        updateNavParams(indicadores) {
            indicadores.forEach(indicador => {
                indicador.items.forEach(paarmetro => {
                    this.navParams.push(paarmetro)
                });
            });
        },
        reloadData() {
            this.getItems();
        }
    }
};
</script>