var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getDataInstalaciones()}}},Object.assign({}, tooltip, on)),[_vm._v(" mdi-account-group ")])]}}],null,true)},[_c('span',[_vm._v("Configurar Comité Ambiental")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"pa-0"},[_c('ModalTitle',{attrs:{"titulo":"Configurar Comité Ambiental"},on:{"cancelar":_vm.cancelar}})],1),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.usuario.nombre)+" "+_vm._s(_vm.usuario.apellido)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pb-0"},[_c('div',[_vm._v("Seleccione el año de la instalación a la que desea asociar al usuario")])]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-virtual-scroll',{attrs:{"items":_vm.instalaciones,"item-height":100,"bench":"1","height":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-card',[_c('v-card-title',{staticClass:"text-md-subtitle-2 text-xs-h6"},[_vm._v(" "+_vm._s(item.nombre)+" ")]),_c('v-card-text',[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""},model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}},_vm._l((item.aniosInscripcion),function(anio){return _c('v-slide-item',{key:anio,attrs:{"value":((item.id) + "-" + anio)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"input-value":active,"active-class":"teal lighten-1 white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(anio)+" ")])]}}],null,true)})}),1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","disabled":_vm.loading,"text":""},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#0B3F67","loading":_vm.loading,"text":""},on:{"click":_vm.configurarComite}},[_vm._v(" Modificar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }