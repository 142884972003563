<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent
    max-width="700px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn  icon v v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-btn>
        </template>
        Configurar Parámetros
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Configurar Parámetros de Categoria" @cancelar="cerrar"/>
      </v-card-title>
      <v-card-text>
        <v-card flat v-sticky="{ zIndex: 4, stickyTop: 1}">
          <v-row align-self="center">
            <v-col cols="12" class="pb-0">
              <v-avatar class="mr-3" size="55" tile>
                <img :src="logoCategoria" contain alt="categoria"/>
              </v-avatar>
              Categoria {{ categoria.nombre }}
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="6" offset="3">
            <v-select
              @change="getParametros"
              :items="anios"
              v-model="anioSelected"
              label="Seleccionar Año"
            ></v-select>
          </v-col>
          <v-col cols="12">
              <v-list two-line>
                <v-list-item-group
                  v-model="selected"
                  active-class="indigo--text"
                  multiple>
                  <template v-for="(item, index) in parametros" >
                    <v-list-item :value="item.id">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.indicador.nombre"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.descripcion"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.puntaje"></v-list-item-action-text>
                          <v-icon
                            v-if="!active"
                            color="grey lighten-1">
                            mdi-check-outline
                          </v-icon>
                          <v-icon
                            v-else
                            color="green darken-3">
                            mdi-check-bold
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < parametros.length - 1"
                      :key="item.nombre"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="cerrar">Cancelar</v-btn>
        <Confirm :categoria="categoria.nombre" :anioEncurso="anioSelected" @userSelection="handleSelection"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from '@/axios'
import VueSticky from 'vue-sticky'
import ModalTitle from '@/components/utils/ModalTitleComponent'
import Confirm from '@/components/categorias/AdvertenciaComponent'
export default {
  props: [
    'categoria',
    'anios'
  ],
  components: {
    ModalTitle,
    Confirm
  },
  directives: {
    'sticky': VueSticky,
  },
  computed: {
    logoCategoria() {
      return require('@/assets/' +  this.categoria.logo)
    },
    usuario() {
      return this.$store.getters.usuario;
    }
  },
  data: () => ({
    dialog: false,
    parametros: [],
    selected: [],
    anioSelected: null
  }),
  methods: {
    handleSelection(selection) {
      switch (selection) {
        case 'aceptar':
          this.agregarParametros();
          break;
        default:
          this.cerrar();
          break;
      }
    },
    getParametros() {
      this.loadingModal(true);
      axios.get(`/categoria/getParametrosTodos/${this.categoria.id}/${this.anioSelected}`).then(response => {
        this.parametros = response.data.parametros;
        this.selected = response.data.paramCategoria;
        this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);
          this.loadingModal(false);
        }
      })
    },
    agregarParametros() {
      this.loadingModal(true); 
      let formData = new FormData();
      formData.append('parametros', this.selected);
      formData.append('usuario', this.usuario.id);
      formData.append('categoria', this.categoria.id);
      formData.append('anio', this.anioSelected);
      axios.post('/categoria/configurarParametrosCategoria', formData)
      .then(response => {
        this.$store.dispatch('noti',{mensaje: 'Categoria ' + this.categoria.nombre + ', configurada', color:'success'});  
        this.loadingModal(false); 
        this.cerrar();
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          this.$store.dispatch('noti',{mensaje: 'Error al configurar la categoria ' + this.categoria.nombre + '.', color:'error'});  
          this.loadingModal(false); 
        }
      })
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
    cerrar() {
      this.dialog = !this.dialog;
      this.selected = [];
      this.anioSelected = null
      this.parametros = []
    }
  },
}
</script>
<style scoped>
.titleHeader {
  height: 80px;
}
</style>