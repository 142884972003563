<template>
    <v-data-table
        :headers="headers"
        :items="items"        
        item-key="id"
        :search="search">  
        <template v-slot:item.action="{ item }">
            <Modificar @reload="reload" :cuestionario="item.id"/>
        </template>      
        <template v-slot:item.fechaRegistro="{ item }">
            {{ fechaFormat(item.fechaRegistro) }}
        </template>
        <template v-slot:item.descargar="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon color="#0B3F67" v-on="on" @click="descargar(item.id)">mdi-download</v-icon>                                                                
                </template>
                Descargar
            </v-tooltip>                
        </template>
    </v-data-table>
</template>
<script>

import Modificar from '@/components/parametros/InscripcionLegal/ModificarCuestComponent'
import axios from '@/axios.js'
import moment from 'moment'

export default {
    props: [
        'search',
        'items'
    ],
    components: {
        Modificar
    },
    data: () => ({
        headers: [            
            {
                text: "Acciones",
                value: "action",
                align: "center",
            },
            {
                text: "Nombre",
                value: "nombre",
                align: "center",
            },
            {
                text: "Año",
                value: "anio",
                align: "center",
            },
            {
                text: "Registrado",
                value: "fechaRegistro",
                align: "center",
            },
            {
                text: "Descargar",
                value: "descargar",
                align: "center",
            },        
        ]
    }),
    methods: {
        descargar(cuestionario) {
            axios({
                url: '/cuestionario/descargar/' + cuestionario,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
        fechaFormat(fecha) {
            return moment(fecha).format('DD/MM/YYYY');
        },
        reload() {
            this.$emit('reload');
        }
    },
}
</script>