<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Manual de Usuario"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align-self="center">
                <Upload v-if="rolUsuario === 'ADMIN'" @reload="getManual()"/>
                <Download v-if="manual != null" :documentoId="documentoId"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" offset-md="3">
                <v-card v-if="manual != null">
                    <v-card-title>
                        <v-btn :disabled="page == 1" icon color="#0B3F67" @click="page--">
                            <v-icon>mdi-arrow-left-thick</v-icon>
                        </v-btn>
                            {{ page }}/{{ pageCount }} 
                        <v-btn :disabled="page == pageCount" icon color="#0B3F67" @click="page++">
                            <v-icon>mdi-arrow-right-thick</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        {{ nombre }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <vue-pdf-embed ref="pdfRef" :page="page" @rendered="handleDocumentRender" height="800" :source="manual"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "@/axios";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Upload from '@/components/documentos/AgregarManualUsuario'
import Download from '@/components/documentos/DescargarManualUsuario'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
export default {
    components: {
        TituloPrincipal,
        VuePdfEmbed,
        Download,
        Upload
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getManual();
    },
    computed: {
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
    },
    data: () => ({
        manual: null,
        page: 1,
        pageCount: 1,
        nombre: null,
        documentoId: null
    }),
    methods: {
        getManual() {
            this.loadingModal(true);
            axios.get(`/documento/getManualUsuario/`).then(response => {
                this.manual = response.data.manualUsuario;
                this.nombre = response.data.nombre;
                this.documentoId = response.data.documentoId;
                this.loadingModal(false);
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
                } else {
                console.log(error);
                }
                this.loadingModal(false);
            })
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>