<template>
    <v-container fluid>
        <logo @cambiaAnio="getEventoClimaticos" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Eventos Climáticos"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <agrModEvento 
                    @reload="getEventoClimaticos" 
                    tipoAccion="Agregar" 
                    :instalacion="instalacion" 
                    :usuario="usuario"
                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Historial de Eventos Climáticos"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <AgrModEvento 
                                    :eventoProp="item" 
                                    @reload="getEventoClimaticos" 
                                    tipoAccion="Modificar" 
                                    :instalacion="instalacion" 
                                    :usuario="usuario"
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                                <Eliminar
                                    requestUrl="/adaptacion/eliminarEventoClimatico"
                                    :requestData="requestData(item)" 
                                    :mensaje="eliminarMsg"
                                    @reload="getEventoClimaticos"/>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import AgrModEvento from '@/components/parametros/Adaptacion/AgregarEventoComponent'
import Eliminar from '@/components/utils/EliminarComponent'
import axios from '@/axios.js'

export default {
    components: {
        TituloPrincipal,
        TituloSimple,
        AgrModEvento,
        NavParameters,
        Logo,
        Eliminar
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getEventoClimaticos();
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        }
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Evento Climático',
                value: 'evento',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Impacto Ocasionado',
                value: 'impacto',
                align: 'left',
                width: '40%',
                sortable: false
            },
            {
                text: 'Año del Evento',
                value: 'anioEvento',
                align: 'center',
                width: '20%',
                sortable: false
            },
        ],
        items: [],
        search: null,
        eliminarMsg: '¡Advertencia!, Eliminar este Evento Climático eliminará las acciones de adatación, plan de acción y seguimiento al plan de acción de este evento, así como las evidencias respectivas.'
    }),
    methods: {
        getEventoClimaticos() {
            axios.get("/adaptacion/getEventosClimaticos/" + this.instalacion.id + "/" +this.anioConsulta)
            .then(response => {
                this.items = response.data;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        requestData(evento) {
            let formData = new FormData();
            formData.append('evento', evento.id);
            formData.append('idParametro', this.parametroActual);
            formData.append('usuario', this.usuario.id);
            return formData;
        }
    },
}
</script>