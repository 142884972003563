<template>
    <v-row justify="center">
        <v-dialog v-model="confirm.show" persistent max-width="290">
            <v-card>
                <v-card-title class="pa-0">
                  <ModalTitle titulo="Está seguro?" @cancelar="cancela"/>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-3">{{confirm.mensaje}}</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="cancela">Cancelar</v-btn>
                    <v-btn color="#0B3F67" text @click="acepta">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    computed: {
        confirm() {
            return this.$store.getters.confirmaData;
        }
    },
    components: {
      ModalTitle
    },
    methods: {
        acepta() {
            this.$store.commit('resolveData', true);
            this.$store.dispatch('confirma', {mensaje: '', show: false});
        },
        cancela() {
            this.$store.commit('resolveData', false)
            this.$store.dispatch('confirma', {mensaje: '', show: false});
        }
    }
};
</script>