<template>
    <v-container fluid>  
        <logo @cambiaAnio="reloadData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal :titulo="'Datos de consumo de gases refrigerantes'"></TituloPrincipal>
            </v-col>
        </v-row>
        <v-row>            
            <v-col v-for="gei in geis" cols="12" :key="gei.medidor">
                <v-container fluid v-if="!vacio(gei.historial)">
                    <TablaIndicador 
                        :historial="gei.historial" 
                        :anio="anioConsulta" 
                        :gei="geiNombre(gei.medidor)"/>
                    <v-divider></v-divider>
                    <v-card>
                        <AgregarHistorial 
                            :gei="gei.medidor" 
                            :historiales="gei.historial"
                            :anioConsulta="anioConsulta" 
                            @agregaConsumo="reloadData"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                        <ModificarConsumo 
                            :gei="gei.medidor" 
                            :historiales="gei.historial"
                            :anioConsulta="anioConsulta"
                            @modificarHistorial="reloadData"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                    </v-card>
                    <br>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "@/axios";
import TituloPrincipal from "@/components/utils/TituloPrincipalComponent";
import AgregarHistorial from "@/components/parametros/ContaminantesAtmosfericos/AgregarContaHistorialComponent";
import TablaIndicador from "@/components/parametros/ContaminantesAtmosfericos/HistorialContaTablaComponent"
import ModificarConsumo from '@/components/parametros/ContaminantesAtmosfericos/ModificarConsumoContaComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    components: {
        TablaIndicador,
        AgregarHistorial,
        TituloPrincipal,
        ModificarConsumo,
        NavParameters,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted(){
        this.reloadData();
    },
    data: () => ({
        geis: [],
    }),
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
    },
    methods: {
        vacio(historial) {
            return historial.length == 0
        },
        reloadData() {
            this.getConsumo();
        },
        getConsumo() {
            axios.post("/historialConta/getHistorialConsumo/",null,{params:{idInstalacion: this.sucursal.id, anio: this.anioConsulta }})
            .then(response => {
                this.geis = response.data.geis;  
                this.$store.dispatch('loadingPage', false);          
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener el historial de consumo: " + error);
                this.$store.dispatch('loadingPage', false);
              }
            })
        },
        geiNombre(gei) {
            return (
                gei == 1 ? 'Dióxido de carbono (CO2)' :
                gei == 2 ? 'Metano (NH4)' : 'Óxido nitroso (N2O)'
            );
        },
    }
}
</script>