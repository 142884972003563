<template>
    <v-container fluid>
        <logo @cambiaAnio="cambiaAnio" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <div class="pt-3">
            <v-tabs v-model="tab"
                center-active
                fixed-tabs
                show-arrows
                @change="changeTab"
                background-color="#0B3F67"
                color="white"
                dark>
                <v-tab v-for="tab in tabs" :key="tab.id">
                    {{ tab.nombre }}
                </v-tab>
            </v-tabs>
        </div>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <PrimEstrellaBlanca ref="estrellaUno"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellasBlancas ref="estrellaDos" :tipo="2"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellasBlancas ref="estrellaTres" :tipo="3"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellasBlancas ref="estrellaCuatro" :tipo="4"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellasBlancas ref="estrellaCinco" :tipo="5"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellasBlancas ref="estrellaSeis" :tipo="6"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellaVerde ref="estrellaSiete" :tipo="7"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellaPlateada ref="estrellaOcho" :tipo="8"/>
            </v-tab-item>
            <v-tab-item>
                <EstrellaNaranja ref="estrellaNueve" :tipo="9"/>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>

import PrimEstrellaBlanca from '@/components/Estrellas/PrimEstrellaBlancaComponent'
import EstrellasBlancas from '@/components/Estrellas/EstrellasBlancasComponent'
import EstrellaVerde from '@/components/Estrellas/EstrellaVerdeComponent'
import EstrellaPlateada from '@/components/Estrellas/EstrellaPlateadaComponent'
import EstrellaNaranja from '@/components/Estrellas/EstrellaNaranjaComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'

export default {
    directives: {
        'sticky': VueSticky,
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    components: {
        PrimEstrellaBlanca,
        EstrellasBlancas,
        EstrellaVerde,
        EstrellaPlateada,
        EstrellaNaranja,
        Logo
    },
    data: () => ({
        tab: 0,
        tabs: [
            { id: 1, nombre: 'Primera Estrella', component: "PrimEstrellaBlanca" },
            { id: 2, nombre: "Segunda Estrella", component: "EstrellasBlancas" },
            { id: 3, nombre: "Tercera Estrella", component: "EstrellasBlancas" },
            { id: 4, nombre: "Cuarta Estrella", component: "EstrellasBlancas" },
            { id: 5, nombre: "Quinta Estrella", component: "EstrellasBlancas" },
            { id: 6, nombre: "Sexta Estrella", component: "EstrellasBlancas" },
            { id: 7, nombre: "Estrella Verde", component: "EstrellaVerde" },
            { id: 8, nombre: "Estrella Plateada", component: "EstrellaPlateada" },
            { id: 9, nombre: "Estrella Anaranjada", component: "EstrellaNaranja" },
        ],
    }),
    computed: {
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
    },
    methods: {
        obtieneEstrellas() {
            return new Promise((resolve, reject) => {
                axios.get("/estrellas/historialEstrellas/" + this.idInstalacion + '/' + this.anioConsulta)
                .then(response => {
                    let historial = response.data.historial;
                    if(historial != null){
                        this.$store.commit("historialEstrella", historial.estados);
                    }
                    this.loadingModal(false);
                    resolve(response);
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    reject(error);
                    this.loadingModal(false);
                  }
                });
            })
        },
        historialEstado(tab) {
            return this.$store.getters.historialEstrella[tab];
        },
        cambiaAnio() {
            switch(this.tab) {
                case 0:
                    this.$refs.estrellaUno.getItems();
                    break;
                case 1:
                    this.$refs.estrellaDos.getData();
                    break;
                case 2:
                    this.$refs.estrellaTres.getData();
                    break;
                case 3:
                    this.$refs.estrellaCuatro.getData();
                    break;
                case 4:
                    this.$refs.estrellaCinco.getData();
                    break;
                case 5:
                    this.$refs.estrellaSeis.getData();
                    break;
                case 6:
                    this.$refs.estrellaSiete.listaHogares();
                    break;
                case 7:
                    this.$refs.estrellaOcho.getData();
                    break;
                case 9:
                    this.$refs.estrellaNueve.getData();
                    break;
            }
            this.loadingModal(false);
        },
        changeTab() {
            this.loadingModal(true);
            setTimeout(function () {this.cambiaAnio()}.bind(this), 1000);
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
};
</script>