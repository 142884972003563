<template>
    <v-dialog
        v-model="dialog"        
        persistent
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on"
            @click="getDatapoliticaOperativa()">mdi-pencil</v-icon>
        </template>
         <v-card :loading="loading">
             <v-card-title class="pa-0">
               <ModalTitle titulo="Modificar Política Operativa" @cancelar="cancelar"/>
            </v-card-title>            
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Nombre"
                        v-model="politicaOperativa.nombre"
                        :error-messages="nameErrors"
                        @input="$v.politicaOperativa.nombre.$touch()"
                        @blur="$v.politicaOperativa.nombre.$touch()"
                        required>
                        </v-text-field>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-textarea label="Descripción"
                        v-model="politicaOperativa.descripcion"
                        counter="100"
                        hint="Campo Opcional"
                        outlined
                        shaped>
                        </v-textarea>
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarpoliticaOperativa">Modificar</v-btn>
            </v-card-actions>
         </v-card>      
    </v-dialog>    
</template>

<script>
import axios from "@/axios.js";
import {required, email} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'idPolitica'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },    
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.politicaOperativa.nombre.$dirty) return errors            
            !this.$v.politicaOperativa.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        politicaOperativa: {
            nombre: '',
            //descripcion: '',    
        },
    }),
    validations: {
        politicaOperativa: {  
            nombre: {
                required
            },
        }
    },
    methods:{
        getDatapoliticaOperativa() {
            this.loading = true;
            axios.get("/politicaOperativa/getDatapoliticaOperativa/"+this.idPolitica).then(response => {
                this.politicaOperativa = response.data.politicaOperativa;
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarpoliticaOperativa() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/politicaOperativa/modificarpoliticaOperativa/"+this.idPolitica, this.politicaOperativa).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Política operativa modificada', color:'success'});
                    this.cancelar();
                    this.$emit('reloadEdit');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la política operativa', color:'error'});
                    this.cancelar();
                  }
                })
            }
        },
        cancelar() {
            this.politicaOperativa = {};
            this.loading = false;            
            this.$v.politicaOperativa.$reset();
            this.dialog = false;
        },
    },
}
</script>