var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","dark":"","icon":""},on:{"click":_vm.getDocumentos}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"#0B3F67"}},[_vm._v("mdi-file-multiple")])],1)]}}],null,true)},[_vm._v(" Ver Archivos Adjuntos ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('ModalTitle',{attrs:{"titulo":"Evidencias"},on:{"cancelar":_vm.cerrar}})],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documentos,"item-key":"id","no-data-text":"No hay documentos adjuntos","footerProps":_vm.footer},on:{"reload":_vm.getDocumentos},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ModicafDoc',{staticClass:"mr-2",attrs:{"documento":item.id,"inst":_vm.instalacion.id},on:{"reload":_vm.getDocumentos}})]}},{key:"item.fechaRegistro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaRegistro))+" ")]}},{key:"item.documentoId",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.descargarDocumento(item.documentoId)}}},on),[_vm._v("mdi-cloud-download-outline")])]}}],null,true)},[_vm._v(" Descargar ")])]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.cerrar}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }