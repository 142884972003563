<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="50%"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="white--text" color="#0B3F67" prepend-icon="mdi-plus">Agregar Evidencia</v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Planta de Tratamiento" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="5" align-self="center" class="text-right subtitle-1">
                        <span>Año de Particpación</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                        <v-select
                            :items="anios"
                            v-model="anio"
                            @change="$v.anio.$touch()"
                            @blur="$v.anio.$touch()"
                            label="Año de Participación"
                            :error-messages="anioErrors"  
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" offset-md="5" class="pa-0">
                        <v-alert dense type="error" v-model="alert">
                            Ya existe una evidencia para este periodo.
                        </v-alert>     
                    </v-col>
                </v-row>
                <div v-if="tanqueSep">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Diagnóstico y plan de mantenimiento</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-textarea
                                label="Diagnóstico y plan de mantenimiento"
                                outlined
                                v-model="tipoSistema.descripcion"
                                :error-messages="descripErrors"     
                                counter="500"        
                                @change="$v.tipoSistema.descripcion.$touch()"
                                @blur="$v.tipoSistema.descripcion.$touch()"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Se realizó limpieza de lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-radio-group v-model="tipoSistema.limpiezaLodos">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Permiso de funcionamiento del proveedor que realiza la limpieza</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Permiso de funcionamiento"
                                accept=".png, .jpg, .jpeg"
                                v-model="permisoFunc"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="permisoFuncErrors"             
                                @change="$v.permisoFunc.$touch()"
                                @blur="$v.permisoFunc.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Permiso de funcionamiento del proveedor que realiza tratamiento de lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Permiso sanitario"
                                accept=".png, .jpg, .jpeg"
                                v-model="permisoSani"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="permisoSaniErrors"             
                                @change="$v.permisoSani.$touch()"
                                @blur="$v.permisoSani.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row v-if="tipoSistema.limpiezaLodos">
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Constancia de tratamiento de lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Evidencia"
                                accept=".png, .jpg, .jpeg"
                                v-model="constProvee"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="constProveeErrors"             
                                @change="$v.constProvee.$touch()"
                                @blur="$v.constProvee.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
                <div v-if="alcantarillado">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Constancia de la descarga de la sede</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Evidencia"
                                accept=".png, .jpg, .jpeg"
                                v-model="tratamientoAgua"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="tratamientoAguaErrors"             
                                @change="$v.tratamientoAgua.$touch()"
                                @blur="$v.tratamientoAgua.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Constancia del tratamiento de las aguas residuales</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Evidencia"
                                accept=".png, .jpg, .jpeg"
                                v-model="constTratAgua"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="constTratAguaErrors"             
                                @change="$v.constTratAgua.$touch()"
                                @blur="$v.constTratAgua.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
                <div v-if="plantaTrat">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Permiso para vertido de aguas residuales</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Permiso para vertido"
                                accept=".png, .jpg, .jpeg"
                                v-model="permisoVertido"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="permisoErrors"             
                                @change="$v.permisoVertido.$touch()"
                                @blur="$v.permisoVertido.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Reporte operacional</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Reporte operacional"
                                accept=".png, .jpg, .jpeg"
                                v-model="reporteOperativo"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="repOperErrors"             
                                @change="$v.reporteOperativo.$touch()"
                                @blur="$v.reporteOperativo.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Reporte de datos de manejo integral de lodos y bisólidos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Reporte de datos"
                                accept=".png, .jpg, .jpeg"
                                v-model="reporteDatos"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="reporteDatosErrors"             
                                @change="$v.reporteDatos.$touch()"
                                @blur="$v.reporteDatos.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Comprobante de pago del canon</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Comprobante de pago del canon"
                                accept=".png, .jpg, .jpeg"
                                v-model="comprobantePago"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="comprobanteErrors"             
                                @change="$v.comprobantePago.$touch()"
                                @blur="$v.comprobantePago.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn :disabled="alert" :loading="loading" color="#0B3F67" text @click="agregarParametro">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>  
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import axios from "@/axios.js";
//Custom validations
const maxMultiFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });
        return total < maximo;
    }
    return true;
}
export default {
    mounted() {
        this.getAnioParticipacion();
    },
    components: {
      ModalTitle
    },
    props: [
        'sistemaActual'
    ],
    computed: {
        constantes() {
            return this.$store.getters.constantes;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        plantaTrat() {
            return this.sistemaActual.sistema === 1 ? true : false;
        },
        tanqueSep() {
            return this.sistemaActual.sistema === 2 ? true : false;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },

        alcantarillado() {
            return this.sistemaActual.sistema === 3 ? true : false;
        },
        anioErrors () {
            const errors = []
            if (!this.$v.anio.$dirty) return errors            
            !this.$v.anio.required && errors.push('Dato requerido.')
            !this.$v.anio.uniqueAnio && errors.push('Ya existe un Sistema de Tratamiento para este año')
            return errors
        },
        //tanque septico
        permisoFuncErrors () {
            const errors = []
            if (!this.$v.permisoFunc.$dirty) return errors            
            !this.$v.permisoFunc.required && errors.push('Evidencia requerida.')  
            !this.$v.permisoFunc.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.permisoFunc != null ? this.permisoFunc.length : 0}MB.`)       
            return errors
        },
        permisoSaniErrors () {
            const errors = []
            if (!this.$v.permisoSani.$dirty) return errors            
            !this.$v.permisoSani.required && errors.push('Evidencia requerida.')
            !this.$v.permisoSani.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.permisoSani != null ? this.permisoSani.length : 0}MB.`)
            return errors
        },
        constProveeErrors () {
            const errors = []
            if (!this.$v.constProvee.$dirty) return errors            
            !this.$v.constProvee.required && errors.push('Evidencia requerida.')
            !this.$v.constProvee.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.constProvee != null ? this.constProvee.length : 0}MB.`)
            return errors
        },
        //alcantarillado
        tratamientoAguaErrors () {
            const errors = []
            if (!this.$v.tratamientoAgua.$dirty) return errors            
            !this.$v.tratamientoAgua.required && errors.push('Evidencia requerida.')
            !this.$v.tratamientoAgua.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.tratamientoAgua != null ? this.tratamientoAgua.length : 0}MB.`)
            return errors
        },
        constTratAguaErrors () {
            const errors = []
            if (!this.$v.constTratAgua.$dirty) return errors            
            !this.$v.constTratAgua.required && errors.push('Evidencia requerida.')
            !this.$v.constTratAgua.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.constTratAgua != null ? this.constTratAgua.length : 0}MB.`)
            return errors
        },
        //Planta de tratamiento
        permisoErrors () {
            const errors = []
            if (!this.$v.permisoVertido.$dirty) return errors            
            !this.$v.permisoVertido.required && errors.push('Evidencia requerida.')  
            !this.$v.permisoVertido.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.permisoVertido != null ? this.permisoVertido.length : 0}MB.`)
            return errors
        },
        repOperErrors () {
            const errors = []
            if (!this.$v.reporteOperativo.$dirty) return errors            
            !this.$v.reporteOperativo.required && errors.push('Evidencia requerida.')  
            !this.$v.reporteOperativo.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.reporteOperativo != null ? this.reporteOperativo.length : 0}MB.`)
            return errors
        },
        reporteDatosErrors () {
            const errors = []
            if (!this.$v.reporteDatos.$dirty) return errors            
            !this.$v.reporteDatos.required && errors.push('Evidencia requerida.')  
            !this.$v.reporteDatos.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.reporteDatos != null ? this.reporteDatos.length : 0}MB.`)       
            return errors
        },
        comprobanteErrors () {
            const errors = []
            if (!this.$v.comprobantePago.$dirty) return errors            
            !this.$v.comprobantePago.required && errors.push('Evidencia requerida.')  
            !this.$v.comprobantePago.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.comprobantePago != null ? this.comprobantePago.length : 0}MB.`)       
            return errors
        },  
        descripErrors () {
            const errors = []
            if (!this.$v.tipoSistema.descripcion.$dirty) return errors            
            !this.$v.tipoSistema.descripcion.required && errors.push('Dato requerido.')      
            !this.$v.tipoSistema.descripcion.maxLength && errors.push('Este dato excede el tamaño permitido.')            
            return errors
        },
    },
    data: () => ({
        dialog: false,
        tipoSistema: {
            descripcion: null,
            legislacion: false,
            limpiezaLodos: false
        },
        anios: [],
        alert: false,
        loading: false,
        anio: null,
        //alcantarillado
        tratamientoAgua: null,
        constTratAgua: null,
        //planta tratamiento
        permisoVertido: null,
        reporteOperativo: null,
        reporteDatos: null,
        comprobantePago: null,
        //tanque septico
        permisoFunc: null,
        permisoSani: null,
        constProvee: null
    }),
    validations: {
        tipoSistema: {
            descripcion: {
                maxLength: maxLength(500),
                required: requiredIf(function(){
                    return (!this.alcantarillado && !this.plantaTrat) 
                })               
            },
        },
        anio: {
            required,
            uniqueAnio(anio) {
                if (anio === '' || anio === null) return true
                return new Promise((resolve, reject) => {
                    axios.get("/instalaciones/verificaPlantaTrat/" + anio + '/' +this.instalacion.id).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        reject(error);
                      }
                    })
                })
            }
        },
        //alcantarillado
        tratamientoAgua: {
            required: requiredIf(function(){
                return this.alcantarillado
            }),
            maxMultiFileSize
        },
        constTratAgua: {
            required: requiredIf(function(){
                return this.alcantarillado
            }),
            maxMultiFileSize
        },
        //tanque septico
        permisoFunc: {
            required: requiredIf(function(){
                return (this.tanqueSep && this.tipoSistema.limpiezaLodos)
            }),
            maxMultiFileSize
        },
        permisoSani: {
            required: requiredIf(function(){
                return (this.tanqueSep && this.tipoSistema.limpiezaLodos)
            }),
            maxMultiFileSize
        },
        constProvee: {
            required: requiredIf(function(){
                return (this.tanqueSep && this.tipoSistema.limpiezaLodos)
            }),
            maxMultiFileSize
        },
        //planta de tratamiento
        permisoVertido: {
            required: requiredIf(function(){
                return this.plantaTrat
            }),
            maxMultiFileSize
        },
        reporteOperativo: {
            required: requiredIf(function(){
                return this.plantaTrat
            }),
            maxMultiFileSize
        },
        reporteDatos: {
            required: requiredIf(function(){
                return this.plantaTrat
            }),
            maxMultiFileSize
        },
        comprobantePago: {
            required: requiredIf(function(){
                return this.plantaTrat
            }),
            maxMultiFileSize
        }
    },
    methods: {
        agregarParametro() {
            this.$v.$touch();
            if(!this.$v.$invalid && !this.alert) {
                this.loading = true;
                let formData = this.agregarEvidencias();
                axios.post("/instalaciones/agregarTipoSistema", formData) 
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Evidencia agregada', color: 'success'});
                    this.cancelar();
                }).catch(error => { 
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$emit('reload');
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la evidencia', color: 'error'});
                      this.cancelar();
                    }
                  }
                })
            }
        },
        cancelar() {
            this.tipoSistema = {
                descripcion: '',
                legislacion: false,
                limpiezaLodos: false
            };
            this.anio = null;
            this.alert = false;
            this.$v.tipoSistema.$reset();
            this.$v.anio.$reset();
            this.loading = false; 
            this.dialog = false;
            //planta de tratamiento
            this.permisoVertido = null;
            this.reporteOperativo = null;
            this.reporteDatos = null;
            this.comprobantePago = null;
            this.$v.reporteOperativo.$reset();
            this.$v.reporteDatos.$reset();
            this.$v.comprobantePago.$reset();
            this.$v.permisoVertido.$reset();
            //tanque septico
            this.permisoFunc = null;
            this.permisoSani = null;
            this.constProvee = null;
            this.$v.permisoFunc.$reset();
            this.$v.permisoSani.$reset();
            this.$v.constProvee.$reset();
            //alcantarillado
            this.tratamientoAgua = null;
            this.constTratAgua = null;
            this.$v.constTratAgua.$reset();
            this.$v.tratamientoAgua.$reset();
        },
        getAnioParticipacion() {
            this.anios = [];
            this.anios.push(this.anioConsulta);                
        },
        agregarEvidencias() {
            let formData = new FormData();
            
            if(this.plantaTrat) {
                this.comprobantePago.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.COMPROBANTE_PAGO]);
                });
                this.reporteDatos.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.REPORTE_DATOS]);
                });
                this.reporteOperativo.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.REPORTE_OPERACIONAL]);
                });
                this.permisoVertido.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.PERMISO_VERTIDO]);
                });
            }
            if(this.tanqueSep && this.tipoSistema.limpiezaLodos) {
                this.permisoFunc.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.PERMISO_PROVEEDOR]);
                });
                this.permisoSani.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.PERMISO_SANITARIO_PROV]);
                });
                if(this.tipoSistema.limpiezaLodos) {
                    this.constProvee.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.CONSTANCIA_PROVEEDOR]);
                    });
                }
            }
            if(this.alcantarillado) {
                this.tratamientoAgua.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.CONSTANCIA_DESCARGA]);
                });
                this.constTratAgua.forEach(file => {
                    formData.append('archivos', file);
                    formData.append('tipoDoc', [file.name, this.constantes.CONSTANCIA_TRAT_AGUA]);
                });
            }
            formData.append('sistema', JSON.stringify(this.tipoSistema));
            formData.append('idInstalacion', this.instalacion.id);
            formData.append('idUsuario', this.$store.getters.usuario.id);
            formData.append('anio', this.anio);
            return formData;
        },
    }
}
</script>