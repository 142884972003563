<template> 
<v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
            <v-switch class="pl-2" :loading="loading" inset @change="activarMedidor" color="#0B3F67" v-model="estadoActual"></v-switch>
        </div>
    </template>
    {{ tooltipMsg }}
</v-tooltip>
</template>
<script>

import axios from "@/axios.js";

export default {
    props: [
        'estado',
        'id'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        estadoActual: {
            get() {
                return this.estado;
            },
            set(estado) {
                this.nuevoEstado = estado;
            }
        },
        indicador() {
            return this.$store.getters.indicadorActual;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        tooltipMsg() {
            let msg = ''
            msg = this.estadoActual ? 'Inactivar' : 'Activar'
            return `${msg} para el año ${this.anioConsulta}`;
        },
        usuario() {
            return this.$store.getters.usuario;
        }
    },
    data: () => ({
        nuevoEstado: null,
        loading: false
    }),
    methods: {
        activarMedidor() {
            this.loading = true;
            axios.put("/instalaciones/activarMedidor/"+this.id, null, { params: {
                medState: this.nuevoEstado,
                indicador: this.indicador,
                anio: this.anioConsulta,
                usuario: this.usuario.id
            }}).then(response => {
                this.loading = false;
                this.$emit('reload');
                this.$store.dispatch('noti',{mensaje: 'Estado modificado', color:'success'});
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el estado', color:'error'});
              }
            })
        }
    }
};
</script>