var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.inscripciones,"item-key":"id","search":_vm.buscar,"no-data-text":"No hay inscripciones para esta Instalación","footer-props":{
            showFirstLastPage: true,
            firstIcon:'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageText: 'Registros por página',
            itemsPerPageAllText: 'Todos',
            showCurrentPage: true,
            showFirstLastPage: true
        }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('Modificar',{attrs:{"inscripcion":item},on:{"reload":_vm.listarInscripciones}}),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR' && !item.galardon)?_c('AgregarEvidencia',{attrs:{"idInscripcion":item.id,"tipo":'galardón',"titulo":'Agregar Galardón',"tooltipText":"Agregar galardón","icon":'mdi-medal-outline'},on:{"reload":_vm.listarInscripciones}}):_vm._e()]}},{key:"item.documentos",fn:function(ref){
        var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"origen":"inscripcionLegal","medidorId":item.instalacion.id,"titulo":'Documentos de Inscripción al Programa Bandera Azul'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }