<template>
  <v-row justify="center">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-img :src="logo" 
          v-on="on"
          max-height="45"
          max-width="45">
        </v-img>
      </template>
      {{ nombreCategoria }}
    </v-tooltip>
  </v-row>   
</template>
<script>
export default {
  props: [
    'instalacion'
  ],
  computed: {
    logo() {
      return require('@/assets/' + this.instalacion.logoCategoria);
    },
    nombreCategoria() {
      return this.instalacion.nombreCategoria;
    }
  }
}
</script>