import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VueMq from "vue-mq";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./ability";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(abilitiesPlugin, ability);
Vue.use(VueMq, {
  breakpoints: {
    xs: 350,
    sm: 750,
    md: 1250,
    lg: Infinity
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
