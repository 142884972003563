var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[(!_vm.tieneContaminante)?_c('v-col',{attrs:{"cols":"12"}},[_c('SinContaminantes')],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarInventario',{on:{"recargaTabla":_vm.listaInventario}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-sheet',{staticClass:"v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center",attrs:{"height":"80","color":"#0B3F67","elevation":"12","max-width":"calc(100% - 32px)"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_vm._v(" Gases Refrigerantes ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"multi-sort":"","search":_vm.search,"headers":_vm.headers,"items":_vm.inventario,"no-data-text":"Sin datos","no-results-text":"Dato no encontrado","footer-props":{
                        showFirstLastPage: true,
                        firstIcon:'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageAllText: 'Todos',
                        showCurrentPage: true,
                        showFirstLastPage: true
                    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarInventario',{attrs:{"id":item.id},on:{"reload":_vm.listaInventario}}):_vm._e()]}},{key:"item.estadoAnio",fn:function(ref){
                    var item = ref.item;
return [_c('ActivarInventario',{attrs:{"estado":item.estadoAnio,"id":item.id},on:{"reload":_vm.listaInventario}})]}},{key:"item.evidencias",fn:function(ref){
                    var item = ref.item;
return [(item.agregarDocs)?_c('AgregarDocs',{attrs:{"titulo":"Agregar Evidencia","tooltipText":"Agregar Evidencia","tipo":1,"icon":"mdi-folder-upload","id":item.id,"anio":_vm.anioConsulta},on:{"reload":_vm.listaInventario}}):_c('ListadosDocs',{attrs:{"idAccion":item.id,"medidorId":item.id,"anioConsulta":_vm.anioConsulta,"titulo":'Documentos Producto contaminante atmosférico',"origen":"contaminanteAtmosferico"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }