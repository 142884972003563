<template>
    <v-container fluid>
        <logo @cambiaAnio="listarParametros" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="titulo"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple :titulo="tituloSimple"/>
                    <v-row justify-sm="center">
                        <v-col cols="12" sm="4" md="2" align-self="center">
                            <plantaTratamiento 
                                ref="agregarPlanta"
                                :sistemaActual="sistemaTratamiento" 
                                @reload="listarParametros" 
                                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && anioConsulta < 2022"/>
                            <Planta-tratamiento-nuevo 
                                ref="agregarPlanta"
                                :sistemaActual="sistemaTratamiento" 
                                @reload="listarParametros" 
                                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && anioConsulta >= 2022"/>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" align-self="center">
                            <justificacion 
                                titulo="Justificación" 
                                tooltipText="Justificación"
                                :reqEvidencias="true" 
                                :tipoDocumento="constantes.JUSTIF_SISTEMA_TRATAMIENTO"
                                v-if="(rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR') && showJustificacion"
                            />
                        </v-col>
                        <v-col cols="12" sm="4" md="3" offset-sm="5" offset-md="3">
                            <v-text-field
                                label="Buscar"
                                v-model="search"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search"
                            sort-by="anio"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon:'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageText: 'Registros por página',
                                itemsPerPageAllText: 'Todos',                
                                showCurrentPage: true,                            
                            }">
                            <template v-slot:item.action="{ item }">
                                <modifMostrat
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && anioConsulta < 2022"
                                    tipoModal="Modificar" 
                                    color="#D59804" 
                                    icon="mdi-pencil" 
                                    :data="item" 
                                    :sistemaActual="sistemaTratamiento" 
                                    @reload="listarParametros"/>
                                <modifMostrat 
                                    v-if="anioConsulta < 2022"
                                    tipoModal="Ver Detalle" 
                                    color="#0B3F67" 
                                    icon="mdi-file-eye-outline" 
                                    :data="item" 
                                    :sistemaActual="sistemaTratamiento"/>
                                <Eliminar 
                                    v-bind:requestData="requestDataFormat(item)" 
                                    requestUrl="/instalaciones/eliminarParametroTratamientoAg/"
                                    @reload="reloadData"
                                />
                            </template>
                            <template v-slot:item.anio="{ item }">
                                {{ anioConsulta }}
                            </template>
                            <template v-slot:item.id="{ item }">
                                <ListadoDocs :medidorId="item.id" :anioConsulta="item.anio" origen="tratamientoParametro" titulo="Evidencias"/>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import ListadoDocs from '@/components/documentos/ListadoDocumentosComponent'
import PlantaTratamiento from '@/components/parametros/AguasResiduales/AgregPlantaTratComponent'
import PlantaTratamientoNuevo from '@/components/parametros/AguasResiduales/AgregPlantaTratNuevoComponent'
import ModifMostrat from '@/components/parametros/AguasResiduales/ModifMostrarSistTratAguaComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js"
import Eliminar from '@/components/utils/EliminarComponent'
import Justificacion from '@/components/utils/JustificacionComponent.vue'

export default {
    components: {
        TituloPrincipal,
        TituloSimple,
        PlantaTratamiento,
        ListadoDocs,
        ModifMostrat,
        NavParameters,
        Logo,
        Eliminar,
        Justificacion,
        PlantaTratamientoNuevo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    mounted(){
        this.listarParametros();
    },
    computed: {
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        titulo() {
            switch(this.sistemaTratamiento.sistema) {
                case 1:
                    return "Planta de Tratamiento";
                break;
                case 2: 
                    return "Tanque Séptico y Drenaje";
                break;
                case 3:
                    return "Alcantarillado Sanitario";
                break;
            }
        },
        tituloSimple() {
            switch(this.sistemaTratamiento.sistema) {
                case 1:
                    return "Historial Planta de Tratamiento";
                break;
                case 2: 
                    return "Historial Tanque Séptico y Drenaje";
                break;
                case 3:
                    return "Historial Alcantarillado Sanitario";
                break;
            }
        },
        headers() {
            switch(this.sistemaTratamiento.sistema) {
                case 1:
                    return this.headersPlanta;
                break;
                case 2: 
                    return this.headersTanque;
                break;
                case 3:
                    return this.headersAlcantarillado;
                break;
            }
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
    },
    data: () => ({
        sistemaTratamiento: {},
        certificado: null,
        headersPlanta: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '25%',
                sortable: false
            },
            {
                text: 'Año',
                value: 'anio',
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: 'Adjuntos',
                value: 'id',
                align: 'center',
                width: '10%',
                sortable: false
            },
        ], 
        headersTanque: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Año',
                value: 'anio',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Diagnóstico y plan de mantenimiento',
                value: 'descripcion',
                align: 'left',
                width: '70%',
                sortable: true
            },
            {
                text: 'Adjuntos',
                value: 'id',
                align: 'center',
                width: '10%',
                sortable: false
            },
        ],
        headersAlcantarillado: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Año',
                value: 'anio',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Adjuntos',
                value: 'id',
                align: 'center',
                width: '20%',
                sortable: false
            },
        ],
        items: [],
        search: null,
        showJustificacion: false
    }),
    methods: {
        listarParametros() {
            this.loading = true;
            let idInstalacion = this.instalacion.id;
            axios.get(`/instalaciones/listarParametrosTratamiento/${idInstalacion}/${this.anioConsulta}`)
            .then(response => {
                this.items = response.data.parametros;
                this.sistemaTratamiento = response.data.sistema;
                this.showJustificacion = response.data.showJustificacion
                this.$refs.agregarPlanta.getAnioParticipacion();
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        requestDataFormat(item) {
            const fd = new FormData();
            fd.set('sistema', this.sistemaTratamiento.id);
            fd.set('usuario', this.idUsuario);
            return fd;
        },
        reloadData() {
            this.listarParametros();
        },
    }
}
</script>