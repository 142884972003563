<template>
    <div>
        <v-dialog v-model="dialog" persistent fullscreen scrollable transition="dialog-transition">
            <template v-slot:activator="{ on:dialog }">
                <v-tooltip top>
                    <template v-slot:activator="{ on:tooltip }">
                        <v-btn @click="cargarImagen" v-on="{ ...dialog, ...tooltip }" icon color="#0B3F67">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    Mostrar Evidencia
                </v-tooltip>
            </template>
            <v-card flat color="blue-grey lighten-5">
                <v-card-title >
                    <div>
                        {{ nombre }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="cerrar" v-on="on" icon color="#0B3F67">
                                <v-icon>mdi-close-thick</v-icon>
                            </v-btn>
                        </template>
                        Cerrar
                    </v-tooltip>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="d-flex justify-center">
                    <v-progress-circular v-if="loading"
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                    <v-img 
                        contain
                        :src="imagen"
                    ></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from '@/axios.js'
export default {
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  },  
    props: [
        'documentoId'
    ],
    data: () => ({
        overlay: false,
        loading: true,
        imagen: null,
        dialog: false,
        nombre: null
    }),
    methods: {
        cargarImagen() {
            this.overlay = true
            axios.get(`/documento/mostrarImagen/${this.documentoId}`)
            .then(response=>{
                this.imagen = response.data.imagen
                this.nombre = response.data.nombre
                this.loading = false
            }).catch(error=>{
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                } else {
                    this.$store.dispatch('noti', {mensaje: 'Error en la carga del documento', color:'error'}); 
                }
                this.loading = false
                this.overlay = false
            })
        },
        cerrar () {
            this.overlay = false,
            this.loading = true,
            this.imagen = null
            this.dialog = false
        }
    },
}
</script>
<style scoped>
 .image-viewer{
    opacity: 0.5;
 }
</style>