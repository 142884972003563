var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"no-data-text":"No hay Actividades para este periodo","footer-props":{
    showFirstLastPage: true,
    firstIcon:'mdi-page-first',
    lastIcon: 'mdi-page-last',
    prevIcon: 'mdi-chevron-left',
    nextIcon: 'mdi-chevron-right',
    itemsPerPageText: 'Registros por página',
    itemsPerPageAllText: 'Todos',
    showCurrentPage: true,
    showFirstLastPage: true
  }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('Modificar-accion',{attrs:{"idAccion":item.id},on:{"reload":_vm.reloadData}}),_c('ListadosDocs',{attrs:{"id":item.id,"titulo":'Evidencias',"origen":'prevencion'}}),_c('Agregar-evidencia',{attrs:{"id":item.id,"titulo":"Agregar Evidencias","tooltipText":"Agregar Evidencias","tipo":1,"icon":"mdi-upload"}})],1)]}},{key:"item.fechaActividad",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaActividad))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }