<template>
    <v-dialog
        v-model="dialog"        
        persistent
        scrollable
        max-width="625px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                    
                    <v-btn large dark icon v-on="{ ...tooltip, ...dialog }"><v-icon color="primary">mdi-table-row-plus-before</v-icon></v-btn>
                </template>
                Agregar Consumo
            </v-tooltip>  
        </template>
        <v-card :loading="cargando">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="nombreGei" @cancelar="cancelar"/>
            </v-card-title>           
            <v-divider></v-divider>            
            <v-card-text>
                <v-row>
                    <v-col cols="12">   
                        <v-col>
                            <v-select
                                v-model="producto"
                                :items="productos"
                                item-text="producto"
                                item-value="id"
                                label="Producto"
                                @input="$v.producto.$touch()"
                                @blur="$v.producto.$touch()"
                                :error-messages="productoError"
                                @change="cambioHistorial"
                                return-object
                                required
                            ></v-select>
                        </v-col> 
                        <v-col>                 
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date"
                                        label="Mes y Año del consumo"
                                        prepend-inner-icon="mdi-calendar-month"
                                        hint="Seleccione el año y mes del registro"
                                        :error-messages="dateErrors"             
                                        @change="$v.date.$touch()"
                                        @blur="$v.date.$touch()"
                                        required
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date" reactive :min="fechaMinima" :max="fechaMaxima" :allowed-dates="mesesDisponibles" locale="es-CR" type="month" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>    
                        <v-col>
                            <v-text-field
                                :label="consumoDetalle"
                                v-model="historial.consumo"
                                :error-messages="consumoErrors"             
                                @change="analizaConsumo"
                                @blur="$v.historial.consumo.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Índice de eficiencia"
                                v-model="historial.indiceEficiencia"
                                :error-messages="indiceEficienciaError"      
                                hint="Ingresar dato de eficiencia relacionado e este indicador"       
                                @change="$v.historial.indiceEficiencia.$touch()"
                                @blur="$v.historial.indiceEficiencia.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-textarea v-if="alerta"
                                label="Justificación"
                                v-model="historial.justificacion"
                                outlined
                                shaped
                                :error-messages="justifErrors"             
                                @change="$v.historial.justificacion.$touch()"
                                @blur="$v.historial.justificacion.$touch()"
                                counter="200"
                                required>
                            </v-textarea>  
                        </v-col>                      
                        <v-alert type="info" :value="alerta">                            
                            Este consumo excede el <strong> promedio del año anterior,</strong> 
                            por favor ingrese una justificación. 
                            <br> <strong>
                                Consumo promedio de {{ this.producto.presentacion }} en el año anterior, fue de 
                                {{consumoAnterior}} por mes.
                            </strong>
                            <br> <strong> Excedente de {{this.producto.presentacion + ': ' + excedente}} </strong>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="cargando" text @click="cancelar()" color="error">Cancelar</v-btn>
                <v-btn :loading="cargando" text @click="agregarHistorial()" color="#0B3F67">Agregar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, decimal, maxLength} from "vuelidate/lib/validators";
import axios from "@/axios.js";

export default {
    props: [
        'anioConsulta',
        'gei',
        'historiales'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
        this.getProductosPorGei();
    },     
    components: {
      ModalTitle
    },
    computed: {
        consumoDetalle() {
            return this.producto ? 'Consumo (' + this.producto.presentacion + ')' : 'Consumo';
        },
        sucursal () {
            return this.$store.getters.sucursal;
        },
        fechaMinima() {
            return this.anioConsulta + '-' + '01';
        },
        fechaMaxima() {
            return this.anioConsulta + '-' + '12';
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        consumoErrors () {
            const errors = []
            if (!this.$v.historial.consumo.$dirty) return errors
            !this.$v.historial.consumo.required && errors.push('Dato requerido')
            !this.$v.historial.consumo.decimal && errors.push('Consumo debe ser un valor numérico')
            return errors
        },
        dateErrors () {
            const errors = []
            if (!this.$v.date.$dirty) return errors
            !this.$v.date.required && errors.push('Dato requerido')
            return errors
        },
        productoError () {
            const errors = []
            if (!this.$v.producto.$dirty) return errors            
            !this.$v.producto.required && errors.push('El producto es requerido.')            
            return errors
        },
        justifErrors () {
            const errors = []
            if (!this.$v.historial.justificacion.$dirty) return errors
            !this.$v.historial.justificacion.required && errors.push('Dato requerido')
            !this.$v.historial.justificacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        geiError () {
            const errors = []
            if (!this.$v.historial.gei.$dirty) return errors            
            !this.$v.historial.gei.required && errors.push('El tipo de GEI es requerido.')            
            return errors
        },
        indiceEficienciaError () {
            const errors = []
            if (!this.$v.historial.indiceEficiencia.$dirty) return errors            
            !this.$v.historial.indiceEficiencia.required && errors.push('El índice de eficiencia es requerido.')
            !this.$v.historial.indiceEficiencia.decimal && errors.push('El índice de eficiencia debe ser numérico.')
            return errors
        },
        nombreGei() {
            return this.gei === "1" ? "Agregar Consumo Dióxido de carbono (CO2)" :
                   this.gei === "2" ? "Agregar Consumo Metano (NH4)" : "Óxido nitroso (N2O)";
        }
    },
    data: () => ({
        dialog: false,
        historial: {
            mes: '',
            anio: '',
            consumo: '',
            justificacion: '',
            indiceEficiencia: '',
        },
        consumos: [],
        consumoAnterior: 0,
        producto: '',
        date: '',
        productos: [],
        modal: false,
        menu: false,
        cargando: false,
        alerta: false,        
        excedente: null
    }),
    validations: {
        historial: {
            consumo: { required, decimal },
            indiceEficiencia: { required, decimal },
            justificacion: { 
                maxLength: maxLength(200),
                required: requiredIf(
                function(){
        	        return this.alerta
                }) 
            },
        },
        producto: { required },
        date: { required }
    },
    methods: {
        getProductosPorGei() {
            axios.get(`/productoContaAtmosf/getProductosGei/${this.sucursal.id}/${this.gei}/${this.anioConsulta}`)
            .then(response => {
                this.productos = response.data.productos;                
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los productos contaminantes atmosféricos: " + error);
              }
            })
        },
        agregarHistorial() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.cargando = true;
                let fecha = this.date.split("-");
                this.historial.mes = fecha[1];
                this.historial.anio = fecha[0];
                axios.post("/historialConta/agregarConsumo", this.historial, {params: { 
                    idProducto: this.producto.id, 
                    usuario: this.$store.getters.usuario.id 
                }}).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Consumo de contaminante agregado', color:'success'}); 
                    this.$emit('agregaConsumo');
                    this.cargando = false;
                    this.cancelar(); 
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar el consumo " + error);
                    this.$store.dispatch('noti',{
                        mensaje: 'Ocurrió un error no se agregó el consumo del contaminante', 
                        color:'error'});
                    this.cargando = false;
                    this.cancelar(); 
                  }
                })
            }
        },        
        cancelar() {
            this.historial = {};
            this.producto = '';
            this.date = '';
            this.$v.historial.$reset();
            this.$v.producto.$reset();
            this.$v.date.$reset();
            this.alerta = false;
            this.excedente = null;
            this.dialog = false;
        },
        mesesDisponibles(val) {
            return this.consumos.includes(parseInt(val.split('-')[1]));
        },
        analizaConsumo() {
            this.$v.historial.consumo.$touch();            
            let excedenteActual = this.historial.consumo - this.consumoAnterior;                        
            this.excedente = excedenteActual;       
            if((this.consumoAnterior != null || this.consumoAnterior != 0) && this.excedente >= 1){
                this.alerta = true;
            } else {
                this.alerta = false;
            }     
        },
        historialIndex() {
            return this.productos.findIndex(p => p.id === this.producto.id);
        },
        cambioHistorial() {
            this.mesesConConsumo();
            this.consumoAnterior = this.historiales[this.historialIndex()].consumoAnterior;
        },
        mesesConConsumo() {
            let meseConCosumos = [];  
            let dat = new Map(Object.entries(this.historiales[this.historialIndex()]));
            dat.forEach(function(val, key) {   
                if(parseFloat(val) == 0) {                                    
                    switch(key) {
                        case 'enero':
                            meseConCosumos.push(1);
                            break;
                        case 'febrero':
                            meseConCosumos.push(2);
                            break;
                        case 'marzo':
                            meseConCosumos.push(3);
                            break;
                        case 'abril':
                            meseConCosumos.push(4);
                            break;
                        case 'mayo':
                            meseConCosumos.push(5);
                            break;
                        case 'junio':
                            meseConCosumos.push(6);
                            break;
                        case 'julio':
                            meseConCosumos.push(7);
                            break;
                        case 'agosto':
                            meseConCosumos.push(8);
                            break;
                        case 'septiembre':
                            meseConCosumos.push(9);
                            break;
                        case 'octubre':
                            meseConCosumos.push(10);
                            break;
                        case 'noviembre':
                            meseConCosumos.push(11);
                            break;
                        case 'diciembre':
                            meseConCosumos.push(12);
                            break;
                    }
                }
            })
            this.consumos = meseConCosumos;
        },
    },
}
</script>