<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" @click="getData" class="white--text"  color="#0B3F67">Identificar Amenazas<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
                </template>
                Identificar Amenazas
            </v-tooltip>  
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Identificar Amenazas" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row v-for="(item, index) in dataAmenaza" :key="index">
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        disabled
                        v-model="item.amenazaNombre"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            outlined
                            :items="escala"
                            item-value="id"
                            item-text="nombre"
                            label="Nivel de Exposición"
                            v-model="item.idnivelExposicion"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregar" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import ModalTitle from '@/components/utils/ModalTitleComponent'

export default {
    props: [
      'amenazas',
      'escala'
    ],
    components: {
      ModalTitle
    },
    computed: {
      anioConsulta() {
        return this. $store.getters.anioConsulta;
      },
      instalacion() {
        return this.$store.getters.sucursal;
      },
      usuario() {
        return this.$store.getters.usuario;
      },
    },
    data: () => ({
        dataAmenaza: [],
        dialog: false,
    }),
    methods: {
      getData() {
        this.dataAmenaza = this.amenazas
        console.log(this.dataAmenaza);
      },
      agregar() {
        this.loadingModal(true);
        axios.post('/adaptacion/identificarAmenazas', this.dataAmenaza, {params: {
            inst: this.instalacion.id,
            usuario: this.usuario.id,
            anio: this.anioConsulta
        }}).then(response => {
            this.$store.dispatch('noti', {mensaje: 'Amenazas identificadas', color: 'success'});
            this.loadingModal(false);
            this.$emit('reload');
            this.cancelar();
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo identificar amenazas', color: 'error'});
            this.loadingModal(false);
          }
        })
      },
      cancelar() {
          this.dialog = false;
      },
      loadingModal(modal) {                        
          this.$store.dispatch('loadingPage', modal);
      },
    },
}
</script>