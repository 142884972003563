<template>
    <v-container fluid>
        <PoliticaOperativaTabla></PoliticaOperativaTabla>
    </v-container>
</template>

<script>

    import PoliticaOperativaTabla from "@/components/politicaOperativa/PoliticaOperativaTablacomponent";

    export default {
        components: {
            PoliticaOperativaTabla
        },
    };
</script>