<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent 
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-icon color="#0B3F67" medium v-on="{...tooltip, ...dialog}">mdi-trash-can-outline</v-icon>
        </template>
        Eliminar
      </v-tooltip>                    
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Eliminar Evidencia" @cancelar="cancelar"/>
      </v-card-title>
       <v-card-text>
        <div class="pt-4">
          ¿Desea eliminar la evidencia del {{ evidencia.anio }}?<br>
          <strong>Los puntos ganados serán reversados</strong>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
        <v-btn @click="eliminar" text color="#0B3F67">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from '@/axios'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  props: [
    'evidencia'
  ],
  components: {
      ModalTitle
    },
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    cancelar() {
      this.dialog = !this.dialog
    },
    eliminar() {
      this.loadingModal(true);
      axios.post('/indicadorAgua/eliminarPotabilidad', null , {params:{
        potabilidad: this.evidencia.id,
        usuario: this.usuario.id
      }}).then( response => {
        this.$store.dispatch('noti',{mensaje: 'Constancia eliminada', color:'success'});
        this.$emit('reload');
        this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          this.$store.dispatch('noti',{mensaje: 'Error al eliminar la constancia', color:'error'});
          this.loadingModal(false);
        }
      })
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  }
}
</script>