<template>
    <v-container fluid>
        <logo @cambiaAnio="getHistorialProgramas" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Programa de Compras Sostenibles" />
            </v-col>
        </v-row>
        <v-row justify-sm="center">
            <v-col cols="12" sm="12" md="6" align-self="center">
                <AgregarPrograma 
                    @reload="getHistorialProgramas" 
                    :aniosConstancia="aniosConstancia" 
                    :anios="aniosConsulta"
                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="2">
                <v-text-field      
                    prepend-inner-icon="mdi-magnify"              
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <tituloSimple titulo="Historial"/>
                        <v-data-table
                            :headers="headers"
                            :items="items"                        
                            item-key="id"                        
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <ModificarPrograma 
                                    @reload="getHistorialProgramas" 
                                    :anios="aniosConsulta" 
                                    :data="item"
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }" v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">                                                                                
                                        <v-btn v-on="on" large dark icon @click="eliminar(item.id)"><v-icon color="#0B3F67">mdi-trash-can-outline</v-icon></v-btn>
                                    </template>
                                    Eliminar programa
                                </v-tooltip>                           
                            </template>    
                            <template v-slot:item.documentoId="{ item }">
                                <div v-if="anioConsulta < 2022">
                                    <v-tooltip top v-if="item.documentoId != 0">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" large dark icon @click="descargarDoc(item.documentoId)"><v-icon color="#0B3F67">mdi-download</v-icon></v-btn>
                                        </template>
                                        Descargar Evidencia
                                    </v-tooltip>    
                                </div>
                                <div v-else>
                                    <ListadosDocs 
                                        :id="item.id" 
                                        origen="programaCompras" 
                                        titulo="Evidencias Programa Compras Sostenibles">
                                    </ListadosDocs>
                                </div>
                            </template>                    
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>            
        </v-row>
    </v-container>
</template>
<script>

import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import AgregarPrograma from '@/components/parametros/ComprasSostenibles/AgregarProgramaComponent'
import ModificarPrograma from '@/components/parametros/ComprasSostenibles/ModificarProgramaComponent'
import ListadosDocs from '@/components/documentos/ListadoDocumentosComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'

export default {
    components: {
        TituloPrincipal,
        NavParameters,
        TituloSimple,
        AgregarPrograma,
        ModificarPrograma,
        ListadosDocs,
        Logo
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    }, 
    props: [
        'navParams'
    ],
    directives: {
        'sticky': VueSticky,
    },
    mounted() {
        this.getHistorialProgramas();
    },
    computed: {
        anioConsulta() {
          return this. $store.getters.anioConsulta;
        },
        aniosConsulta() {
            return this.$store.getters.aniosConsulta;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Año',
                value: 'anio',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Descripción del Programa',
                value: 'descripPrograma',
                align: 'left',
                width: '45%',
                sortable: false
            },
            {
                text: "Fecha Registro",
                value: "fechaFormat",
                align: "center",
                width: '15%',
                sortable: true
            },
            {
                text: "Evidencia",
                width: '10%',
                value: "documentoId",
                align: "center",
                sortable: false,                
            }
        ],
        items: [],
        search: null,
        aniosConstancia: null
    }),
    methods: {
        getHistorialProgramas() {            
            let instalacion = this.instalacion.id;
            axios.get("/comprasSostenibles/getHistorialProgramas/" + instalacion + "/" +this.anioConsulta)
            .then(response => {
                this.items = response.data.programas;
                this.aniosConstancia = response.data.aniosConstancia;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        descargarDoc(documentoId) {
             axios({
                url: '/documento/descargar/' + documentoId,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },        
        eliminar(id) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar el programa?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    axios.delete("/comprasSostenibles/eliminarPrograma", { params: {
                        id: id,                
                        user: this.usuario.id                
                    }}).then(response => {
                        this.$store.dispatch('loadingPage', false);
                        this.getHistorialProgramas();
                        this.$store.dispatch('noti', {mensaje: 'Programa eliminado', color: 'success'});
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se eliminó el programa', color: 'error'});
                      }
                    })
                }
            })
        }
    },
}
</script>