<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Impactos del cambio climático"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <ImpactoInstalacion @reload="getData"/>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Impactos del cambio climático"/>
                    <v-card-text>
                        <v-row v-for="(impactos, nivel) in impactos" :key="nivel">
                            <v-col cols="12">
                                <v-subheader>
                                    <div class="text-capitalize">A nivel de {{ nivel }}</div>
                                </v-subheader>
                            </v-col>
                            <v-col cols="12">
                                <v-list>
                                    <template v-for="(item, index) in impactos">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.impacto }} - {{ item.otro }}</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-checkbox disabled v-model="item.seleccionado" :value="true"></v-checkbox>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </template>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VueSticky from 'vue-sticky'
    import Logo from '@/components/utils/CompanyComponent'
    import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
    import TituloSimple from '@/components/utils/TituloSimpleComponent'
    import NavParameters from '@/components/utils/NavParameterComponent'
    import ImpactoInstalacion from '@/components/parametros/Adaptacion/ImpactoInstalacionComponent'
    import axios from '@/axios.js'

    export default {
        components: {
            TituloPrincipal,
            TituloSimple,
            NavParameters,
            Logo,
            ImpactoInstalacion
        },
        directives: {
            'sticky': VueSticky,
        },
        props: [
            'navParams'
        ],
        computed: {
            usuario() {
                return this.$store.getters.usuario;
            },
            instalacion() {
                return this.$store.getters.sucursal;
            },
            rolUsuario(){
                return this.$store.getters.usuario.rol;
            },
            anioConsulta() {
                return this. $store.getters.anioConsulta;
            },
            parametroActual() {
                return this.$store.getters.parametroActual;
            }
        },
        created() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
        },
        mounted() {
            this.getData()
        },
        data: () => ({
            impactos:[],
            escala: [],
            headers: [
                { 
                    text: "Amenaza climática",
                    value: "amenazaNombre",
                    align: "center",
                    width: '20%',
                    sortable: false
                },
                { 
                    text: "Definición",
                    value: "amenazaDefinicion",
                    align: "left",
                    width: '60%',
                    sortable: false
                },
                { 
                    text: "Nivel de exposición ",
                    value: "exposicionNombre",
                    align: "center",
                    width: '40%',
                    sortable: false
                },
            ]
        }),
        methods: {
            getData() {
                axios.get(`/adaptacion/getDataImpactoClimatico/${this.instalacion.id}/${this.anioConsulta}`)
                .then(response => {
                    this.impactos = response.data.impactos;
                    this.loadingModal(false);
                }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                } else {
                    this.loadingModal(false);
                }
                })
            },

            loadingModal(modal) {                        
                this.$store.dispatch('loadingPage', modal);
            }
        },
    }
</script>

<style scoped>

</style>