<template>
    <v-speed-dial
      v-model="fab"
      fixed
      bottom            
      right
      direction="top"
      open-on-hover
      transition="scale-transition">
        <template v-slot:activator>
            <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-tooltip left v-for="btn in navigation" :key="btn.path">
            <template v-slot:activator="{ on }">            
                <v-btn v-on="on"
                    fab
                    dark
                    small
                    @click="redirectTo(btn.path, btn.id)"
                    :color="btn.color">
                    <v-icon>{{btn.icono}}</v-icon>
                </v-btn>            
            </template>
            <span>{{ btn.tooltip }}</span>
        </v-tooltip>
    </v-speed-dial>
</template>
<script>
export default {
    props: [
        'navParams'
    ],
    computed: {
        navigation() {
            let dummyParam = [this.dashboardNav];
            let navParams = this.navParams;
            if(this.navParams === undefined) {
                navParams = this.$store.getters.navParams;
            }
            let exists = navParams.some( param => param['id'] === 0 )
            if(exists) {
                return navParams
            }
            return dummyParam.concat(navParams);
        }
    },
    data: () => ({
        fab: false,
        dashboardNav: {
            id: 0,
            icono: 'mdi-tablet-dashboard',
            tooltip: 'Parámetros de Cumplimiento',
            path: 'BanderaAzulDashboard', 
            color: '#78909C'
        }
    }),
    methods: {
        redirectTo(path, idParametro) {
            if(path != this.$router.currentRoute.name) {
                this.$store.dispatch('loadingPage', true);
                this.$store.commit('parametroActual', idParametro)
                this.$router.push({ name: path, params: { navParams: this.navigation }});
            }
        }
    }
}
</script>