<template>
    <v-dialog
        v-model="dialog"        
        persistent
        scrollable
        max-width="70vw"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                    
                    <v-btn class="white--text" color="#0B3F67" v-on="{ ...tooltip, ...dialog }">
                        Educación Ambiental y/o acciones
                        <v-icon right>mdi-plus-box-outline</v-icon>
                    </v-btn>
                </template>
                Actividades de Educación Ambiental y/o acciones para reducción y control
            </v-tooltip>  
        </template>
        <v-card :loading="cargando">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Actividades de Educación Ambiental y/o acciones para reducción y control" @cancelar="cancelar"/>
            </v-card-title>           
            <v-divider></v-divider>            
            <v-card-text>
                <TablaEducacion ref="tabla" @reload="getData"/>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="cargando" text @click="cancelar()" color="error">Cerrar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>
import axios from "@/axios.js"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import TablaEducacion from "@/components/parametros/combustiblesFosiles/EducReducTablaComponent"

export default {
    props: [
        'historiales'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    components: {
      ModalTitle,
      TablaEducacion
    },
    computed: {
        sucursal () {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
    },
    data: () => ({
        dialog: false,
        historial: {
            mes: '',
            anio: '',
            consumo: '',
            justificacion: '',
            indiceEficiencia: '',
        },
        consumos: [],
        consumoAnterior: 0,
        producto: '',
        date: '',
        productos: [],
        modal: false,
        menu: false,
        cargando: false,
        alerta: false,        
        excedente: null
    }),
    methods: {
        getData() {
            this.cargando = true;
/*             axios
                .get(`/instalaciones/getDataEducReduc/${this.instalacion.id}/${this.anioConsulta}/${this.indicadorActual}`)
                .then(response => {
                this.actividades = response.data;
                this.cargando = false;
                this.loadingModal(false);
            })
                .catch(error => {
                console.log(error);
                this.cargando = false;
                this.loadingModal(false);
            }); */
        }, 
        cancelar() {
            this.dialog = false;
        }
    },
}
</script>