<template>
    <v-container fluid>
        <logo @cambiaAnio="getDataAccPlan" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Plan de Acción para Medida de Adaptación"/>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">            
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">            
                <v-card>
                    <tituloSimple titulo="Plan de Acción por Evento Climático"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search"
                            fixed-header
                            no-data-text="No acciones de adaptación registradas"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon:'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageText: 'Registros por página',
                                itemsPerPageAllText: 'Todos',                            
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }">
                            <template v-slot:item.action="{item}">
                                <div>
                                    <agreModfPlan 
                                        v-if="(item.idPlan === 0 || item.idPlan === null || item.idPlan === undefined) && (rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR')"
                                        @reload="getDataAccPlan" 
                                        :detalle="false" 
                                        tipoAccion="Agregar" 
                                        icono="mdi-plus-circle-outline"
                                        :planProp="item" 
                                        :instalacion="instalacion" 
                                        :usuario="usuario">
                                    </agreModfPlan>
                                </div>
                                <div v-if="item.idPlan != 0 && item.idPlan != null && item.idPlan != undefined">
                                    <agreModfPlan
                                        @reload="getDataAccPlan" 
                                        :detalle="false" 
                                        tipoAccion="Modificar" 
                                        icono="mdi-pencil"
                                        :planProp="item" 
                                        :instalacion="instalacion" 
                                        :usuario="usuario"
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">
                                    </agreModfPlan> 
                                    <agreModfPlan
                                        @reload="getDataAccPlan" 
                                        :detalle="true" 
                                        tipoAccion="Detalle" 
                                        icono="mdi-text-box-multiple-outline"
                                        :planProp="item" 
                                        :instalacion="instalacion" 
                                        :usuario="usuario"
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">
                                    </agreModfPlan> 
                                    <Eliminar 
                                        requestUrl="/adaptacion/eliminarPlan"
                                        :requestData="eliminarDataFormat(item)"
                                        @reload="getDataAccPlan"
                                    />
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import AgreModfPlan from '@/components/parametros/Adaptacion/AgreModfPlanComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'
import Eliminar from '@/components/utils/EliminarComponent.vue'

export default {
    components: {
        TituloPrincipal,
        TituloSimple,
        AgreModfPlan,
        NavParameters,
        Logo,
        Eliminar
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
            'navParams'
        ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getDataAccPlan();
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        parametro() {
            return this.$store.getters.parametroActual;
        }
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Evento Climático',
                value: 'nombreEvento',
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: 'Evento climático',
                value: 'nombreEvento',
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: 'Descripción',
                value: 'descripcion',
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: 'Plazo de Ejecución',
                value: 'plazo',
                align: 'center',
                width: '15%',
                sortable: false
            },            
            {
                text: 'Fecha Registro Plan',
                value: 'fechaRegistro',
                align: 'center',
                width: '15%',
                sortable: false
            },
        ],
        items: [],
        search: null
    }),
    methods: {
        getDataAccPlan() {
            axios.get(`/adaptacion/getDataPlanAccion/${this.instalacion.id}/${this.anioConsulta}`)
            .then(response => {
                this.items = response.data.planes;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {         
            this.$store.dispatch('loadingPage', modal);
        },
        eliminarDataFormat(plan) {
            let formData = new FormData();
            formData.append('plan', plan.idPlan);
            formData.append('parametro', this.parametro);
            formData.append('usuario', this.usuario.id);
            return formData;
        }
    },
}
</script>