var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1","align-self":"center"}},[_c('AgregarEquipo',{on:{"reload":_vm.getEquipos}})],1),(_vm.activos < 1)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","align-self":"center"}},[_c('Justificacion',{attrs:{"titulo":"Justificación","tooltipText":"Justificación","reqEvidencias":true,"tipoDocumento":_vm.constantes.JUSTIF_FLOTA_VEHICULAR}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.equipos,"calculate-widths":"","item-key":"id","search":_vm.search,"loading":_vm.loading,"no-data-text":"No hay equipos para este periodo","footer-props":{
                          showFirstLastPage: true,
                          firstIcon:'mdi-page-first',
                          lastIcon: 'mdi-page-last',
                          prevIcon: 'mdi-chevron-left',
                          nextIcon: 'mdi-chevron-right',
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageAllText: 'Todos',
                          showCurrentPage: true,
                          showFirstLastPage: true
                      }},scopedSlots:_vm._u([{key:"item.estadoAnio",fn:function(ref){
                      var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('ActivarEquipo',{attrs:{"estado":item.estadoAnio,"id":item.id},on:{"reload":_vm.getEquipos}})],1)]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('ModificarEquipo',{attrs:{"id":item.id},on:{"reload":_vm.getEquipos}}),_c('DetalleEquipo',{attrs:{"equipo":item}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }