<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-btn color="#0B3F67" dark v-on="on">Crear Categoria<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Crear Categoria" @cancelar="cancelar"/>
      </v-card-title> 
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
              label="Nombre Categoria"
              v-model="categoria.nombre"
              :error-messages="nameErrors"
              @input="$v.categoria.nombre.$touch()"
              @blur="$v.categoria.nombre.$touch()"
              required
            ></v-text-field>
            <v-card class="text-center">
              <img height="150" width="140" :src="logo" contain alt="zurqui"/>
              <v-alert type="error" :value="alert">
                Debe seleccionar un logo
              </v-alert>
              <v-card-actions>
                <seleccionar-logo @cambiarLogo="cambiarLogo"/>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="#0B3F67" @click.native="agregarCategoria">Agregar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import SeleccionarLogo from './SeleccionarLogoComponent';
import { required } from "vuelidate/lib/validators";
import axios from '@/axios'
export default {
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.categoria.nombre.$dirty) return errors            
      !this.$v.categoria.nombre.required && errors.push('El nombre es requerido.')            
      return errors
    },
    logo() {
      if(this.categoria.logo != null && this.categoria.logo != undefined) {
        return require('@/assets/' + this.categoria.logo)
      } else {
        return require('@/assets/sin-imagen.png')
      }
    }
  },
  components: {
    ModalTitle,
    SeleccionarLogo
  },
  data: () => ({
    categoria: {
      nombre: null,
      logo: null,
    },
    dialog: false,
    alert: false
  }),
  validations: {
    categoria: {
      nombre: {
        required,               
      },
      logo: {
        required,               
      },
    }
  },
  methods: {
    cancelar() {
      this.categoria = {
        nombre: null,
        logo: null,
      },
      this.$v.categoria.$reset();
      this.dialog = !this.dialog;
    },
    cambiarLogo(logo) {
      this.categoria.logo = logo;
      this.alert = false;
    },
    agregarCategoria() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.loadingModal(true); 
        axios.post('/categoria/agregar', this.categoria)
        .then(response => {
          this.$store.dispatch('noti',{mensaje: 'Categoria ' + this.categoria.nombre + ', agregada', color:'success'});  
          this.$emit('reload')
          this.loadingModal(false); 
          this.cancelar();
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            this.$store.dispatch('noti',{mensaje: 'Error al agregar la categoria ' + this.categoria.nombre, color:'error'});  
            this.loadingModal(false); 
            this.cancelar();
          }
        })
      } else {
        if(this.$v.categoria.logo.$invalid) {
          this.alert = true;
        }
      }
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  },
}
</script>