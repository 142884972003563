<template>
    <v-progress-circular
        :value="avance"
        size="45"
        width="7"
        :color="setColor()">
        {{ avance }}%
    </v-progress-circular>
</template>
<script>
export default {
    props: [
        'avance'
    ],
    methods: {
        setColor() {
            if(this.avance === 100) {
                return 'green darken-2';
            } else {
                if(this.avance >= 90 && this.avance < 100) {
                    return 'yellow accent-4';
                } else {
                    return 'red darken-1';
                }
            }
            
        }
    },
}
</script>