<template>
    <v-container fluid>         
       <EnfoqueTabla></EnfoqueTabla>
    </v-container>
</template>
<script>
import EnfoqueTabla from "@/components/Enfoque/TipoEnfoqueTablaComponent";
export default {
    components: {
        EnfoqueTabla
    },    
}
</script>