<template>
    <v-dialog
        v-model="dialog"
        scrollable 
        persistent 
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon @click="getDataModificar" v-on="{ ...tooltip, ...dialog }">mdi-pencil</v-icon>
                </template>
                Modificar
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Planta de Tratamiento1" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Tipo de Sistema</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-select
                            :items="items"
                            item-text="nombre"
                            item-value="valor"
                            v-model="sistemaTratamiento.sistema"
                            label="Sistema"
                            :error-messages="nameErrors"             
                            @change="$v.sistemaTratamiento.sistema.$touch()"
                            @blur="$v.sistemaTratamiento.sistema.$touch()"
                        ></v-select>
                    </v-col>
                </v-row>   
                <v-row>
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Año</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-select
                            disabled
                            :items="aniosConsulta"
                            v-model="sistemaTratamiento.anio"
                            label="Año"
                            :error-messages="anioErrors"             
                            @change="$v.sistemaTratamiento.anio.$touch()"
                            @blur="$v.sistemaTratamiento.anio.$touch()"
                        ></v-select>
                    </v-col>
                </v-row>   
                <v-row>
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Descripción</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-textarea
                            label="Descripción"
                            outlined
                            counter="500"
                            v-model="sistemaTratamiento.descripcion"
                            :error-messages="descripErrors"             
                            @change="$v.sistemaTratamiento.descripcion.$touch()"
                            @blur="$v.sistemaTratamiento.descripcion.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>   
                <v-row v-if="isPlantaTrat">
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Planta de Tratamiento Propia</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-radio-group v-model="sistemaTratamiento.propia" 
                            hint="Indique si la planta de tratamiento es propia."
                            validate-on-blur
                            :error-messages="propiaErrors"             
                            @change="$v.sistemaTratamiento.propia.$touch()"
                            @blur="$v.sistemaTratamiento.propia.$touch()">
                            <v-radio label="Si" :value="true"></v-radio>
                            <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group> 
                    </v-col>
                </v-row>   

                <v-row v-if="sistemaTratamiento.sistema == 3">
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Documento Emitido a Nombre de la Sede</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-radio-group v-model="sistemaTratamiento.propia" 
                            hint="Indique si la planta de tratamiento es propia."
                            validate-on-blur
                            :error-messages="propiaErrors"             
                            @change="$v.sistemaTratamiento.propia.$touch()"
                            @blur="$v.sistemaTratamiento.propia.$touch()">
                            <v-radio label="Si" :value="true"></v-radio>
                            <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group> 
                    </v-col>
                </v-row>

                <v-row v-if="sistemaTratamiento.sistema == 3 && !sistemaTratamiento.propia">
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Justificación</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-textarea
                            label="Justificación"
                            outlined
                            counter="500"
                            v-model="sistemaTratamiento.justificacion"
                            :error-messages="justifErrors"             
                            @change="$v.sistemaTratamiento.justificacion.$touch()"
                            @blur="$v.sistemaTratamiento.justificacion.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row> 

                <v-row v-if="sistemaTratamiento.anio > 2021">
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Acciones realizadas a aguas residuales tipo especial o justificar si considera que no se generan</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-textarea
                            label="Acciones realizadas"
                            outlined
                            counter="250"
                            v-model="sistemaTratamiento.accioneeRealizadas"
                            :error-messages="accionErrors"             
                            @change="$v.sistemaTratamiento.accioneeRealizadas.$touch()"
                            @blur="$v.sistemaTratamiento.accioneeRealizadas.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>      
                <v-row>
                    <v-col cols="5" md="5" class="text-right subtitle-1" align-self="center">
                        <span>Adjuntar Croquis</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                     <v-col cols="4" md="5" v-if="!modificacion">
                        <v-text-field
                            disabled
                            v-model="croquisDoc.nombre"
                            label="Croquis"
                        ></v-text-field>        
                    </v-col> 
                    <v-col cols="2" md="1" v-if="!modificacion" align-self="center">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon @click="modificarCroquis" v-on="on">mdi-pencil</v-icon>
                            </template>
                            Modificar
                        </v-tooltip>
                    </v-col> 
                    <v-col cols="6" md="6" v-else>
                        <v-file-input label="Adjuntar Croquis"
                            accept=".png, .jpeg, .jpg"
                            v-model="croquis"
                            show-size                                
                            clearable
                            :error-messages="croquisErrors"             
                            @change="$v.croquis.$touch()"
                            @blur="$v.croquis.$touch()">
                        </v-file-input>           
                    </v-col>             
                </v-row>  
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cancelar" color="error" text>Cancelar</v-btn>
                <v-btn @click="modificar" color="#0B3F67" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { required, requiredIf, maxLength } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from '@/axios.js'
//Custom validations
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}

export default {
    props: [
        'id'
    ],
    components: {
      ModalTitle
    },
    computed: {
        isPlantaTrat() {
            return this.sistemaTratamiento.sistema === 1 ? true : false;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        aniosConsulta() {
            return this.$store.getters.aniosConsulta;
        },
        nameErrors () {
            const errors = []
            if (!this.$v.sistemaTratamiento.sistema.$dirty) return errors            
            !this.$v.sistemaTratamiento.sistema.required && errors.push('El sistema es requerido.')            
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.sistemaTratamiento.anio.$dirty) return errors            
            !this.$v.sistemaTratamiento.anio.required && errors.push('El año es requerido.')                 
            return errors
        },
        descripErrors () {
            const errors = []
            if (!this.$v.sistemaTratamiento.descripcion.$dirty) return errors            
            !this.$v.sistemaTratamiento.descripcion.required && errors.push('La descripción es requerido.')  
            !this.$v.sistemaTratamiento.descripcion.maxLength && errors.push('Este dato excede el tamaño permitido.')    
            return errors
        },
        accionErrors () {
            const errors = []
            if (!this.$v.sistemaTratamiento.accioneeRealizadas.$dirty) return errors            
            !this.$v.sistemaTratamiento.accioneeRealizadas.required && errors.push('Dato requerido.')  
            !this.$v.sistemaTratamiento.accioneeRealizadas.maxLength && errors.push('Este dato excede el tamaño permitido.')    
            return errors
        },
        propiaErrors () {
            const errors = []
            if (!this.$v.sistemaTratamiento.propia.$dirty) return errors            
            !this.$v.sistemaTratamiento.propia.required && errors.push('El dato es requerido.')            
            return errors
        },
        croquisErrors () {
            const errors = []
            if (!this.$v.croquis.$dirty) return errors            
            !this.$v.croquis.required && errors.push('El croquis es requerido.')
            !this.$v.croquis.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')        
            return errors
        },
        justifErrors () {
            const errors = []
            if (!this.$v.sistemaTratamiento.justificacion.$dirty) return errors            
            !this.$v.sistemaTratamiento.justificacion.required && errors.push('La justificación es requerido.')  
            !this.$v.sistemaTratamiento.justificacion.maxLength && errors.push('Este dato excede el tamaño permitido.')    
            return errors
        },
    },
    data: () => ({
        dialog: false,
        items: [
            { nombre: 'Planta de Tratamiento', valor: 1 },
            { nombre: 'Tanque séptico y drenaje', valor: 2 },
            { nombre: 'Alcantarillado sanitario', valor: 3 }
        ],
        sistemaTratamiento: {
            sistema: null,
            anio: null,
            descripcion: null,
            accioneeRealizadas: null,
            propia: false,
            justificacion: null
        },
        croquis: null,
        croquisDoc: {
            nombre: null
        },
        modificacion: false
    }),
    validations: {
        sistemaTratamiento: {
            sistema: {
                required               
            },
            anio: {
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            accioneeRealizadas: {
                maxLength: maxLength(250),
                required:  requiredIf(function(){
                    return this.sistemaTratamiento.anio > 2021
                }) 
            },
            propia: {
                required: requiredIf(function(){
                    return this.isPlantaTrat
                }) 
            },
            justificacion: {
                maxLength: maxLength(500),
                required:  requiredIf(function(){
                    return (this.sistemaTratamiento.sistema == 3 && !this.sistemaTratamiento.propia)
                }) 
            },
        },
        croquis: {
            required: requiredIf(function(){
                return this.modificacion
            }),
            maxFileSize
        }
    },
    methods: {
        getDataModificar() {
            this.loadingModal(true);
            axios.get("/instalaciones/getSistTratModificar/" + this.id)
            .then(response => {
                this.sistemaTratamiento = response.data.sistema;
                this.croquisDoc = response.data.croquis;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {  
                this.loadingModal(true);
                let formData = new FormData();
                formData.append('croquis', this.croquis);
                formData.append('sistema',  JSON.stringify(this.sistemaTratamiento));
                formData.append('idInstalacion', this.instalacion.id);
                formData.append('idUsuario', this.usuario.id);
                axios.post("/instalaciones/agregarModificarSistema", formData)
                .then(response => {
                    this.$store.dispatch('noti', {mensaje: 'El tipo de sistema se ' + response.data, color: 'success'});
                    this.loadingModal(false);
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti', {
                        mensaje: 'Ocurrió un error no se ' + response.data + ' el tipo de sistema', 
                        color: 'error'
                    });
                    this.loadingModal(false);
                  }
                })
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        cancelar() {
            this.sistemaTratamiento = {
                sistema: null,
                descripcion: null,
                accioneeRealizadas: null,
                propia: false
            }
            this.croquis = null,
            this.$v.sistemaTratamiento.$reset();
            this.$v.croquis.$reset();
            this.modificacion = false;
            this.dialog = false;
        },
        modificarCroquis() {
            this.modificacion = !this.modificacion;
        }
    }
}
</script>