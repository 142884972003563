<template>
  <v-bottom-sheet
    inset
    v-model="pestana">
    <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          bottom
          right
          fixed
          color="#25D366"
          v-on="on">
          <v-img
            contain
            max-width="63"
            min-height="63"
            src="@/assets/whatsapp-logo.svg">
          </v-img>
        </v-btn>
    </template>
    <v-card rounded="xl">
      <v-toolbar
        color="#075E54"
        dark>
         <v-badge
          dot
          bottom
          overlap
          color="success"
          offset-x="10"
          offset-y="15">
          <v-avatar
            class="pa-0"
            color="white"
            size="50">
            <v-img
              max-height="40"
              max-width="40"
              src="@/assets/iconos/logoszurqui/mariposa_zurqui.png"></v-img>
          </v-avatar>
        </v-badge>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Zurquí</v-list-item-title>
            <v-list-item-subtitle>Gestor de Sostenibilidad</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-spacer></v-spacer>

        <v-btn icon @click="pestana = !pestana">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img src="@/assets/whatsapp-back.png" height="150px">
        <v-card-text>
          <v-row class="pa-3">
            <v-col cols="12" md="6">
              <v-card 
                class="rounded-tl-0"
                rounded="xl">
                <div class="pa-3 pb-0">{{ greetings }}</div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="caption">{{ currentTime }}</div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-img>
      <v-card-actions class="pa-0">
        <v-text-field
          solo
          outlined
          rounded
          append-icon="mdi-send"
          label="Mensaje"
          @click:append="openLink"
          v-model="mensaje"
        ></v-text-field>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import moment from 'moment'
export default {
  computed: {
    currentTime() {
      return moment(this.hoy,'h:mm a').format('h:mm a');
    }
  },
  data: () => ({
    greetings: 'Bienvenido a su nuevo #GestordeSostenibilidad.',
    hoy: new Date(),
    telefono: "+50683146930",
    mensaje: null,
    pestana: false
  }),
  methods: {
    openLink() {
      let url = "https://web.whatsapp.com/send";
      const all = `${url}?phone=${this.telefono}&text=${this.mensaje}`;
      window.open(all, "_blank");
    }
  }
}
</script>