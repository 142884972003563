<template>
    <v-data-table
        :headers="headers"
        :items="actividades"
        hide-default-footer
        item-key="id"
        :search="search"
        no-data-text="No hay Actividades para este periodo">
        <template v-slot:item.fechaActividad="{ item }">
            {{ dateFormat(item.fechaActividad) }}
        </template>
        <template v-slot:item.action="{ item }">
            <ModificarActividadCompesacion 
                @reload="listarActividadesCompesacion" 
                v-bind:actividadComp="item"
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            <AgregarEvidencia
                @reload="listarActividadesCompesacion"
                :idActividad="item.id"
                :origen="origen"
                :titulo="titulo"
                tooltipText="Agregar Evidencias"
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
            ></AgregarEvidencia>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon 
                        v-on="on"
                        color="#0B3F67" 
                        @click="borrarActividad(item.id)" 
                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">
                        mdi-trash-can-outline
                    </v-icon>
                </template>
                <span>Eliminar Evidencia</span>
            </v-tooltip>
        </template>
        <template v-slot:item.evidencia="{ item }">
            <ListadosDocs :idActividad="item.id" origen="actividadCompesacion" :titulo="titulo"></ListadosDocs>
        </template>

    </v-data-table>
</template>
<script>

import moment from "moment"
import ModificarActividadCompesacion from "@/components/parametros/ActividadCompesacion/ModificarActividadCompesacionComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import axios from "@/axios.js";

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
        ModificarActividadCompesacion,
        AgregarEvidencia,
        ListadosDocs
    },
    props: [
        'headers',
        'actividades',
        'search'
    ],
    mounted() {
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    computed: {
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        }
    },
    data: () => ({
        titulo: "Documento(s) de Evidencia",
        origen: "actividadCompesacion"
    }),
    methods: {
        dateFormat(fecha) {
            return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        borrarActividad(id) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar la actividad?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    this.cargando = true;
                    axios.delete("/actividadCompesacion/borrarActividad/" + id, {params: {idUsuario: this.usuario.id}}).then(response => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Actividad de compensación borrada",
                            color: "success"
                        });
                        this.cargando = false;
                        this.$emit('reload');
                        })
                        .catch(error => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Ocurrió un error no se eliminó la actividad de compensación",
                            color: "error"
                        });
                        console.log(error);
                        this.cargando = false;
                        });
                }
            })
        },
        listarActividadesCompesacion(){
            this.$emit('reload');
        },
    },
}
</script>