<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">  
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon small exact-active-class="indigo" class="ma-2" v-on="{ ...tooltip, ...on }"     
                        @click="getDataInstalacion()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>          
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Instalación" @cancelar="cancelar"/>
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-row>
                       <v-col cols="12">
                            <v-text-field 
                                label="Nombre"
                                :error-messages="nameErrors"
                                v-model="instalacion.nombre"
                                @input="$v.instalacion.nombre.$touch()"
                                @blur="$v.instalacion.nombre.$touch()"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Ubicación"
                                v-model="instalacion.ubicacion">
                                </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Provincia"
                                v-model="provincia">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Cantón"
                                v-model="canton">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Distrito"
                                v-model="distrito">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Latitud"
                                :error-messages="latitudErrors"
                                v-model="instalacion.latitud"
                                @change="$v.instalacion.latitud.$touch()"
                                @blur="$v.instalacion.latitud.$touch()"
                                required
                                decimal>
                                </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Longitud"
                                :error-messages="longitudErrors"
                                @change="$v.instalacion.longitud.$touch()"
                                @blur="$v.instalacion.longitud.$touch()"
                                v-model="instalacion.longitud"
                                required
                                decimal>
                                </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Cantidad de Personas"
                                :error-messages="cantidadErrors"
                                v-model="instalacion.cantidadPersonal" 
                                @change="$v.instalacion.cantidadPersonal.$touch()"
                                @blur="$v.instalacion.cantidadPersonal.$touch()"
                                required
                                numeric>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Teléfono"
                                v-model="instalacion.telefono"
                                v-mask="telMask">
                            </v-text-field>
                        </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                            <v-select
                                :items="tipos"
                                item-text="nombre"
                                item-value="id"
                                v-model="tipoInstalacion"
                                :error-messages="tiposErrors"
                                label="Tipo Instalacion"             
                                @change="$v.tipoInstalacion.$touch()"
                                @blur="$v.tipoInstalacion.$touch()"
                                required
                            ></v-select>
                        </v-col>   
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Correo"
                                v-model="instalacion.correo"
                                :error-messages="emailErrors"
                                @input="$v.instalacion.correo.$touch()"
                                @blur="$v.instalacion.correo.$touch()"
                                required
                                email>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-textarea
                                label="Alcance de Implementación"
                                counter
                                rows="3"
                                no-resize
                                :error-messages="alcanceErrors"
                                v-model="instalacion.alcance" 
                                @change="$v.instalacion.alcance.$touch()"
                                @blur="$v.instalacion.alcance.$touch()"
                                required>
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-textarea
                                counter
                                rows="3"
                                no-resize
                                label="Detalle Instalación"
                                :error-messages="detalleErrors"
                                v-model="instalacion.detalle" 
                                @change="$v.instalacion.detalle.$touch()"
                                @blur="$v.instalacion.detalle.$touch()"
                                required>
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select
                                :items="organizaciones"
                                :disabled="notAdmin"
                                item-text="nombre"
                                item-value="id"
                                v-model="organizacion"
                                :error-messages="orgErrors"
                                label="Organización"             
                                @change="$v.organizacion.$touch()"
                                @blur="$v.organizacion.$touch()"
                                required
                            ></v-select>
                        </v-col> 
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarInstalacion" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>

import axios from "@/axios.js";
import { mask } from "vue-the-mask";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import {required, email, decimal, numeric, maxLength} from "vuelidate/lib/validators";

export default {
    props: [
        'id'
    ],
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.instalacion.nombre.$dirty) return errors            
            !this.$v.instalacion.nombre.required && errors.push('El nombre es requerido.')            
            return errors
        },
        latitudErrors () {
            const errors = []
            if (!this.$v.instalacion.latitud.$dirty) return errors            
            !this.$v.instalacion.latitud.required && errors.push('La latitud es requerida.') 
            !this.$v.instalacion.latitud.decimal && errors.push('Deben ser solo números.')           
            return errors
        },
        longitudErrors () {
            const errors = []
            if (!this.$v.instalacion.longitud.$dirty) return errors            
            !this.$v.instalacion.longitud.required && errors.push('La longitud es requerida.')
            !this.$v.instalacion.longitud.decimal && errors.push('Deben ser solo números.')               
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.instalacion.correo.$dirty) return errors
            !this.$v.instalacion.correo.required && errors.push('El correo es requerido.')
            !this.$v.instalacion.correo.email && errors.push('Debe ser un correo válido.')
            return errors
        },
        tiposErrors () {
            const errors = []
            if (!this.$v.tipoInstalacion.$dirty) return errors
            !this.$v.tipoInstalacion.required && errors.push('El tipo es requerido')
            return errors
        },
        cantidadErrors () {
            const errors = []
            if (!this.$v.instalacion.cantidadPersonal.$dirty) return errors
            !this.$v.instalacion.cantidadPersonal.required && errors.push('La cantidad de personas es requerida.')
            !this.$v.instalacion.cantidadPersonal.numeric && errors.push('Deben ser solo números.')
            return errors
        },
        orgErrors() {
            const errors = []
            if (!this.$v.organizacion.$dirty) return errors
            !this.$v.organizacion.required && errors.push('La Organización es requerida.')
            return errors
        },
        alcanceErrors() {
            const errors = []
            if (!this.$v.instalacion.alcance.$dirty) return errors
            !this.$v.instalacion.alcance.required && errors.push('El alcance es requerido')
            !this.$v.instalacion.alcance.maxLength && errors.push('Dato excede tamaño máximo')
            return errors
        },
        detalleErrors() {
            const errors = []
            if (!this.$v.instalacion.detalle.$dirty) return errors
            !this.$v.instalacion.detalle.required && errors.push('El detalle es requerido')
            !this.$v.instalacion.detalle.maxLength && errors.push('Dato excede tamaño máximo')
            return errors
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        notAdmin() {
            return this.usuario.rol === 'ADMIN' ? false : true;
        }
    },
    data: () => ({
        dialog: false,
        instalacion: {
            nombre: '',
            ubicacion: '',
            latitud: '',
            longitud: '',
            telefono: '',
            correo: '',
            sitioweb: '',
            descripcion:'',
            cantidadPersonal: '',
            tieneCombustibles: false,
            alcance: '',
            detalle: '',
            direccion: ''
        },
        provincia: '',
        canton:'',
        distrito: '',
        tipos: [],
        tipoInstalacion: '',
        telMask: '####-####',
        loading: false,
        organizacion: {},
        organizaciones: [],
        categoria: null
    }),
    validations: {
        instalacion: {
            nombre: {
                required,               
            },
            longitud: {
                required,
                decimal
            },
            latitud:{
                required,
                decimal
            },
            correo: {
                required,
                email
            },
            cantidadPersonal: {
                required,
                numeric
            },
            alcance: {
                required,
                maxLength: maxLength(250)
            },
            detalle: {
                required,
                maxLength: maxLength(250)
            }
        },
        tipoInstalacion:{
            required
        },
        organizacion: {
            required
        }
    },
    methods: {
        getDataInstalacion() {
            this.loading = true;
            axios.get("/instalaciones/getDataModificar/"+this.id).then(response => {
                this.instalacion = response.data.instalacion;
                this.direccionInst(this.instalacion.direccion);
                this.organizacion = response.data.organizacion;
                this.organizaciones = response.data.organizaciones;
                this.tipoInstalacion = response.data.tipoInstalacion;
                this.categoria = response.data.categoria;
                this.tipos = response.data.tipos;
                this.$emit('reload');
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },        
        modificarInstalacion() {
            this.$v.$touch();
            let instaMod = this.instalacion;
            delete instaMod.logoCategoria;
            delete instaMod.nombreCategoria;
            delete instaMod.nombreOrganizacion;
            delete instaMod.tieneCombustible;
            delete instaMod.tipoDeInstalacionNombre;
            delete instaMod.fechaRegistro
            instaMod.direccion = `${this.provincia}, ${this.canton}, ${this.distrito}`
            let formData = new FormData();
            formData.append('instaMod', JSON.stringify(instaMod));
            formData.append('instalacion', this.instalacion.id);
            formData.append('tipoInst', this.tipoInstalacion);
            formData.append('organizacion', this.organizacion);
            formData.append('idUsuario', this.usuario.id);
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/instalaciones/modificar/", formData).then(response => {
                    this.loading = false;
                    this.dialog = false;
                    this.$store.dispatch('noti',{mensaje: 'Instalación modificada', color:'success'});      
                    this.$store.commit('inscripcion',response.data.inscripcion);
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la Instalación', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        cancelar() {
            this.instalacion = {};
            this.tipoInstalacion = '';
            this.dialog = !this.dialog;   
            this.loading = false;                
            this.$v.instalacion.$reset();
        },
        direccionInst(direccion) {
            if(direccion) {
                let data = direccion.split(',')
                this.provincia = data[0]
                this.canton = data[1]
                this.distrito = data[2]
            }
        }
    },
}
</script>