var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('Modificar',{attrs:{"cuestionario":item.id},on:{"reload":_vm.reload}})]}},{key:"item.fechaRegistro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fechaFormat(item.fechaRegistro))+" ")]}},{key:"item.descargar",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.descargar(item.id)}}},on),[_vm._v("mdi-download")])]}}],null,true)},[_vm._v(" Descargar ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }