<template>
    <v-container fluid>        
        <EtiquetasTabla></EtiquetasTabla>
    </v-container>
</template>

<script>

import EtiquetasTabla from "@/components/etiquetas/EtiquetasTablaComponent";

export default {
    components: {
        EtiquetasTabla
    },
};
</script>