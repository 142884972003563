<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Parámetros de Medición" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Instalación"
                            v-model="instalacion.nombre"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-select                            
                            :items="eficiencias"
                            item-text="nombre"
                            item-value="id"
                            v-model="eficiencia"
                            label="Eficiencia"               
                            :error-messages="eficienciaErrors"             
                            @change="$v.eficiencia.$touch()"
                            @blur="$v.eficiencia.$touch()"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-select
                            v-model="indicadoresSeleccionados"
                            :items="indicadores"
                            item-value="id"
                            item-text="nombre"
                            label="Indicadores"
                            color="#0B3F67"
                            :error-messages="indicadoresErrors"             
                            @change="$v.indicadoresSeleccionados.$touch()"
                            @blur="$v.indicadoresSeleccionados.$touch()"
                            required
                            return-object
                            multiple>
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle" >
                                    <v-list-item-action>
                                        <v-icon 
                                            :color="indicadoresSeleccionados.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icono }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Seleccionar todos</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 3">
                                    <span>{{ item.nombre }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 3"
                                    class="grey--text caption"
                                >(+ otros {{ indicadoresSeleccionados.length - 3 }})</span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarIndicador">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    props: [
        'instalacion',
        'eficiencias',
        'indicadores',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        instalacionEficienciaEnlace: [],
        eficiencia: '',
        indicadoresSeleccionados: [],
    }),
    computed: {
        eficienciaErrors() {
            const errors = []
            if (!this.$v.eficiencia.$dirty) return errors            
            !this.$v.eficiencia.required && errors.push('La eficiencia es requerida.')            
            return errors
        },
        indicadoresErrors() {
            const errors = []
            if (!this.$v.indicadoresSeleccionados.$dirty) return errors            
            !this.$v.indicadoresSeleccionados.required && errors.push('Seleccione un indicador.')
            return errors
        },
        todosLosIndicadores () {
            return this.indicadoresSeleccionados.length === this.indicadores.length
        },
        algunosIndicadores () {
            return this.indicadoresSeleccionados.length > 0 && !this.todosLosIndicadores
        },
        icono () {
            if (this.todosLosIndicadores) return 'mdi-close-box'
            if (this.algunosIndicadores) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        cancelar() {
            this.dialog = !this.dialog;
            this.indicadoresSeleccionados = []
            this.$v.eficiencia.$reset()            
            this.$v.indicadoresSeleccionados.$reset()
            this.eficiencia = ''
            this.instalacionEficienciaEnlace = []
            this.loading = false;
        },
        agregarIndicador() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                this.agregaEnlaces();
                axios.post("/instalacionEficiencia/agregarEficienciaInstalacion",
                    this.instalacionEficienciaEnlace, 
                {params:{
                    idEficiencia: this.eficiencia, 
                    idInstalacion : this.instalacion.id,
                }}).then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Parámetro de Medición agregado', color:'success'});
                    this.$emit('recargaTabla');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                  this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.loading = false;
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el Parámetros de Medición', color:'error'});
                  }
                })
            }
        },
        toggle () {
            this.$nextTick(() => {
                if (this.todosLosIndicadores) {
                    this.indicadoresSeleccionados = []
                } else {
                    this.indicadoresSeleccionados = this.indicadores.slice()
                }
            })
        },
        agregaEnlaces() {
            this.indicadoresSeleccionados.forEach(
                indicador => {
                    this.instalacionEficienciaEnlace.push({                        
                        indicador: indicador.id
                    })
            });
        }
    },
    validations: {
        eficiencia: {
            required
        },
        indicadoresSeleccionados: {
            required
        }
    },
};
</script>