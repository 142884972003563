import { render, staticRenderFns } from "./UsuariosTablaComponent.vue?vue&type=template&id=64551668&scoped=true&"
import script from "./UsuariosTablaComponent.vue?vue&type=script&lang=js&"
export * from "./UsuariosTablaComponent.vue?vue&type=script&lang=js&"
import style0 from "./UsuariosTablaComponent.vue?vue&type=style&index=0&id=64551668&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64551668",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCol,VContainer,VDataTable,VLayout,VRow,VSheet,VSpacer,VTextField})
