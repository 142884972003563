<template>
    <v-container fluid>
        <v-row class="mb-3">                     
            <v-col cols="6" sm="5" md="5" lg="3" xl="3"><AgregarInstalacion @reload="listarInstlaciones" /></v-col>
            <v-spacer />
            <v-col cols="6" sm="4" md="4" lg="3" xl="3">            
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búsqueda"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>  
        </v-row>                      
         <v-row>
            <v-col cols="12">
                <v-card>
                    <v-sheet height="80" class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">                   
                        <v-layout v-if="isAdmin" fill-height align-center justify-center>                            
                            Catálogo de Instalaciones
                        </v-layout>   
                        <v-layout v-else fill-height align-center justify-center> 
                            Datos de Instalaciones
                        </v-layout>                               
                    </v-sheet>                                        
                    <v-data-table
                        multi-sort        
                        :search="search"        
                        :headers="headers"
                        :items="instalaciones"
                        class="elevation-1"            
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }">
                        <template v-slot:item.action="{ item }">
                            <ModificarInstalacion @reload="listarInstlaciones" v-bind:id="item.id"/>
                            <LogoComponent v-if="item.cluster" v-bind:id="item.id"/>
                        </template>
                        <template v-slot:item.estado="{ item }">
                            <v-layout justify-center>
                                <activarInstalacion @reload="listarInstlaciones" v-bind:estado="item.estado" v-bind:id="item.id"/>
                            </v-layout>
                        </template>
                        <template v-slot:item.combustibles="{ item }">
                            <v-layout justify-center>
                                <Combustibles-switch @reload="listarInstlaciones" v-bind:estadoComb="item.tieneCombustibles" v-bind:id="item.id" :instalacion="item.nombre"/>
                            </v-layout>
                        </template>
                    </v-data-table>                        
                </v-card>
            </v-col>
        </v-row>       
    </v-container>
</template>

<script>

import AgregarInstalacion from "@/components/instalaciones/InstalacionesAgregarComponent";
import ModificarInstalacion from "@/components/instalaciones/InstalacionesModificarComponent";
import ActivarInstalacion from "@/components/instalaciones/ActivarInstalacionComponent";
import CombustiblesSwitch from "@/components/instalaciones/CombustiblesSwitchComponent";
import axios from "@/axios.js";
import LogoComponent from "../documentos/LogoComponent.vue";

export default {
    components: {
        AgregarInstalacion,
        ModificarInstalacion,
        ActivarInstalacion,
        CombustiblesSwitch,
        LogoComponent
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    mounted () {
        this.listarInstlaciones();
    },
    computed: {
        organizacionId() {
            return this.$store.getters.organizacion != null ? this.$store.getters.organizacion.id : 0;
        },
        isAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN';
        },
    },
     data: () => ({
        headers:[
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Consume Combustibles',
                value: 'combustibles',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Nombre',
                value: 'nombre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Teléfono',
                value: 'telefono',
                align: 'center',
                sortable: true,                
            },
            {
                text: 'Correo',
                value: 'correo',
                align: 'center',
                sortable: true,                
            },
            {
                text: 'Tiempo en el Programa',
                value: 'tiempoEnPrograma',
                align: 'center',
                sortable: true,                
            },    
            {
                text: 'Organización',
                value: 'nombreOrganizacion',
                align: 'center',
                sortable: true,                
            },                              
        ],
        search: '',
        instalaciones: [],
        cargando: false
    }),
    methods: {
        listarInstlaciones() {
            this.cargando = true;
            let org = this.organizacionId;
            let rol = this.$store.getters.usuario.rol;
            let idUsuario = this.$store.getters.usuario.id;
            axios.get("/instalaciones/listarInstalaciones/" + org + "/" + rol + "/" + idUsuario) 
            .then(response => {
                this.instalaciones = response.data.instalaciones;   
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            })
        }
    }
}
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>

