<template>
    <v-container fluid>  
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="'Inscripción de Hogares'"/>
            </v-col>
        </v-row>   
        <v-row>
            <v-col cols="12">
                <AgregarEstrellaVerde 
                    :titulo="'Inscribir Hogares'"
                    @agregaHogares="listaHogares"/>
            </v-col>
        </v-row>
        <EstrellaTabla 
            :tituloTabla="'Hogares'" 
            :titulo="'Hogares Inscritos'" 
            :headers="headers" 
            :tipo="tipo"
            @modificaHogar="listaHogares"
            :hogares="hogares">
        </EstrellaTabla>
        <v-divider></v-divider>
        <NotasTabla :hogares="hogares"></NotasTabla>
    </v-container>
</template>

<script>
import axios from "@/axios";
import EstrellaTabla from "@/components/Estrellas/EstrellaTablaComponent";
import AgregarEstrellaVerde from "@/components/Estrellas/AgregarEstrellaVerdeComponent";
import NotasTabla from "@/components/Estrellas/NotaHogarTablaComponent";
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'

export default {
    components: {
        EstrellaTabla,
        TituloPrincipal,
        NotasTabla,
        AgregarEstrellaVerde,
    },
    props: [
        'tipo',
    ],
    data: () => ({
        cargando: false,
        hogares: [],
        hogar: '',
        headers: [
            { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
            { text: 'Comité Encargado', value: 'comiteRevision', align: 'center', sortable: true, filterable: true, },
            { text: 'Persona de contacto', value: 'personaContacto', align: 'center', sortable: true, filterable: true, },
            { text: 'Teléfono', value: 'telefono', align: 'center', sortable: true, filterable: true, },
            { text: 'Correo', value: 'correo', align: 'center', sortable: true, filterable: true, },
            { text: 'Estrellas Ganadas', value: 'estrellasGanadas', align: 'center', sortable: true, filterable: true, },
            { text: 'Calificación Final', value: 'calificacionFinal', align: 'center', sortable: true, filterable: true, },
            { text: 'Informes Recibidos', value: 'informesRecibidos', align: 'center', sortable: true, filterable: true, },
            { text: 'Informes Aprobados', value: 'informesAprobados', align: 'center', sortable: true, filterable: true, },
            { text: 'Evidencias', value: 'evidencias', align: 'center', sortable: false, filterable: false, },
        ],
    }),
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    computed: {
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
    },
    mounted() {
        this.listaHogares();
    },
    methods: {
        listaHogares() {
            let anio = this.$store.getters.anioConsulta;
            this.cargando = true;
            axios.get("/estrellas/getHogarInscritoInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.hogares = response.data.hogares;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
    }
};
</script>