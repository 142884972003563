<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="#0B3F67" dark v-on="on"
        >Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn
      >
    </template>
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <ModalTitle titulo="Agregar Eficiencia" @cancelar="cancelar"/>
      </v-card-title>  
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              :items="parametroEficiencia"
              item-text="nombre"
              item-value="valor"
              v-model="eficiencia.nombre"
              label="Parámetro de Eficiencia"
              :error-messages="nombreErrors"
              @change="$v.eficiencia.nombre.$touch()"
              @blur="$v.eficiencia.nombre.$touch()"
              required
            ></v-select>
            <v-select
              :items="unidadesMedida"
              item-text="nombre"
              item-value="valor"
              v-model="eficiencia.descripcion"
              label="Unidad de Medida"
              :error-messages="unidadmedidaErrors"
              @change="$v.eficiencia.descripcion.$touch()"
              @blur="$v.eficiencia.descripcion.$touch()"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" color="error" @click="cancelar"
          >Cancelar</v-btn
        >
        <v-btn
          text
          :loading="loading"
          color="#0B3F67"
          @click="agregarEficiencia"
        >
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";

export default {
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$store.getters.token;
  },
  components: {
    ModalTitle
  },
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.eficiencia.nombre.$dirty) return errors;
      !this.$v.eficiencia.nombre.required &&
        errors.push("El Parámetro de Eficiencia es requerido.");
      return errors;
    },
    unidadmedidaErrors() {
      const errors = [];
      if (!this.$v.eficiencia.descripcion.$dirty) return errors;
      !this.$v.eficiencia.descripcion.required &&
        errors.push("Por favor agregue la unidad de medida.");
      return errors;
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    eficiencia: {
      nombre: "",
      descripcion: ""
    },
    parametroEficiencia: [
      { nombre: "Población Laboral", valor: "Población Laboral" },
      { nombre: "Unidad Productivas", valor: "Unidad Productivas" },
      { nombre: "Área", valor: "Áreas" },
      { nombre: "Ventas", valor: "Ventas" }    
    ],
    unidadesMedida: [
      { nombre: "Cantidad de Personas", valor: "Cantidad de Personas" },
      { nombre: "Unidades de Masas", valor: "Unidades de Masas" },
      { nombre: "Números de Piezas", valor: "Números de Piezas" },
      { nombre: "Unidades de Área", valor: "Unidades de Área" },
      { nombre: "Unidad Monetaria", valor: "Unidad Monetaria" }
    ]
  }),

  validations: {
    eficiencia: {
      nombre: {
        required
      },
      descripcion: {
        required
      }
    }
  },
  methods: {
    cancelar() {
      this.eficiencia = {};
      this.dialog = false;
      this.loading = false;
      this.$v.eficiencia.$reset();
    },
    agregarEficiencia() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;
        axios
          .post("/eficiencia/agregarEficiencia", this.eficiencia)
          .then(response => {
            this.$emit("recargaTabla");
            this.$store.dispatch("noti", {
              mensaje: "Eficiencia agregada",
              color: "success"
            });
            this.cancelar();
          })
          .catch(error => {
            console.log("Error al agregar eficiencia " + error);
            this.cancelar();
            this.$store.dispatch("noti", {
              mensaje: "Ocurrió un error no se agregó la eficiencia",
              color: "error"
            });
            this.dialog = !this.dialog;
          });
      }
    }
  }
};
</script>
