<template>
    <v-dialog
        v-model="dialog" 
        persistent    
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" class="white--text"  color="#0B3F67">Agregar Programa<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
                </template>
                Agregar Nuevo Programa
            </v-tooltip>                            
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Programa" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="anios"
                            v-model="anioConsulta"
                            label="Año de Consulta"
                            :error-messages="anioErrors"             
                            @change="verificarAnio"
                            @blur="$v.anioConsulta.$touch()"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                        <v-alert dense type="error" v-model="alert">
                            Ya existe un programa para este periodo.
                        </v-alert>     
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-textarea
                            label="Descripción del programa"
                            v-model="descripcion"
                            hint="Descripción del programa o Criterios de Compras Sostenibles"
                            counter="500"
                            :error-messages="descrErrors"             
                            @change="$v.descripcion.$touch()"
                            @blur="$v.descripcion.$touch()"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" v-if="anioConsulta < 2022">
                        <v-file-input label="Adjuntar Programa"
                            accept=".png, .jpg, .jpeg"
                            v-model="programa"
                            show-size                                
                            clearable
                            :error-messages="programaErrors"             
                            @change="$v.programa.$touch()"
                            @blur="$v.programa.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-file-input label="Adjuntar Programa"
                            accept=".png, .jpg, .jpeg"
                            v-model="evidencias"
                            show-size                                
                            clearable
                            multiple
                            :error-messages="evidenciaErrors"             
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>                
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarPrograma">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js';
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
//Custom validations
const maxFileSize = (file) => {
    if(file != null) {
        return file.size < 1000000 ? true : false;
    }
    return true;
}
const maxMultiFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });
        return total < maximo;
    }
    return true;
}

export default {
    props: [        
        'anios',
        'aniosConstancia'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        programaErrors () {
            const errors = []
            if (!this.$v.programa.$dirty) return errors            
            !this.$v.programa.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.anioConsulta.$dirty) return errors            
            !this.$v.anioConsulta.required && errors.push('Por favor seleccione un año.')
            return errors
        },
        descrErrors () {
            const errors = []
            if (!this.$v.descripcion.$dirty) return errors            
            !this.$v.descripcion.required && errors.push('Dato Requerido.')
            !this.$v.descripcion.maxLength && errors.push('No debe superar el Tamaño máximo.')
            return errors
        },
        evidenciaErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('Evidencia requerida.')  
            !this.$v.evidencias.maxMultiFileSize && errors.push(`El tamaño no debe exceder ${this.evidencias != null ? this.evidencias.length : 0}MB.`)       
            return errors
        },
    },
    data: () => ({
        dialog: false,
        anioConsulta: null,
        programa: null,
        descripcion: null,
        alert: false,
        loading: false,
        evidencias: null
    }),
    validations: {
        programa: {
            required: requiredIf(function(){
                return this.anioConsulta < 2022
            }),
            maxFileSize
        },
        anioConsulta: {
            required
        },
        descripcion: {
            required,
            maxLength: maxLength(500)
        },
        evidencias: {
            required: requiredIf(function(){
                return this.anioConsulta > 2021
            }),
            maxMultiFileSize
        }
    },
    methods: {
        agregarPrograma() {
            this.$v.$touch();
            if(!this.$v.$invalid && !this.alert) {  
                this.loading = true;              
                let formData = new FormData();
                let tipoDoc = [];
                if(this.anioConsulta > 2021) {
                    this.evidencias.forEach(file => {
                        formData.append('archivos', file);      
                        tipoDoc.push([file.name, this.constantes.PROGRAMA_COMPRAS]);
                    });
                } else {
                    formData.append('archivo', this.programa);
                }
                formData.append('tipoDoc', JSON.stringify(tipoDoc));
                formData.append('anio', this.anioConsulta);
                formData.append('descripcion', this.descripcion);
                formData.append('instalacion', this.instalacion.id);
                formData.append('user', this.usuario.id);
                axios.post("/comprasSostenibles/agregarPrograma", formData)
                .then(response => {
                    this.$store.dispatch('noti', {mensaje: 'Programa agregado', color: 'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el programa', color: 'error'});
                      this.loading = false;
                    }
                  }
                })
            }
        },
        cancelar() {
            this.programa = null;
            this.anioConsulta = null;
            this.alert = false;
            this.descripcion = null;
            this.$v.programa.$reset();
            this.$v.descripcion.$reset();
            this.$v.anioConsulta.$reset();
            this.dialog = false;
            this.loading = false;
        },
        verificarAnio() {
            let tieneConst = this.aniosConstancia.includes(this.anioConsulta);
            if(tieneConst) {
                this.alert = true;                
            } else {
                this.alert = false;
                this.$v.anioConsulta.$touch()
            }
        },
    }
}
</script>