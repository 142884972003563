<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="520px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon 
                        medium
                        v-on="{ ...tooltip, ...dialog }" 
                        color="#0B3F67"
                        class="mr-2">
                        mdi-file-image-outline
                    </v-icon>                    
                </template>
                Agregar Logo
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Logo" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    v-model="logo"
                    :error-messages="docErrors"
                    @change="$v.logo.$touch()"
                    @blur="$v.logo.$touch()"
                    label="Seleccionar Logo">
                </v-file-input> 
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="argregarLogo" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}
export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    props: [
        'id',
    ],
    computed: {
      usuario(){
          return this.$store.getters.usuario;
      },
      docErrors () {
          const errors = []
          if (!this.$v.logo.$dirty) return errors            
          !this.$v.logo.maxFileSize && errors.push('El tamaño total debe ser menor a 1 MB.')
          !this.$v.logo.required && errors.push('Dato requerido.')
          return errors
      }
    },
    data: () => ({
        dialog : false,
        loading: false,
        logo: null,
    }),
    validations: {
        logo: {
            required,
            maxFileSize,
        }
    },
    methods: {
        argregarLogo() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('instalacion', this.id);
                formData.append('logo', this.logo);
                formData.append('usuario', this.usuario.id)
                axios.post('/instalaciones/agregarLogo/', formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Logo agregado ', color:'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', { mensaje: error.response.data, color: 'error' });
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', { mensaje: 'Error al agregar el logo', color:'error' }); 
                      this.cancelar();
                    }
                  }
                });
            }
        },
        cancelar() {
            this.logo = null;
            this.$v.logo.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>