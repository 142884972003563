<template>
    <v-container fluid>
        <TituloPrincipal v-bind:titulo="'Parámetros de Medición'"></TituloPrincipal>
        <v-row class="mb-3">
            <v-col cols="6" sm="6" md="6" lg="3" xl="3" v-if="instalacion && indicadores.length > 0">
                <AgregarIndicador
                    v-bind:instalacion="instalacion"
                    v-bind:indicadores="indicadores"
                    v-bind:eficiencias="eficiencias"
                    @recargaTabla="listaEficienciasInstalacion" />
            </v-col>
        </v-row>
        <v-row class="mb-3">
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                    <v-select                            
                        :items="instalaciones"
                        item-text="nombre"
                        item-value="id"
                        v-model="instalacion"
                        label="Instalación"   
                        @change="listaEficienciasInstalacion"  
                        return-object>
                    </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búsqueda"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-sheet
                        :height="alturaHoja()"
                        class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                        color="#0B3F67"
                        elevation="12"
                        max-width="calc(100% - 32px)">
                        <v-layout v-if="instalacion" fill-height align-center justify-center>{{"Parámetros de Medición " + instalacion.nombre}}</v-layout>
                        <v-layout v-else fill-height align-center justify-center>Parámetros de Medición</v-layout>
                    </v-sheet>
                    <v-data-table
                        multi-sort
                        :search="search"
                        :headers="headers"
                        :items="eficienciasInstalaciones"
                        class="elevation-1"
                        :loading="cargando"
                        loading-text="Cargando..."
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',                    
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                    >
                    <template v-slot:item.action="{ item }">
                        <ModificarIndicador
                            @recargaEditar="listaEficienciasInstalacion"
                            v-bind:idInstEfic="item.id"
                        />
                    </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "@/axios";
import TituloPrincipal from "@/components/utils/TituloPrincipalComponent";
import AgregarIndicador from "@/components/eficiencia/configuracionDesempenio/IndicadorDesempenioAgregarComponent";
import ModificarIndicador from "@/components/eficiencia/configuracionDesempenio/IndicadorDesempenioEditarComponent";

export default {
    components: {
        AgregarIndicador,
        ModificarIndicador,
        TituloPrincipal,
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted() {
        this.getDataAgregarIndicador();
    },
    data: () => ({
        instalacion: '',
        instalaciones: [],
        headers: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
                width: "25%",
                sortable: false
            },
            {
                text: "Indicador",
                value: "indicadorNombre",
                align: "center",
                width: "25%",
                sortable: true,
                filterable: true
            },
            {
                text: "Parámetro de Eficiencia",
                value: "eficienciaNombre",
                align: "center",
                width: "25%",
                sortable: true,
                filterable: true
            },
            {
                text: "Unidad de Medición",
                value: "eficienciaDescripcion",
                align: "center",
                width: "25%",
                sortable: true,
                filterable: true
            },

        ],
        search: "",
        indicadores: [],
        indicadoresActuales: [],
        eficiencias: [],
        eficienciasInstalaciones: [],
        cargando: false
    }),
    methods: {
        initIndicadores() {
            this.indicadores = []
            this.indicadores.push(
                {
                    id: this.$store.getters.constantes.indicadorEnergia, 
                    nombre: 'Energía eléctrica'
                },
                {
                    id: this.$store.getters.constantes.indicadorAgua,
                    nombre: 'Agua'
                },
                {
                    id: this.$store.getters.constantes.indicadorCombustibles, 
                    nombre: 'Combustibles fósiles'
                },
                {
                    id: this.$store.getters.constantes.indicadorCumpliLegal, 
                    nombre: 'Cumplimiento legal'
                },
                {
                    id: this.$store.getters.constantes.indicadorAguasResiduales, 
                    nombre: 'Tratamiento aguas residuales'
                },
                {
                    id: this.$store.getters.constantes.indicadorResiduos, 
                    nombre: 'Residuos'
                },
                {
                    id: this.$store.getters.constantes.indicadorContamiAdmosf, 
                    nombre: 'Gases refrigerantes'
                },
                {
                    id: this.$store.getters.constantes.indicadorCompSostenibles, 
                    nombre: 'Compras sostenibles'
                },
                {
                    id: this.$store.getters.constantes.indicadorAdaptacion, 
                    nombre: 'Adaptación'
                },
                {
                    id: this.$store.getters.constantes.indicadorCompensacion,
                    nombre: 'Compensación'
                },
            )
        },
        setSucursal() {
            this.$store.commit('sucursal', this.instalacion);
        },
        listaEficienciasInstalacion() {
            this.initIndicadores();
            this.setSucursal();
            this.indicadoresActuales = []
            this.eficienciasInstalaciones = [] 
            this.cargando = true;
            axios.get("/instalacionEficiencia/getEficienciasInstalacion/"+this.instalacion.id)
            .then(response => {
                this.eficienciasInstalaciones = response.data.eficienciasInstalacion;
                this.getIndicadoresActuales();
                this.indicadoresDisponibles();
                this.cargando = false;
            })
            .catch(error => {
                console.log(error);
                this.cargando = false;
            });
        },
        alturaHoja() {
            return this.$mq === "sm" ? 120 : 80;
        },
        getDataAgregarIndicador() {
            let organizacion = this.$store.getters.organizacion.id;
            axios.get("/instalacionEficiencia/getDataAgregar/"+organizacion)
            .then(response => {
                this.eficiencias = response.data.eficiencias;
                this.instalaciones = response.data.instalaciones;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error en la carga de datos: " + error);
              }
            })
        },
        getIndicadoresActuales() {
            this.eficienciasInstalaciones.forEach(
                efiInst => this.indicadoresActuales.push({
                    id: efiInst.indicador, 
                    nombre: efiInst.indicadorNombre, 
                })
            );
        },
        indicadoresDisponibles() {
            this.indicadoresActuales.forEach(actual => {
                this.indicadores = this.indicadores.filter(
                    indicador => 
                        indicador.id != actual.id
                );
            });
        },
    },
};
</script>

<style scoped>
table.v-table thead th {
    font-size: 20px !important;
}

.v-sheet--offset {
    top: -24px;
    position: relative;
}
</style>