<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal titulo="Actividades de Educación Ambiental y/o acciones para reducción y control"/>
            </v-col>
        </v-row>
        <TablaEducacion ref="tabla" @reload="getData"/>
    </v-container>
</template>
<script>

import TablaEducacion from "@/components/parametros/combustiblesFosiles/EducReducTablaComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    components: {
        TablaEducacion,
        TituloPrincipal,
        NavParameters,
        Logo
    },
    props: [
        'navParams'
    ],
    directives: {
        'sticky': VueSticky,
    },
    data: () => ({

    }),
    methods: {
        getData() {
            this.$refs.tabla.getDataPorAnio();
        }
    }
}
</script>