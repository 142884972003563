<template>
  <v-card flat class="pt-15 pb-15">
    <v-row justify-content="space-around">
      <v-col cols="4" class="d-flex justify-end">
        <v-img 
          min-height="250"
          max-width="250"
          src="@/assets/iconos/logoszurqui/mariposa_zurqui.png"/>
      </v-col>
      <v-col cols="1" class="d-flex justify-center">
        <v-divider class="divisor" vertical></v-divider>
      </v-col>
      <v-col cols="7">
        <v-img 
          contain
          min-height="250"
          max-width="500"
          src="@/assets/iconos/logoszurqui/nombre_zurqui.png"/>
        </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  
}
</script>
<style scoped>
  .divisor {
    border-width: 4px !important;
    border-color: #a3c61c !important;
    border-radius: 25%;
    height: 100%;
  }
</style>