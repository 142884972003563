<template>
  <v-card 
    class="float-right"
    color="#0B3F67"
    rounded="xl" 
    outlined 
    elevation="5" 
    max-width="67">
    <v-card-text class="text-center">
      <div class="pa-1"
        v-for="item in items"
        :key="item.title">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn 
              icon 
              small 
              dark 
              v-on="on" 
              :color="item.color"
              :href="item.url"
              target="_blank">
              <v-icon large>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
            {{ item.title }}
        </v-tooltip>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    items: [
      { icon: 'mdi-facebook', color: '#FFFFFF', title: 'Facebook', url: 'https://www.facebook.com/Epromcr/' },
     // { icon: 'mdi-instagram', color: '#FFFFFF', title: 'Instagram', url: 'https://www.instagram.com/prevassaasesoriassa/' },
     // { icon: 'mdi-linkedin', color: '#FFFFFF', title: 'Linkedin', url: 'https://www.linkedin.com/company/prevassa-asesorias' },
      //{ icon: 'mdi-twitter', color: '#FFFFFF', title: 'Twitter', url: 'https://twitter.com/' },
      { icon: 'mdi-google-plus', color: '#FFFFFF', title: 'Google+', url: 'https://www.epromcr.com/' },
    ]
  })
}
</script>