<template>
    <v-container style="background-color: rgb(11, 63, 103)">
        <v-card raised>
            <!-- Titulo Principal -->
            <v-card height="100%" outlined>
                <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                    Reducción en el Consumo de {{ tituloIndicador }} <br>
                    AÑO {{ periodoAnterior }} respecto al AÑO {{ periodoActual }}                    
                </v-card-text>
            </v-card>        
            <!-- Primer encabezado -->
            <v-row no-gutters align="stretch" align-content="stretch">
                <v-col cols="12" md="6" align-self="center" justify-self="center">
                    <v-card height="100%">
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                        Datos Requeridos
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card height="100%" outlined>
                        <v-card-text class="text-center title white--text" style="background-color: rgb(11, 63, 103)">
                            Equivalencias
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>                 
            <!-- Encabezado de las columnas -->
            <v-row no-gutters align="stretch" align-content="stretch">
                <v-col cols="6" align-self="stretch">
                    <v-row no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    <p>{{ detalle }}</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-row no-gutters align="stretch" align-content="stretch">
                                <v-col cols="12">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Consumo Total <div v-if="indicadorActual == constantes.indicadorAgua">m<sup>3</sup></div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="stretch" align-content="stretch">
                                <v-col cols="6" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Anterior <br>
                                            {{ periodoAnterior }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" align-self="stretch">
                                    <v-card height="100%" outlined>
                                        <v-card-text class="text-center font-weight-black">
                                            Actual <br>
                                            {{ periodoActual }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Ahorro <div v-if="indicadorActual == constantes.indicadorAgua">m<sup>3</sup></div>
                                    <div v-if="indicadorActual == constantes.indicadorEnergia">Kwh</div>
                                    <div v-if="indicadorActual == constantes.indicadorCombustibles">L</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Detalle para Indicador Agua -->
                <v-col v-if="indicadorActual == constantes.indicadorAgua" cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Colones
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Bidones de Agua de 20 L
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Tanques de Almacenamiento de Agua de 750 L
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Piscinas Olimpicas
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Detalle para Indicador Electricidad -->
                <v-col v-if="indicadorActual == constantes.indicadorEnergia" cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Colones
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    <p>Hogares Abastecidos</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                     <p>Ton CO2, eq evitadas</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Detalle para Indicador Combustibles -->
                <v-col v-if="indicadorActual == constantes.indicadorCombustibles" cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="12" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    Colones Ahorrados
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Detalle para Indicador Gestion de Residuos-->
                 <v-col v-if="indicadorActual == constantes.indicadorResiduos" cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="6" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    <p>Arboles</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center font-weight-black">
                                    <p>Dinero</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Fila de detalle -->
            <v-row v-for="data in datos" :key="data.nombre" no-gutters align="stretch" align-content="stretch">
                <v-col cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    {{ data.nombre }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="2" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    {{ numberFormat(data.consumoAnterior) }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="2" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    {{ numberFormat(data.consumoActual) }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.ahorroConsumo)">{{ numberFormat(data.ahorroConsumo) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Formato indicador agua -->
                <v-col v-if="indicadorActual == constantes.indicadorAgua" cols="6" align-self="stretch">
                    <v-row class="altura" no-gutters align="stretch" align-content="stretch">
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100" outlined>
                                <v-card-text class="altura text-center">
                                    <div :class="numberColor(data.ahorroColones)">₡{{ numberFormat(data.ahorroColones) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.bidones)">{{ numberFormat(data.bidones) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.tanquesAgua)">{{ numberFormat(data.tanquesAgua) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.piscinasOlimp)">{{ numberFormat(data.piscinasOlimp) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- formato indicador Electricidad -->
                <v-col v-else-if="indicadorActual == constantes.indicadorEnergia" cols="6" align-self="stretch">
                    <v-row no-gutters align="stretch" align-content="stretch">
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.ahorroColones)">₡{{ numberFormat(data.ahorroColones) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.hogaresAbas)">{{ numberFormat(data.hogaresAbas) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.tonC02)">{{ numberFormat(data.tonC02) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- formato indicador Combustibles -->
                <v-col v-else-if="indicadorActual == constantes.indicadorCombustibles" cols="6" align-self="stretch">
                    <v-row no-gutters align="stretch" align-content="stretch">
                        <v-col cols="12" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.ahorroColones)">₡{{ numberFormat(data.ahorroColones) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                 <!-- formato indicador Residuos -->
                <v-col v-if="indicadorActual==constantes.indicadorResiduos" cols="6" align-self="stretch">
                    <v-row no-gutters align="stretch" align-content="stretch">
                        <v-col cols="6" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center ">
                                    <div :class="numberColor(data.arboles)">{{ data.arboles }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" align-self="stretch">
                            <v-card height="100%" outlined>
                                <v-card-text class="text-center">
                                    <div :class="numberColor(data.ahorroColones)">₡{{ data.ahorroColones }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-divider></v-divider>
        <v-card>
            <v-row no-gutters align="stretch" align-content="stretch" >
                <v-col cols='12' md="3">
                    <Justificacion v-if="!necesitaJustificacion" :botonIcono="true" :reqEvidencias="true" :tipoDocumento="tipoDocumento"/>
                    <div v-else :class="{'pt-3':!errores, 'pl-3':!errores}">
                        <v-alert v-if="!errores"
                            dense
                            text
                            type="success">
                            Reducci&oacute;n Alcanzada, mayor o igual a 1%    
                        </v-alert>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>

import axios from "@/axios.js";
import Justificacion from "@/components/utils/JustificacionComponent";

export default {
    components: {
    Justificacion
},
    computed: {
        detalle() {
             let detalle = '' ;
             switch(this.indicadorActual) {
                case this.constantes.indicadorCombustibles:
                    return 'Combustible';
                    break;
                case this.constantes.indicadorResiduos:
                    return 'Material Resmas';
                    break;
                default:
                    return this.anioConsulta < 2022 ? 'Instalación' : 'Medidor';
             }
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        tituloIndicador() {
            let indicadorActual = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;

            switch(indicadorActual) {
                case constantes.indicadorAgua:
                    return "Agua";
                    break;
                case constantes.indicadorEnergia:
                    return "Electricidad";
                    break;
                case constantes.indicadorCombustibles:
                    return "Combustibles Fósiles"
                    break;
                    case constantes.indicadorResiduos:
                    return "Papel"
                    break;
            }
        },
        tipoDocumento() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            let tipoDocumento = null;
            switch(indicador) {
                case constantes.indicadorEnergia:
                    tipoDocumento = constantes.JUSTIF_REDUCCION_ELECT;
                    break;
                case constantes.indicadorAgua:                                    
                    tipoDocumento = constantes.JUSTIF_REDUCCION_AGUA;
                    break;
                case constantes.indicadorCombustibles:
                    tipoDocumento = constantes.JUSTIF_REDUCCION_COMB;
                    break;
                case constantes.indicadorResiduos:
                    tipoDocumento = constantes.JUSTIF_REDUCCION_PAPEL;
                    break;
            }
            return tipoDocumento;
        }
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    props: [
        'data'
    ],
    mounted() {
        this.getDataReduccion();
    },
    data: () => ({
        datos: [],
        periodoActual: null,
        periodoAnterior: null,
        necesitaJustificacion: false,
        justificacion :'',
        errores: false
    }),
    methods: {
        getDataReduccion() {
            let instalacion = this.instalacion.id ;
            axios.get("/instalaciones/getDataReduccion/" + instalacion + "/" + this.indicadorActual + "/" +this.anioConsulta)
            .then(response => {
                this.datos = response.data.consumos;
                this.periodoActual = response.data.periodoActual;
                this.periodoAnterior = response.data.periodoAnterior;
                this.necesitaJustificacion = response.data.necesitaJustificacion;
                this.getJustificacion();
                this.loadingModal(false);
                this.errores = false
            }).catch(error => {
                this.errores = true
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los datos " + error);
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getJustificacion(){
            this.loading = true;
            axios.get("/instalaciones/obtenerJustificacion/"+this.indicadorActual+'/'+this.instalacion.id+'/'+this.periodoActual+'/'+1 ).then(response =>{
                this.justificacion = response.data.justificacion;
                if(this.justificacion == 'null'){
                   this.justificacion = 'Agregue una justificación.'
                }
                this.errores = false
            }).catch(error => {
                this.errores = true
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false; 
              }
            })
        }, 
        formatoCombustible(monto){
            let resultado = monto.toFixed(2) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\ ')           
            return Number.isNaN(resultado) ? 0 : resultado;
        },
        numberFormat(number) {
            return number.toLocaleString('es-CR');
        },
        numberColor(number) {
            if(number > 0) {
                return 'green--text text--darken-1 font-weight-bold';
            }
            if(number < 0) {
                return 'red--text text--lighten-1 font-weight-bold';
            } else {
                return 'font-weight-bold';
            }
        }
    },
}
</script>
<style scoped>
    .altura {
        height: 100%;
    }    
</style>