<template>
    <v-container>
        <v-row class="mb-3">
            <v-col cols="12" sm="5" md="5" lg="3" xl="3"><usuarioAgregar @recargaTabla="buscarUsuarios" /></v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="4" lg="3" xl="3">            
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búsqueda"                    
                    single-line
                    hide-details>
                </v-text-field>
            </v-col>  
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-sheet height="80" class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">                   
                        <v-layout v-if="isAdmin" fill-height align-center justify-center>                            
                            Catálogo de Usuarios
                        </v-layout>
                        <v-layout v-else fill-height align-center justify-center>                            
                            Datos de Usuarios
                        </v-layout>                                  
                    </v-sheet>
                    <v-data-table                    
                        multi-sort
                        :search="search"        
                        :headers="headers"
                        :items="usuarios"
                        class="elevation-1"
                        :loading="cargando"
                        loading-text="Cargando..."
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            pageCount: 12,                       
                            showCurrentPage: true,                            
                        }">
                        <template v-slot:item.action="{ item }">
                            <v-layout justify-center>
                                <modificarUsuario 
                                    @reloadEdit="buscarUsuarios" 
                                    v-bind:idUsuario="item.id"/>
                                <ComiteAmbiental 
                                    v-if="item.nombreRol == 'Comité Ambiental' && item.estado"
                                    @reload="buscarUsuarios" 
                                    :usuario="item" 
                                />
                            </v-layout>
                        </template>
                        <template v-slot:item.estado="{ item }">
                            <v-layout justify-center>
                                <activarUsuario @reload="buscarUsuarios" v-bind:estado="item.estado" v-bind:id="item.id"/>
                            </v-layout>
                        </template>
                    </v-data-table>      
                </v-card> 
            </v-col>
        </v-row>

      </v-container>
</template>

<script>

import ActivarUsuario from "@/components/usuarios/ActivarUsuarioComponent";
import ModificarUsuario from "@/components/usuarios/ModificarUsuarioComponent";
import UsuarioAgregar from "@/components/usuarios/UsuarioAgregarComponent";
import ComiteAmbiental from "@/components/usuarios/ConfComiteAmbientalComponent";
import axios from "@/axios.js";

export default {
    components: {
        ActivarUsuario,
        ModificarUsuario,
        UsuarioAgregar,    
        ComiteAmbiental    
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    mounted () {
        this.buscarUsuarios();
        if(this.$store.getters.usuario.rol === 'ADMIN') {
            this.headers.push(this.org);
        }
    },
    computed: {
        headersAxios() {                  
            return { Authorization: "Bearer " + this.$store.getters.token };
        },
        isAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN';
        },
    },
    data: () => ({
        headers:[
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Nombre',
                value: 'nombre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Apellido',
                value: 'apellido',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Cédula',
                value: 'cedula',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Rol',
                value: 'nombreRol',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Puesto de Trabajo',
                value: 'puestoTrabajo',
                sortable: true,
                filtrerable: false,
            },
            {
                text: 'Teléfono',
                value: 'telefono',
                align: 'center',
                sortable: true,
                filtrerable: false,
            },
            {
                text: 'Correo',
                value: 'correo'
            }
        ],
        org: {
            text: 'Organización',
            value: 'nombreOrganizacion',
            align: 'center',
            sortable: true,
            filterable: true,
        },
        search: '',
        usuarios: [],
        cargando: false,
    }),
    methods: {
        buscarUsuarios() {
            this.cargando = true;
            if(this.$store.getters.usuario.rol === 'ADMIN') {
                axios.get("/usuario/listarUsuarios", { headers : this.headersAxios }).then(response => {
                    this.usuarios = response.data.usuarios;   
                    this.cargando = false;
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.cargando = false;
                  }
                })
            } else {
                let organizacion = this.$store.getters.organizacion;
                axios.post("/usuario/listarPorOrganizacion", organizacion, { headers : this.headersAxios }).then(response => {
                    this.usuarios = response.data.usuarios;   
                    this.cargando = false;
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.cargando = false;
                  }
                })
            }
        },
    }
}
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>

