<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
            <v-card>
                <v-sheet
                    :height="alturaHoja()"
                    class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                    color="#0B3F67"
                >
                    <v-layout fill-height align-center justify-center>Legislaciones Ambientales</v-layout>
                </v-sheet>
                <v-data-table 
                    :items-per-page="legislaciones.length"
                    disable-sort
                    :headers="headers"
                    :items="legislaciones"
                    dense
                    hide-default-footer=""
                    no-data-text="Sin datos"
                    disable-pagination
                >
                    <template v-slot:item.link="{ item }">
                        <v-layout justify-center>
                            <template>
                                <a :href="item.link" target="blank">
                                    <v-icon class="blue--text text--lighten-2">mdi-file-document-outline</v-icon>
                                </a>
                            </template>
                        </v-layout>
                    </template>
                </v-data-table>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        headers: [
            {
                text: "Legislación",
                value: "legislacion",
                align: "left",
            },
            {
                text: "Aplicabilidad",
                value: "aplicabilidad",
                align: "left",
            },
            {
                text: "Documento",
                value: "link",
                align: "left",
            }
        ],
        legislaciones: [
            {
                legislacion: "Estrategia Nacional Separación, Recuperación y Valorización de Residuos 2016-2021",
                aplicabilidad: "Todos",
                link: "https://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=82186&nValor3=105110&strTipM=TC",
            },
            {
                legislacion: "Reglamento para la Calidad del Agua Potable, Decreto Ejecutivo N°38924-S",
                aplicabilidad: "Todos",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?nValor1=1&nValor2=80047"
            },
            {
                legislacion: "Decreto N°33601 - Reglamento de Vertido y Reúso de Aguas Residuales. (Gaceta N°55, del 19/03/2007)",
                aplicabilidad: "Planta Tratamiento Aguas Residuales",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=59524&nValor3=110119&strTipM=TC"
            },
            {
                legislacion: "Reglamento de Aprobación de Sistemas de Tratamiento de Aguas Residuales N°39887-S-MINAE",
                aplicabilidad: "Planta Tratamiento Aguas Residuales",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=82487&nValor3=105490&strTipM=TC"
            },
            {
                legislacion: "Reglamento del Canon Ambiental por Vertidos (Gaceta N°74, del 17/04/2008)",
                aplicabilidad: "Planta Tratamiento Aguas Residuales",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=62896&nValor3=81024&strTipM=TC"
            },
            {
                legislacion: "Decreto N°39316-Reglamento para el Manejo y Disposición Final de Lodos y Biosólidos",
                aplicabilidad: "Planta Tratamiento Aguas Residuales y Tanque Séptico",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?nValor1=1&nValor2=80715"
            },
            {
                legislacion: "Decreto Ejecutivo N°30131, Reglamento para la Regulación del Sistema de Almacenamiento y Comercialización de Hidrocarburos",
                aplicabilidad: "Almacenamiento de combustibles",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?nValor1=1&nValor2=48016"
            },
            {
                legislacion: "Decreto Ejecutivo N°27001, Reglamento General para Clasificación y Manejo de Residuos Peligrosos",
                aplicabilidad: "Productos Químicos",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?nValor1=1&nValor2=54836"
            },
            {
                legislacion: "Decreto Ejecutivo N°41527-S-MINAE Reglamento General para la Clasificación y Manejo de Residuos Peligrosos",
                aplicabilidad: "Producto Químicos",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=88120&nValor3=114959&strTipM=TC"
            },
            {
                legislacion: "Ley 8839: Gestión Integral de Residuos y todos sus reglamentos",
                aplicabilidad: "Todos",
                link: "https://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?nValor1=1&nValor2=68300"
            },
            {
                legislacion: 'Decreto Ejecutivo N°33745- S del 8 de febrero del 2007 “Reglamento sobre Llantas de Desecho”',
                aplicabilidad: "Llantas",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=60030&nValor3=67344&strTipM=TC"
            },
            {
                legislacion: 'Decreto Ejecutivo N°35933-S del 12 de febrero del 2010 “Reglamento para la Gestión Integral de Residuos Electrónicos”',
                aplicabilidad: "Electrónicos",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=67850&nValor3=80550&strTipM=TC"
            },
            {
                legislacion: "Decreto ejecutivo N°37788 Reglamento General para la Clasificación y Manejo de Residuos Peligrosos",
                aplicabilidad: "Peligrosos",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?nValor1=1&nValor2=75279"
            },
            {
                legislacion: "Decreto N°37614-MINAE",
                aplicabilidad: "Refrigerantes",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_texto_completo.aspx?param1=NRTC&nValor1=1&nValor2=74569&nValor3=92131&strTipM=TC"
            },
            {
                legislacion: "Decreto N°35676, Art. 30",
                aplicabilidad: "Refrigerantes",
                link: "http://www.pgrweb.go.cr/scij/Busqueda/Normativa/Normas/nrm_articulo.aspx?param1=NRA&nValor1=1&nValor2=67392&nValor3=107657&nValor4=-1&nValor5=31&nValor6=06/08/2009&strTipM=FA"
            },
            {
                legislacion: "Documentos Oficiales Categoría Cambio Climático ",
                aplicabilidad: "Todos",
                link: "http://apps.grupoice.com/PBAECC/recursos.xhtml"
            },    
        ],
    }),
    methods: {
        alturaHoja() {
            return this.$mq === "sm" ? 120 : 80;
        }
    },
}
</script>
