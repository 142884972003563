<template>
    <v-dialog
        v-model="dialog"       
        persistent
        scrollable
        max-width="625px"
        transition="dialog-transition">  
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"     
                        @click="getDataSuministros()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>          
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Categoria" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" >
                            <v-text-field                              
                                label="Nombre"
                                v-model="suministros.nombre"
                                :error-messages="nameErrors"                                
                                required
                            ></v-text-field>
                        </v-col>                                             
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarSuministros" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>
import axios from "@/axios.js";
import {required} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: 
    [      
        'idSuministros'    
    ],
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.suministros.nombre.$dirty) return errors            
            !this.$v.suministros.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        suministros: {
            nombre: '',           
            estado: 0,
        },
        loading: false
        
    }),
    validations: {
        suministros: 
        {
            nombre: 
            {
                required,               
             },
        }
           
    },
    methods: {          
         getDataSuministros() {
            this.loading = true;
            axios.get("/suministros/getDataSuministros/"+this.idSuministros).then(response => {
                this.suministros = response.data.tipoSumi;                       
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarSuministros() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/suministros/modificarSuministros/"+this.idSuministros, this.suministros)                
                .then(response => {
                    this.loading = false;
                    this.dialog = false;                    
                    this.$store.dispatch('noti',{mensaje: 'Categoría de cadena de suministros modificada', color:'success'});
                    this.$emit('reloadEdit');                    
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la categoría de cadena de suministros', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },             
        cancelar() {
            this.suministros = {};
            this.dialog = !this.dialog;           
            this.$v.suministros.$reset();    
            this.loading=false;        
        },
    },
}
</script>