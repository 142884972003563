import { InformeService } from '../informe-api'
import diccionario from '@/imageDictionary.js'

export function informe2021(constantes,  anio, anioAnterior, instalacion, organizacion, datos) {
    const informe = new InformeService(constantes)
    let content = [
      { 
        table: {
            widths: ['71%', '29%'],
            body: [
                [
                    {
                        text: 'IV Categoria:\n Cambio Climático',
                        fontSize: 26,
                        bold: true,
                        alignment: 'right'
                    },
                    {
                        rowSpan: 3,
                        text: anio,
                        fontSize: 26,
                        bold: true,
                        alignment: 'center',
                        fillColor: '#9bbb59',
                        color: '#ffffff',
                    },
                ],
                [ 
                   {
                        text: 'Programa Bandera Azul\n Ecológica',
                        fontSize: 30,
                        alignment: 'right',
                        color: '#ffffff',
                        fillColor: '#4f81bd'
                    }
                ],
                [
                    {
                        image: diccionario.logoBandera,
                        width: 440,
                  height: 600,
                    }
                ],
            ]
        },
        layout: {
            defaultBorder: false,
        },
    },
    {
        table: {
            widths: ['50%', '50%'],
            body: [
                [
                    {
                        image: diccionario.logoBanderaAzulUno,
                        alignment: 'right',
                        fit: [100, 100]
                    },
                    {
                        image: diccionario.logoBanderaAzulDos,
                        alignment: 'left',
                        fit: [124, 124]
                    }
                ]
            ]
        },
        layout: {
            defaultBorder: false,
        },
    },
    {
        text: 'Informe Final Formato Año ' + anio,
        alignment: 'center',
        fontSize: 26,
        bold: true,
        pageBreak: 'after'
    },
    //Fin de la Portada del informe
    //Inicio Cuerpo del informe
    {
        text: 'Informe Final Formato Año ' + anio,
        alignment: 'center',
        bold: true
    },
    {
        text: 'Aspectos Generales',
        alignment: 'left',
        bold: true,
        margin: [0, 10, 0, 0]
    },
    {
        text: 'La organización participante, debe elaborar y entregar un informe final del año de participación. Ese informe se debe enviar del 1° de enero al 15 de febrero, del año posterior al de participación. No se recibirán informes finales después del 15 de febrero. En el caso de organizaciones multisedes, deben entregar un informe por cada sede inscrita, que contenga la especificidad de lo realizado por esa sede (Ver documento: Lineamientos para Organizaciones Multisedes), el cual puede consultar en el siguiente enlace: http://apps.grupoice.com/PBAECC/recursos.xhtml ',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'Este Informe Final se debe elaborar, de acuerdo con el tipo o formato (Procesador de texto) definido, asimismo se debe respetar la forma y estructura, de acuerdo con el orden establecido. No se acepta ningún tipo de cambio a este informe, lo deben llenar con la forma y orden de contenidos que se les comparte. Igualmente deben agotar cada tema (Parámetro y subparámetro) en el espacio que corresponde, de acuerdo con el orden establecido, sin mezclar contenidos ni evidencias que no corresponden al tema que se está tratando. Asimismo, no se aceptan anexos en el informe ni tampoco por aparte. El documento oficial para elaborar este informe se debe descargar del siguiente enlace: http://apps.grupoice.com/PBAECC/recursos.xhtml ',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'Una vez elaborado el informe final, se debe convertir del tipo o formato procesador de texto, al tipo o formato PDF (no se acepta ningún informe final, si no viene en tipo o formato PDF).',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'El informe final se debe subir y enviar en un solo documento en digital, por medio de la nueva plataforma informática. Para ingresar a ese sistema, deben primeramente registrarse en el siguiente enlace: http://apps.grupoice.com/PBAECC/registro.xhtml (Para asignarle usuario y contraseña).',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'Teniendo la contraseña asignada deben abrir el siguiente enlace: http://apps.grupoice.com/PBAECC/login.xhtml y digitar el usuario que se les asignó previamente. Realizado ese paso, seguidamente deben abrir donde dice comité y buscar la opción informe final, tal y como se muestra en cuadro adjunto.',
        alignment: 'justify',
        margin: [40, 20, 40, 20]
    },
    {
        columns: [
            { width: '*', text: '' },
            { width: 'auto', image: diccionario.subirInforme, fit:[700, 150], alignment: 'center' },
            { width: '*', text: '' }
        ]
    },
    {
        text: 'El nombre del archivo para enviar el informe final debe ir de la siguiente forma:\n Informe final PBAE-CCC 2020 0039 ICE Sucursal Los Chorros de Guácimo (El nombre de la sede debe ser, como quieren que aparezca en el certificado).',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'Las evidencias de todo lo realizado, son fundamentales para otorgar los puntos establecidos, según parámetro y requisitos solicitados, tales como: fotografías, certificados, constancias, correos, listas de asistencia u otros, que demuestren las acciones ejecutadas por la organización para obtener el galardón; sin embargo, las evidencias son solo una muestra de lo realizado y deben tener presente que no es necesario, por ejemplo, poner todas las fotos tomadas de una actividad, pero si es muy importante poner los pies de foto para tener claro a cuál actividad se refieren. Igualmente, si la participación en una actividad fue masiva la lista de asistencia puede ser solo una muestra en donde se indique cual (es) personas de la sede participante participaron de esa actividad.',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'Para efectos de la entrega del informe final, en los cuadros que deben llenar, el consumo anterior que se indica con la letra A, es del año anterior al año de participación. Se recomienda que incluyan los datos de consumo de todos los años de participación, y que los muestren también en un gráfico, para ver la tendencia de consumo por años. Las sedes que participan deben hacerlo como año natural, abarcando por consiguiente los doce meses establecidos: Año Natural: 1° de enero al 31 de diciembre de cada año de participación. ',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: 'La utilización de este Formato de Informe Final es obligatorio para revisarlo',
        alignment: 'center',
        bold: true,
        margin: [40, 20, 40, 0],
    },
    {
      margin: [40, 20, 40, 0],
      alignment: 'justify',
      text: [
          { text: 'Reiterar', bold: true },
          { text: ', en cada parámetro (Tema), debe venir toda la información solicitada y demostrar lo realizado, por cada una de las sedes participantes. Asimismo, deben enviar un solo documento. No se aceptan anexos en el informe final ni en archivos aparte. En específico, en cada parámetro y subparámetro establecido en el manual de procedimiento, deben poner toda la información solicitada, con las evidencias que deseen incluir y que demuestren lo realizado.' }
      ]
    },
    {
        text: 'Tres aspectos son indispensables de tomar en cuenta para elaborar el informe final:',
        alignment: 'justify',
        margin: [40, 20, 40, 0],
    },
    {
        ul: [
            { text: 'Información cuantitativa (Métricas), en los casos que se les pida', bold: true, margin: [50, 20, 40, 0], },
            { text: 'Descripción de los proyectos o actividades desarrolladas.', bold: true, margin: [50, 0, 40, 0], },
            { text: 'Evidencias de lo que hicieron (fotografías, certificados, listas de asistencia, correos, notas, constancias u otros), que demuestren las acciones realizadas, en específico, por cada una de las sedes participantes para obtener el galardón.', bold: true, margin: [50, 0, 40, 0], alignment: 'justify' }
        ]
    },
    {
        text: 'Para la notación de las cifras de los consumos, se deben seguir lo estipulado por el Reglamento Técnico RTCR 443:2010 Metrología. Unidades de Medida Sistema Internacional (SI):',
        alignment: 'justify',
        margin: [40, 20, 40, 0],
    },
    {
        ul: [
            { text: 'Como separador de decimales puede utilizarse el punto (.) o la coma (,), pero su uso debe ser uniforme en todo el documento.', bold: true, margin: [50, 20, 40, 0] },
            { text: 'Los números con grandes cantidades de cifras se deben agrupar en conjuntos de 3, separados por espacios, NO por comas o puntos, como se indica en el siguiente cuadro.', bold: true, margin: [50, 0, 40, 20] },
        ]
    },
    {
        columns: [
            { width: '*', text: '' },
            {
                width: 'auto',
                    table: {
                        body: [
                            [{text: 'Correcto', alignment: 'center', fillColor: '#4f81bd', bold: true}, {text: 'Incorrecto', alignment: 'center', fillColor: '#4f81bd', bold: true}],
                            [{text: '34 756.52', alignment: 'center', fillColor: '#dbe5f1'}, {text: '34,756.52', alignment: 'center', fillColor: '#dbe5f1'}],
                            [{text: '43 729,168 29', alignment: 'center'}, {text: '43.729,16829', alignment: 'center'}]
                        ]
                    }
            },
            { width: '*', text: '' },
        ]
    },
    {
        margin: [50, 20, 40, 0], 
        alignment: 'justify',
        text: [
            { text: '1. Resumen Ejecutivo: ', bold: true },
            { text: 'Descripción sobre la actividad(s) sustantiva(s) que desarrolla la organización y demás detalles que consideren pertinentes de indicar, que permitan ubicar al evaluador del informe, sobre la naturaleza de la organización participante y sus particularidades.', italics: true }
        ]
    },
    {
        text: '2. Generalidades de la Organización Categoría Cambio Climático PBAE', bold: true, margin: [50, 20, 40, 20], 
    },
    {
        table: {
            widths: ['20%', '30%', '50%'],
            body: [
                [
                    informe.informeLogo(datos.orgaLogo),
                    {
                        text: 'Nombre de la Organización',
                        bold: true,
                        alignment: 'center',
                        fillColor: '#8db3e2'
                    },
                    { text: organizacion.nombre }
                ],
                [
                    '',
                    { text: 'Nombre de la Sede', bold: true, alignment: 'center', fillColor: '#8db3e2' },
                    { text: instalacion.nombre}
                ],
                [
                    '',
                    { text: 'Código de la Inscripción', bold: true, alignment: 'center', fillColor: '#8db3e2'},
                    { text: instalacion.codigoPbae}
                ],
                [
                    '',
                    { text: 'Ubicación de la Sede', bold: true, alignment: 'center', fillColor: '#8db3e2'},
                    { text: instalacion.ubicacion}
                ],
                [
                    '',
                    { text: 'Cantidad de colaboradores/as', bold: true, alignment: 'center', fillColor: '#8db3e2'},
                    { text: instalacion.cantidadPersonal }
                ],
                [
                    '',
                    { text: 'Años en que ha participado' , bold: true, alignment: 'center', fillColor: '#8db3e2' },
                    { text: instalacion.tiempoEnPrograma }
                ],
                [
                    { text: 'Indicar año y estrellas ganadas en años anteriores al que están participando', bold: true, alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                    '',
                    { text: datos.estrellasInforme }
                ],
                [
                    { text: 'Descripción de la organización (Actividad sustantiva a que se dedica)' , bold: true, alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                    '',
                    { text: datos.descripcionOrg }
                ],
                [
                    { text: 'Mostrar foto (s), que demuestre, la iza o exhibición de la bandera, en el caso que tuvieran ese galardón del PBAE', bold: true, alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                    '',
                    {
                        image: diccionario.logoBanderaAzulUno,
                        alignment: 'center',
                        fit: [100, 100]
                    }
                ]
            ]
        }
    },
    {
        text: '3. Integrantes Comité de Sede Participante: Categoría Cambio Climático PBAE', bold: true, margin: [50, 20, 40, 20], 
    },
    //tabla para comite ambiental
    informe.agregaTablaComite(datos.comiteAmbiental, instalacion),
    {
        text: '4. Nombre del Coordinador del Comité local', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: 'Por favor indique los datos de la persona coordinadora del comité local a la cual se le notificará los resultados de la evaluación 2019.',
        alignment: 'justify',
        margin: [40, 20, 40, 20]
    },
    {
        table: {
            widths: ['25%', '25%', '25%', '25%'],
            body: [
                [
                    { text: 'Nombre de la persona', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Puesto dentro de la organización', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Correo electrónico', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Teléfono', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                ],
                [
                    { text: datos.coordinador.nombre + ' ' + datos.coordinador.apellido, alignment: 'left'},
                    { text: datos.coordinador.puestoTrabajo, alignment: 'left'},
                    { text: datos.coordinador.correo, alignment: 'center'},
                    { text: instalacion.telefono, alignment: 'center'},
                ]
            ]
        }
    },
    {
        text: 'Información por Parámetro', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: 'Seguidamente se describen los aspectos obligatorios requeridos para cada parámetro, que la organización debe adjuntar, de manera que el reporte refleje, la información básica para así optar por el galardón Bandera Azul Ecológica en la VI Categoría: Cambio Climático.',
        alignment: 'justify',
        margin: [40, 20, 40, 0]
    },
    {
        text: '1. Cumplimiento Legal', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        ul: [
            { text: 'Para evidenciar el cumplimiento de este parámetro, es obligatorio llenar las columnas celestes: Estado y Justificación del documento: Cuestionario para Autoevaluar el Parámetro de Cumplimiento Legal y presentarlo junto con este informe final en archivos aparte y en PDF, cada uno de ellos. La columna de evidencias sugeridas es para uso de la sede participante y no requieren llenarla para efectos de presentar este documento, pero si lo desean lo pueden hacer y presentarla también. Si tener presente, que no deben presentar ningún documento extra aparte de llenar el cuestionario como se les pide e indica en líneas anteriores. Todos los años la sede participante debe llenar y presentar este cuestionario firmado por el representante legal de la sede participante, de lo contrario no se revisará el informe final. El cuestionario se debe descargar del siguiente enlace: http://apps.grupoice.com/PBAECC/recursos.xhtml', margin: [50, 20, 40, 0],},
            { text: 'Una vez que hayan llenado el cuestionario, que ponemos a su disposición en formato Excel, se debe convertir del tipo o formato procesador de texto, al tipo o formato PDF, (no se acepta ningún cuestionario lleno si no viene en tipo o formato PDF). Tomar en cuenta, que la persona al decirle al archivo guárdese en PDF, le indique es todo el libro, es un paso adicional a convertirlo en PDF.', margin: [50, 20, 40, 0],},
            { text: 'Es frecuente que las organizaciones no mantengan un 100% de cumplimiento en todos los requisitos legales que deben satisfacer, por lo que este instrumento se convierte en una gran ayuda, que les permite detectar incumplimientos e ir cerrando brechas en ese tema. Esto les permite realizar los trámites de aquellos permisos pendientes. Recuerde, que este es un requisito de participación y es indispensable que la organización participante demuestre un genuino compromiso de cumplimiento y el cuestionario de cumplimiento legal lo guía en ese propósito.', margin: [50, 20, 40, 0],}
        ]
    },
    //evidencia inscripcion legal
    informe.envidenciaSimple(datos.inscripcionLegal),
    {
        text: '2. Combustibles fósiles', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: 'Caso 1: Comités locales que cuentan con flotilla vehicular, maquinaria y/o equipo propio o subcontratado, que sea administrado en su totalidad por la sede participante y puedan llevarle el control del consumo de  combustibles fósiles asociados a ella.', alignment:'left', bold: true, margin: [50, 20, 40, 0], decoration: 'underline' 
    },
    {
        text: 'Inventario de la flota vehicular, maquinaria y/o equipos que consumen combustible fósil bajo control del comité (Sede) participante.', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Presentar inventario completo de la flotilla, maquinaria y/o equipo del comité participante por tipo de vehículo o maquinaria (vehículo, tractor, guadaña, generador u otros), marca, año, cilindraje, tipo de combustible que consume, de acuerdo con lo solicitado en el cuadro 1.', alignment:'left', margin: [50, 0, 40, 0]
    },
    {
        text: 'Cuadro 1', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Inventario de la flotilla vehicular, maquinaria y equipo que consumen combustibles fósiles', alignment:'left', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: '(Los criterios de las columnas del cuadro pueden modificarse de acuerdo a las necesidades y al tipo de equipo de la organización)', alignment:'left', italics: true, margin: [50, 0, 40, 20]
    },
    //inventario de consumidores de combustible
    informe.combustiblesTablaCasoUno(datos.flotaCasoUno),
    {
        text: '2.1 Datos de Medición', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Cuadros de consumo y reducción', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'ncluir en los cuadros de consumo y reducción, los datos solicitados para comparar los consumos de combustibles fósiles del año anterior al de participación con los del año de participación. Se debe justificar el aumento en el consumo de combustibles. Se recomienda que se muestren también en gráficos los datos de consumo solicitados en los cuadros 2-3-4.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 2', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        margin: [50, 0, 40, 0], 
        alignment: 'center',
        text: [
            { text: 'Consumo Mensual de Combustibles Fósiles del ', bold: true },
            { text: 'año anterior al de participación', bold: true }
        ]
    },
    {
        text: anioAnterior, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    informe.tablaConsumo(datos.consumoCombAnterior, constantes.indicadorCombustibles, anioAnterior, anio, '#fac090'),
    {
        text: 'Cuadro 3', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        margin: [50, 0, 40, 0], 
        alignment: 'center',
        text: [
            { text: 'Consumo Mensual de Combustibles Fósiles del ', bold: true },
            { text: 'año de participación', bold: true }
        ]
    },
    {
        text: anio, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    informe.tablaConsumo(datos.consumoCombutibles, constantes.indicadorCombustibles, anio, anio, '#8db3e2'),
    {
        margin: [0, 20, 40, 20], 
        alignment: 'justify',
        text: [
            { text: 'Datos deben darse en: ', bold: true, italics: true, decoration: 'underline' },
            { text: 'L= Litros', bold: true }
        ]
    },
    {
        text: 'Cuadro 4', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Comparación de Consumos Combustibles Fósiles', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: anioAnterior + ' respecto al ' + anio, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    informe.comparacionConsumoTable(datos.reducCombust, constantes.indicadorCombustibles, anio, anioAnterior, instalacion),
    {
        text: 'Siglas, abreviaturas y símbolos a utilizar', alignment:'left', bold: true, margin: [0, 0, 40, 20], decoration: 'underline', italics: true
    },
    {
        text: 'L: Litros', alignment:'left', bold: true, margin: [0, 0, 40, 20]
    },
    {
        text: 'Justificación en caso de aumento del consumo de combustibles fósiles', alignment:'left', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Se requiere especificar los motivos por los cuales no se logró la reducción del consumo de combustibles fósiles. El aumento en el consumo no se castiga cuando exista una justificación válida relacionada, por ejemplo, con el crecimiento de la organización u otro motivo y se aporten las evidencias que lo demuestren.', alignment: 'justify', margin: [50, 0, 40, 20], italics: true
    },
     informe.justificacionReduccion(datos.justCombus),
     informe.evidenciaJustificacion(datos.justAnioComb),
    {
        text: '2.2 Educación ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: '2.2.1 Detalle de actividades realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Debe completar el siguiente cuadro con los datos solicitados, de todas las actividades de educación ambiental realizadas para generar o compartir conocimiento, entre los colaboradores, acerca del parámetro combustibles fósiles. Consultar Manual de Procedimiento 2021 con posibles actividades a ejecutar.', alignment: 'justify', margin: [50, 0, 40, 20], italics: true
    },
    {
        text: 'Cuadro 5', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
         text: 'Educación ambiental', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.educacionAmbiental(datos.educaCombustibles),
    {
        text: '2.2.2 Evidencias actividades de educación ambiental realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las actividades de educación ambiental ejecutadas en el año de aplicación e indicadas en el cuadro 5. Consultar Manual de Procedimientos 2021, en donde vienen diversos ejemplos de evidencias a presentar.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.educEvidenCombust),
    {
        text: '2.3 Acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: '2.3.1 Detalle de acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Describir todos aquellos proyectos o acciones ambientales que se implementaron para controlar o reducir el consumo de combustible en la organización, de acuerdo con lo solicitado en el cuadro siguiente. Consultar Manual de Procedimientos 2021, en donde vienen diversos ejemplos de acciones a ejecutar.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 6', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Proyectos o Acciones Para Controlar o Reducir El Consumo De Combustible ' + anio, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },                
     informe.tablaAccionesReduccion(datos.accionReducComb),
    {
        text: '2.3.2 Evidencias acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las acciones realizadas en el año 2021 e indicadas en el cuadro 6. Consultar Manual de Procedimientos 2021, en donde vienen diversos ejemplos de posibles acciones a ejecutar.', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
     informe.tablaEvidencias(datos.accReducEvidenCombust),
    {
        text: 'Caso 2 Comités locales que NO cuentan con flotilla vehicular, maquinaria y/o equipo que consumen combustibles fósiles', alignment:'justify', bold: true, decoration: 'underline', margin: [50, 20, 40, 20]
    },
    {
        text: 'Los comités que no cuentan con flotilla vehicular, maquinaria y/o equipos que consumen combustibles fósiles, deben describir todas las acciones de primer y segundo orden implementadas, de acuerdo con lo solicitado en el siguiente cuadro. La organización podrá realizar una combinación de actividades de Primer y Segundo Orden, para pretender ganar los puntos establecidos en este parámetro. El máximo puntaje para obtener es 20 puntos. Consultar Manual de Procedimientos 2021, en donde vienen diversos ejemplos de acciones de primer y segundo orden a ejecutar.', alignment:'justify', italics: true, decoration: 'underline', margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 7', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Acciones de Primer y Segundo Orden' + anio, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },   
     informe.combustiblesTablaCasoDos(datos.accionPrimerSegOrden, instalacion),
    {
        text: '2.4 Evidencias de acciones de primer y segundo orden', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las acciones de primer y segundo orden realizadas. Consultar Manual de Procedimiento 2021 en donde vienen diversos ejemplos de acciones a ejecutar.', alignment:'justify', italics: true, decoration: 'underline', margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.eviAccionPrimerSegOrden),                
    {
        text: '3. Agua', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '3.1 Verificación de la calidad de agua potable', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Adjuntar constancia emitida por el ente operador (AyA, municipalidad, ASADA, ESPH, otros) del servicio de agua para consumo humano del año de aplicación, donde se indique que el agua es de calidad potable.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En el caso de que la organización cuente con fuentes propias de abastecimiento de agua para consumo humano o tanques de almacenamiento de agua en sus instalaciones, deben presentar un análisis de calidad del agua potable, que considere al menos el nivel N1.  que establece el Reglamento para la Calidad del Agua Potable, Decreto Ejecutivo N° 38924-S, emitidos por la empresa que les brinda el servicio, o bien por medio de un laboratorio acreditado por el Ente Costarricense de Acreditación (ECA). La verificación de la calidad del agua debe ser del año de participación.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En el caso de que la organización cuente con fuentes propias de abastecimiento de agua para consumo humano, debe mostrar también la evidencia de haber presentado el último reporte semestral ante el Ministerio de Salud.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En caso de no poder demostrar la calidad del agua potable, la organización debe demostrar las acciones realizadas para mejorar esta situación. ', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.potabilidadAgua),
    {
        text: '3.2 Datos de Medición', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Cuadros de consumo y reducción ', alignment:'left', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Incluir en los cuadros de consumo y reducción, los datos solicitados para comparar los consumos de agua del año anterior al de participación, con los del año de participación. Se debe justificar el aumento o disminución en el consumo de agua. Asimismo, los datos de consumo solicitados en los cuadros 8-9-10, se recomienda, que se muestren también en gráficos.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 8', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        margin: [50, 0, 40, 0], 
        alignment: 'center',
        text: [
            { text: 'Consumo Mensual de Agua del ', bold: true },
            { text: 'año anterior al de participación', bold: true, decoration: 'underline'}
        ]
    },
    {
        text: anioAnterior, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.tablaConsumo(datos.consumoAguaAnterior, constantes.indicadorAgua, anioAnterior, anio, '#fac090'),
    {
        text: 'Cuadro 9', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        margin: [50, 0, 40, 0], 
        alignment: 'center',
        text: [
            { text: 'Consumo Mensual de Agua del ', bold: true },
            { text: 'año de participación', bold: true, decoration: 'underline'}
        ]
    },
    {
        text: anio, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.tablaConsumo(datos.consumoAguaActual, constantes.indicadorAgua, anio, anio, '#8db3e2'),
    {
        text: 'Cuadro 10', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Comparativo Ahorro En El Consumo De Agua', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'AÑO ' + anioAnterior + ' respecto al AÑO '+ anio, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.reduccionConsumo(datos.reduccionAgua, constantes.indicadorAgua, anio, anioAnterior),
    {
        text: 'Siglas, abreviaturas y símbolos' + anio, alignment:'left', bold: true, decoration:'underline', italics: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'm3: Metros cúbicos.' + anio, alignment:'left', italics: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'L: Litros.' + anio, alignment:'left', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Justificación en caso de aumento del consumo de agua' + anio, bold: true, alignment:'left', margin: [50, 0, 40, 0]
    },
    {
        text: 'Se requiere especificar los motivos por los cuales no se logró la reducción del consumo de agua. El aumento en el consumo no se castiga, cuando exista una justificación válida relacionada con el crecimiento de la organización u otra causa y se aporten las evidencias que lo demuestren.' + anio, alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.justificacionReduccion(datos.justAgua),
     informe.evidenciaJustificacion(datos.justAnioAgua),
    {
        text: '3.3 Medidas para evitar la propagación de enfermedades', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Deben completar el siguiente cuadro, indicando puntualmente las acciones realizadas (Mínimo 2), describir esas acciones, cuantas hicieron y cuando las ejecutaron. Consultar Manual de Procedimiento 2021, donde vienen diversos ejemplos de acciones a ejecutar.' + anio, alignment:'justify', margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 11', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Medidas Para Evitar Propagación de Enfermedades', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
      informe.tablaAccionesPrevencion(datos.accionesPrevencion),
    {
        text: '3.3.1 Evidencias de medidas para evitar propagación de enfermedades', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar todas las acciones que realizaron en el año 2021 y que indicaron en cuadro 11.', alignment:'justify', margin: [50, 0, 40, 20]
    },
      informe.tablaEvidencias(datos.evidAccionesPrevencion),
    {
        text: '3.4 Limpieza y desinfección de instalaciones', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Deben completar el siguiente cuadro, indicando puntualmente que medidas de limpieza ornato y aseo realizaron para mantener la higiene en las instalaciones, cuantas hicieron y cuando las ejecutaron. Consultar Manual de Procedimiento 2021, donde vienen diversos ejemplos de acciones a ejecutar (Mínimo deben demostrar dos medidas).' + anio, alignment:'justify', margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 12', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Medidas de Limpieza y Desinfección de instalaciones', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
      informe.tablaAccionesPrevencion(datos.accionesLimpieza),
    {
        text: '3.4.1 Evidencias de limpieza de instalaciones', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: `Evidenciar todas las acciones de limpieza y desinfección implementadas en el año ${anio} e indicadas en el cuadro 12.`, alignment:'justify', margin: [50, 0, 40, 20]
    },
      informe.tablaEvidencias(datos.evidaccionesLimpieza),
    {
        text: '3.5 Educación ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: '3.5.1 Detalle de actividades realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: `Debe completar el cuadro siguiente con los datos solicitados, de todas las actividades de educación ambiental realizadas para generar o compartir conocimiento, entre los colaboradores, acerca del parámetro agua. Consultar Manual de Procedimiento 2021, donde se indican diversos ejemplos de actividades a ejecutar.`, alignment:'justify', margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 13', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Educación Ambiental', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.educacionAmbiental(datos.educacionAgua),
    {
        text: '3.5.2 Evidencias Educación Ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las actividades de educación ambiental ejecutada en el año de aplicación y descritas en el cuadro 13. Consultar Manual de Procedimiento 2021, en donde se describen diversas evidencias a presentar.', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
     informe.tablaEvidencias(datos.educEvidenAgua),
    {
        text: '3.6 Acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Deben describir en el cuadro siguiente, todos aquellos proyectos o acciones ambientales que se implementaron para controlar o reducir el consumo de agua en la organización. Consultar Manual de Procedimiento 2021, en donde se indican diversos ejemplos de acciones a ejecutar.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 14', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Acciones realizadas', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.tablaAccionesReduccion(datos.accionReducAgua),
    {
        text: '3.6.1 Evidencias acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar todas las acciones realizadas y ejecutadas en el año de aplicación y que indicaron en el cuadro 14. Consultar Manual de Procedimiento 2021.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.accReducEvidenAgua),
    {
        text: '4. Tratamiento Aguas Residuales', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '4.1 Datos de Medición', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Deben identificar e indicar el tipo de sistema, al que someten sus aguas residuales utilizados por la organización (tanque séptico, planta de tratamiento, alcantarillado sanitario, entre otros). Además, debe hacer una descripción del sistema de tratamiento empleado y adjuntar croquis donde se ubique su localización.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: '4.2 Sistema de tratamiento de aguas residuales utilizado', alignment:'left', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: '4.2.1 Planta de Tratamiento', alignment:'left', bold: true, margin: [50, 0, 40, 20]
    },
    {
        margin: [50, 0, 40, 20],
        alignment: 'justify',
        text: [
            { text: 'Caso 1: Planta de tratamiento de aguas residuales propia: ', bold: true },
            { text: 'debe realizar una descripción del sistema de tratamiento y presentar el certificado de calidad de agua emitido por el Ministerio de Salud o los reportes operacionales del año de participación, en los cuales se evidencie que la organización no sobrepasa los límites máximos permitidos de los parámetros de vertido de aguas residuales (Calidad del agua vertida), que le correspondan según la actividad. La organización debe demostrar el cumplimiento de los decretos ejecutivos:' }
        ]
    },
    {
        ul: [
            { text: 'Decreto Ejecutivo N° 39887-S-MINAE, Reglamento de Aprobación de Sistemas de Tratamiento de Aguas Residuales', bold: false, margin: [50, 20, 40, 0], },
            { text: 'Decreto Ejecutivo N° 34431, Reglamento del Canon Ambiental por Vertidos.', bold: false, margin: [50, 0, 40, 0], },
            { text: 'Decreto Ejecutivo Nº39316, Reglamento para el Manejo y Disposición Final de Lodos y Biosólidos.', bold: false, margin: [50, 0, 40, 0], alignment: 'justify' },
            { text: 'Decreto Ejecutivo Nº 33601, Reglamento de Vertido y Reúso de Aguas Residuales.', bold: false, margin: [50, 0, 40, 0], alignment: 'justify' }
        ]
    },
    {
        margin: [50, 20, 40, 0],
        alignment: 'justify',
        text: [
            { text: 'Caso 2: Planta de tratamiento de aguas residuales de un tercero: ', bold: true },
            { text: 'si la empresa no cuenta con un sistema de tratamiento de aguas residuales propia, pero tiene acceso al sistema de un tercero no público, como el caso de los condominios o parques industriales, por ejemplo, debe cumplir con lo siguiente:' }
        ]
    },
    {
        ul: [
            { text: 'Solicitar al administrador del sistema de tratamiento, el último certificado de calidad de agua emitido por parte del Ministerio de Salud. En caso de que no se haya generado ningún certificado, presentar el último reporte operacional vigente y la evidencia de la gestión realizada para obtener el certificado.', bold: false, margin: [50, 20, 40, 0] },
        ]
    },
    {
        text: '4.2.2 Tanque Séptico', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'En el caso de que la organización posea un tanque séptico y drenaje deben adjuntar un croquis del sistema, donde se ubique la localización del tanque y drenaje, y brindar una descripción de la forma de operación y mantenimiento que se le da, realizar un diagnóstico del estado del tanque séptico y el drenaje, elaborar un plan de mantenimiento de los mismos, y si en el año de participación se realiza limpieza de lodos del tanque séptico, se debe demostrar la correcta gestión de estos lodos, según el Decreto 39316 (Reglamento para el Manejo y Disposición Final de Lodos y Biosólidos).', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Asimismo, es recomendable presentar una constancia emitida por un técnico o profesional, con formación en ese tema, que garantice el funcionamiento adecuado del tanque séptico y el drenaje, sin derrames superficiales o malos olores, entre otros.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: '4.2.3 Alcantarillado Sanitario', alignment:'left', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Si las aguas residuales van directamente al alcantarillado sanitario de la zona, la organización participante debe evidenciar la conexión de su sistema de aguas residuales hacia ese sistema, mediante una certificación de la empresa que administra ese servicio o un documento de un técnico en la materia. Además, deben solicitarle a esa empresa, cuál es el tratamiento que le da a esas aguas y la disposición final de las mismas. Además, la sede participante debe indicar qué tratamiento previo se les da a esas aguas, antes de hacerlas llegar al alcantarillado sanitario, como, por ejemplo, ubicar trampas de grasa o utilizar productos de limpieza biodegradables.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Croquis localización del Sistema', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.envidenciaSimple(datos.croquisSist),
    {
        text: 'Descripción del Sistema', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.tipoSistemaTratamiento(datos.tipoSistemaTrat),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.sistTratEvidencias),
    {
        text: '4.3 Comprobación del no vertido de sustancias altamente peligrosas a cuerpos de agua u otro ecosistema (Obligatorio para conseguir el galardón)', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Si las aguas residuales de la organización están catalogadas como especiales, según el Reglamento de Vertido y Reúso de Aguas Residuales (Decreto N° 33601), se deberá presentar una constancia de un profesional competente, que garantice el no vertido de sustancias altamente peligrosas a los cuerpos de agua u otro ecosistema.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En el caso de comités que almacenan hidrocarburos, deben demostrar el cumplimiento del Decreto Ejecutivo 30131 (Reglamento para la Regulación del Sistema de Almacenamiento y Comercialización de Hidrocarburos).', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En el caso de comités que almacenan o utilicen sustancias peligrosas, deben demostrar el cumplimiento de los decretos: ', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Decreto 27001-MINAE Reglamento para el Manejo de los Desechos Peligrosos Industriales Decreto Ejecutivo N° 41527-S-MINAE Reglamento General para la Clasificación y Manejo de Residuos Peligrosos', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Decreto Ejecutivo N° 41527-S-MINAE Reglamento General para la Clasificación y Manejo de Residuos Peligrosos', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Si no se cumple la condición anterior,  no se debe presentar ninguna constancia.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.sustanPeligrosasEvi),
    {
        text: '4.4 Educación ambiental ', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Complete el cuadro siguiente para todas las actividades de educación realizadas para generar o compartir conocimiento entre los colaboradores sobre el tratamiento de aguas residuales. Consultar Manual Procedimiento 2021, donde vienen diversos ejemplos para cumplir con este punto.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 15', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.educacionAmbiental(datos.educcacionAguasRes),
    {
        text: '4.4.1 Evidencias Educación Ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las actividades de educación ambiental ejecutada en el año de aplicación 2021 e indicadas en el cuadro 15. Consultar Manual de Procedimiento 2021, donde vienen diversos ejemplos de actividades que pueden ejecutar para el cumplimiento de este punto.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.educEvidenAguResid),
    {
        text: '4.5 Acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Describir en cuadro siguiente todos aquellos proyectos o acciones ambientales que se implementaron para operar y mantener apropiadamente el sistema de tratamiento de aguas residuales de la organización. Consultar Manual de Procedimientos 2021, donde vienen diversos ejemplos de acciones que pueden realizar.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 16', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Acciones realizadas', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.tablaAccionesReduccion(datos.accionReducAguResid),
    {
        text: '4.5.1 Evidencias acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las acciones realizadas en el año de aplicación 2021 y descritas en el cuadro 16. Consultar Manual de Procedimientos 2021, donde vienen diversos ejemplos de acciones que pueden realizar.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.accionReducEvidAguResid),
    {
        text: '5. Energía Eléctrica', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '5.1 Datos de Medición', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        alignment: 'justify',
        margin: [50, 20, 40, 20],
        text: [
            { text: 'Cuadros de consumo y reducción: ', bold: true, italics: true },
            { text: 'Incluir en los cuadros de consumo y reducción los datos solicitados para comparar los consumos de energía eléctrica del año anterior al de participación, con los del año de participación. Se debe justificar el aumento o reducción en el consumo de electricidad. Asimismo, se recomienda que los datos de consumo que se les pide en los cuadros 17-18-19 se muestren también en gráficos.', italics: true },
        ]
    },
    {
        text: 'Cuadro 17', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        margin: [50, 0, 40, 0], 
        alignment: 'center',
        text: [
            { text: 'Consumo Mensual de Electricidad Del ', bold: true },
            { text: `Año Anterior Al De Participación ${anioAnterior}`, bold: true }
        ]
    },
     informe.tablaConsumo(datos.consumoEnergiaAnterior, constantes.indicadorEnergia, anioAnterior, anio, '#fac090'),
    {
        text: 'Cuadro 18', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        margin: [50, 0, 40, 0], 
        alignment: 'center',
        text: [
            { text: 'Consumo Mensual de Electricidad  del ', bold: true },
            { text: `Año Anterior Al De Participación ${anio}`, bold: true }
        ]
    },
     informe.tablaConsumo(datos.consumoEnergiaActual, constantes.indicadorEnergia, anio, anio, '#8db3e2'),
    {
        text: 'Cuadro 19', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: `Comparativo Del Consumo De Energía Eléctrica Año ${anioAnterior} Respecto Año ${anio}`, alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.reduccionConsumo(datos.reduccionEnergia, constantes.indicadorEnergia, anio, anioAnterior),
    {
        text: 'Siglas, abreviaturas y símbolos' + anio, alignment:'left', bold: true, decoration:'underline', italics: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'KW/h: Kilowatt hora' + anio, alignment:'left', italics: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Ton CO2 eq: Toneladas de dióxido de carbono equivalente' + anio, alignment:'left', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Justificación en caso de aumento del consumo de energía eléctrica', alignment:'left', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Se requiere especificar los motivos por los cuales no se logró la reducción del consumo de electricidad. El aumento en el consumo no se castiga cuando exista una justificación válida relacionada con el crecimiento de la organización y se aporten las evidencias que lo demuestren.' + anio, alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.justificacionReduccion(datos.justEnergia),
     informe.evidenciaJustificacion(datos.justAnioEnergia),
    {
        text: '5.2 Educación ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: '5.2.1 Detalle de actividades realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Debe completar el cuadro siguiente con los datos solicitados, de todas las actividades de educación ambiental realizadas para generar o compartir conocimiento, entre los colaboradores, acerca del parámetro energía eléctrica. Consultar Manual de Procedimiento 2021 donde se indican diversos ejemplos de actividades a ejecutar.' + anio, alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 20', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Educación Ambiental', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.educacionAmbiental(datos.educacionEnergia),
    {
        text: '5.2.2 Evidencias Educación Ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las actividades de educación ambiental ejecutada en el año de aplicación 2021. Consultar Manual de Procedimientos 2021, donde vienen diversos.' + anio, alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.educEvideEnergia),
    {
        text: '5.3 Acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Describir todos aquellos proyectos o acciones ambientales que se implementaron para controlar o reducir el consumo de energía eléctrica en la organización.' + anio, alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 21', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Acciones realizadas', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.tablaAccionesReduccion(datos.accionReduccionEnergia),
    {
        text: '5.3.1 Evidencias acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar todas las acciones ejecutadas en el año de aplicación explicadas en la sección 5.4. Consultar Manual de Procedimientos 202, donde vienen diversos ejemplos como guía de posibles acciones a ejecutar.' + anio, alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.accionReduccionEnergiaEvid),
    {
        text: '6. Gestión de Residuos', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '6.1 Datos de Medición', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        alignment: 'justify',
        margin: [50, 0, 40, 20],
        text: [
            { text: 'Cuadros de consumo y reducción: ', bold: true, italics: true },
            { text: 'Incluir en los cuadros de consumo y reducción los datos solicitados para comparar la generación de residuos del año anterior al de participación, con los del año de participación. Se debe justificar el aumento o reducción en la generación de residuos. Asimismo, los datos de consumo que se les pide en los cuadros: 22-23-24-25-26-27 se recomienda que se muestren también en gráficos.', italics: true }
        ]
    },
    {
        text: 'Cuadro 22', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        alignment: 'center',
        margin: [50, 0, 40, 0],
        text: [
            { text: `Consumo Mensual De Papel De Oficina Del Año Anterior Al De Participación ${anioAnterior}`, bold: true },
        ]
    },
     informe.tablaConsumo(datos.consumoResiduosAnterior, constantes.indicadorResiduos, anioAnterior, anio, '#fac090'),
    {
        text: 'Nota: Una resma equivale a 500 hojas', alignment:'left', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Cuadro 23', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        alignment: 'center',
        margin: [50, 0, 40, 0],
        text: [
          { text: `Consumo Mensual De Papel De Oficina Del Año Anterior Al De Participación ${anio}`, bold: true }
        ]
    },
     informe.tablaConsumo(datos.consumoResiduosActual, constantes.indicadorResiduos, anio, anio, '#8db3e2'),
    {
        text: 'Cuadro 24', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: `Comparativo Del Consumo De Papel Año ${anioAnterior} Respecto Año ${anio}`, alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.reduccionConsumo(datos.reduccionPapel, constantes.indicadorResiduos),
    {
        text: 'Justificación en caso de aumento del consumo de papel de oficina', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Se requiere especificar los motivos por los cuales no se logró la reducción del consumo de papel de oficina. El aumento en el consumo no se castiga cuando exista una justificación válida relacionada con el crecimiento de la organización y se aporten las evidencias que lo demuestren.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.justificacionReduccion(datos.justResPapel),
     informe.evidenciaJustificacion(datos.justAnioResiduosPapel),
    {
        text: 'Cuadro 25', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: `Reducción En La Tasa De Generación De Residuos Valorizables Comparación Años ${anioAnterior}-${anio}`, alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.tasaGeneracionRes(datos.resValorizales, false, anio, anioAnterior),
    {
        text: 'Nota: Esta clasificación de residuos valorizables, responde a la Estrategia Nacional de Separación, Recuperación y Valorización de Residuos.', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Cuadro 26', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: `Reducción En La Tasa De Generación De Residuos No Valorizables Comparación Años ${anioAnterior}-${anio}`, alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.tasaGeneracionRes(datos.resNoValorizales, true, anio, anioAnterior),
    {
        text: 'Justificación en caso de aumento de la generación de residuos', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Se requiere especificar los motivos por los cuales no se logró la reducción de la generación de residuos. El aumento en el consumo no se castiga cuando exista una justificación válida relacionada con el crecimiento de la organización y se aporten las evidencias que lo demuestren.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.justificacionReduccion(datos.justResiduos),
     informe.evidenciaJustificacion(datos.justAnioResiduos),
    {
        text: 'Cuadro 27', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Generación de residuos de manejo especial', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.residuosEspecialesTraz(datos.residuosEspeciales),
    {
      alignment: 'justify',
      margin: [50, 20, 40, 0],
      text: [
        { text: 'Ejemplos de residuos de manejo especial son los siguientes: ', bold: true, italics: true },
        {
          text: 'llantas usadas (reguladas por el Decreto Ejecutivo N° 33745- S del 8 de febrero del 2007 “Reglamento sobre Llantas de Desecho”), baterías ácido plomo, pilas de reloj, pilas: carbón-manganeso, carbón-zinc, litio-cadmio, litio y zinc, aires acondicionados, refrigeradoras, transporte de frío y equipos de refrigeración industrial, aceite lubricante usado, envases plásticos para contener aceites lubricantes, envases metálicos, plástico y vidrio para contener agroquímicos (después del triple lavado), artefactos eléctricos (línea blanca), artefactos electrónicos (regulados por el Decreto Ejecutivo N° 35933-S del 12 de febrero del 2010 “Reglamento para la Gestión Integral de Residuos Electrónicos”), fluorescentes y bombillos compactos, refrigerantes, colchones, poliestireno (estereofon), vehículos automotores y equipo especial.', italics: true
        },
      ]
    },
    {
        text: 'Cuadro 28', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Generación de residuos peligrosos', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.residuosEspecialesTraz(datos.residuosPeligrosos),
    {
      alignment: 'justify',
      margin: [50, 20, 40, 0],
      text: [
        { text: 'Ejemplos de residuos peligrosos ', bold: true, italics: true },
        {
          text: 'se pueden consultar en el Decreto Ejecutivo Nº 41527 Reglamento General para la Clasificación y Manejo de Residuos Peligrosos.', italics: true
        },
      ]
    },
    {
        text: '6.2 Evidencias de la gestión de los residuos peligrosos y de manejo especial:', alignment:'justify', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'En aquellos casos en que se generen, la organización debe indicar la gestión que realiza de los residuos peligrosos y de manejo especial y presentar certificación de un gestor autorizado de residuos (para verificar lista de gestores autorizados puede consultar en la siguiente dirección electrónica: https://www.ministeriodesalud.go.cr/index.php/tramites-ms/registro-de-gestores-en-salud).', alignment:'justify', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.residuosPeligrososEvid),
    {
        text: '6.3 Educación Ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
      text: '6.3.1 Detalle de actividades realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
      text: 'Debe completar el cuadro siguiente con los datos solicitados, de todas las actividades de educación ambiental realizadas para generar o compartir conocimiento, entre los colaboradores, acerca de Parámetro Gestión de Residuos. Consultar Manual de Procedimiento 2021 donde se indican diversos ejemplos de actividades a ejecutar.', alignment:'justify', color: '#bfbfbf', italics: true, margin: [50, 20, 40, 20]
    },
    {
      text: 'Cuadro 29', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
      text: 'Educación Ambiental', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.educacionAmbiental(datos.educacionResiduos),
    {
      text: '6.3.2 Evidencias Educación Ambiental', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
      text: 'Evidenciar todas las actividades de educación ambiental ejecutadas en el año de aplicación, indicadas en el cuadro 29. Consultar Manual de Procedimiento 2021, donde vienen diversos ejemplos de posibles actividades a ejecutar.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.educacionResiduosEvid),
    {
      text: '6.4 Acciones realizadas ', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
      text: 'Describir todos aquellos proyectos o acciones ambientales que se implementaron para controlar o reducir la generación de residuos en la organización.', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Cuadro 30', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
      text: 'Acciones realizadas', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.tablaAccionesReduccion(datos.accionReduccionResiduos),
    {
        text: '6.4.1 Evidencias acciones realizadas', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar todas las acciones realizadas ejecutadas en el año de aplicación, indicadas en el cuadro 30. Consultar Manual de Procedimiento 2021 para ver diversos ejemplos de actividades a ejecutar.', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
     informe.tablaEvidencias(datos.accionReduccionResiduosEvid),
    {
        text: '7. Contaminantes Atmosféricos', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '7.1 Datos de Medición', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        alignment: 'justify',
        margin: [50, 0, 40, 0],
        text: [
            { text: 'Gráficos de consumo y reducción: ', bold: true, italics: true },
            { text: 'Completar los siguientes cuadros con la información sobre los contaminantes atmosféricos que se usan en la organización (cámaras de refrigeración, refrigeradoras, aires acondicionados fijos y de los vehículos y maquinaria, productos con presentación en aerosol, extintor, combustión de biomasa. El inventario debe contener la descripción del contaminante atmosférico que contiene la presentación y los consumos.). Los datos de consumo del cuadro 31 se recomienda que se muestren también en gráficos.', italics: true }
        ]
    },
    {
        text: 'Cuadro 31', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: `Inventario de Productos que Contengan Contaminantes Atmosféricos Años ${anioAnterior}-${anio}`, alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.inventarioContam(datos.inventarioContAtmos, anio, anioAnterior),
    {
        text: 'Cuadro 32', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Detalle del Mantenimiento a los Equipos que usan contaminantes atmosféricos', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.mantenimientoEquipos(datos.equiposContaminantes),
    {
        text: '*Adjuntar el certificado de capacitación de manejo de equipos de refrigeración y el respectivo carnet emitido por el MINAE', alignment:'justify', italics: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.evideRefrigerantes),
    {
        text: 'Justificación en caso de aumento del consumo de contaminantes atmosféricos', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Se requiere especificar los motivos por los cuales no se logró la reducción del consumo de contaminantes atmosféricos. El aumento en el consumo no se castiga, cuando exista una justificación válida relacionada con el crecimiento de la organización o los mantenimientos preventivos y se aporten las evidencias que lo demuestren.', alignment:'justify', margin: [50, 0, 40, 20]
    },
     informe.justificacionReduccion(datos.justContam),
     informe.evidenciaJustificacion(datos.justAnioConta),
    {
        text: '7.2 Evidencias', alignment:'left', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar las acciones realizadas para disminuir el consumo, controlar las fugas y dar el mantenimiento apropiado a los equipos que usan contaminantes atmosféricos que se detallaron en los cuadros 24 y 25.', alignment:'justify', margin: [50, 0, 40, 20]
    },
     informe.tablaAccionesReduccion(datos.accionReduccionContAtmos),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.accionReducEvidContAtmos),
    {
        text: '8. Compras Sostenibles', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '8.1 Contar con una política o un programa de compras sostenibles en ejecución, que permita a la empresa un consumo ambiental y socialmente responsable.', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Describa y adjunte muestra de la política o programa de compras sostenibles de la organización, aprobado por la alta dirección (Firma de quien avala ese programa o política). Si no posee política o programa de compras sostenibles, al menos describa los criterios sostenibles que utiliza la organización en la compra de bienes y servicios.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Si las compras de bienes y servicios no las realiza la sede participante, de igual forma explique el programa de compras o los criterios de compras sostenibles que aplica la organización como un todo.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Recomendación: Utilizar la “Guía de Compras Públicas Sustentables” del Ministerio de Hacienda, el “Manual para la Implementación de Compras Verdes en el Sector Público de Costa Rica” CEGESTI e INTE/ISO 20400:2017 Compras Sostenibles.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
      informe.comprasSotenibles(datos.programaCompSost),
      informe.evidComprasSostenibles(datos.programaCompSostEvid),
    {
        text: '8.2. Inventario: Uso de productos con menor impacto ambiental, de mayor biodegradación y que estén identificados dentro del programa de compras sostenibles.', alignment:'justify', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Incluir en el cuadro los productos, herramientas, equipos, entre otros, para limpieza, mantenimiento, uso de oficina, uso domiciliar, uso cotidiano o cualquier otro uso, el uso que se le da al mismo y el aporte del producto a la sostenibilidad, por ejemplo, certificación del porcentaje de biodegradabilidad del producto en un tiempo determinado. Deben contar con comprobación técnica sobre la veracidad de la información del producto con menor impacto ambiental o aporte que el producto tiene para la sostenibilidad. ', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Cuadro 33', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Inventario de compras sostenibles realizadas por la organización', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.inventCompSost(datos.productosSost),
    {
        text: '*En el caso de los productos que se indiquen como biodegradables, se deberá aportar la certificación de biodegradabilidad del producto, firmada por el representante legal o regente químico de la empresa que los brinda o en su defecto las hojas de seguridad de los productos (o MSDS).', alignment:'justify', italics: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.producSostEvi),
    {
        text: '8.3 Sustitución de productos', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Debe reemplazar al menos un producto con mayor impacto ambiental, utilizado actualmente, por uno de menor impacto ambiental. ', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Deben contar con comprobación técnica sobre la veracidad de la información del producto amigable, así como la comparación entre el producto que se utilizaba anteriormente con relación al nuevo producto.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.sustitucionProds(datos.prdSustituidos),
    {
        text: '8.4 Evidencias', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar el programa de compras sostenibles implementado en la organización, boletines, documentos, correos y/o notas relacionadas, fotografías, entre otros. Es importante adjuntar las fichas técnicas de los productos, y el producto que sustituyó en la parte 8.3.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.evidPrdSustituido),
    {
        text: '9. Adaptación', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '9.1 Evaluación del impacto del cambio climático en la organización.', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Análisis de cómo el cambio climático ha impactado a la organización, considerando los eventos hidrometereológicos que ha experimentado y sus consecuencias. Tomando de referencia documentos como los estudios y los pronósticos realizados por el Instituto Meteorológico Nacional, la Comisión Nacional de Emergencia o los que la Municipalidad ha realizado.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro de comportamiento: ', alignment:'justify', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'ncluir en el cuadro, el detalle de los eventos hidrometeorológicos que ha experimentado la organización y sus impactos.1 Igualmente para los años 2020-2021 puede consultar el documento: Lineamientos informe final 2020-2021 en donde se indican otras alternativas para cumplir con este parámetro.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 34', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Eventos Hidrometeorológicos Sufridos E Impacto Ocasionado', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.eventosClimaticostab(datos.eventosClimaticos),
    {
        text: '9.2 Identificación de las acciones ya realizadas por la organización para la adaptación ante el cambio climático.', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Describa las condiciones y capacidades de la organización para adaptarse ante el cambio climático, considerando las acciones que ya la misma organización realiza, como parte de la implementación del PBAE y otros programas.', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Por ejemplo: contar con un comité de emergencia equipado y preparado, incorporación criterios de construcción sostenible en el diseño de las instalaciones, capacitación a su público interno y externo, en adaptación al cambio climático, contar con estudios de riesgo ante eventos climáticos, desarrollo de mejoras en la infraestructura de la organización para enfrentar eventos hidrometereológicos, contar con un plan de continuidad del negocio, ante el cambio climático.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 35', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Acciones Realizadas En Adaptación Al Cambio Climático', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.accionesEventosClim(datos.eventosClimaticos),
    {
        text: '9.3 Identificación de medidas de adaptación y plan de acción', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Plan de acción para al menos una opción de adaptación que la organización haya considerado factible implementar. Dicho plan de acción debe contemplar al menos lo siguiente: nombre de la medida, descripción, objetivo, meta, indicador, plazo de ejecución, presupuesto y la oportunidad de esta medida para la organización.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 36', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Plan De Acción Para La Medida De Adaptación Prioridad 1', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.planDeAccionTab(datos.planesDeAccion),
    {
        text: '9.4 Seguimiento del plan de acción.', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar mediante informes, correos electrónicos, fotografías y similares las acciones realizadas para poner en marcha o darle seguimiento al plan de acción propuesto en el paso anterior, cada año.', alignment:'justify', italics: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.evidPlanDeAccion),
    {
        text: '10. Compensación', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        text: '10.1 Datos de actividades realizadas', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        alignment: 'justify',
        margin: [50, 20, 40, 20],
        text: [
            { text: 'Cuadro de comportamiento:', bold: true },
            { text: 'Incluir en el cuadro el detalle de las actividades de compensación realizadas, durante el año de participación. Se debe especificar los logros alcanzados por cada una de las actividades realizadas; en términos de: personas impactadas, árboles plantados, hectáreas reforestadas, kilómetros de cuenca protegidos o limpiados, entre otros; así como la cantidad de personas que se vieron beneficiadas por las mismas. Igualmente, para los años 2020-2021 puede consultar el documento: Lineamientos informe final 2020-2021 en donde se indican otras alternativas para cumplir con este parámetro.', italics: true },
        ]
    },
    {
        text: 'Cuadro 37', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Actividades de Compensación Realizadas', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.activComponsacion(datos.actividadesComp),
    {
        text: 'Cuadro 38', alignment:'center', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Educación Ambiental A Lo Externo o Interno', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.educAmbiExterno(datos.educAmbCompensacion),
    {
        text: '10.2 Evidencias de actividades de compensación realizadas', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Evidenciar la cantidad de proyectos de compensación realizados con fotografías, boletines, documentos y/o notas relacionadas, entre otros, así como las actividades de educación ambiental externa o interna ejecutadas en el año de aplicación, explicadas en las secciones. Consultar Manual Procedimiento 2021 con ejemplos de evidencias a indicar.', alignment:'justify', italics: true, margin: [50, 0, 40, 0]
    },
    {
        alignment: 'justify',
        margin: [50, 20, 40, 20],
        text: [
            { text: 'Nota: ', bold: true, italics: true, decoration: 'underline' },
            { text: 'en caso de que las actividades de compensación con lleven procesos de reforestación, favor indicar el número de árboles plantados o donados, llenando la columna del cuadro 37.', italics: true },
        ]
    },
    {
        text: 'Evidencias Actividades de Compensación', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.actividadesCompEvid),
    {
        text: 'Evidencias Educación Ambiental a lo Externo', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.educAmbCompensacionEvid),
    {
        text: 'Gradación de Estrellas', alignment:'center', bold: true, margin: [50, 20, 40, 0], 
    },
    {
        ul: [
            { text: 'UNA ESTRELLA:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'El comité local que logre un porcentaje anual del 90 al 100% de los parámetros obligatorios.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        ul: [
            { text: 'DOS ESTRELLAS:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de obtener el 100% del porcentaje de los puntos evaluados, se debe incluir evidencias del cumplimiento de alguno de los siguientes aspectos: Contar con algún otro programa de gestión interno o reconocimiento externo: Marca País Esencial Costa Rica, Vigilantes del Agua, Planes de Gestión de Riesgo, Planes de Emergencia, 5´S, Programa de Gestión Ambiental Institucional (PGAIs), Certificado de Sostenibilidad Turística (CST), Programa Sello de Calidad Sanitaria del AyA, e igualmente si cuentan con alguna certificación, de conformidad con los requisitos establecidos en las normas ISO, entre otros.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En caso de que exista un certificado emitido por una tercera parte, adjuntar copia de dicho documento. En caso contrario, adjuntar una constancia emitida por la alta dirección de la organización, que garantice que se cuenta con el programa de gestión interna que están aplicando para la segunda estrella, vigente en el año de participación.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Asimismo, una sede participante puede optar para obtener la segunda estrella, si promueve y demuestra, entre quienes trabajan en esa organización, lo que denominamos: “Salud Integral y la Felicidad” a través de la promoción de estilos de vida saludables, como por ejemplo: actividad física, alimentación sana, salud mental, así como promover convivios entre los compañeros, en aras de mejorar las relaciones interpersonales, mediante estrategias de comunicación asertivas de trabajo, que les permita conocerse y mejorar el clima laboral.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEstrellaDos(datos.programasGest),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.programasGestEvid),
    {
        text: '', margin: [50, 20, 40, 0]
    },
    {
        ul: [
            { text: 'TRES ESTRELLAS:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de cumplir con los requisitos de la segunda estrella, se debe evidenciar el cumplimiento de lo siguiente: promover, entre sus partes interesadas, a través de su cadena de valor, clientes proveedores y entre sus colaboradores directos la implementación del PBAE en cualquier categoría, acompañándolos en el proceso.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Para los comités de nuevo ingreso, el primer año deben brindar la orientación necesaria para dar a conocer el PBAE. En el segundo año de participación, si es requisito que logren la inscripción de algún comité nuevo, en cualquiera de las categorías, lo que deben seguir haciendo, todos los años de participación y con comités nuevos. (ver Manual de Procedimientos 2021).', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEstrellaTres(datos.promoPbaeCadVal),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.promoPbaeCadValEvid),
    {
        text: '', margin: [50, 20, 40, 0]
    },
    {
        ul: [
            { text: 'CUATRO ESTRELLAS:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de cumplir con los requisitos de la tercera estrella, se debe evidenciar el cumplimiento de lo siguiente: promover el PBAE en cualquiera de las categorías que lo integran, en su comunidad inmediata: centros educativos, asociaciones de desarrollo, instituciones público o privadas, entre otros. Además, debe acompañarlos en el proceso. Para los comités de nuevo ingreso, el primer año deben brindar la orientación necesaria para dar a conocer el PBAE. En el segundo año de participación, si es requisito que logren la inscripción de algún comité nuevo. Esto lo deben seguir haciendo todos los años de participación y con comités nuevos, para pretender obtener la cuarta estrella. (Ver Manual de Procedimientos 2021).', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEstrellaCuatro(datos.promoPbaeComu),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Evidenciar acciones realizadas con fotografías, listas de asistencia, documentos, constancias y/o notas relacionadas, entre otros. Consultar Manual Procedimiento 2021.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.promoPbaeComuEvid),
    {
        text: '', margin: [50, 20, 40, 0]
    },
    {
        ul: [
            { text: 'CINCO ESTRELLAS:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de cumplir con los requisitos de la cuarta estrella, se debe evidenciar el cumplimiento de lo siguiente: cuantificación de sus emisiones y remociones, y que las mismas están acordes con los requisitos bajo las normas nacionales: “INTE/ISO 14064: 2006” o la parte que corresponde a la “INTE B5:2016”, lo cual les permite demostrar con evidencia, que están en proceso del carbono neutralidad. Consultar Manual Procedimiento 2021.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEstrellaCinco(datos.inventarioGei),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
      text: 'demostrar las acciones realizadas con documentos, constancias y/o notas relacionadas, entre otros de que posee la cuantificación de emisiones y remociones. Consultar Manual Procedimiento 2021.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
  },
     informe.tablaEvidencias(datos.inventarioGeiEvid),
    {
        text: '', margin: [50, 20, 40, 0]
    },
    {
        ul: [
            { text: 'SEIS ESTRELLAS:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de cumplir con los requisitos de la quinta estrella, se debe evidenciar el cumplimiento de lo siguiente: certificación de su carbono neutralidad, otorgada por un organismo de validación y verificación, bajo la norma nacional: “INTE B5:2016. Norma para demostrar la Carbono Neutralidad” para lo cual debe presentar la constancia que demuestre lo indicado. Consultar Manual Procedimiento 2021.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEstrellaSeis(datos.certCarbNeut),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
    {
        text: 'Cualquier iniciativa para cumplir con el requisito de las estrellas: 2-3-4, se debe demostrar con evidencias. Asimismo, debe mostrar constancias de cumplimiento, para pretender optar por las estrellas 5-6. Todo lo indicado, de acuerdo con lo solicitado.', alignment:'justify', bold: true, italics: true, margin: [50, 0, 40, 20]
    },
     informe.tablaEvidencias(datos.certCarbNeutEvid),
    {
        text: '', margin: [50, 20, 40, 0]
    },
    {
        ul: [
            { text: 'ESTRELLA VERDE:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de aspirar a ganar la bandera con una gradación de estrellas blancas, que van de 1 a 6, un comité (sede) participante, puede obtener también una estrella verde.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Para ese propósito, deben evidenciar al menos la inscripción de una familia (hogar), en la Categoría Hogares Sostenibles, su acompañamiento en el desarrollo de esa iniciativa. Al respecto deben evidenciar, que efectivamente acompañaron a ese comité en ese logro.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'En el caso de aquellas sedes participantes, que además de cumplir con lo indicado en el párrafo anterior, también revisen el informe final del hogar que están acompañando, deben llenar en este informe final, los cuadros que se les pide indicando familia, nota y gradación de estrellas del hogar ganador: cuadros 39-40-41. La obtención de la estrella verde es independiente de la cantidad de estrellas blancas logradas.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 39', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Hogares Sostenibles inscritos', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.hogaresSost(datos.hogaresSostData, anio),
    {
        text: 'Reporte de hogares sostenibles ganadores', alignment:'justify', bold: true, margin: [50, 20, 40, 0]
    },
    {
        text: 'Además, completar el siguiente cuadro con los datos de los hogares ganadores. Se completa el cuadro 39, por cada hogar ganador.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cuadro 40', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Información Del Comité Que Da Seguimiento A Hogares Sostenibles', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
     informe.infoHogaresSost(datos.hogaresSostData),
    {
        text: 'Cuadro 41', alignment:'center', bold: true, margin: [50, 0, 40, 0]
    },
    {
        text: 'Información De Familia Ganadora', alignment:'center', bold: true, margin: [50, 0, 40, 20]
    },
     informe.detaHogarSost(datos.notaHogares),
    {
        text: '', margin: [50, 20, 40, 0]
    },
    {
        ul: [
            { text: 'ESTRELLA PLATEADA:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de aspirar a ganar la bandera con una gradación de estrellas blancas, que van de 1 a 6 y una estrella verde, el comité participante (sede), puede aspirar a ganar una estrella plateada, aportando la evidencia de la gestión sus residuos, por medio de la iniciativa Ecolones. Al respecto, el comité (sede), participante, debe evidenciar el acopio de los residuos valorizables que generan, separarlos de acuerdo con el decreto 39760 denominado: Estrategia Nacional de Separación, Recuperación y Valorización de Residuos (ENSRVR) 2016-2021 y entregarlos limpios a un centro de acopio asociado a Ecolones, con los registros de cantidades en kilogramos entregados, según residuo y demostrar todo lo indicado con evidencias ', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
     informe.estellaPlateada(datos.progEcolones),
    {
        text: 'Evidencias', alignment:'center', bold: true, margin: [50, 20, 40, 20]
    },
     informe.tablaEvidencias(datos.progEcolonesEvid),
    {
        ul: [
            { text: 'ESTRELLA ANARANJADA:', bold: true, decoration: 'underline' }
        ]
    },
    {
        text: 'Además de aspirar a ganar la bandera con una gradación de estrellas blancas, que van de 1 a 6 y una estrella verde, así como una estrella plateada, el comité participante (sede) puede aspirar a ganar una estrella anaranjada. Para ese propósito debe llenar el formulario, actividades para estrella anaranjada, el cual se encuentra en el siguiente enlace: https://docs.google.com/forms/d/e/1FAIpQLSd7Lu6JXsRQcTQEe6rfMaOPgioeVt_rL_EYi0QyZcdwUmR2g/viewform?usp=sf_link ', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Cualquier información que requiera conocer para optar por la estrella anaranjada, debe comunicarse directamente con el Equipo Técnico de la Categoría Bienestar Animal del PBAE al correo: bienestaranimalpbae@gmail.com   quienes coordinarán y supervisarán el cumplimiento de los requisitos para optar por esta estrella y serán ellos quienes comunicarán los resultados al Equipo Técnico de la Categoría Cambio Climático.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Recalcar, que no deben enviar el resultado de la evaluación que les hicieron para optar por la estrella anaranjada al Equipo Técnico de la Categoría Cambio Climático. Hay que reiterar que nosotros solo somos los receptores de los resultados que nos envíe el Equipo Técnico de la Categoría Bienestar Animal.', alignment:'justify', italics: true, margin: [50, 0, 40, 20]
    },
    {
        text: 'Nota: La utilización de este Formato de Informe Final, es de carácter obligatorio para efectos de revisión del mismo.', alignment:'justify', bold: true, italics: true, margin: [50, 20, 40, 20]
    }
  ]

  return content;
}