<template>
    <v-snackbar
        v-model="noti.show"
        :color="noti.color"
        right
        bottom
        multi-line>
        <span class="font-weight-medium body-2">
            <v-icon class="pr-1" color="white">{{ noti.icono }}</v-icon>
            {{ noti.mensaje }}
        </span>
        <v-icon            
            class="mr-2 white--text"
            @click.native="noti.show = false">
            mdi-window-close
        </v-icon>    
    </v-snackbar>
</template>

<script>
export default {
    computed: {
        noti(){
            return this.$store.getters.notiData;
        }
    },
}
</script>