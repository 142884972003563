<template>
  <v-container fluid>
    <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
    <navParameters :navParams="navParams"/>
    <v-row>
      <v-col cols="12">
        <tituloPrincipal titulo="Medidas de Limpieza y Desinfección"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" align-self="center">
        <Agregar-medida v-if="anioConsulta > 2020" @reload="getData" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="3">
        <v-text-field 
          label="Buscar"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <tituloSimple titulo="Acciones de Limpieza y Desinfección"/>
          <v-card-text>
            <Tabla-limpieza @reload="getData" :items="actividades"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TablaLimpieza from '@/components/parametros/Agua/DesinfeccionTablaComponent'
import AgregarMedida from '@/components/parametros/Agua/AgregarLimpiezaComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'
export default {
  components: {
    TablaLimpieza,
    TituloPrincipal,
    NavParameters,
    TituloSimple,
    Logo,
    AgregarMedida
  },
  directives: {
    'sticky': VueSticky,
  },
  props: [
      'navParams'
  ],
  computed: {
    instalacion() {
      return this.$store.getters.sucursal;
    },
    usuario() {
      return this.$store.getters.usuario;
    },
    anioConsulta() {
      return this. $store.getters.anioConsulta;
    }
  },
  data: () => ({
    search: null,
    actividades: []
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`/limpieza/listarInstalacionAnio/${this.instalacion.id}/${this.anioConsulta}`)
      .then(response => {
        this.actividades = response.data;
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);
          this.$store.dispatch('noti', {mensaje: 'Ocurrió un error', color:'error'});  
        }
      }).finally(() => {
        this.loadingModal(false);
      })
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  }
}
</script>