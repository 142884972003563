<template>
  <v-card hover>
    <v-sheet class="v-sheet--offset mx-auto pt-2" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
        <v-list-item>
          <v-list-item-content>        
            <v-list-item-title class="white--text headline text-center">{{ titulo }}</v-list-item-title>
            <v-list-item-subtitle class="white--text subtitle text-center">{{ subtitulo }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>  
    </v-sheet> 
    <v-card-text>
      <v-simple-table fixed-header dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Instalación</th>
              <th class="text-left">Enero</th>
              <th class="text-left">Febrero</th>
              <th class="text-left">Marzo</th>
              <th class="text-left">Abril</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.ene }}</td>
              <td>{{ item.feb }}</td>
              <td>{{ item.mar }}</td>
              <td>{{ item.abr }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
    props: [
      'items',
      'titulo',
      'subtitulo'
    ],
}
</script>
<style scoped>
    thead th {
      font-size: 15px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>