<template>
    <v-container fluid>     
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal titulo="Sistema de Tratamiento de Aguas Residuales"/>
            </v-col>
        </v-row>       
        <v-row>
            <v-col cols="12" md="3" align-self="center">
                <agregarSistema @reload="getSistemaTratamiento"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pt-3">
                    <TituloSimple titulo="Identificación del Sistema Tratamiento de Aguas"/>
                    <v-card-text class="pt-0">
                        <sistemas @reload="getSistemaTratamiento" :search="search" :items="sistemas"/>     
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>   
    </v-container>
</template>
<script>

import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Sistemas from '@/components/parametros/AguasResiduales/SistTrataTablaComponent'
import AgregarSistema from '@/components/parametros/AguasResiduales/AgregarSistemaTratComponent'
import axios from '@/axios.js'

export default {
    components: {
        TituloPrincipal,
        TituloSimple,
        NavParameters,
        Sistemas,
        AgregarSistema
    },
    props: [
        'navParams'
    ],
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    mounted(){
       this.getSistemaTratamiento();
    },
    data: () => ({
        search: null,
        items: [
            { nombre: 'Planta de Tratamiento', valor: 1 },
            { nombre: 'Tanque séptico y drenaje', valor: 2 },
            { nombre: 'Alcantarillado sanitario', valor: 3 }
        ],
        sistemas: [],
    }),

    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getSistemaTratamiento() {
            let idInstalacion = this.instalacion.id;
            axios.get("/instalaciones/getSistemaTratamiento/" + idInstalacion)
            .then(response => {
                this.sistemas = response.data.sistema;
                setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener el sistema: " + error);
              }
            })
        },
        descargarDocumento(documentoId) {
            axios({
                url: '/documento/descargar/' + documentoId,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
    }
}
</script>
<style scoped>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>