<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
            <v-card>
                <v-sheet
                    :height="alturaHoja()"
                    class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                    color="#0B3F67"
                >
                    <v-layout fill-height align-center justify-center>Contactos de PBAE</v-layout>
                </v-sheet>
                <v-data-table 
                    :items-per-page="contactos.length"
                    disable-sort
                    :headers="headerscontactos"
                    :items="contactos"
                    dense
                    hide-default-footer=""
                    no-data-text="Sin datos"
                    disable-pagination
                >
                    <template v-slot:item.url="{ item }">
                        <template>
                        <v-layout justify-center>
                            <a :href="item.url" target="blank">{{item.web}}</a>
                        </v-layout>
                        </template>
                    </template>
                </v-data-table>
            </v-card>
            </v-col>
        </v-row>
                <v-row>
            <v-col cols="12">
            <v-card>
                <v-sheet
                    :height="alturaHoja()"
                    class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                    color="#0B3F67"
                >
                    <v-layout fill-height align-center justify-center>Fechas Importantes</v-layout>
                </v-sheet>
                <v-data-table 
                    :items-per-page="fechas.length"
                    disable-sort
                    :headers="headersfechas"
                    :items="fechas"
                    dense
                    hide-default-footer=""
                    no-data-text="Sin datos"
                    disable-pagination
                >
                    <template v-slot:item.url="{ item }">
                        <template>
                        <v-layout justify-center>
                            <a :href="item.url" target="blank">{{item.web}}</a>
                        </v-layout>
                        </template>
                    </template>
                </v-data-table>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        headerscontactos: [
            {
                text: "Encargado",
                value: "encargado",
                align: "center",
            },
            {
                text: "Correo Electrónico",
                value: "correo",
                align: "center",
            },
            {
                text: "Página Web",
                value: "url",
                align: "center",
            },
            {
                text: "Teléfono",
                value: "telefono",
                align: "center",
            },
             ],
        headersfechas: [
            {
                text: "Inscripción",
                value: "inscripción",
                align: "center",
            },
            {
                text: "Participación",
                value: "participación",
                align: "center",
            },
            {
                text: "Informe Final",
                value: "informe",
                align: "center",
            },
            {
                text: "Diagnóstico Inicial",
                value: "diagnóstico",
                align: "center",
            },
            {
                text: "Cuestionario de Cumplimiento Legal",
                value: "cuestionario",
                align: "center",
            },

        ],
        contactos: [
            {
                encargado: "Carlos Fallas Saborío",
                correo: "cfallas@ice.go.cr",
                url: "http://banderaazulecologica.org/landing-de-categorias/cambio-climatico",
                web: "Bandera Azul Ecológica",
                telefono: "2000-8156",
            },
            {
                encargado: "Karol Murillo",
                correo: "zurqui@epromcr.com",
                url: "https://www.epromcr.com/",
                web: "EPROMCR",
                telefono: "2100-6009",
            }
        ],

        fechas: [
            {
                inscripción: "1°enero al 31 de marzo (Debe realizarse todos los años por medio del Sistema establecido).",
                participación: "1° enero al 31 diciembre del año en que se realiza inscripción.",
                informe: "1° enero al 15 febrero (Debe presentarse por medio del Sistema establecido, todos los años en el año posterior al de inscripción y participación).",
                diagnóstico: "1° enero al 15 febrero (Debe presentarse en el mismo momento que el informe final, solo aquellas sedes que participan por primera vez, por medio del Sistema establecido).",
                cuestionario: "1° enero al 15 febrero (Debe presentarse todos los años en el mismo momento que el informe final del año de participación, por medio del Sistema establecido).",
            },

        ]

        
    }),
    methods: {
        alturaHoja() {
            return this.$mq === "sm" ? 120 : 80;
        }
    },
}
</script>
