<template>
    <v-card height="100%">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title 
                    class="headline mb-1" 
                    @click="$emit('organizacionesMap')">
                    Organizaciones
                </v-list-item-title>
                <v-list-item-subtitle>Activas</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
            <v-simple-table fixed-header height="500">
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left subtitle-1">Nombre</th>
                        <th class="text-center subtitle-1">Instalaciones</th>
                        <th class="text-center subtitle-1">Modulos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.name" v-on:click="cargaInfo(item)">
                        <td>{{ item.nombre }}</td>
                        <td class="text-center">{{ item.cantInstalaciones }}</td>
                        <td class="text-center"></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    components: {
    },
    props: [
        'data'
    ],
    mounted() {
        this.$store.commit('organizacion', null);
    },
    data: () => ({
        counter: 0
    }),
    methods: {
        cargaInfo: function (item) {
            this.$store.commit('organizacion', item);
            this.$emit('getListaInstlaciones');
        }
    }
}
</script>