<template>
   <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition">
      <template v-slot:activator="{ on:dialog }">
         <v-tooltip top>
               <template v-slot:activator="{ on:tooltip }">
                  <v-icon color="#0B3F67" medium v-on="{...tooltip, ...dialog}">mdi-trash-can-outline</v-icon>
               </template>
               Eliminar
         </v-tooltip>                    
      </template>
      <v-card>
         <v-card-title class="pa-0">
            <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
         </v-card-title>
         <v-card-text>
            <div class="pa-2">{{ mensaje }}</div>
         </v-card-text>
         <v-divider></v-divider>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar" color="error">Cancelar</v-btn>
            <v-btn text @click="eliminar" color="#0B3F67">Eliminar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from '@/axios'
export default {
   components: {
      ModalTitle
   },
   props: {
      requestUrl: {
         type: String,
         required: true
      },
      mensaje: {
         type: String,
         default: 'Este registro se eliminará permanentemente, ¿Desea continuar?',
         required: false
      },
      requestData: {
         type: FormData,
         required: true
      },
      titulo: {
         type: String,
         default: 'Eliminar',
         required: false
      },
   },
   data: () => ({
      dialog: false
   }),
   methods: {
      eliminar() {
         this.loadingModal(true);
         axios.post(this.requestUrl, this.requestData)
         .then(() => {
            this.$store.dispatch('noti',{mensaje: 'Registro eliminado', color:'success'});
            this.$emit('reload')
         }).catch(error => {
            this.$store.dispatch('noti',{mensaje: 'Error al eliminar el registro', color:'error'});
         }).finally(() => {
            this.loadingModal(false);
            this.dialog = false;
         });
      },
      cancelar() {
         this.dialog = !this.dialog;
      },
      loadingModal(modal) {                        
         this.$store.dispatch('loadingPage', modal);
      },
   }
}
</script>