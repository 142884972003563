<template>
    <v-container fluid>
        <v-row class="mb-3">
            <v-col cols="12" xs="12" sm="5" md="5" lg="3" xl="3">
                <AgregarEficiencia @recargaTabla="listaEficiencias" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búsqueda"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-sheet
                        :height="alturaHoja()"
                        class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                        color="#0B3F67"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                    >
                        <v-layout fill-height align-center justify-center>Catálogo Eficiencia</v-layout>
                    </v-sheet>
                    <v-data-table
                        multi-sort
                        :search="search"
                        :headers="headers"
                        :items="eficiencia"
                        class="elevation-1"
                        :loading="cargando"
                        loading-text="Cargando..."
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',                    
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <ModificarEficiencia
                                @reloadEdit="listaEficiencias"
                                v-bind:idEficiencia="item.id"
                            />
                        </template>
                        <template v-slot:item.estado="{ item }">
                            <v-layout justify-center>
                                <ActivarEficiencia
                                    @reload="listaEficiencias"
                                    v-bind:estado="item.estado"
                                    v-bind:idEficiencia="item.id"
                                />
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "@/axios";
import AgregarEficiencia from "@/components/eficiencia/EficienciasAgregarComponent";
import ModificarEficiencia from "@/components/eficiencia/EficienciasModificarComponent";
import ActivarEficiencia from "@/components/eficiencia/ActivarEficienciasComponent";

export default {
    components: {
        AgregarEficiencia,
        ModificarEficiencia,
        ActivarEficiencia
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted() {
        this.listaEficiencias();
    },
    data: () => ({
        headers: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
                width: "10%",
                sortable: false
            },
            {
                text: "Estado",
                value: "estado",
                align: "center",
                width: "10%",
                sortable: false
            },
            {
                text: "Parámetro de Eficiencia",
                value: "nombre",
                align: "center",
                sortable: true,
                filterable: true
            },
            {
                text: "Unidad de Medición",
                value: "descripcion",
                align: "center",
                width: "25%",
                sortable: true,
                filterable: true
            }
        ],
        search: "",
        eficiencia: [],
        cargando: false
    }),
    methods: {
        listaEficiencias() {
            this.cargando = true;
            axios.get("/eficiencia/listarEficiencia")
                .then(response => {
                    this.eficiencia = response.data.eficiencia;
                    this.cargando = false;
                })
                .catch(error => {
                    console.log(error);
                    this.cargando = false;
                });
        },
        alturaHoja() {
            return this.$mq === "sm" ? 120 : 80;
        }
    }
};
</script>

<style scoped>
table.v-table thead th {
    font-size: 20px !important;
}

.v-sheet--offset {
    top: -24px;
    position: relative;
}
</style>