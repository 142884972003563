
<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="550px" transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            small
            exact-active-class="indigo"
            class="ma-2"
            v-on="{ ...tooltip, ...on }"
            @click="getDataModificar()"
          >mdi-pencil</v-icon>
        </template>
        <span>Modificar</span>
      </v-tooltip>
    </template>
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Equipo" @cancelar="cancelar"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field 
              label="Tipo de Consumidor"
              v-model="equipo.tipoConsumidor"
              :error-messages="tipoErrors"
              @change="$v.equipo.tipoConsumidor.$touch()"
              @blur="$v.equipo.tipoConsumidor.$touch()"
              counter="50"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea 
              label="Descripción"
              counter="500"
              :error-messages="descripErrors"
              @change="$v.equipo.descripcion.$touch()"
              @blur="$v.equipo.descripcion.$touch()"
              v-model="equipo.descripcion"
            ></v-textarea>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
            label="Cilindrada"
            v-model="equipo.cilindrada"
            v-mask="maskCilindrada"
            :error-messages="cilindradaErrors"
            @change="$v.equipo.cilindrada.$touch()"
            @blur="$v.equipo.cilindrada.$touch()"
            required            
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
              label="Placa"
              :error-messages="placaErrors"
              @change="$v.equipo.numPlacaActivo.$touch()"
              @blur="$v.equipo.numPlacaActivo.$touch()"
              v-model="equipo.numPlacaActivo"
              counter="50"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
            label="Num Activo"
            v-model="equipo.numActivo"
            :error-messages="numActivoErrors"
            @change="$v.equipo.numActivo.$touch()"
            @blur="$v.equipo.numActivo.$touch()"
            counter="50"
            required
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
            label="Marca"
            v-model="equipo.marca"
            :error-messages="marcaErrors"
            @change="$v.equipo.marca.$touch()"
            @blur="$v.equipo.marca.$touch()"
            counter="50"
            required
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
              label="Modelo"
              v-model="equipo.modelo"
              :error-messages="modeloErrors"
              @change="$v.equipo.modelo.$touch()"
              @blur="$v.equipo.modelo.$touch()"
              counter="50"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
            label="Año"
            v-model="equipo.anio"
            v-mask="mask"
            :error-messages="anioErrors"
            @change="$v.equipo.anio.$touch()"
            @blur="$v.equipo.anio.$touch()"
            required
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-select
              :items="tipoCombustibles"
              item-text="nombre"
              item-value="valor"
              v-model="equipo.tipoCombustible"
              label="Tipo Combustible"
              :error-messages="tipoCombustibleErrors"
              @change="$v.equipo.tipoCombustible.$touch()"
              @blur="$v.equipo.tipoCombustible.$touch()"
              counter="100"
              required>
            </v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field 
              label="Responsable de Uso"
              v-model="equipo.responsableUso"
              :error-messages="responErrors"
              @change="$v.equipo.responsableUso.$touch()"
              @blur="$v.equipo.responsableUso.$touch()"
              counter="100"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-select
              :items="unidadesConsumo"
              item-text="nombre"
              item-value="valor"
              v-model="equipo.unidadMedidaConsumo"
              label="Medida Consumo"  
            ></v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-select
              :items="unidadesCosto"
              item-text="nombre"
              item-value="valor"
              v-model="equipo.unidadCostoRecibo"
              label="Unidad de Costo"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
        <v-btn color="#0B3F67" :loading="loading" text @click="modificarEquipo">Modificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>

import axios from "@/axios.js";
import { required, maxLength } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { mask } from "vue-the-mask"

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    data:()=>({
        dialog: false,
        loading: false,
        equipo:{
            tipoConsumidor: '',
            descripcion:'',
            numPlacaActivo:'',
            marca:'',
            modelo:'',
            anio:'',
            capacidad:'',
            tipoCombustible:'',
            responsableUso:'',
            unidadMedidaConsumo:'',
            unidadCostoRecibo:'',
            cilindrada:'',
            numActivo: '',
        },
        unidadesCosto: [
          {nombre: 'COLONES', valor: 1}, 
          {nombre: 'DOLARES', valor: 2}
        ],
        unidadesConsumo: [
          {nombre:'LITROS', valor: 2},              
          {nombre:'GALONES', valor: 3},
        ],
        tipoCombustibles:[
          {nombre: 'GASOLINA', valor:'GASOLINA'},
          {nombre: 'GAS LP', valor:'GAS_LP'},
          {nombre: 'DIESEL', valor:'DIESEL'},
          {nombre: 'BUNKER', valor:'BUNKER'},
      	  {nombre: 'OTRO', valor:'OTRO'}
        ],
        mask: '####',
        maskCilindrada: '#######'
    }),
    directives: {
        mask,
    },
    props: ["id"],
    computed:{
        usuario(){
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
         tipoErrors () {
            const errors = []
            if (!this.$v.equipo.tipoConsumidor.$dirty) return errors
            !this.$v.equipo.tipoConsumidor.required && errors.push('Campo requerido')
            !this.$v.equipo.tipoConsumidor.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        numActivoErrors () {
            const errors = []
            if (!this.$v.equipo.numActivo.$dirty) return errors
            !this.$v.equipo.numActivo.required && errors.push('Campo requerido')
            !this.$v.equipo.numActivo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        marcaErrors () {
            const errors = []
            if (!this.$v.equipo.marca.$dirty) return errors
            !this.$v.equipo.marca.required && errors.push('Campo requerido')
            !this.$v.equipo.marca.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.equipo.anio.$dirty) return errors
            !this.$v.equipo.anio.required && errors.push('Campo requerido')
            return errors
        },        
        cilindradaErrors () {
            const errors = []
            if (!this.$v.equipo.cilindrada.$dirty) return errors
            !this.$v.equipo.cilindrada.required && errors.push('Campo requerido')
            return errors
        },
        tipoCombustibleErrors () {
            const errors = []
            if (!this.$v.equipo.tipoCombustible.$dirty) return errors
            !this.$v.equipo.tipoCombustible.required && errors.push('Campo requerido')
            !this.$v.equipo.tipoCombustible.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descripErrors() {
            const errors = []
            if (!this.$v.equipo.descripcion.$dirty) return errors            
            !this.$v.equipo.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        placaErrors() {
            const errors = []
            if (!this.$v.equipo.numPlacaActivo.$dirty) return errors            
            !this.$v.equipo.numPlacaActivo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        modeloErrors() {
            const errors = []
            if (!this.$v.equipo.modelo.$dirty) return errors            
            !this.$v.equipo.modelo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        responErrors() {
            const errors = []
            if (!this.$v.equipo.responsableUso.$dirty) return errors            
            !this.$v.equipo.responsableUso.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
  },
  validations:{
    equipo:{
      tipoConsumidor:{
        maxLength: maxLength(50),
        required
      },
      numActivo:{
        maxLength: maxLength(50),
        required
      },
      marca:{
        maxLength: maxLength(50),
        required
      },
      anio:{
        required
      },
      cilindrada:{
        required
      },
      modelo: {
        maxLength: maxLength(50),
      },
      responsableUso: {
        maxLength: maxLength(100),
      },
      descripcion: {
        maxLength: maxLength(500),
      },
      numPlacaActivo: {
        maxLength: maxLength(50),
      },
      tipoCombustible:{
        maxLength: maxLength(100),
        required
      }
    }
  },
    methods:{
        getDataModificar(){
            axios.get("/inventarioConsumidores/getDataModificar/"+this.id, null)
                .then(response=>{
                    this.equipo = response.data.equipo;
                }).catch(error=>{
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                  }
                })

        },
        modificarEquipo(){
          this.$v.$touch();
          if(!this.$v.$invalid){
            this.loading = true;
            delete this.equipo.unidadMedidaConsumoString;
            delete this.equipo.unidadCostoReciboString;
            delete this.equipo.unidadCostoReciboString;
            axios.post("/inventarioConsumidores/modificar/", this.equipo, {params:{ 
              idUsuario: this.usuario.id,
              instalacion: this.instalacion.id,
              tipoCombustible: this.equipo.tipoCombustible
            }}).then(response=>{
                this.$store.dispatch("noti", {mensaje: "Actividad modificada",color: "success"});
                this.$emit("reload");
                this.dialog = false;
                this.loading = false;
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.$store.dispatch("noti", {
                    mensaje: "Ocurrió un error no se modificó el equipo",
                    color: "error"});
                console.log(error);
                this.dialog=false;
                this.loading = false
              }
            })
          }
        },
        cancelar(){
          this.equipo={};
          this.dialog = false;
          this.loading = false;
        }
    }
}
</script>