<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" sm="6" md="3" align-self="center" >
        <agregarActividad v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" @reload="getDataPorAnio" />
      </v-col>
      <v-col cols="6" sm="6" md="2" offset-md="7">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="actividades"
                    calculate-widths
                    item-key="id"
                    :loading="loading"
                    :search="search"
                    no-data-text="No hay Actividades para este periodo"
                    :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                >
                    <template v-slot:item.fechaActividad="{ item }">{{ dateFormat(item.fechaActividad) }}</template>
                    <template v-slot:item.action="{ item }">
                    <ModificarEducAmbiental v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && item.tipoNum == 1" @reload="getDataPorAnio" v-bind:id="item.id" />
                    <ModificarAccionReduccion 
                      v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && item.tipoNum == 2" 
                      @reload="getDataPorAnio" 
                      v-bind:accion1="item" />
                    <AgregarEvidencia
                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                        @reload="getDataPorAnio"
                        :idActividad="item.id"
                        :idAccion="item.id"
                        :origen="getOrigenActividad(item.tipoNum)"
                        tooltipText="Agregar Evidencias"
                        :titulo="titulo"
                    ></AgregarEvidencia>
                    <Eliminar 
                      v-bind:requestData="requestDataFormat(item)" 
                      requestUrl="/instalaciones/borrarActividadRedEdc/"
                      @reload="reloadData"
                    /> 
                    </template>
                    <template v-slot:item.evidencia="{ item }">
                    <ListadosDocs :idActividad="item.id" :idAccion="item.id" :origen="getOrigenActividad(item.tipoNum)" :titulo="titulo"></ListadosDocs>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   </v-container>
</template>
<script>
import ModificarAccionReduccion from "@/components/parametros/AccionReduccion/ModificarAccionReduccionComponent";
import ModificarEducAmbiental from "@/components/parametros/EducacionAmbiental/ModificarEducAmbientalComponent";
import AgregarActividad from "@/components/parametros/combustiblesFosiles/AgreEducRedComponent";
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import Eliminar from '@/components/utils/EliminarComponent';
import moment from "moment";
import axios from "@/axios.js";

export default {
  components: {
    ModificarAccionReduccion,
    ModificarEducAmbiental,
    AgregarActividad,
    ListadosDocs,
    AgregarEvidencia,
    Eliminar,
  },
  created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  },  
  mounted() {
    this.getDataPorAnio();
  },
  computed: {
    anioConsulta() {
      return this. $store.getters.anioConsulta;
    },
    instalacion() {
      return this.$store.getters.sucursal;
    },
    rolUsuario(){
      return this.$store.getters.usuario.rol;
    },
    indicadorActual() {
      return this.$store.getters.indicadorActual;
    },
    idUsuario(){
      return this.$store.getters.usuario.id;
    },
    parametroActual() {
      return this.$store.getters.parametroActual;
    },
  },
  data: () => ({
    loading: false,
    todasLasActividades: [],
    actividadesPorAnio: [],
    actividades: [],
    items: [],
    anio: "",
    origen: "EducCasoDos",
    titulo: "Evidencia de Documento",
    search: null,
    headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                class: "educTable",
            },
            {
                text: "Tipo de Actividad",
                value: "tipoActividad",
                align: "center",
                class: "educTable",
            },
            {
                text: "Descripción de la Actividad",
                value: "descripcion",
                align: "start",
                class: "educTable",
            },
            {
                text: "Cantidad de Actividades",
                value: "cantidadActividades",
                align: "center",
                class: "educTable",
            },
            {
                text: "Fecha de la Actividad",
                value: "fechaActividad",
                align: "center",
                class: "educTable",
            },
            {
                text: "Cantidad de Personas",
                value: "cantidadPersonas",
                align: "center",
                class: "educTable"
            },
            {
                text: "Tipo",
                value: "tipo",
                align: "center",
                class: "educTable"
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                class: "educTabla",
            }
        ],
  }),
  methods: {
    dateFormat(fecha) {
      return fecha ? moment(fecha).format("DD/MM/YYYY") : "";
    },
    getDataPorAnio() {
      this.cargando = true;
      axios
        .get(`/instalaciones/getDataEducReduc/${this.instalacion.id}/${this.anioConsulta}/${this.indicadorActual}`)
        .then(response => {
          this.actividades = response.data;
          this.cargando = false;
          this.loadingModal(false);
        })
        .catch(error => {
          console.log(error);
          this.cargando = false;
          this.loadingModal(false);
        });
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
    
    reloadData() {
      this.$emit('reload')
    },

    requestDataFormat(data) {
      let datos = new FormData()
      datos.append('id', data.id);
      datos.append('usuario', this.idUsuario);
      datos.append('tipoAccion', data.tipoNum);
      return datos;
    },

    getOrigenActividad(tipo) {
        switch (tipo) {
            case 1:
                return 'educacionAmbiental';
        
            default:
                return 'accionReduccion';
        }
    },
    getOrigenUploadFile(tipo) {
      switch (tipo) {
          case 1:
              return 'EducCasoDos';
      
          default:
              return 'AccReducCasoDos';
        }
    }
  }
};
</script>