<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="650px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-list>
        <v-list-item class="pl-0">
          <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">
              <v-list-item-avatar tile v-on="{ ...tooltip, ...dialog }">
                <v-img alt="categoria" :src="logo"></v-img>
              </v-list-item-avatar>
            </template>
            {{ tooltipLogo }}
          </v-tooltip>
          <v-list-item-content>
            <v-list-item-title v-text="nombre"></v-list-item-title>
          </v-list-item-content>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-list-item-icon v-on="on">
                <v-icon :color="categoria != null ? 'success' : 'warning'">
                  {{ icono }}
                </v-icon>
              </v-list-item-icon>
            </template>
            {{ tooltipIcono }}
          </v-tooltip>
        </v-list-item>
      </v-list>
    </template>
    <v-card color="blue lighten-5">
      <v-card-title class="pa-0">
        <ModalTitle titulo="Seleccione Categoria" @cancelar="cerrar"/>
      </v-card-title>  
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col sm="12" md="4" :offset-md="responsiveOffset" v-for="categ in categorias" :key="categ.id">
            <categoria :categoria="categ" @selectCategoria="enviarCategoria"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import axios from '@/axios'
import ModalTitle from '@/components/utils/ModalTitleComponent'
import Categoria from '@/components/categorias/CategoriaComponent'

export default {
  components: {
    Categoria,
    ModalTitle
  },
  mounted() {
    this.getCategorias();
  },
  computed: {
    logo() {
      if(this.categoria != null) {
        return require('@/assets/' + this.categoria.logo);
      } else {
        return require('@/assets/select-categ.png');
      }
    },
    nombre() {
      if(this.categoria != null) {
        return 'Categoria ' + this.categoria.nombre
      } else {
        return 'Seleccione una Categoria';
      }
    },
    icono() {
      if(this.categoria != null) {
        return 'mdi-check-outline'
      } else {
        return 'mdi-alert-outline';
      }
    },
    tooltipLogo() {
      if(this.categoria != null) {
        return this.categoria.nombre
      } else {
        return 'Seleccione una Categoria';
      }
    },
    tooltipIcono() {
      if(this.categoria != null) {
        return 'Categoria Seleccionada'
      } else {
        return 'Sin Categoria';
      }
    },
    responsiveOffset() {
      let tamanio = this.categorias.length;
      if(tamanio == 1) {
        return '4';
      } else if(tamanio == 2) {
        return '2';
      } else if(tamanio < 2) {
        return '0';
      }
    }
  },
  data: () => ({
    dialog: false,
    categoria: null,
    categorias: [],
    gridSize: ''
  }),
  methods: {
    getCategorias() {
      axios.get("/categoria/listarCategorias").then(response => {
        this.categorias = response.data;
        this.loading = false;
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);  
        }
      })
    },
    enviarCategoria(categoria) {
      this.categoria = categoria;
      this.$emit('enviarCategoria', categoria)
      this.cerrar();
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
    cancelar() {
      this.categoria = null;
    }
  }
}
</script>