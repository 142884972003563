var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"3","align-self":"center"}},[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarAccion',{on:{"reloadAn":_vm.aniosConsulta,"reload":_vm.getDataPorAnio}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.acciones,"calculate-widths":"","item-key":"id","loading":_vm.loading,"no-data-text":"No hay Acciones para este periodo","footer-props":{
                        showFirstLastPage: true,
                        firstIcon:'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageAllText: 'Todos',
                        showCurrentPage: true,
                        showFirstLastPage: true
                    }},scopedSlots:_vm._u([{key:"item.fechaActividad",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.dateFormat(item.fechaActividad)))]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idActividad":item.id,"origen":_vm.origen,"titulo":_vm.titulo,"tooltipText":"Agregar Evidencias"},on:{"reload":_vm.getDataPorAnio}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarAccion',{attrs:{"id":item.id},on:{"reloadAn":_vm.aniosConsulta,"reload":_vm.getDataPorAnio}}):_vm._e()]}},{key:"item.evidencia",fn:function(ref){
                    var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"origen":"accionesPoS","titulo":_vm.titulo}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }