var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('logo',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 4, stickyTop: 65}),expression:"{ zIndex: 4, stickyTop: 65}"}],on:{"cambiaAnio":_vm.getDataAccPlan}}),_c('navParameters',{attrs:{"navParams":_vm.navParams}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tituloPrincipal',{attrs:{"titulo":"Seguimiento Plan de Acción para Medida de Adaptación"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('tituloSimple',{attrs:{"titulo":"Seguimiento Plan de Acción"}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"fixed-header":"","no-data-text":"No hay planes de acción registrados","footer-props":{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',                            
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('AgregarEvidencias',{attrs:{"titulo":"Agregar Evidencias","tooltipText":"Agregar Evidencias","anioActual":_vm.anioConsulta,"origen":"planAccion","data":item}}),_c('ModificarEstado',{attrs:{"idPlan":item.idPlan,"instalacion":_vm.instalacion,"estadoAct":item.estado,"usuario":_vm.usuario},on:{"reload":_vm.getDataAccPlan}})]}},{key:"item.estado",fn:function(ref){
                        var item = ref.item;
return [_c('estadoPlan',{attrs:{"estado":item.estado}})]}},{key:"item.idPlan",fn:function(ref){
                        var item = ref.item;
return [_c('evidencias',{attrs:{"origen":"planAccion","titulo":"Evidencias Plan de Acción","id":item.idPlan}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }