var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('logo',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 4, stickyTop: 65}),expression:"{ zIndex: 4, stickyTop: 65}"}],on:{"cambiaAnio":_vm.getHistorialProgramas}}),_c('navParameters',{attrs:{"navParams":_vm.navParams}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tituloPrincipal',{attrs:{"titulo":"Programa de Compras Sostenibles"}})],1)],1),_c('v-row',{attrs:{"justify-sm":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","align-self":"center"}},[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarPrograma',{attrs:{"aniosConstancia":_vm.aniosConstancia,"anios":_vm.aniosConsulta},on:{"reload":_vm.getHistorialProgramas}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('tituloSimple',{attrs:{"titulo":"Historial"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarPrograma',{attrs:{"anios":_vm.aniosConsulta,"data":item},on:{"reload":_vm.getHistorialProgramas}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","dark":"","icon":""},on:{"click":function($event){return _vm.eliminar(item.id)}}},on),[_c('v-icon',{attrs:{"color":"#0B3F67"}},[_vm._v("mdi-trash-can-outline")])],1)]}}:null],null,true)},[_vm._v(" Eliminar programa ")])]}},{key:"item.documentoId",fn:function(ref){
var item = ref.item;
return [(_vm.anioConsulta < 2022)?_c('div',[(item.documentoId != 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","dark":"","icon":""},on:{"click":function($event){return _vm.descargarDoc(item.documentoId)}}},on),[_c('v-icon',{attrs:{"color":"#0B3F67"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_vm._v(" Descargar Evidencia ")]):_vm._e()],1):_c('div',[_c('ListadosDocs',{attrs:{"id":item.id,"origen":"programaCompras","titulo":"Evidencias Programa Compras Sostenibles"}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }