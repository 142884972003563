<template>
    <v-dialog
        v-model="dialog"        
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon color="#0B3F67" large v-on="{...tooltip, ...dialog}">mdi-file-plus-outline</v-icon>                                                  
                </template>
                Agregar Evidencia
            </v-tooltip>                          
        </template>        
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Evidencia" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="anios"
                            v-model="anio"
                            label="Año"
                            :error-messages="anioErrors"             
                            @change="$v.anio.$touch()"
                            @blur="$v.anio.$touch()"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input label="Adjuntar Evidencia"
                            v-model="evidencias"
                            accept=".png, .jpeg, .jpg"
                            show-size                                
                            clearable
                            multiple
                            :error-messages="evidenciaErrors"             
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>                
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="agregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js';
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    mounted() {
        this.getAnioParticipacion();
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        evidenciaErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('Por favor adjuntar evidencias.')      
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + ' MB.')      
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.anio.$dirty) return errors            
            !this.$v.anio.required && errors.push('Seleccione un año.')            
            return errors
        },
    },
    data: () => ({
        evidencias: null,
        loading: false,
        dialog: false,
        anio: null,
        anios: []
    }),
    validations: {
        evidencias: {
            required,
            maxFileSize
        },
        anio: {
            required
        }
    },
    methods: {
        agregarEvidencias() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.evidencias.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append('anio', this.anio);
                formData.append('inst', this.instalacion.id);
                formData.append('user', this.usuario.id);
                axios.post('/tratamientoAgua/agregarEvidenciaSustancias',formData)
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: "Evidecias agregadas", color: 'success'});
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: "Ocurrió un error no se agregaron las evidencias", color: 'error'});
                      this.loading = false;
                    }
                  }
                })
            }
        },
        getAnioParticipacion() {
            let id = this.instalacion.id    ;
            axios.get("/instalaciones/getAniosParticipacion/" + id
            ).then(response => {
                this.anios = response.data.anios;                
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los años de participacion: " + error);
              }
            })
        },
        cancelar() {
            this.anio = null;
            this.evidencias = null;
            this.$v.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>