<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                    small
                    exact-active-class="indigo"
                    class="ma-2"
                    v-on="{ ...tooltip, ...on }"
                    @click="getDataModificar()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Acción de Reducción" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-select
                            :items="actividades"
                            v-model="accion.tipo"
                            :error-messages="tipoErrors"             
                            @change="$v.accion.tipo.$touch(); isOtro()"
                            @blur="$v.accion.tipo.$touch()"
                            counter="200"
                            required                            
                            label="Tipo de Actividad"    
                        ></v-select>
                    </v-col>
                    <v-col v-if="valSelect" cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Nombre Actividad"
                            counter="100"
                            v-model="otro"
                            :error-messages="otroErrors"             
                            @change="$v.otro.$touch()"
                            @blur="$v.otro.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12 ">
                        <v-textarea
                            rows="3"
                            label="Descripción"
                            counter="500"
                            v-model="accion.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.accion.descripcion.$touch()"
                            @blur="$v.accion.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Cantidad de Actividades"
                            v-model="accion.cantidad"
                            v-mask="mask"
                            hint="Ingresar solo números"
                            :error-messages="cantErrors"             
                            @change="$v.accion.cantidad.$touch()"
                            @blur="$v.accion.cantidad.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaInscripcion" 
                                locale="es-CR"
                                @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarAccion">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import { required, maxLength,requiredIf } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total <= maximo;
    }
    return true;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    mounted() {
            let indicadorActual = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            switch(indicadorActual) {
                case constantes.indicadorCombustibles:
                    this.actividades = this.actividadesFosil.sort();
                    break;
                case constantes.indicadorAguasResiduales:
                    this.actividades = this.actividadesAguaRes.sort();
                    break;
                case constantes.indicadorAgua:
                    this.actividades = this.actividadesAgua.sort();
                    break;
                case constantes.indicadorEnergia:
                    this.actividades = this.actividadesEnergia.sort();
                    break;
                case constantes.indicadorContamiAdmosf: //karol debe definir esta lista
                    this.actividades = this.actividadesFosil.sort();
                    break;
                case constantes.indicadorResiduos:
                    this.actividades = this.actividadesResiduos.sort();
            }
            this.actividades.push("Otro")
     }, 
    directives: {
        mask,
    },
    props: [
        'accion1'
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        fechaMoment () {
            return this.fechaInscripcion ? moment(this.fechaInscripcion).format('DD/MM/YYYY') : '';
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.accion.tipo.$dirty) return errors
            !this.$v.accion.tipo.required && errors.push('Campo requerido')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.accion.descripcion.$dirty) return errors
            !this.$v.accion.descripcion.required && errors.push('Campo requerido')
            !this.$v.accion.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.accion.cantidad.$dirty) return errors
            !this.$v.accion.cantidad.required && errors.push('Campo requerido')
            return errors
        },
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        otroErrors(){
            const errors = []
            if(this.accion.tipo === "Otro" && !this.$v.otro.$dirty) return errors
            !this.$v.otro.required && errors.push('Dato requerido')
            return errors
        }, 
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        
    },
    data: () => ({
        loading: false,
        dialog: false,
        menu: false,
        mask: '####',
        accion: {
            tipo: '',
            descripcion: '',
            cantidad: '',
        },
        fechaInscripcion: '',
        archivos: [],

        actividades:[],
        actividadesFosil:[
        "Adquisición de equipo más eficiente.",
        "Mantenimiento preventivo de la flotilla vehicular, maquinaria y/o equipo.",
        "Implementación de dispositivos (mecánicos) para aumentar la eficiencia.",
        "Definición de rutas más adecuadas para reducir consumos.",
        "Implementar iniciativas de movilidad sostenible.",
        "Acondicionar estacionamientos para bicicletas.",
        "Pizarras informativas.",
        "Compartir información asociada al parámetro por distintos medios: redes sociales, correo electrónico, chats, otros.",
        "Colocación de calcomanías.",
        "Incluir los vehículos de colaboradores y proveedores.",
        "Implementar iniciativas para mejorar el registro y control de los consumos.",
        "Sistemas para el control del consumo",
        "Campañas de comunicación",
        "Promover transporte compartido",
        "Promover iniciativas de movilidad sostenible",
        "Planificar las giras o gestiones de trabajo",
        "Definir rutas y el vehiculo mas adecuado",
        "Realizar varios trámites en un mismo viaje",
        "Medicion de consumo de combustibles en colaboradores / proveedores",
        "Reduccion de consumo por concepto de kilometraje",
        "Reduccion de consumo por concepto de viajes aereos"
            ],
        actividadesAguaRes:[
            "Sustituir productos de limpieza a biodegradables",
            "Utilizar trampas de grasa",
            "Reutilización de aguas tratadas",
            "Implementar el uso de biojardineras",
            "No vertido de residuos en fregaderos y desagues",
            "Pizarras informativas",
            "Compartir información por medios sociales",
            "Colocación de calcomanías"
            ],
        actividadesAgua:[
            "Mejoras y mantenimiento preventivo del sistema de abastecimiento de agua",
            "Cambios de equipos y accesorios de ahorro de agua",
            "Cosecha de agua de lluvia",
            "Reutilización de agua",
            "Establecer horarios de uso adecuado de agua",
            "Ajustar la presión del agua",
            "Verificar la calibración del medidor",
            "Pizarras informativas",
            "Compartir información por medios sociales",
            "Mejorar el registro y control de consumos"
            ],
        actividadesEnergia:[
            "Uso de fuentes de energía renovables no convencionales",
            "Cambio a tecnologías de mayor eficiencia energética",
            "Aprovechamiento de luz natural",
            "Uso de colores claros en paredes, techo y mobiliario",
            "Utilizar los aires acondicionados a 24C",
            "Configurar el equipo de cómputo en el modo de ahorro de energía",
            "Techos y paredes verdes para la climatización de ambientes internos",
            "Desconectar equipos electronicos y electricos cuando no estan en uso",
            "Calibrar los medidores eléctricos",
            "técnicas para el aislamiento térmico de las instalaciones",
            "Sistemas de monitoreo y control de consumo de electricidad",
            "Pizarras informativas",
            "Compartir información por medios sociales",
            "Mejorar el registro y control de consumos"
            ],
        actividadesResiduos:[
            "Innovación en pro de la reutilización de los residuos",
            "Sustitución de materiales o insumos por otros con mayor capacidad de biodegradación",
            "Aplicación de técnicas o tecnologías limpias para el tratamiento y aprovechamiento de los residuos",
            "Aplicación de técnicas o tecnologias para reducir el impacto de residuos peligrosos",
            "Pizarras Informativas",
            "Compartir información por medios sociales",
            "Mejorar el registro y control de consumos",
            "Colocación de calcomanías"
            ],
        otro:"",
        valSelect: false,
    }),
    validations: {
        accion: {
            tipo: {
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            cantidad: {
                required
            },
        },
        fechaInscripcion: {
            required
        },
        /*archivos: {
            maxFileSize
        },*/
        otro: {
            required: requiredIf(function(){
                return this.accion.tipo === "Otro" ? true : false;
            })
        }
    },
    methods: {
        getDataModificar(){
            this.accion.id = this.accion1.id;
            if(this.actividades.includes(this.accion1.tipoActividad)){
                this.accion.tipo = this.accion1.tipoActividad;
                this.valSelect = false;
            } else{
                this.accion.tipo = "Otro";
                this.otro = this.accion1.tipoActividad;
                this.valSelect = true;
            }
            this.accion.descripcion = this.accion1.descripcion;
            this.fechaInscripcion = new Date(this.accion1.fechaActividad).toISOString().substr(0, 10);
            this.accion.cantidad = this.accion1.cantidadActividades;
            // this.archivos = this.$attrs.accion.documentos;
        },
        modificarAccion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                /*this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });*/
                formData.append("otro", this.otro);
                formData.append('id', this.accion.id);
                formData.append('tipoActividad', this.accion.tipo);
                formData.append('descripcion', this.accion.descripcion);
                formData.append('cantidadActividades', this.accion.cantidad);
                formData.append('fechaActividad', this.fechaInscripcion);
                formData.append('idUsuario', this.usuario.id);
                formData.delete('undefined');
                axios.post("/accionReduccion/modificar", formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Acción de reducción modificada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modifico la acción de reducción', color:'error'});   
                    this.cancelar();         
                  }  
                })
            }
        },
        cancelar() {
            this.accion = {};
            this.fechaInscripcion = '';
            //this.archivos = null;
            this.otro = "";
            this.$v.accion.$reset();    
            this.$v.$reset();
            this.$v.otro.$reset();   
            this.dialog = false;
            this.loading = false;
            this.valSelect = false;
        },
        isOtro(){
            if (this.accion.tipo === "Otro"){
                this.valSelect = true;
            }else{
                this.valSelect= false;
            }
        }
    },
}
</script>