<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <TituloPrincipal :titulo="'Bitácoras'"></TituloPrincipal>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GenerarInforme :indicadores="indicadores" :instalaciones="instalaciones" :anios="anios"/>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col md="3">
        <v-card>
          <v-card-title primary-title>
            Filtros
          </v-card-title>
          <v-divider></v-divider>
          <v-col md="12">
            <v-select
              :items="instalaciones"
              v-model="instalacion"
              item-text="nombre"
              item-value="id"
              label="Instalación"
              @change="buscarBitacoras"
              :error-messages="instalacionErrors"
            ></v-select>
          </v-col>
          <v-col md="12">
            <v-select
              :items="indicadores"
              v-model="indicador"
              item-text="nombre"
              item-value="id"
              label="Indicador"
              @change="buscarBitacoras"
            ></v-select>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="9">
        <Bitacoras ref="bitList" :indicador="indicador" :instalacion="instalacion" :instalacionName="instalacionName" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from '@/axios'
import { required } from "vuelidate/lib/validators";
import Bitacoras from '@/components/bitacora/BitacoraListComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import GenerarInforme from '@/components/bitacora/GenerarInformeComponent.vue'
export default {
  components: {
    TituloPrincipal,
    Bitacoras,
    GenerarInforme
},
  mounted() {
    this.getDataBitacora();
  },
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    instalacionErrors() {
      const errors = []
      if (!this.$v.instalacion.$dirty) return errors            
      !this.$v.instalacion.required && errors.push('Seleccione una instalación.')
      return errors
    },
    instalacionName() {
      let inst = this.instalaciones.find(obj => obj.id == this.instalacion);
      return this.instalacion != null ? inst.nombre : '';
    }
  },
  data: () => ({
    indicador: 0,
    indicadores: [],
    instalacion: null,
    instalaciones: [],
    bitacoras: [],
    items: [],
    anios: []
  }),
  validations: {
    instalacion: {
      required
    },
  },
  methods: {
    getDataBitacora() {
      this.loadingModal(true);
      axios.get(`/bitacora/getDataBitacoras/${this.usuario.id}` ).then(response => {
        this.indicadores = response.data.indicadores;
        this.instalaciones = response.data.instalaciones;
        this.anios = response.data.anios
        this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);
          this.loading = false;
        }
        this.loadingModal(false);
      })
    },
    buscarBitacoras() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        this.$refs.bitList.buscarBitacoras(this.indicador, this.instalacion);
      }
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  },
}
</script>