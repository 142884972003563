<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="750px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">Agregar Constancia<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Constancia" @cancelar="limpiar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>                            
                <v-row>                    
                    <v-col cols="5" class="text-right subtitle-1 pb-0" align-self="center">
                        Año que Aplica la Constancia    
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" md="5" class="pt-0 pb-0">                                                                                                    
                        <v-select
                            :items="anios"
                            v-model="potabilidad.anio"
                            label="Año"
                            :error-messages="anioErrors"             
                            @change="verificarAnio"
                            @blur="$v.potabilidad.anio.$touch()"
                        ></v-select>                                           
                    </v-col>                    
                    <v-col cols="12" md="8" offset-md="2" class="pa-0">
                        <v-alert dense type="error" v-model="alert">
                            Ya existe una constancia para este año.
                        </v-alert>     
                    </v-col>
                </v-row>
                <v-row>                    
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        Fuente de Abastecimiento       
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" class="pt-0 pb-0">                                                                                                 
                        <v-radio-group v-model="potabilidad.fuenteAbastecimiento"
                            :error-messages="fuenteErrors"
                            @change="$v.potabilidad.fuenteAbastecimiento.$touch()">
                            <v-radio label="Externo" value="0"></v-radio>
                            <v-radio label="Interno" value="1"></v-radio>
                        </v-radio-group>                                                    
                    </v-col>
                </v-row>                                                                                                                                      
                <v-row>                    
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        Ente Emisor de la Constancia
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" md="5">
                        <v-select
                            :items="items"
                            v-model="potabilidad.emisor"
                            label="Ente Emisor"
                            :error-messages="emisorErrors"             
                            @change="$v.potabilidad.emisor.$touch()"
                            @blur="$v.potabilidad.emisor.$touch()"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row align-content="center">
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        El Agua es Potable                                                     
                    </v-col>        
                    <v-divider vertical class="pa-0"></v-divider>                       
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-radio-group v-model="potabilidad.potable" 
                            hint="El usuario ingresar el resultado de la constancia"
                            :error-messages="potableErrors"
                            @change="$v.potabilidad.potable.$touch()">
                            <v-radio label="Si" value="true"></v-radio>
                            <v-radio label="No" value="false"></v-radio>
                        </v-radio-group> 
                    </v-col>
                </v-row> 
                <v-row>                    
                    <v-col cols="5" class="text-right subtitle-1 pt-0 pb-0" align-self="center">
                        Constancia
                    </v-col>
                    <v-divider vertical class="pa-0"></v-divider>
                    <v-col cols="6" md="5" class="pt-0 pb-0">
                        <v-file-input label="Adjuntar Constancia"
                            accept=".png, .jpeg, .jpg"
                            v-model="archivos"
                            show-size                                
                            clearable
                            multiple
                            :error-messages="constanciaErrors"
                            @change="$v.archivos.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>                                                        
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="limpiar()" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregarConstancia()" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    components: {
      ModalTitle
    },
    computed: {
        fuenteErrors () {
            const errors = []
            if (!this.$v.potabilidad.fuenteAbastecimiento.$dirty) return errors            
            !this.$v.potabilidad.fuenteAbastecimiento.required && errors.push('Dato requerido.')
            return errors
        },
        potableErrors () {
            const errors = []
            if (!this.$v.potabilidad.potable.$dirty) return errors
            !this.$v.potabilidad.potable.required && errors.push('Dato requerido')
            return errors
        },
        emisorErrors () {
            const errors = []
            if (!this.$v.potabilidad.emisor.$dirty) return errors
            !this.$v.potabilidad.emisor.required && errors.push('La Estructura Legal es requerida')
            return errors
        },
        constanciaErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors
            !this.$v.archivos.required && errors.push('Dato requerido')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + ' MB.')
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.potabilidad.anio.$dirty) return errors
            !this.$v.potabilidad.anio.required && errors.push('Dato requerido')
            return errors
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
    },
    props: [
        'anios',
        'aniosConstancia'
    ],
    data: () => ({
        dialog: false,
        archivos: [],
        alert: false,
        potabilidad: {
            fuenteAbastecimiento: '',
            potable: '',        
            emisor: '',
            anio: ''
        },
        items: [
            'Acueductos y Alcantarillados',
            'Municipalidad',
            'Asada',
            'ESPH',
            'Otro'
        ],    
    }),
    validations: {        
        potabilidad: {    
            fuenteAbastecimiento: {
                required
            },
            potable: {
                required
            },
            emisor: {
                required
            },
            anio: {
                required
            }
        },
        archivos: {
            required,
            maxFileSize
        }
    },
    methods: {
        agregarConstancia() {
            this.$v.$touch();
            if(!this.$v.$invalid && !this.alert) {
                this.$store.dispatch('loadingPage', true);
                let formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append('anio', this.potabilidad.anio);
                formData.append('potabilidad', JSON.stringify(this.potabilidad));
                formData.append('idInstalacion', this.$store.getters.sucursal.id);
                formData.append('usuario', this.usuario.id);
                axios.post("/indicadorAgua/agregarConstanciaAgua", formData)
                .then(response => {
                    this.$store.dispatch('loadingPage', false);
                    this.$store.dispatch('noti',{mensaje: 'Constancia agregada', color:'success'});
                    this.$emit('reloadTable');
                    this.limpiar();             
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                    } else {
                      this.$store.dispatch('loadingPage', false);
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la constancia', color:'error'});              
                    }
                  }
                })
            }
        },
        verificarAnio() {
            let tieneConst = this.aniosConstancia.includes(this.potabilidad.anio);
            if(tieneConst) {
                this.alert = true;                
            } else {
                this.alert = false;
                this.$v.potabilidad.anio.$touch()
            }
        },
        limpiar() {
            this.$v.potabilidad.$reset();
            this.$v.archivos.$reset();
            this.potabilidad = {};
            this.archivos = [];
            this.alert = false;
            this.dialog = false;
        },
    },
}
</script>