<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            small
            exact-active-class="indigo"
            class="ma-2"
            v-on="{ ...tooltip, ...on }"
            @click="getDataModificar()"
          >mdi-pencil</v-icon>
        </template>
        <span>Modificar</span>
      </v-tooltip>
    </template>
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Actividad" @cancelar="cancelar"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-select
              :items="actividades"
              v-model="actividad.tipoActividad"
              :error-messages="tipoErrors"             
              @change="$v.actividad.tipoActividad.$touch()"
              @blur="$v.actividad.tipoActividad.$touch()"
              required                            
              label="Tipo de Actividad"
            ></v-select>
          </v-col>
          <v-col v-if="isOtro" cols="12" md="12" class="pt-0 pb-0">
              <v-textarea
                  rows="3"
                  label="Nombre Actividad"
                  counter="100"
                  v-model="otro"
                  :error-messages="otroErrors"             
                  @change="$v.otro.$touch()"
                  @blur="$v.otro.$touch()"          
                  required
              ></v-textarea>
          </v-col>
          <v-col cols="12" md="12 ">
            <v-textarea
              rows="3"
              label="Descripción"
              counter="500"
              v-model="actividad.descripcion"
              :error-messages="descErrors"
              @change="$v.actividad.descripcion.$touch()"
              @blur="$v.actividad.descripcion.$touch()"
              required
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="12 ">
            <v-textarea
              rows="3"
              label="Temas Tratados"
              counter="500"
              v-model="actividad.temasTratados"
              :error-messages="temasErrors"
              @change="$v.actividad.temasTratados.$touch()"
              @blur="$v.actividad.temasTratados.$touch()"
              required
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Cantidad de Personas"
              v-model="actividad.cantidadPersonas"
              v-mask="mask"
              hint="Ingresar solo números"
              :error-messages="cantErrors"
              @change="$v.actividad.cantidadPersonas.$touch()"
              @blur="$v.actividad.cantidadPersonas.$touch()"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Cantidad de Actividades"
              v-model="actividad.cantidadActividades"
              v-mask="mask"
              hint="Ingresar solo números"
              :error-messages="cantAcErrors"             
              @change="$v.actividad.cantidadActividades.$touch()"
              @blur="$v.actividad.cantidadActividades.$touch()"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-select
              label="Participantes"
              :items="participantes"
              item-text="nombre"
              item-value="id"
              multiple
              chips
              v-model="listParticipantes"
              :error-messages="ParticipanteErrors"             
              @change="$v.listParticipantes.$touch()"
              @blur="$v.listParticipantes.$touch()"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="12">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaMoment"
                  label="Fecha de la Actividad"
                  prepend-inner-icon="mdi-calendar-month"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="actividad.modalidad"
                row>
                <template v-slot:label>
                    <div class="text-md-h6">Modalidad</div>
                </template>
                <v-radio
                    v-for="m in modalidades"
                    :key="m.id"
                    :label="m.nombre"
                    :value="m.id"
                ></v-radio>
              </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
        <v-btn color="#0B3F67" :loading="loading" text @click="modificarActividad">Modificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/axios.js";
import { required, email, maxLength, requiredIf } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import moment from "moment";
import Evidencia from "@/components/utils/AgregarEvidenciaComponent";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  directives: {
    mask
  },
  components: {
    Evidencia,
    ModalTitle
  },
  props: ["id"],
  created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  },  
  computed: {
    fechaMoment() {
      return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
    },
    tipoErrors() {
      const errors = [];
      if (!this.$v.actividad.tipoActividad.$dirty) return errors;
      !this.$v.actividad.tipoActividad.required &&
        errors.push("Campo requerido");
      return errors;
    },
    descErrors() {
      const errors = [];
      if (!this.$v.actividad.descripcion.$dirty) return errors;
      !this.$v.actividad.descripcion.required && errors.push("Campo requerido")
      !this.$v.actividad.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
      return errors;
    },
    cantErrors() {
      const errors = [];
      if (!this.$v.actividad.cantidadPersonas.$dirty) return errors;
      !this.$v.actividad.cantidadPersonas.required &&
        errors.push("Campo requerido");
      return errors;
    },
    cantAcErrors () {
      const errors = []
      if (!this.$v.actividad.cantidadActividades.$dirty) return errors
      !this.$v.actividad.cantidadActividades.required && errors.push('Campo requerido')
      return errors
    },
    usuario(){
      return this.$store.getters.usuario;
    },
    temasErrors(){
      const errors = [];
      if (!this.$v.actividad.temasTratados.$dirty) return errors;
      !this.$v.actividad.temasTratados.required && errors.push("Campo requerido")
      !this.$v.actividad.temasTratados.maxLength && errors.push('No debe exceder el tamaño permitido.')
      return errors;
    },
    isOtro(){
      return this.actividad.tipoActividad === "Otro" ? true : false;
    },
    otroErrors(){
      const errors = []
      if(!this.$v.otro.$dirty) return errors
      !this.$v.otro.required && errors.push('Dato requerido')
      return errors
    },
    ParticipanteErrors () {
      const errors = []
      if (!this.$v.listParticipantes.$dirty) return errors
      !this.$v.listParticipantes.required && errors.push('Campo requerido')
      return errors
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    menu: false,
    mask: "####",
    actividad: {
      tipoActividad: "",
      descripcion: "",
      cantidadPersonas: "",
      cantidadActividades: "",
      temasTratados:"",
      modalidad: 0
    },
    fechaActividad: new Date().toISOString().substr(0, 10),
    archivos:[],
    actividades:[
            "Capacitacion",
            "Charla",
            "Cine foro",
            "Comunicaciones",
            "Congreso",
            "Conversatorio",
            "Curso",
            "Feria de ambiente y salud",
            "Foro",
            "Información audiovisual y/o escrita complementada con preguntas generadoras, encuestas o debates",
            "Mesa redonda",
            "Taller",
            "Otro"
            ],
    otro : "",
    participantes: [],
    listParticipantes: [],
    modalidades: [],
  }),
  validations: {
    actividad: {
      tipoActividad: {
        required
      },
      descripcion: {
        maxLength: maxLength(500),
        required
      },
      cantidadPersonas: {
        required
      },
      cantidadActividades: {
        required
      },
      temasTratados: {
        maxLength: maxLength(500),
        required
      }
    },
    otro: {
      required: requiredIf(function(){
          return this.actividad.tipoActividad === "Otro" ? true : false;
        })
    },
    listParticipantes: {
      required
    },
  },
  methods: {
    getDataModificar() {
      this.loading = true;
      axios
        .get("/educacionAmbiental/getDataModificar/" + this.id)
        .then(response => {
          this.actividad = response.data.actividad;
          this.actividad.modalidad = this.actividad.modalidad.id;
          this.participantes = response.data.participantes;
          this.listParticipantes = response.data.participantesAct;
          this.modalidades = response.data.modalidades;
          if(this.actividades.includes(response.data.actividad.tipoActividad)){
            this.actividad.tipoActividad = response.data.actividad.tipoActividad;
          }else{
            this.otro = response.data.actividad.tipoActividad;
            this.actividad.tipoActividad = "Otro";
          }
          this.fechaActividad = this.dateFormat(response.data.actividad.fechaActividad);
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    modificarActividad() {
        this.$v.$touch();
        if(!this.$v.$invalid) {
          let participantesStrg = this.listParticipantes.toString();
            this.loading = true;
            if(this.actividad.tipoActividad === "Otro"){
              this.actividad.tipoActividad = this.otro;
            }
            this.actividad.indicador = this.actividad.indicador.id;
            delete this.actividad.docEvidencia
            axios.put("/educacionAmbiental/modificarActividad", this.actividad, {
                params: {
                    fechaActividad: this.fechaActividad,idUsuario: this.usuario.id,
                    participantes: participantesStrg
                }
            }).then(response => {
                this.$store.dispatch("noti", {mensaje: "Actividad de educación modificada",color: "success"});
                this.$emit("reload");
                this.$emit('reloadAn');
                this.cancelar();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.$store.dispatch("noti", { mensaje: "Ocurrió un error no se modificó la actividad de educación", color: "error"});
                console.log(error);
                this.loading = false;
              }
            });
    }
     },
    dateFormat(fechaActividad) {
      return new Date(fechaActividad).toISOString().substr(0, 10);
    },
    cancelar() {
      this.actividad = {};
      this.$v.actividad.$reset();
      this.dialog = false;
      this.loading = false;
    }
  }
};
</script>