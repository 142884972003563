<template>
    <v-tooltip top>
        <template v-slot:activator="{on}">
            <v-btn color="#0B3F67" @click="descargarDocumento()" dark v-on="on">Descargar Manual<v-icon right>mdi-download</v-icon></v-btn>
        </template>
        Descagar
    </v-tooltip>
</template>
<script>
import axios from "@/axios";
export default {
    props: [
        'documentoId'
    ],
    methods: {
        descargarDocumento() {
            axios({
                url: `/documento/descargar/${this.documentoId}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
    }
}
</script>