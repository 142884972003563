<template>
    <v-container fluid>
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPricipal titulo="Verificación del No Vertido de Sustancias Peligrosas"/>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="5" sm="5" md="2" lg="2" align-self="center" class="text-right">
                        <v-row>
                            <span>Gestiona Sustancias Peligrosas</span>
                        </v-row>                                
                        <v-row>
                            <v-radio-group v-model="instalacion.gestionaResiduos"
                                @change="activaGestionResiduos">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group>
                        </v-row>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="9">
                        <v-card>
                            <tituloSimple titulo="Historial de Evidencias"/>
                            <v-card-text>
                                <v-data-table
                                    :headers="headerEvi"
                                    :items="evidencias"
                                    item-key="id">
                                    <template v-slot:item.acciones="{ item }">                                                                                
                                      <eliminar-evidencia @eliminaEvidencia="getDataSustancias" :sustancia="item" :usuario="usuario" :parametroActual="parametroActual"/>
                                    </template>  
                                    <template v-slot:item.id="{ item }">                                                                                
                                      <tablaDocs :id="item.id" origen="vertidoSustancias"/>
                                    </template>                            
                                </v-data-table>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>                                
                                <agregarEvidencia
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" 
                                    @reload="getDataSustancias"/>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>              
            </v-col>
        </v-row>
        <v-row v-if="instalacion.gestionaResiduos">
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Inventario de Productos Peligrosos"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"                                                                      
                            item-key="id"                        
                            :search="search">
                            <template v-slot:item.action="{ item }">                                                                
                                <agregarSustancia 
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                    @reload="getDataSustancias" tipoModal="Modificar" :sustanciaModif="item" icon="mdi-pencil" iconColor="#D59804"/>
                            </template>                        
                        </v-data-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <agregarSustancia 
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                            @reload="getDataSustancias" tipoModal="Agregar" icon="mdi-plus-thick" iconColor="#0B3F67"/>
                    </v-card-actions>
                </v-card>
            </v-col>            
        </v-row>
    </v-container>
</template>
<script>

import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPricipal from '@/components/utils/TituloPrincipalComponent'
import TablaDocs from '@/components/documentos/ListadoDocumentosComponent'
import EliminarEvidencia from '@/components/parametros/AguasResiduales/EliminarEvidComponent'
import AgregarEvidencia from '@/components/parametros/AguasResiduales/AgregarEvidenciaComponent'
import AgregarSustancia from '@/components/parametros/AguasResiduales/AgregarSustanciaComponent'
import axios from "@/axios.js"

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    mounted() {
        this.getDataSustancias();        
        this.gestionaResiduos = this.instalacion.gestionaResiduos;
    },
    props: [
        'navParams'
    ],
    components: {
        TituloPricipal,
        AgregarSustancia,
        NavParameters,
        TituloSimple,
        AgregarEvidencia,
        TablaDocs,
        EliminarEvidencia
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        deshabilitado(){
            return (this.$store.getters.usuario.rol != 'CEO' && this.$store.getters.usuario.rol != 'GERENTE' && this.$store.getters.usuario.rol != 'SUPERVISOR') != true ? false : true;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
    },
    data: () => ({
        headers: [
          {
            text: 'Acciones',
            value: 'action',
            align: 'center',
            width: '10%',
            sortable: false
          },
          {
            text: 'Sustancia',
            value: 'nombreSustancia',
            align: 'center',
            width: '10%',
            sortable: true
          },
          {
            text: 'Clasificacion SGA',
            value: 'clasificacionSgaName',
            align: 'center',
            width: '10%',
            sortable: true
          },
          {
            text: 'Tipo Sustancia',
            value: 'tipoSustanciaName',
            align: 'center',
            width: '10%',
            sortable: true
          },
          {
            text: 'Lugar Almacenamiento',
            value: 'almacenamiento',
            align: 'center',
            width: '10%',
            sortable: true
          },
          {
            text: 'Encargado',
            value: 'gestor',
            align: 'center',
            width: '10%',
            sortable: true
          },
          {
            text: 'Tratamiento',
            value: 'tratamientoName',
            align: 'center',
            width: '10%',
            sortable: true
          },
        ],
        headerEvi: [
          {
            text: 'Acciones',
            value: 'acciones',
            align: 'center',
            width: '10%',
            sortable: false
          },
          {
            text: 'Año',
            value: 'anio',
            align: 'center',
            width: '10%',
            sortable: true
          },
          {
            text: 'Evidencias',
            value: 'id',
            align: 'center',
            width: '10%',
            sortable: true
          }
        ],
        evidencias: [],
        items: [],
        search: null,
        gestionaResiduos: false
    }),
    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);            
        },
        getDataSustancias() {            
            let instalacion = this.instalacion.id;
            axios.get("/tratamientoAgua/getSustancias/" +instalacion)
            .then(response => {                                
                this.items = response.data.sustancias;
                this.evidencias = response.data.evidencias;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        activaGestionResiduos() {
            this.loadingModal(true);
            axios.put("/instalaciones/gestionaResiduos", null, {params: {
                idInstalacion: this.instalacion.id,
                gestionaResiduos: this.instalacion.gestionaResiduos,
                usuario: this.usuario.id
            }}).then(response => {
                this.loadingModal(false);
                this.$store.commit('sucursal', response.data)
                this.$store.dispatch('noti', {mensaje: 'La instalación se modificó', color: 'success'})
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la instalación', color: 'error'})
              }
            })
        }
    }
}
</script>