<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="tituloPrincipal"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <TablaInventario ref="inventario"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import axios from "@/axios"
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import NavParameters from "@/components/utils/NavParameterComponent"
import TituloPrincipal from "@/components/utils/TituloPrincipalComponent"
import TablaInventario from "@/components/parametros/ContaminantesAtmosfericos/InventarioContaTablaComponent"

export default {
    components: {
        TituloPrincipal,
        NavParameters,
        TablaInventario,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    data: () => ({                             
        tituloPrincipal: 'Inventario de Gases Refrigerantes',
    }),
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
    },
    methods: {
        getData() {
          this.$refs.inventario.listaInventario();
        }
    }
}
</script>