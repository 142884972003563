<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getDataInstalaciones()">
                        mdi-account-group
                    </v-icon>
                </template>
                <span>Configurar Comité Ambiental</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Configurar Comité Ambiental" @cancelar="cancelar"/>
            </v-card-title>           
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-card flat>
                        <v-card-title>
                            {{ usuario.nombre }} {{ usuario.apellido }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pb-0">
                            <div>Seleccione el año de la instalación a la que desea asociar al usuario</div>
                        </v-card-text>
                        <v-card-text class="pt-0">
                            <v-virtual-scroll
                                :items="instalaciones"
                                :item-height="100"
                                bench="1"
                                height="300">
                                <template v-slot:default="{ item }">
                                <v-card>
                                    <v-card-title class="text-md-subtitle-2 text-xs-h6">
                                        {{ item.nombre }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-slide-group
                                            v-model="selectedData"
                                            multiple
                                            show-arrows>
                                            <v-slide-item
                                                v-for="anio in item.aniosInscripcion" 
                                                    :key="anio"
                                                    v-slot="{ active, toggle }"
                                                    :value="`${item.id}-${anio}`">
                                                <v-btn
                                                    class="mx-2"
                                                    :input-value="active"
                                                    active-class="teal lighten-1 white--text"
                                                    depressed
                                                    rounded
                                                    @click="toggle">
                                                    {{ anio }}
                                                </v-btn>
                                            </v-slide-item>
                                        </v-slide-group>
                                    </v-card-text>  
                                </v-card>
                            </template>
                            </v-virtual-scroll>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn 
                    color="#0B3F67" 
                    :loading="loading" 
                    @click="configurarComite" 
                    text>
                    Modificar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
export default {
    props: [
        'usuario'
    ],
    components: {
      ModalTitle
    },
    mounted() {
      this.anio = this.anioConsulta      
    },
    data: () => ({
        dialog: false,
        instalacionesSeleccionadas: [],
        loading: false,
        instalaciones: [],
        anio: null,
        anios: [],
        selectedData: []
    }),
    computed: {
        colsSize () {
            return this.superAdmin ? 6 : 12;
        },
        superAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN'
        },
        nombreCompleto() {
            return this.usuario.nombre + ' ' + this.usuario.apellido;
        },
        org() {
            return !this.superAdmin ? this.$store.getters.organizacion : this.usuario.organizacion;
        },
        user() {
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
    },
    methods: {
        getDataInstalaciones() {
            this.loading = true;
            axios.get("/instalaciones/getInstalacionUsuario/"+this.usuario.id)
            .then(response => {
                this.instalaciones = response.data.instalaciones;
                this.selectedData = response.data.selectedData
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        configurarComite() {
            this.loading = true;
            let idUsuario = this.usuario.id;
            axios.post(`/instalaciones/agregarUsuarioComite/${idUsuario}`,this.selectedData, {params: {
                usuarioLogged: this.user.id
            }})
            .then(response => {
                this.loading = false;
                this.$store.dispatch('noti',{mensaje: 'Comité ambiental modificado', color:'success'});
                this.cancelar();
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
                } else {
                console.log(error);
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el comité ambiental', color:'error'});
                this.loading = false;
                }
            })
        },
        cancelar() {
            this.dialog = !this.dialog;
            this.loading = false;
        }
    },
}
</script>