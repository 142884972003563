<template>
    <v-dialog
        v-model="dialog"
        scrollable 
        persistent 
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon color="#0B3F67" medium v-on="{...tooltip, ...dialog}">mdi-delete-alert</v-icon> 
                </template>
                Disposición final
            </v-tooltip>   
        </template>
        <v-card>
            <v-card-title class="pa-0">
                <ModalTitle titulo="Agregar Residuo" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="text-md-h6">{{ residuo.nombre }}</div>
                <v-select
                    :items="items"
                    v-model="disposicion.disposicion"
                    label="Disposición final"
                    :error-messages="disposicionErrors"  
                    @change="$v.disposicion.disposicion.$touch()"
                    @blur="$v.disposicion.disposicion.$touch()"
                ></v-select>
                <v-text-field
                    label="Gestor Autorizado"
                    v-model="disposicion.gestor"
                    counter="300"
                    :error-messages="gestorErrors"  
                    @change="$v.disposicion.gestor.$touch()"
                    @blur="$v.disposicion.gestor.$touch()"
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregar">Agregar</v-btn>
            </v-card-actions>  
        </v-card>
    </v-dialog>
</template>
<script>
import axios from '@/axios'
import { required, maxLength } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    components: {
        ModalTitle
    },
    props: [
        'residuo'
    ],
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        gestorErrors(){
            const errors = []
            if (!this.$v.disposicion.gestor.$dirty) return errors
            !this.$v.disposicion.gestor.required && errors.push('Campo requerido')
            !this.$v.disposicion.gestor.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        disposicionErrors(){
            const errors = []
            if (!this.$v.disposicion.disposicion.$dirty) return errors
            !this.$v.disposicion.disposicion.required && errors.push('Campo requerido')
            !this.$v.disposicion.disposicion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors            
        },
    },
    data: () => ({
        items: [
            'Biodigestores',
            'Compost',
            'Recolector privado',
            'Recolector público',
            'Otro'
        ],
        disposicion: {
            gestor: null,
            disposicion: null,
            anio: null
        },
        gestor: null,
        dialog: false,
        loading: false
    }),
    validations: {
        disposicion: {
            gestor: {
                required,
                maxLength: maxLength(300),
            },
            disposicion: {
                required,
                maxLength: maxLength(300),
            }
        }
    },
    methods: {
        agregar() {
            this.$v.$touch();
            if(!this.$v.$invalid){
                this.loadingModal(true);
                this.disposicion.anio = this.anioConsulta;
                axios.post('/gestionResiduos/agregarDisposicionFinal', this.disposicion, { params: {
                    residuo: this.residuo.id
                }}).then(() => {            
                    this.$store.dispatch('noti',{mensaje: 'Disposición agregada', color:'success'});   
                    this.$emit('reload');
                    this.loadingModal(false);
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti',{mensaje: 'Error agregar la disposición', color:'error'});   
                    console.error(error);
                    this.loadingModal(false);
                  }
                })
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        cancelar() {
            this.dialog = false;
            this.$v.disposicion.$reset();
            this.disposicion = {
                gestor: null,
                disposicion: null,
                anio: null
            }
        }
    },
}
</script>