var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5","lg":"3","xl":"3"}},[_c('usuarioAgregar',{on:{"recargaTabla":_vm.buscarUsuarios}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-sheet',{staticClass:"v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center",attrs:{"height":"80","color":"#0B3F67","elevation":"12","max-width":"calc(100% - 32px)"}},[(_vm.isAdmin)?_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_vm._v(" Catálogo de Usuarios ")]):_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_vm._v(" Datos de Usuarios ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"multi-sort":"","search":_vm.search,"headers":_vm.headers,"items":_vm.usuarios,"loading":_vm.cargando,"loading-text":"Cargando...","no-results-text":"Dato no encontrado","footer-props":{
                        showFirstLastPage: true,
                        firstIcon:'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageAllText: 'Todos',
                        pageCount: 12,                       
                        showCurrentPage: true,                            
                    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('modificarUsuario',{attrs:{"idUsuario":item.id},on:{"reloadEdit":_vm.buscarUsuarios}}),(item.nombreRol == 'Comité Ambiental' && item.estado)?_c('ComiteAmbiental',{attrs:{"usuario":item},on:{"reload":_vm.buscarUsuarios}}):_vm._e()],1)]}},{key:"item.estado",fn:function(ref){
                    var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('activarUsuario',{attrs:{"estado":item.estado,"id":item.id},on:{"reload":_vm.buscarUsuarios}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }