<template>
    <v-dialog
        v-model="dialog"
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon class="mr-2" v-on="{...tooltip, ...on}" dark color="#ffb605">mdi-pencil</v-icon>
                </template>
                <span> Modificar Documento </span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Seleccione un Documento" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-file-input label="Seleccione un Archivo"
                    accept=".png, .jpeg, .jpg"
                    v-model="newDocumento"
                    show-size                                
                    clearable
                    :error-messages="docErrors"             
                    @change="$v.newDocumento.$touch()"
                    @blur="$v.newDocumento.$touch()">
                </v-file-input> 
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarDoc" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";
//Custom validations
const maxFileSize = (file) => file != null && file.size < 1000000

export default {
    props: [
        'documento', //debe ser el id del documento
        'inst'
    ],
    components: {
      ModalTitle
    },
    computed: {
        docErrors () {
            const errors = []
            if (!this.$v.newDocumento.$dirty) return errors            
            !this.$v.newDocumento.required && errors.push('El documento es requerido.')
            !this.$v.newDocumento.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')        
            return errors
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        newDocumento: null
    }),
    validations: {
        newDocumento: {
            required,
            maxFileSize
        },
    },
    methods: {
        modificarDoc() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('archivo', this.newDocumento);
                formData.append('documento', this.documento);
                if (this.instalacion == null) {
                    formData.append('inst', this.inst);
                } else {
                    formData.append('inst', this.instalacion.id);
                }
                formData.append('user', this.usuario.id);
                formData.append('indicador', this.indicadorActual);
                axios.put('/documento/modificarDocumento', formData)
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Documento modificado', color: 'success'});
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el documento', color: 'error'});
                      this.loading = false;
                    }
                  }
                })
            }
        },
        cancelar() {
            this.newDocumento = null;
            this.$v.newDocumento.$reset();
            this.dialog = false;
            this.loading = false;
        }
    }
}
</script>