<template>
  <v-menu offset-x offset-overflow open-on-hover>
    <template v-slot:activator="{ on, attrs }">
        <v-icon 
          v-bind="attrs"
          v-on="on"
          dark
          color="primary">
          mdi-information-variant
        </v-icon>
    </template>
    <v-sheet
      rounded="m"
      class="d-flex"
      color="blue lighten-4"
      elevation="7"
      width="500">
      <div class="pa-2">{{ mensaje }}</div>
    </v-sheet>
  </v-menu>
</template>
<script>
export default {
  props: [
    'mensaje',
    'offset-x',
    'offset-y'
  ]
}
</script>