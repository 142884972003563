<template>
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" color="#0B3F67">Aceptar</v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Advertencia" @cancelar="userSelection('cancelar')"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="title">
          <strong class="title">¡Advertencia!</strong><br>
          <div class="subtitle-1">Se modificarán los parámetros de cumplimiento para
          las instalaciones con<strong> Categoria {{ categoria }}  e inscripción legal activa </strong> para
          el año en curso <strong> ( {{ anioEncurso }} ). </strong><br>
          ¿Desea continuar?</div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="userSelection('cancelar')" text color="error">Cancelar</v-btn>
        <v-btn @click="userSelection('aceptar')" text color="#0B3F67">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  components: {
    ModalTitle
  },
  props: [
    'categoria',
    'anioEncurso'
  ],
  data: () => ({
    dialog: false
  }),
  methods: {
    userSelection(seleccion) {
      this.$emit('userSelection', seleccion);
    }
  },
}
</script>