<template>
    <v-container class="fill-height" fluid>
        <v-row class="pt-2">
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <v-card hover>
                    <v-sheet class="v-sheet--offset mx-auto pt-2" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
                        <v-list-item>
                            <v-list-item-content>        
                                <v-list-item-title class="white--text headline text-center">Huella Carbono</v-list-item-title>
                                <v-list-item-subtitle class="white--text subtitle text-center">{{ new Date().getFullYear() }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-sheet> 
                    <v-card-text>
                        <graficoDona :chartdata="chartData" :options="options"/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="8" xl="8">
                <v-card hover>
                    <v-sheet class="v-sheet--offset mx-auto pt-2" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
                        <v-list-item>
                            <v-list-item-content>        
                                <v-list-item-title class="white--text headline text-center">Organizaciones</v-list-item-title>
                                <v-list-item-subtitle class="white--text subtitle text-center">{{ new Date().getFullYear() }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>  
                    </v-sheet> 
                    <v-card-text>
                        <mapa v-bind:dataMarkers="organizaciones"/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col  cols="12" sm="12" md="12" lg="4" xl="4">
                <v-card hover>
                    <v-sheet class="v-sheet--offset mx-auto pt-2" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
                        <v-list-item>
                            <v-list-item-content>        
                                <v-list-item-title class="white--text headline text-center">Huella Hidrica</v-list-item-title>
                                <v-list-item-subtitle class="white--text subtitle text-center">{{ new Date().getFullYear() }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-sheet> 
                    <v-card-text>
                        <polarChart :chartdata="chartData" :options="options"/>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <tablaDashboad v-bind:items="desserts" v-bind:titulo="titulo2" v-bind:subtitulo="subtitulo" v-bind:act="subtitulo"  v-bind:ant="anterior"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <tablaDashboad v-bind:items="desserts" v-bind:titulo="titulo" v-bind:subtitulo="subtitulo" v-bind:act="subtitulo"  v-bind:ant="anterior"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import GraficoDona from '@/components/graficos/DoughnutChartComponent'
import PolarChart from '@/components/graficos/PolarChartComponent'
import TablaDashboad from '@/components/dashboard/DashboardTablaComponent'
import Mapa from '@/components/graficos/MapaComponent'

export default {
    components: {
        GraficoDona,
        PolarChart,
        Mapa,
        TablaDashboad,
    },
    data: () => ({
        chartData: {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
            datasets: [{
                label: '# of Votes',
                data: [12, 19, 30, 25, 20, 43],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
         options: {
            legend: {
                position: 'right'
            },
            responsive: true,
            aspectRatio: 1,
            animation: {
                duration: 2000
            }
        },
        organizaciones: [
            {title: 'Plaza Viquez', latitud: 9.940240, longitud: -84.098097, tipo: 1, marker: ''},
            {title: 'Arkkosoft', latitud: 9.924379, longitud: -84.072448, tipo: 2},
            {title: 'Eprom', latitud: 9.921420, longitud: -84.069424, tipo: 1},
            {title: 'Musmanni', latitud: 9.918673, longitud: -84.049056, tipo: 2},
            {title: 'City Mall', latitud: 10.008631, longitud: -84.215591, tipo: 1},
            {title: 'ICE', latitud: 9.612211, longitud: -84.041463, tipo: 1},
            {title: 'Hotel Herradura', latitud: 9.667830, longitud: -84.663819, tipo: 1},
        ],
        desserts: [
          {
            name: 'A',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'B',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'C',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'D',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'E',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'F',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'G',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'H',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'I',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
          {
            name: 'J',
            ene: 159,
            feb: 20,
            mar: 199,
            abr: 387,
          },
        ],
        titulo: 'Huella Hidrica',
        titulo2: 'Huella Carbono',
        subtitulo: '2019',
        anterior: '2018'
    })
}
</script>
<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>