<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="400px"
        transition="dialog-transition"
        >
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn large class="white--text" color="#0B3F67" v-on="{ ...tooltip, ...dialog }">{{ tituloBtn }}</v-btn>
                </template>
                {{tituloBtn}}
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="tituloBtn" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field
                    label="Residuo"
                    v-model="medidor.medidor"
                    :error-messages="medidorErrors"             
                    @change="$v.medidor.medidor.$touch()"
                    @blur="$v.medidor.medidor.$touch()"
                    required
                    hint="Nombre del resiudo"
                ></v-text-field>
                <v-select
                    :items="tiposDeResiduos"
                    item-text= "nombre"
                    item-value="valor"
                    v-model="tipoDeResiduo"
                    :error-messages="tipoResiduoErrors"             
                    @change="$v.tipoDeResiduo.$touch()"
                    @blur="$v.tipoDeResiduo.$touch()"
                    required
                    label="Tipo del Residuo"
                ></v-select>                  
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarMedidor">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>

<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";
import axios from "@/axios.js"

export default {
    props:['indicador'],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        indicadorActual(){
            return parseInt(this.$store.getters.indicadorActual);
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        tituloBtn(){
            return "Nuevo Residuo";
        },
        medidorErrors () {
            const errors = []
            if (!this.$v.medidor.medidor.$dirty) return errors            
            !this.$v.medidor.medidor.required && errors.push('El residuo es necesario.')                   
            return errors
        },
        tipoResiduoErrors(){
            const errors = []
            if(!this.$v.tipoDeResiduo.$dirty) return errors
            !this.$v.tipoDeResiduo.required && errors.push('Debe elegir un tipo de residuo.')
            return errors
        }
    },
    data: ()=>({
        dialog: false,
        disabled: false,
        loading: false,
        tiposDeResiduos: [
            {nombre: 'VALORIZABLE', valor: true},
            {nombre: 'NO VALORIZABLE', valor: false}
        ],
        medidor: {
            medidor: '',
        },
        tipoDeResiduo: '',
    }),
    methods:{
        agregarMedidor(){
            this.$v.$touch();
            if(!this.$v.$invalid){
                this.loading = true;
                axios.post("/instalaciones/agregarMedidorResiduos", this.medidor, {params:{
                    idInstalacion : this.$store.getters.sucursal.id,
                    tipoResiduo : this.tipoDeResiduo,
                    user: this.usuario.id
                }}).then(response=>{
                    this.$store.dispatch('noti',{mensaje: 'Medidor agregado', color:'success'});
                    this.$emit('agregarMedidor');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agreagar el medidor " + error);
                    this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se agregó el medidor', color:'error'}); 
                    this.$emit('agregarMedidor');
                    this.cancelar();
                  }
                })  
            }
        },
        cancelar(){
            this.dialog = false;
            this.$v.medidor.$reset();
            this.$v.tipoDeResiduo.$reset();
            this.disabled=false;
            this.loading = false;
            this.medidor = {};
            this.tipoDeResiduo = '';
        }
    },
    validations:{
        medidor: {
            medidor:{required}
        },
        tipoDeResiduo : {
            required
        }, 
    }
}
</script>