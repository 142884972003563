<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">  
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon small exact-active-class="indigo" class="ma-2" v-on="{ ...tooltip, ...on }"     
                        @click="getDataEnlace()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>          
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Parámetro de Medición" @cancelar="cancelar"/>
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-row>
                       <v-col cols="12">
                            <v-text-field
                                item-text="nombre"
                                v-model="instalacion"
                                label="Instalación"             
                                disabled
                            ></v-text-field>
                        </v-col>    
                        <v-col cols="12">
                            <v-text-field
                                v-model="indicador"
                                item-text="nombre"
                                label="Indicador"
                                disabled
                            ></v-text-field>  
                        </v-col>
                        <v-col cols="12">
                            <v-select                            
                                :items="eficiencias"
                                item-text="nombre"
                                item-value="id"
                                v-model="eficiencia"
                                label="Eficiencia"               
                                :error-messages="eficienciaErrors"             
                                @change="$v.eficiencia.$touch()"
                                @blur="$v.eficiencia.$touch()"
                                required
                            ></v-select>
                        </v-col>        
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarEnlace" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>

import axios from '@/axios';
import {required, decimal} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    props: [
        'idInstEfic',
    ],
    computed: {
        eficienciaErrors() {
            const errors = []
            if (!this.$v.eficiencia.$dirty) return errors            
            !this.$v.eficiencia.required && errors.push('La eficiencia es requerida.')            
            return errors
        },
    },
    data: () => ({
        dialog: false,
        enlace: {},
        eficiencias: [],
        instalacion: '',
        eficiencia: '',
        indicador: '',
        loading: false,
    }),
    validations: {
        eficiencia:{
            required
        },
    },
    methods: {
        getDataEnlace() {
            this.loading = true;
            axios.get("/instalacionEficiencia/getDataModificar/"+this.idInstEfic)
            .then(response => {
                this.enlace = response.data.InstalacionEficienciaEnlace;
                this.indicador = this.enlace.indicadorNombre;
                this.instalacion = this.enlace.instalacionNombre;
                this.eficiencia = response.data.eficiencia;
                this.eficiencias = response.data.eficiencias;
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },        
        modificarEnlace() {
            this.$v.$touch();
            let enlaceMod = this.enlace;
            delete enlaceMod.eficienciaNombre;
            delete enlaceMod.eficienciaDescripcion;
            delete enlaceMod.indicadorNombre;
            delete enlaceMod.instalacionNombre;
            if(!this.$v.$invalid) { 
                this.loading = true;
                let formData = new FormData();
                formData.append("idEficiencia", this.eficiencia)
                formData.append("enlaceS", JSON.stringify(this.enlace));
                axios.put("/instalacionEficiencia/modificar/", formData)
                .then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Parámetro de Medición modificado', color:'success'});      
                    this.$emit('recargaEditar');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el Parámetro de Medición', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        cancelar() {
            this.enlace = {};
            this.instalaciones = [];
            this.enlaces = [];
            this.instalacion = '';
            this.enlace = '';
            this.indicador = '';
            this.dialog = !this.dialog;  
            this.loading = !this.loading;                 
            this.$v.eficiencia.$reset();
        },
    },
}
</script>