<template>
    <v-dialog
        v-model="dialog"
        scrollable 
        persistent 
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon v-on="{ ...dialog, ...tooltip }" color="#0B3F67">mdi-file-edit-outline</v-icon>                 
                </template>
                Modificar
            </v-tooltip>            
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Cuestionario" @cancelar="cancelar"/>
            </v-card-title>
            <v-card-text>
                <v-file-input
                    accept=".pdf" 
                    show-size 
                    counter 
                    v-model="archivo" 
                    label="Agregar nuevo Cuestionario"
                    :error-messages="evidErrors"
                    @change="$v.archivo.$touch()"
                    @blur="$v.archivo.$touch()">
                </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
                <v-btn @click="modificar" text color="#0B3F67">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import { required } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'

export default {
    props: [
        'cuestionario'
    ],
    components: {
      ModalTitle
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        evidErrors() {
            const errors = []
            if (!this.$v.archivo.$dirty) return errors            
            !this.$v.archivo.required && errors.push('Por favor adjuntar Cuestionario.')            
            return errors
        },
    },
    data: () => ({
        archivo: null,
        dialog: false
    }),
    validations: {
        archivo: {
            required
        }
    },
    methods: {
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                let formData = new FormData();
                formData.append('archivo', this.archivo);
                formData.append('cuestionario', this.cuestionario);
                axios.post('/cuestionario/modificar', formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Cuestionario modificado', color:'success'});
                    this.loadingModal(false);
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti',{mensaje: 'No se pudo modificar el cuestionario', color:'error'});
                    this.loadingModal(false);
                  }
                })
            }
        },
        cancelar() {
            this.$v.archivo.$reset();
            this.archivo = null
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>