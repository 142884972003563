<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn class="white--text" v-on="{...tooltip, ...dialog}" color="#0B3F67">Agregar Sustitución</v-btn>
                </template>
                Nueva Sustitución
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Sustitución" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-select
                            :items="anios"
                            v-model="productoSust.anio"
                            :error-messages="anioErrors"
                            label="Año que aplica la sustitución"   
                            @change="getProductosSost(), $v.productoSust.anio.$touch()"       
                            @blur="$v.productoSust.anio.$touch()"                  
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field                            
                            label="Producto para Sustituir"
                            v-model="productoSust.nombre"
                            :error-messages="nomErrors"
                            @change="$v.productoSust.nombre.$touch()"       
                            @blur="$v.productoSust.nombre.$touch()" 
                            counter="200"
                            hint="Nombre del producto que va a ser sustituido"                   
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-select
                            :items="prodSostenibles"
                            v-model="produtoSost"
                            :error-messages="produtoSostErrors"
                            item-text="nombre"
                            item-value="id"
                            label="Producto Sostenible"         
                            @change="$v.produtoSost.$touch()"
                            @blur="$v.produtoSost.$touch()"                                               
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">                    
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="evidencias"
                            label="Agregar Evidencias"
                            :error-messages="evidErrors"
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col> 
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregarProductoSust" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import ModalTitle from '@/components/utils/ModalTitleComponent'
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    props: [
        'anios'
    ],
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        nomErrors() {
            const errors = []
            if (!this.$v.productoSust.nombre.$dirty) return errors            
            !this.$v.productoSust.nombre.required && errors.push('Dato requerido.')   
            !this.$v.productoSust.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')   
            return errors
        },
        anioErrors() {
            const errors = []
            if (!this.$v.productoSust.anio.$dirty) return errors            
            !this.$v.productoSust.anio.required && errors.push('El año de la evidencia es requerido.')            
            return errors
        },
        evidErrors() {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        produtoSostErrors() {
            const errors = []
            if (!this.$v.produtoSost.$dirty) return errors            
            !this.$v.produtoSost.required && errors.push('Seleccionar un producto sustituto.')            
            return errors
        }
    },
    data: () => ({
        dialog: false,
        productoSust: {
            nombre: '',
            anio: '',
        },        
        prodSostenibles: [],
        produtoSost: null,
        evidencias: null
    }),
    validations: {
        productoSust: {
            nombre: {
                maxLength: maxLength(200),
                required
            },
            anio: {
                required
            }
        },
        produtoSost: {
            required
        },
        evidencias: {
            required,
            maxFileSize
        }
    },
    methods: {
        getProductosSost() {
            let instalacion = this.instalacion.id;
            let anio = this.productoSust.anio;
            this.loadingModal(true);
            axios.get(`/comprasSostenibles/getProductosSosteniblesInstAnio/${instalacion}/${anio}`)
            .then(response => {
                this.prodSostenibles = response.data.productos
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              }
            }).finally(() => {
                this.loadingModal(false);
            })
        },
        agregarProductoSust() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                let formData = new FormData();
                formData.append('producto', JSON.stringify(this.productoSust));
                formData.append('usuario', this.usuario.id);
                formData.append('productoSostenible', this.produtoSost);
                this.evidencias.forEach(file => {
                    formData.append('evidencias', file);
                });
                axios.post("/comprasSostenibles/agregarSustitucionProducto", formData)
                .then(response => {
                    this.$emit("reload");
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Sustitución agregada', color: 'success'});
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                    } else {
                      this.loadingModal(false);
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se guardó la sustitución', color: 'error'});                    
                    }
                  }
                })
            }
        },
        cancelar() {
            this.productoSust = {};
            this.produtoSost = null;
            this.evidencias = null;
            this.$v.productoSust.$reset();
            this.$v.produtoSost.$reset();
            this.$v.evidencias.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>