<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
         <v-card :loading="loading">
             <v-card-title class="pa-0">
               <ModalTitle titulo="Agregar Sector Productivo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Nombre"
                        v-model="productivo.nombre"
                        :error-messages="nameErrors"
                        @input="$v.productivo.nombre.$touch()"
                        @blur="$v.productivo.nombre.$touch()"
                        required>
                        </v-text-field>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-textarea label="Descripción"
                        v-model="productivo.descripcion"
                        @input="$v.productivo.nombre.$touch()"
                        @blur="$v.productivo.nombre.$touch()"
                        counter="100"
                        hint="Campo Opcional"
                        outlined
                        shaped>
                        </v-textarea>
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarSector">Agregar</v-btn>
            </v-card-actions>
         </v-card>      
    </v-dialog>    
</template>

<script>
import axios from "@/axios.js";
import {required} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.productivo.nombre.$dirty) return errors            
            !this.$v.productivo.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    data: () => ({
        dialog: false,
        loading: false,
        productivo: {
            nombre: '',
            //descripcion: '',    
        },        
    }),
    validations: {
        productivo: {  
            nombre: {
                required
            },
        }
    },
    methods:{
        cancelar() {
            this.productivo = {};
            this.loading = false;
            this.dialog = false;            
            this.$v.productivo.$reset();
        },
        agregarSector() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;               
                axios.post("/sector/agregarSector", this.productivo).then(response => {                    
                    this.$emit('recargaTabla');
                    this.$store.dispatch('noti',{mensaje: 'Sector productivo agregado', color:'success'});                
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar el sector productivo: " + error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el sector productivo', color:'error'});
                    this.cancelar();
                  }
                })
            }
        }
    },
}
</script>