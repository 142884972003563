<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="tituloIndicador"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ReduccionTabla ref="reduccionRes" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    
    import Logo from '@/components/utils/CompanyComponent'
    import VueSticky from 'vue-sticky'
    import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
    import NavParameters from '@/components/utils/NavParameterComponent'
    import ReduccionTabla from '@/components/parametros/ResiduosValorizables/AhorroResiduosComponent';

    export default {
    components:{
        TituloPrincipal,
        NavParameters,
        ReduccionTabla,
        Logo
    },
    props: [
        'navParams'
    ],
    directives: {
        'sticky': VueSticky,
    },
    computed:{
        tituloIndicador(){
            return "Consumo y Reducción de Residuos Valorizables / No Valorizables";
        }
    },
    mounted() {        
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    data:()=>({
        tipos:[{valor: true}, {valor:false}]
    }),
    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getData() {
            this.$refs.reduccionRes.getDataReduccion();
        }
    },
}

</script>