<template>
    <v-container fluid>        
        <IntalacionTabla></IntalacionTabla>
    </v-container>
</template>

<script>
import IntalacionTabla from "@/components/instalaciones/TipoInstalacionesTablaComponent";
export default {
    components: {
        IntalacionTabla
    },    
}
</script>