<template>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        no-results-text="No hay registros"
        :footer-props="{
            showFirstLastPage: true,
            firstIcon:'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageText: 'Registros por página',
            itemsPerPageAllText: 'Todos',
            pageCount: 12,                       
            showCurrentPage: true,                            
        }">
        <template v-slot:item.action="{ item} ">
            <modificarPlanta @reload="reload" :id="item.id"/>
            <v-tooltip top>
            <template v-slot:activator="{ on }" >                                        
                <v-icon v-on="on" color="#0B3F67" @click="borrarAccion(item.id)">mdi-trash-can-outline</v-icon>
            </template>
            Eliminar
        </v-tooltip>
        </template>
        <template v-slot:item.sistema="{ item }">
            {{ tipoSistema(item.sistema) }}
        </template>
        <template v-slot:item.propia="{ item }">
            {{ sistemaPropio(item.propia )}}
        </template>
    </v-data-table>
</template>
<script>

import ModificarPlanta from '@/components/parametros/AguasResiduales/ModificarTratAguaComponent'
import axios from '@/axios.js'

export default {
    props: [
        'items',
        'search'
    ],
    components: {
        ModificarPlanta
    },

    computed:{
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
    },
    
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Año',
                value: 'anio',
                align: 'center',
                width: '10%',
                sortable: true
            },
            {
                text: 'Tipo Sistema',
                value: 'sistema',
                align: 'center',
                width: '20%',
                sortable: true
            },
            {
                text: 'Descripción',
                value: 'descripcion',
                align: 'left',
                width: '45%',
                sortable: true
            },
            {
                text: 'Planta Propia',
                value: 'propia',
                align: 'center',
                width: '15%',
                sortable: true
            },
        ]
    }),
    methods: {
        tipoSistema(tipo) {
            switch(tipo) {
                case 1:
                    return 'Planta de Tratamiento';
                case 2:
                    return 'Tanque séptico y drenaje';
                case 3:
                    return 'Alcantarillado sanitario';
            }
        },
        sistemaPropio(propio) {
            return propio ? 'Si': 'No'; 
        },
        reload() {
            this.$emit('reload');
        },
        borrarAccion(id) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar la acción de reducción y control?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    this.cargando = true;
                    let formData = new FormData();
                    formData.append('idInstalacion', this.instalacion.id);
                    formData.append('idUsuario', this.usuario.id);
                    formData.append('anio',this.anioConsulta);
                    axios.post("/instalaciones/borrarTipoSistema/",formData).then(response => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Tipo de Sistema eliminado",
                            color: "success"
                        });
                        this.cargando = false;
                        this.$emit('reload');
                        })
                        .catch(error => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Ocurrió un error no se eliminó el Tipo de Sistema eliminado",
                            color: "error"
                        });
                        console.log(error);
                        this.cargando = false;
                        });
                }
            })
        },
    }
}
</script>