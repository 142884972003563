<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="550px" transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-btn @click="getDataAgregar" class="white--text" color="#0B3F67" v-on="on">
        Agregar
        <v-icon right>mdi-plus-box-outline</v-icon>
      </v-btn>
    </template>
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <ModalTitle titulo="Agregar Equipo" @cancelar="cancelar"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field 
              label="Tipo de Consumidor"
              v-model="equipo.tipoConsumidor"
              :error-messages="tipoErrors"
              @change="$v.equipo.tipoConsumidor.$touch()"
              @blur="$v.equipo.tipoConsumidor.$touch()"
              counter="50"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pt-0">
            <v-textarea 
              label="Descripción"
              counter="500"
              :error-messages="descripErrors"
              @change="$v.equipo.descripcion.$touch()"
              @blur="$v.equipo.descripcion.$touch()"
              v-model="equipo.descripcion"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
            label="Cilindrada"
            v-model="equipo.cilindrada"
            v-mask="maskCilindrada"
            :error-messages="cilindradaErrors"
            @change="$v.equipo.cilindrada.$touch()"
            @blur="$v.equipo.cilindrada.$touch()"
            required            
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
            label="Placa"
            :error-messages="placaErrors"
            @change="$v.equipo.numPlacaActivo.$touch()"
            @blur="$v.equipo.numPlacaActivo.$touch()"
            v-model="equipo.numPlacaActivo"
            counter="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
              label="Num Activo"
              v-model="equipo.numActivo"
              :error-messages="numActivoErrors"
              @change="$v.equipo.numActivo.$touch()"
              @blur="$v.equipo.numActivo.$touch()"
              counter="50"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
            label="Marca"
            v-model="equipo.marca"
            :error-messages="marcaErrors"
            @change="$v.equipo.marca.$touch()"
            @blur="$v.equipo.marca.$touch()"
            counter="50"
            required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
              label="Modelo"
              v-model="equipo.modelo"
              :error-messages="modeloErrors"
              @change="$v.equipo.modelo.$touch()"
              @blur="$v.equipo.modelo.$touch()"
              counter="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
            label="Año"
            v-model="equipo.anio"
            v-mask ="mask"
            :error-messages="anioErrors"
            @change="$v.equipo.anio.$touch()"
            @blur="$v.equipo.anio.$touch()"
            required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-select
              :items="tipoCombustibles"
              item-text="nombre"
              item-value="valor"
              v-model="tipoCombustible"
              label="Tipo Combustible"
              :error-messages="tipoCombustibleErrors"
              @change="$v.tipoCombustible.$touch()"
              @blur="$v.tipoCombustible.$touch()"
              counter="100"
              required>
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-select
              :items="unidadesConsumo"
              item-text="nombre"
              item-value="valor"
              v-model="equipo.unidadMedidaConsumo"
              label="Medida Consumo"  
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-select
              :items="unidadesCosto"
              item-text="nombre"
              item-value="valor"
              v-model="equipo.unidadCostoRecibo"
              label="Unidad de Costo"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-text-field 
              label="Responsable de Uso"
              v-model="equipo.responsableUso"
              :error-messages="responErrors"
              @change="$v.equipo.responsableUso.$touch()"
              @blur="$v.equipo.responsableUso.$touch()"
              counter="100"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
        <v-btn color="#0B3F67" :loading="loading" text @click="agregarEquipo">Agregar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/axios.js";
import { required, maxLength } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { mask } from "vue-the-mask"

export default {
  directives: {
    mask,
  },
  components: {
    ModalTitle
  },
  created() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
  },
  data: () => ({
    dialog: false,
    loading: false,
    equipo: {
        estado: '',
        tipoConsumidor: '',
        descripcion:'',
        numPlacaActivo:'',
        marca:'',
        modelo:'',
        anio:'',
        capacidad:'',        
        responsableUso:'',
        unidadMedidaConsumo:'',
        unidadCostoRecibo:'',
        cilindrada:'',
        numActivo: '',
        anioRegistro: null
    },
    tipoCombustible:'',
    unidadesCosto: [
      {nombre: 'COLONES', valor: 1}, 
      {nombre: 'DOLARES', valor: 2}
    ],
    unidadesConsumo: [
      {nombre:'LITROS', valor: 2},              
      {nombre:'GALONES', valor: 3},
    ],
    tipoCombustibles:[],
    mask: '####',
    maskCilindrada: '#######'
  }),
  computed:{
    anioConsulta() {
        return this.$store.getters.anioConsulta;
    },
    sucursal() {
        return this.$store.getters.sucursal;
    },
    usuario(){
        return this.$store.getters.usuario;
    },
    tipoErrors () {
        const errors = []
        if (!this.$v.equipo.tipoConsumidor.$dirty) return errors
        !this.$v.equipo.tipoConsumidor.required && errors.push('Campo requerido')
        !this.$v.equipo.tipoConsumidor.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    numActivoErrors () {
        const errors = []
        if (!this.$v.equipo.numActivo.$dirty) return errors
        !this.$v.equipo.numActivo.required && errors.push('Campo requerido')
        !this.$v.equipo.numActivo.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    marcaErrors () {
        const errors = []
        if (!this.$v.equipo.marca.$dirty) return errors
        !this.$v.equipo.marca.required && errors.push('Campo requerido')
        !this.$v.equipo.marca.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    anioErrors () {
        const errors = []
        if (!this.$v.equipo.anio.$dirty) return errors
        !this.$v.equipo.anio.required && errors.push('Campo requerido')
        return errors
    },        
    cilindradaErrors () {
        const errors = []
        if (!this.$v.equipo.cilindrada.$dirty) return errors
        !this.$v.equipo.cilindrada.required && errors.push('Campo requerido')
        return errors
    },
    tipoCombustibleErrors () {
        const errors = []
        if (!this.$v.tipoCombustible.$dirty) return errors
        !this.$v.tipoCombustible.required && errors.push('Campo requerido')
        !this.$v.tipoCombustible.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    descripErrors() {
        const errors = []
        if (!this.$v.equipo.descripcion.$dirty) return errors            
        !this.$v.equipo.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    placaErrors() {
        const errors = []
        if (!this.$v.equipo.numPlacaActivo.$dirty) return errors            
        !this.$v.equipo.numPlacaActivo.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    modeloErrors() {
        const errors = []
        if (!this.$v.equipo.modelo.$dirty) return errors            
        !this.$v.equipo.modelo.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
    responErrors() {
        const errors = []
        if (!this.$v.equipo.responsableUso.$dirty) return errors            
        !this.$v.equipo.responsableUso.maxLength && errors.push('No debe exceder el tamaño permitido.')
        return errors
    },
  },
  validations:{
    equipo:{
      tipoConsumidor:{
        maxLength: maxLength(50),
        required
      },
      numActivo:{
        maxLength: maxLength(50),
        required
      },
      marca:{
        maxLength: maxLength(50),
        required
      },
      anio:{
        required
      },
      cilindrada:{
        required
      },
      modelo: {
        maxLength: maxLength(50),
      },
      responsableUso: {
        maxLength: maxLength(100),
      },
      descripcion: {
        maxLength: maxLength(500),
      },
      numPlacaActivo: {
        maxLength: maxLength(50),
      }
    },
    tipoCombustible:{
      maxLength: maxLength(100),
      required
    }
  },
  methods:{
    getDataAgregar() {
      axios.get("/inventarioConsumidores/getDataAgregar")
      .then(response => {
        this.tipoCombustibles = response.data;
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        }
      })
    },
    agregarEquipo(){
      this.$v.$touch();
      if(!this.$v.$invalid){
        this.loading = true;
        this.equipo.anioRegistro = this.anioConsulta;
        this.equipo.estado = true;
        axios.post("/inventarioConsumidores/agregar/", this.equipo, {params:{
          usuario: this.usuario.id,
          instalacion: this.sucursal.id,
          tipoCombustible: this.tipoCombustible,
          anio: this.anioConsulta,
        }}).then(response => {
          this.$store.dispatch('noti',{mensaje: 'Equipo agregado', color:'success'});
          this.$emit("reload");
          this.cancelar();
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            this.loading = false;
            this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se agregó el equipo', color:'error'}); 
          }
        })
      }
    },

    cancelar(){
      this.$v.equipo.$reset();
      this.equipo = {};
      this.tipoCombustible = '';
      this.$v.tipoCombustible.$reset();
      this.dialog = false;
      this.loading = false;
    }
  }
};
</script>