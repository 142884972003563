<template>
    <v-dialog
        v-model="dialog"       
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">  
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"     
                        @click="getDataCadenas()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>          
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Cadena de Suministros" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" >
                            <v-text-field                              
                                label="Nombre"
                                v-model="cadenas.nombre"
                                :error-messages="nameErrors"                                
                                required
                            ></v-text-field>
                        </v-col>                                             
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarCadenas" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>
import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import {required} from "vuelidate/lib/validators";

export default {
    props: [      
      'idCadenas'    
    ],
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.cadenas.nombre.$dirty) return errors            
            !this.$v.cadenas.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        cadenas: {
            cadenas: '',           
            estado: 0,
        },
        loading: false
        
    }),
    validations: {
        cadenas: 
        {
            nombre: 
            {
                required,               
             },
        }
           
    },
    methods: {          
         getDataCadenas() {
            this.loading = true;
            axios.get("/cadenas/getDataCadenas/"+this.idCadenas).then(response => {
                this.cadenas = response.data.cadenas;                       
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarCadenas() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/cadenas/modificarCadena/"+this.idCadenas, this.cadenas)                
                .then(response => {
                    this.loading = false;
                    this.dialog = false;                    
                    this.$store.dispatch('noti',{mensaje: 'Cadena de suministros modificada', color:'success'});
                    this.$emit('reloadEdit');                    
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la cadena de suministros', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },             
        cancelar() {
            this.cadenas = {};
            this.dialog = !this.dialog;           
            this.$v.cadenas.$reset();            
        },
    },
}
</script>