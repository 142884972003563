<template>    
    <v-switch :loading="loading" inset @change="activarEquipo" color="#0B3F67" v-model="estadoActual"></v-switch>    
</template>

<script>

import axios from "@/axios.js";

export default {
    props: [
        'estado',
        'id'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    computed: {
        estadoActual: {
            get() {
                return this.estado;
            },
            set(estado) {
                this.nuevoEstado = estado;
            }
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        nuevoEstado: null,
        loading: false
    }),
    methods:{
        activarEquipo(){
            let invState;
            this.loading = true;
            axios.put("/inventarioConsumidores/activarDeactiva/",null,{params:{
                id:this.id,
                invState: this.nuevoEstado,
                idUsuario: this.usuario.id,
                anio: this.anioConsulta
            }}).then(response=>{
                this.loading = false;
                this.$emit('reload');
                this.$store.dispatch('noti',{mensaje: 'Estado modificado', color:'success'});
            }).catch(error => {
            if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el estado', color:'error'})                
              }
            })
        }
    }
    
}
</script>