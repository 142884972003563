<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="50%"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon @click="setData" class="mr-2" v-on="{...tooltip, ...on}" :color="color">{{ icon }}</v-icon>
                </template>
                <span>{{ tipoModal }}</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="5" align-self="center" class="text-right subtitle-1">
                        <span>Año de Particpación</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                        <v-select
                            :items="anios"
                            :disabled="!tipoModificar"
                            v-model="tipoSistema.anio"
                            label="Año de Participación"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="plantaTrat || tanqueSep">
                    <v-col cols="5" align-self="center" class="text-right subtitle-1">
                        <span v-if="plantaTrat">Descripción del Sistema de Tratamiento</span>
                        <span v-if="tanqueSep">Descripción de la Operación y Mantenimiento</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                        <v-textarea
                            :disabled="!tipoModificar"
                            label="Descripción"
                            outlined
                            counter="500"
                            v-model="tipoSistema.descripcion"
                            :error-messages="descripErrors"             
                            @change="$v.tipoSistema.descripcion.$touch()"
                            @blur="$v.tipoSistema.descripcion.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>
                 <!-- Para cuando hay Planta de Tratamiento -->
                <div v-if="plantaTrat">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Cumple con la Legislación</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-radio-group v-model="tipoSistema.legislacion" 
                                hint="Indique si cumple la legislacion"
                                :disabled="!tipoModificar">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group> 
                        </v-col>
                    </v-row>
                </div>
                <div v-if="tanqueSep">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Se realizó limpieza de lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-radio-group v-model="tipoSistema.limpiezaLodos"
                                :disabled="!tipoModificar">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group> 
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" color="#0B3F67" v-if="tipoModal === 'Ver Detalle'" text @click="cancelar">Cerrar</v-btn>
                <v-btn :disabled="loading" color="error" v-if="tipoModal === 'Modificar'" text @click="cancelar">Cancelar</v-btn>
                <v-btn :loading="loading" v-if="tipoModal === 'Modificar'" color="#0B3F67" text @click="modificarParametro">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>  
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators";
import axios from "@/axios.js";

export default {
    mounted() {
        this.getAnioParticipacion();
    },
    props: [
        'sistemaActual',
        'tipoModal',
        'data',
        'icon',
        'color'
    ],
    components: {
      ModalTitle
    },
    computed: {
        titulo() {
            return this.tipoModificar ? 'Modificar Planta de Tratamiento' : 'Detalle Planta de Tratamiento';
        },
        plantaTrat() {
            return this.sistemaActual.sistema === 1 ? true : false;
        },
        tipoModificar() {
            return this.tipoModal === 'Modificar' ? true : false;
        },
        tanqueSep() {
            return this.sistemaActual.sistema === 2 ? true : false;
        },
        alcantarillado() {
            return this.sistemaActual.sistema === 3 ? true : false;
        },
        descripErrors () {
            const errors = []
            if (!this.$v.tipoSistema.descripcion.$dirty) return errors            
            !this.$v.tipoSistema.descripcion.required && errors.push('La descripción es requerida.')     
            !this.$v.tipoSistema.descripcion.maxLength && errors.push('Este dato excede el tamaño permitido.')        
            return errors
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        tipoSistema: {
            get() {
                return this.tipoSistemaAg;
            },
            set(newData) {
                this.tipoSistemaAg = newData;
            }
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        tipoSistemaAg: {
            anio: null,
            descripcion: null,
            legislacion: false,
            limpiezaLodos: false
        },
        anios: [],
    }),
    validations: {
        tipoSistema: {
            descripcion: {
                maxLength: maxLength(500),
                required,               
            },
        },
    },
    methods: {
        modificarParametro() {
            this.$v.$touch();
            if(!this.$v.$invalid) {   
                this.loading = true;             
                axios.put("/instalaciones/modificarTipoSistema", this.tipoSistema)
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {
                        mensaje: 'Planta de tratamiento modificada', 
                        color: 'success'
                    });
                    this.cancelar();
                }).catch(error => { 
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la evidencia', color: 'error'});
                    this.cancelar();
                  }
                })
            }
        },
        cancelar() {
            this.tipoSistema = {
                descripcion: '',
                legislacion: false,
                limpiezaLodos: false
            };
            this.loading = false;
            this.$v.tipoSistema.$reset();
            this.dialog = false;
        },
        setData() {
            this.tipoSistema = this.data;
        },
        getAnioParticipacion() {
            let id = this.instalacion.id    ;
            axios.get("/instalaciones/getAniosParticipacion/" + id
            ).then(response => {
                this.anios = response.data.anios;                
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los años de parrticipacion: " + error);
              }
            })
        },
    }
}
</script>