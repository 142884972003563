<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="tituloIndicador"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ReduccionTabla ref="reduccion" v-if="!isContaAtmos"/>
                <ReduccionContaAtmosTabla ref="contAtmos" v-if="isContaAtmos"/>
            </v-col>
        </v-row>
    </v-container>    
</template>
<script>

import ReduccionTabla from "@/components/parametros/AhorroConsumoComponent"
import ReduccionContaAtmosTabla from "@/components/parametros/ContaminantesAtmosfericos/AhorroConsContaAtmosComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    components: {
        ReduccionTabla,
        ReduccionContaAtmosTabla,
        TituloPrincipal,
        NavParameters,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed: {
        isContaAtmos(){
            return parseInt(this.$store.getters.indicadorActual) === 7;
        },
        tituloIndicador() {
            let indicadorActual = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            switch(indicadorActual) {
                case constantes.indicadorAgua:
                    return "Reducción Consumo de Agua";
                    break;
                case constantes.indicadorEnergia:
                    return "Reducción Consumo de Electricidad";
                    break;
                case constantes.indicadorCombustibles:
                    return "Reducción Consumo de Combustibles";
                    break;
                case constantes.indicadorContamiAdmosf:
                    return "Reducción Consumo de Gases Refrigerantes";
                    break;
                case constantes.indicadorResiduos:
                    return "Reducción Consumo de Papel"
                    break;
            }
        }
    },
    mounted() {        
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getData() {
            if(this.isContaAtmos) {
                this.$refs.contAtmos.getDataReduccion();
            } else {
                this.$refs.reduccion.getDataReduccion();
            }
        }
    },
}
</script>