<template>
    <v-container fluid>  
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="'Actividad de Sensibilización Significativa y Divulgación en Bienestar Animal'"/>
            </v-col>
        </v-row>
        <p></p>

        <p><strong>*</strong>Recuerde llenar el formulario para participar en la estrella <a href= "https://docs.google.com/forms/d/e/1FAIpQLSd7Lu6JXsRQcTQEe6rfMaOPgi-oeVt_rL_EYi0QyZcdwUmR2g/viewform" target="_blank" > Click</a>.</p>
       
    
        
        <EstrellaTabla ref="tablaEstrella" :titulo="'Actividad de Sensibilización Significativa y Divulgación en Bienestar Animal'" :headers="headers" :tipo="tipo"></EstrellaTabla>
    </v-container>
</template>

<script>
import EstrellaTabla from "@/components/Estrellas/EstrellaTablaComponent";
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'

export default {
    components: {
        EstrellaTabla,
        TituloPrincipal,
    },
    props: [
        'tipo',
    ],
    data: () => ({
        headers: [
            { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
            { text: 'Actividad', value: 'actividad', align: 'center', sortable: true, filterable: true, },
            { text: 'Incluida en formulario', value: 'incluidaString', align: 'center', sortable: true, filterable: true, }
        ]
    }),
    methods: {
        getData() {
            this.$refs.tablaEstrella.listaEstrellas();
        }
    }
};
</script>