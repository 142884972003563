<script>
    import { Bar, Line, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins

    export default {
        mixins: [Bar, Line, reactiveProp],
        props: ['options'],
        mounted () {
            // this.chartData is created in the mixin.            
            this.renderChart(this.chartData, this.options)
        }
    }
</script>