<template>
    <v-dialog
        v-model="dialog" 
        persistent    
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                    
                    <v-btn @click="getDescripcion" v-on="{ ...tooltip, ...dialog }" large dark icon><v-icon color="#0B3F67">mdi-pencil</v-icon></v-btn>
                </template>
                Modificar Programa
            </v-tooltip>                            
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Programa" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="anios"
                            v-model="data.anio"
                            disabled
                            label="Año de Consulta"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-textarea
                            label="Descripción del programa"
                            v-model="descripcion"
                            hint="Descripción del programa o Criterios de Compras Sostenibles"
                            counter="500"
                            :error-messages="descrErrors"             
                            @change="$v.descripcion.$touch()"
                            @blur="$v.descripcion.$touch()"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input label="Adjuntar Programa"
                            accept=".png, .jpg, .jpeg"
                            v-model="programa"
                            show-size                                
                            clearable
                            :error-messages="programaErrors"             
                            @change="$v.programa.$touch()"
                            @blur="$v.programa.$touch()">>
                        </v-file-input>
                    </v-col>
                </v-row>                
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificar">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>           
</template>
<script>

import { required, maxLength } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from '@/axios.js';
//Custom validations
const maxFileSize = (file) => {
    if(file != null) {
        return file.size < 1000000 ? true : false;
    }
    return true;
}

export default {
    props: [
        'data',
        'anios'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        programaErrors () {
            const errors = []
            if (!this.$v.programa.$dirty) return errors            
            !this.$v.programa.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        descrErrors () {
            const errors = []
            if (!this.$v.descripcion.$dirty) return errors            
            !this.$v.descripcion.required && errors.push('Dato Requerido.')
            !this.$v.descripcion.maxLength && errors.push('No debe superar el Tamaño máximo.')
            return errors
        }
    },
    data: () => ({
        dialog: false,        
        programa: null,
        descripcion: null,
        loading: false,
    }),
    validations: {
        programa: {            
            maxFileSize
        },
        descripcion: {
            required,
            maxLength: maxLength(500)
        }
    },
    methods: {
        cancelar() {
            this.programa = null;
            this.descripcion = null,
            this.$v.programa.$reset();   
            this.$v.descripcion.$reset();          
            this.dialog = false;
            this.loading = false;
        },
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('archivo', this.programa);
                formData.append('descripcion', this.descripcion);
                formData.append('user', this.usuario.id);
                formData.append('programa', this.data.id);
                axios.put("/comprasSostenibles/modificarPrograma", formData)
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Programa modificado', color: 'success'});
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el programa', color: 'error'});
                      this.loading = false;
                    }
                  }
                })
            }
        },
        getDescripcion() {
            this.descripcion = this.data.descripPrograma;
        }
    },
}
</script>