<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="listarEducaActividadesCompesacion" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Educación Ambiental Externa"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3" align-self="center">
                <agregarEducacionActividad 
                    @reload="listarEducaActividadesCompesacion" 
                    @agregarEducacionActividad="agregarEducacionActividad" 
                    v-bind:instalacion="instalacion"
                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
            <v-col cols="6" md="2" offset-md="7">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-card>
            <tituloSimple titulo="Educación Ambiental Externa"/>
            <v-divider></v-divider>
            <v-card-text>
                <EducacionCompesacionTabla :search="search" v-bind:headers="headers" v-bind:educacion="data" @reload="listarEducaActividadesCompesacion"/>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import EducacionCompesacionTabla from "@/components/parametros/ActividadCompesacion/EducacionCompesacionTabla"
import AgregarEducacionActividad from "@/components/parametros/ActividadCompesacion/AgregarEducacionExternaComponent"
import axios from "@/axios.js";

export default {

    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    props: [
        'navParams'
    ],
    computed: {
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        }
    },
    directives: {
        'sticky': VueSticky,
    },
    components: {
        NavParameters,
        EducacionCompesacionTabla,
        AgregarEducacionActividad,
        TituloPrincipal,
        TituloSimple,
        Logo
    },
    data: () => ({
        search: null,
        data: [],
        headers: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
            },
            {
                text: "Actividad Compesación",
                value: "actividad",
                align: "start",
            },
            {
                text: "Actividad Educación",
                value: "actividadEducacion",
                align: "start",
            },
            {
                text: "Fecha de la Actividad",
                value: "fechaActividad",
                align: "center",
            },
            {
                text: "Personas beneficiadas",
                value: "cant_beneficiados",
                align: "center",
            },
            {
                text: "Evidencia",
                value: "evidencia",
                align: "center",
            },
        ]
    }),
    mounted () {
        this.listarEducaActividadesCompesacion();
    },
    methods: {
        agregarEducacionActividad(educacion) {
            this.data.push(educacion);
        },
        listarEducaActividadesCompesacion(){
            let idInstalacion = this.instalacion.id;
            let year = this.anioConsulta;
            axios.get("/actividadCompesacion/listarEducaActividadesCompesacion/"+idInstalacion,{params:{year: year}}).then(response => {
                this.data = response.data.educacion;
                this.items = response.data.years;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },  
    },
}
</script>