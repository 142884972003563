<template>
  <v-container>
    <v-row>
      <v-carousel
        cycle
        hide-delimiter-background
        hide-delimiters
        height="550"
        show-arrows>
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src">
          <v-row class="fill-height" :align="item.align" :justify="item.justify">
            <v-col :cols="item.cols">
              <div v-if="item.title == 'Zurquí'" class="pl-15 pt-6 pb-2 text-background">
                <v-img max-width="140" max-height="70" src="@/assets/iconos/logoszurqui/blancoT50_logo_zurqui.png"></v-img>
              </div>
              <div v-else class="pl-15 pt-6 text-background-title">
                  {{ item.title }}
              </div>
              <div class="pl-15 pr-15 text-background">{{ item.text }}</div>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    //imagenes del carrusel. 
    items:[
      { 
        src: require('@/assets/DSC_001.png'), 
        title: 'Zurquí', 
        text: 'Palabra de origen indígena, se relaciona con una fuente u ojo de agua.',
        cols: 12,
        justify: 'start',
        align: 'center'
      },
      { 
        src: require('@/assets/DSC_1563_02.png'), 
        title: 'La mariposa ', 
        text: 'Es un símbolo universal de transformación, su diseño evoca la tecnología como aliado del cambio que las organizaciones necesitan.', 
        cols: 12,
        justify: 'start',
        align: 'center'
      },
      { 
        src: require('@/assets/DSC_0092_02.png'), 
        title: 'Los colores', 
        text: 'Simbolizan los 3 pilares de la sostenibilidad.',
        cols: 12,
        justify: 'start',
        align: 'center'
      },
      { src: require('@/assets/DSC_1577_02.png'), 
        title: 'Seamos', 
        text: 'EFICIENTES, RESISTENTES Y SOSTENIBLES',
        cols: 12,
        justify: 'start',
        align: 'center'
      },
    ],
  })
}
</script>
<style>
@font-face {
    font-family: "futura";
    src: url("~@/assets/fonts/Futura Condensed ExtraBold/Futura Condensed ExtraBold.ttf");
  }
  .text-background {
    background-color: rgba(0, 0, 0, 0.3);
    font-family: 'futura';
    color: #ffffff;
    font-size: 26px;
  }
  .text-background-title {
    background-color: rgba(0, 0, 0, 0.3);
    font-family: 'futura';
    color: #ffffff;
    font-size: 35px;
  }
</style>