<template>
    <v-container fluid>
        <Ayuda></Ayuda>             
    </v-container>
</template>
<script>
import Ayuda from "@/components/Informativo/AyudaComponent";
export default {
    components: {
        Ayuda,       
    },    
}
</script>