<template>
    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{on:dialog}">
            <v-tooltip top>
                <template v-slot:activator="{on:tooltip}">
                    <v-icon color="#0B3F67" medium v-on="{...tooltip, ...dialog}">mdi-trash-can-outline</v-icon>
                </template>
                Eliminar
            </v-tooltip>    
        </template>
        <v-card>
        <v-card-title class="pa-0">
          <ModalTitle titulo="Eliminar Acción Adatación" @cancelar="cancelar"/>
        </v-card-title>
        <v-card-text>
            <div class="pt-4">
            ¿Desea eliminar la acción {{ evento.acciones }}?<br>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
            <v-btn @click="eliminar" text color="#0B3F67">Aceptar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from '@/axios'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'evento',
        'Instalacion',
        'anioConsulta'
    ],
    components: {
      ModalTitle
    },
    computed:{
        usuario(){
            return this.$store.getters.usuario;
        },
        parametroActual(){
            return this.$store.getters.parametroActual
        }
    },
    data:() =>({
        dialog: false
    }),
    methods:{
        cancelar(){
            this.dialog = !this.dialog
        },
        eliminar(){
            this.loadingModal(true);
            axios.post('/adaptacion/eliminarAccion/', null,{params:{
                idEvento: this.evento.id,
                usuario: this.usuario.id,
                inst: this.Instalacion.id,
                anioConsulta: this.anioConsulta
            }}).then(response =>{
                this.$store.dispatch('noti', {mensaje: 'Acción Adaptación eliminada', color: 'success'});
                this.$emit('reload');
                this.loadingModal(false);
            }).catch(error =>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.$store.dispatch('noti', {mensaje: 'Error al eliminar la Acción Adaptación', color:'error'});
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal){
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>
