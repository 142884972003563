<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            small
            class="mr-2"
            v-on="{ ...tooltip, ...on }"
            @click="getDataEficiencia()"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Modificar</span>
      </v-tooltip>
    </template>
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Eficiencia" @cancelar="cancelar"/>
      </v-card-title>  
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              :items="parametroEficiencia"
              item-text="nombre"
              item-value="valor"
              v-model="eficiencia.nombre"
              label="Parámetro de Eficiencia"
              :error-messages="nombreErrors"
              @change="$v.eficiencia.nombre.$touch()"
              @blur="$v.eficiencia.nombre.$touch()"
              required
            ></v-select>
            <v-select
              :items="unidadesMedida"
              item-text="nombre"
              item-value="valor"
              v-model="eficiencia.descripcion"
              label="Unidad de Medida"
              :error-messages="unidadmedidaErrors"
              @change="$v.eficiencia.descripcion.$touch()"
              @blur="$v.eficiencia.descripcion.$touch()"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" color="error" @click="cancelar"
          >Cancelar</v-btn
        >
        <v-btn
          text
          :loading="loading"
          color="#0B3F67"
          @click="modificarEficiencia"
          >Modificar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators";

export default {
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$store.getters.token;
  },
  components: {
    ModalTitle
  },
  props: ["idEficiencia"],
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.eficiencia.nombre.$dirty) return errors;
      !this.$v.eficiencia.nombre.required &&
        errors.push("El Parámetro de Eficiencia es requerido.");
      return errors;
    },
    unidadmedidaErrors() {
      const errors = [];
      if (!this.$v.eficiencia.descripcion.$dirty) return errors;
      !this.$v.eficiencia.descripcion.required &&
        errors.push("Por favor agregue la unidad de medida.");
      return errors;
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    eficiencia: {
      nombre: "",
      descripcion: ""
    },
    parametroEficiencia: [
      { nombre: "Población Laboral", valor: "Población Laboral" },
      { nombre: "Unidad Productivas", valor: "Unidad Productivas" },
      { nombre: "Área", valor: "Áreas" },
      { nombre: "Ventas", valor: "Ventas" }    
    ],
    unidadesMedida: [
      { nombre: "Cantidad de Personas", valor: "Cantidad de Personas" },
      { nombre: "Unidades de Masas", valor: "Unidades de Masas" },
      { nombre: "Números de Piezas", valor: "Números de Piezas" },
      { nombre: "Unidades de Área", valor: "Unidades de Área" },
      { nombre: "Unidad Monetaria", valor: "Unidad Monetaria" }
    ]
  }),
  validations: {
    eficiencia: {
      nombre: {
        required
      },
      descripcion: {
        required
      }
    }
  },
  methods: {
    getDataEficiencia() {
      this.loading = true;
      axios
        .get("/eficiencia/getDataEficiencia/" + this.idEficiencia)
        .then(response => {
          this.eficiencia = response.data.eficiencia;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    modificarEficiencia() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        axios
          .put(
            "/eficiencia/modificarEficiencia/" + this.idEficiencia,
            this.eficiencia
          )
          .then(response => {
            this.cancelar();
            this.$store.dispatch("noti", {
              mensaje: "Eficiencia modificada",
              color: "success"
            });
            this.$emit("reloadEdit");
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("noti", {
              mensaje: "Ocurrió un error no se modificó la eficiencia",
              color: "error"
            });
            this.cancelar();
          });
      }
    },
    cancelar() {
      this.eficiencia = {};
      this.loading = false;
      this.dialog = false;
      this.$v.eficiencia.$reset();
    }
  }
};
</script>
