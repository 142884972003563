<template>
    <v-container fluid>  
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="titulo"/>
            </v-col>
        </v-row>   
        <EstrellaTabla ref="tablaEstrella" :titulo="titulo" :headers="headers" :tipo="tipo"></EstrellaTabla>
    </v-container>
</template>

<script>
import EstrellaTabla from "@/components/Estrellas/EstrellaTablaComponent";
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'

export default {
    components: {
        EstrellaTabla,
        TituloPrincipal,
    },
    props: [
        'tipo',
    ],
    computed: {
        titulo() {
            return this.tipo === 2 ? 'Programas de Gestión o Reconocimiento' :
                this.tipo === 3 ? 'Promoción PBAE - Cadena de Valor' :
                this.tipo === 4 ? 'Promoción PBAE - Comunidad' :
                this.tipo === 5 ? 'Inventario Emisiones GEI' :
                'Certificación Carbono Neutralidad';
        },
        headers() {
            if (this.tipo === 2) return [
                { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
                { text: 'Programa', value: 'nombre', align: 'center', sortable: true, filterable: true, },
                { text: 'Descripción del Programa', value: 'descripcion', align: 'center', sortable: true, filterable: true, },
                { text: 'Fecha de ejecución', value: 'fecha', align: 'center', sortable: true, filterable: true, },
                { text: 'Evidencias', value: 'evidencias', align: 'center', sortable: false, filterable: false,},   
            ]; else if (this.tipo === 3 || this.tipo === 4) return [
                { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
                { text: 'Interesado', value: 'interesado', align: 'center', sortable: true, filterable: true, },
               // { text: 'Categroría', value: 'categoria', align: 'center', sortable: true, filterable: true, },
                { text: 'Descripción de Actividad', value: 'descripcionActividad', align: 'center', sortable: true, filterable: true, },
                { text: 'Fecha', value: 'fecha', align: 'center', sortable: true, filterable: true, },
                { text: 'Categoría PBAE', value: 'categoriaPbae', align: 'center', sortable: true, filterable: true, },
                { text: 'Número de Registro', value: 'numeroRegistro', align: 'center', sortable: true, filterable: true, },
                { text: 'Evidencias', value: 'evidencias', align: 'center', sortable: false, filterable: false,},
            ]; else if (this.tipo === 5) return [
                { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
                { text: 'Emisiones CO2', value: 'emisionCo2', align: 'center', sortable: false, filterable: false,},
                { text: 'Nombre de Reconocimiento', value: 'normativaReferencia', align: 'center', sortable: false, filterable: false,},
                //{ text: 'Otros Detalles', value: 'otrosDetalles', align: 'center', sortable: false, filterable: false,},
                { text: 'Fecha de Cuantificación', value: 'fecha', align: 'center', sortable: false, filterable: false,},
                { text: 'Evidencias', value: 'evidencias', align: 'center', sortable: false, filterable: false,},
            ]; else return [
                { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
                { text: 'Organismo de Validación', value: 'organismoValidacion', align: 'center', sortable: false, filterable: false,},
                { text: 'Nombre de Reconocimiento', value: 'normativaReferencia', align: 'center', sortable: false, filterable: false,},
                { text: 'Fecha de Cuantificación', value: 'fecha', align: 'center', sortable: false, filterable: false,},
                { text: 'Evidencias', value: 'evidencias', align: 'center', sortable: false, filterable: false,},
            ];
        },
    },
    methods: {
        getData() {
            this.$refs.tablaEstrella.listaEstrellas();
        }
    }
};
</script>