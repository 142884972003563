<template>
    <v-container fluid>
        <orgaTabla />
    </v-container>
</template>
<script>

import OrgaTabla from "@/components/organizaciones/OrgTablaComponent";

export default {
    components: {
        OrgaTabla
    }
}
</script>