<template>
    <v-container fluid>
        <logo @cambiaAnio="getEventoClimaticos" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Acciones Realizadas ante Eventos Climáticos"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex align-center" cols="12" sm="12" md="3" v-if="anioConsulta > 2023">
                <AccionesAdaptacion @reload="getEventoClimaticos" :instalacion="instalacion" :anioConsulta="anioConsulta" :usuario="usuario"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">            
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">            
                <v-card>
                    <tituloSimple titulo="Acciones de Adaptación a Eventos Climáticos"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"                                                                                                
                            item-key="id"                        
                            :search="search"
                            no-data-text="No hay Eventos Climáticos agregados"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon:'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageText: 'Registros por página',
                                itemsPerPageAllText: 'Todos',                            
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }">
                            <template v-slot:item.action="{item}">
                                <div v-if="anioConsulta > 2023">
                                    <AgregarEvidencia
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                        @reload="getEventoClimaticos"
                                        :idActividad="item.id"
                                        origen="accionAdaptacion"
                                        tooltipText="Agregar Evidencias"
                                        titulo="Acción Adaptación"
                                    />
                                    <ModAccionesAdaptacion 
                                        @reload="getEventoClimaticos"
                                        :idEvento="item.id" 
                                        :instalacion="instalacion" 
                                        :usuario="usuario"
                                        :anioConsulta="anioConsulta"
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                    />
                                    <EliminarAccionAdaptacion
                                        @reload="getEventoClimaticos"
                                        :evento="item" 
                                        :instalacion="instalacion" 
                                        :anioConsulta="anioConsulta" 
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                    />
                                </div>
                                <div v-else>                                
                                    <AgreModfAccion 
                                        @reload="getEventoClimaticos" 
                                        tipoAccion="Modificar" 
                                        :eventoProp="item" 
                                        :instalacion="instalacion" 
                                        :usuario="usuario"
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                                </div>
                            </template>
                            <template v-slot:item.evidencia="{ item }">
                                <ListadosDocs :id="item.id" origen="accionAdaptacion" titulo="Evidencias Acción Adaptación"></ListadosDocs>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent"
import AgreModfAccion from '@/components/parametros/Adaptacion/AgrModAccionComponent'
import AccionesAdaptacion from '@/components/parametros/Adaptacion/AccionesAdaptacion'
import ModAccionesAdaptacion from '@/components/parametros/Adaptacion/ModAccionesAdaptacion'
import EliminarAccionAdaptacion from '@/components/parametros/Adaptacion/EliminarAccionAdaptacion'
import axios from '@/axios.js'

export default {
    components: {
        AgregarEvidencia,
        EliminarAccionAdaptacion,
        ModAccionesAdaptacion,
        AccionesAdaptacion,
        TituloPrincipal,
        TituloSimple,
        NavParameters,
        AgreModfAccion,
        ListadosDocs,
        Logo
    },
    props: [
        'navParams'
    ],
    directives: {
        'sticky': VueSticky,
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getEventoClimaticos();
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        headers() {
            return this.$store.getters.anioConsulta <= 2023 ? this.headers2023 : this.headers2024;
        }
    },
    data: () => ({
        search: null,
        headers2023: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Evento Climático',
                value: 'evento',
                align: 'left',
                width: '20%',
                sortable: false
            },
            {
                text: 'Impacto Ocasionado',
                value: 'impacto',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Acciones Realizadas',
                value: 'acciones',
                align: 'left',
                width: '40%',
                sortable: false
            }
        ],
        headers2024: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Acción Adaptación',
                value: 'acciones',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Descripción',
                value: 'evento',
                align: 'left',
                width: '40%',
                sortable: false
            },
            {
                text: 'Evidencias',
                value: 'evidencia',
                align: 'center',
                width: '10%',
                sortable: false
            },
            
        ],
        items: []
    }),
    methods: {
        getEventoClimaticos() {
            axios.get("/adaptacion/getEventosClimaticos/" + this.instalacion.id + "/" + this.anioConsulta)
            .then(response => {
                this.items = response.data;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>