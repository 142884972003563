<template>
    <v-dialog
        v-model="dialog"        
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                                        
                    <v-btn large dark icon v-on="{ ...tooltip, ...dialog }" @click="getTipoDeResiduos()"><v-icon color="#ffb605">mdi-pencil</v-icon></v-btn>
                </template>
                Modificar Consumo
            </v-tooltip>  
        </template>
        <v-card :loading="cargando">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Generación" @cancelar="cancelar"/>
            </v-card-title>          
            <v-divider></v-divider>            
            <v-card-text>
                <v-row>
                    <v-col cols="12">           
                        <v-select
                            :items="tiposDeResiduos"
                            item-text= "nombre"
                            item-value="valor"
                            v-model="tipoDeResiduo"
                            :error-messages="tipoDeResiduoErrors"             
                            @change="$v.tipoDeResiduo.$touch(); cambiarSeleccion()"
                            @blur="$v.tipoDeResiduo.$touch()"
                            required
                            label="Tipo del Residuo"
                        ></v-select>      
                        <v-menu
                            v-if= "seleccionado"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date"
                                    label="Mes y Año del consumo"
                                    prepend-inner-icon="mdi-calendar-month"
                                    hint="Seleccione el año y mes del registro"
                                    :error-messages="dateErrors"             
                                    @change="$v.date.$touch()"
                                    @blur="$v.date.$touch()"
                                    required
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @change="getHistorial" reactive :min="fechaMinima" :max="fechaMaxima" :allowed-dates="mesesDisponibles" locale="es-CR" type="month" @input="menu = false"></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-if= "seleccionado"
                            label="Consumo"
                            v-model="historial.consumo"
                            :error-messages="consumoErrors"
                            @blur="$v.historial.consumo.$touch()"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-if="seleccionado"
                            label="Eficiencia"
                            v-model="historial.indiceEficiencia"
                            hint="Ingresar dato de eficiencia relacionado e este indicador"
                            :error-messages="eficErrors"             
                            @change="$v.historial.indiceEficiencia.$touch()"
                            @blur="$v.historial.indiceEficiencia.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="cargando" @click="cancelar()" color="error">Cancelar</v-btn>
                <v-btn text :loading="cargando" @click="modificarHistorial()" color="#0B3F67">Modificar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
import axios from "@/axios.js";

const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}

export default {
    props: [
        'medidor',
        'anioConsulta',
        'tipo',
        'consumoAnterior'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },    
    computed: {
        fechaMinima() {
            return this.anioConsulta + '-' + '01';
        },
        fechaMaxima() {
            return this.anioConsulta + '-' + '12';
        },       
        constantes() {
            return this.$store.getters.constantes;
        },
        consumoErrors () {
            const errors = []
            if (!this.$v.historial.consumo.$dirty) return errors
            !this.$v.historial.consumo.required && errors.push('Dato requerido')
            return errors
        },
        dateErrors () {
            const errors = []
            if (!this.$v.date.$dirty) return errors
            !this.$v.date.required && errors.push('Dato requerido')
            return errors
        },
        justifErrors () {
            const errors = []
            if (!this.$v.historial.justificacion.$dirty) return errors
            !this.$v.historial.justificacion.required && errors.push('Dato requerido')
            return errors
        },
        consumoErrors () {
            const errors = []
            if (!this.$v.historial.consumo.$dirty) return errors
            !this.$v.historial.consumo.required && errors.push('Dato requerido')
            !this.$v.historial.consumo.decimal && errors.push('Solo ingrese números')
            return errors
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        eficErrors () {
            const errors = []
            if (!this.$v.historial.indiceEficiencia.$dirty) return errors
            !this.$v.historial.indiceEficiencia.required && errors.push('Dato requerido')
            !this.$v.historial.indiceEficiencia.decimal && errors.push('Solo ingrese números')
            return errors
        },
        tipoDeResiduoErrors(){
            const errors = []
            if (!this.$v.tipoDeResiduo.$dirty) return errors
            !this.$v.tipoDeResiduo.required && errors.push('Dato requerido')
            return errors
        }
    },
    data: () => ({
       dialog: false,
        historial: {
            mes: '',
            anio: '',
            consumo: '',
            indiceEficiencia: '',
        
        },
        recibo: null,
        date: '',
        modal: false,
        menu: false,
        cargando: false,
        alerta: false,        
        excedente: null,
        tipoDeResiduo:'',
        tiposDeResiduos:[],
        seleccionado: false,
        arr: [],
    }),
    validations: {
        historial: {
            consumo: { required, decimal },
            indiceEficiencia: { required, decimal },
            justificacion: { required: requiredIf(function(){
        	        return this.alerta
                })
            },
        },
        date: { required },
        recibo: { maxFileSize },
        tipoDeResiduo: {required}
    },
    methods: {
        getHistorial() {
            this.cargando = true;
            axios.get("/instalaciones/getConsumoResiduo/" +this.tipoDeResiduo + "/" + this.date.split('-')[1] + "/"+this.date.split('-')[0] + "/" +this.sucursal.id
            ).then(response => {
                this.historial.consumo = response.data.consumo;
                this.historial.indiceEficiencia = response.data.eficiencia;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al buscar el consumo " + error);
                this.$store.dispatch('noti',{mensaje: 'Error buscando el consumo', color:'error'}); 
                this.cargando = false;
              }
            })            
        },
        modificarHistorial(){
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.cargando = true;
                let fecha = this.date.split("-");
                this.historial.mes = fecha[1];
                this.historial.anio = fecha[0];
                axios.put("/instalaciones/modificarConsumoResiduo/" + this.sucursal.id, this.historial, {params:{
                    tipoResiduo: this.tipoDeResiduo
                }})
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Consumo modificado', color:'success'}); 
                    this.$emit('modificarHistorial');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al modificar el consumo " + error);
                    this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se modificó el consumo', color:'error'}); 
                    this.cargando = false;
                  }
                })
            }
        },
        cancelar() {
            this.historial = {};
            this.date = '';
            this.$v.historial.$reset();
            this.$v.date.$reset();
            this.$v.tipoDeResiduo.$reset();
            this.dialog = false;
            this.tipoDeResiduo='';
            this.seleccionado = false;
            this.cargando = false;
        },
        mesesDisponibles(val) {
            let arr=this.arr;
            return arr.includes(parseInt(val.split('-')[1]));
        },
        getTipoDeResiduos(){
            let valorizable = false;
            if (this.tipo != 1){
                valorizable = true;
            }
            axios.get(`/instalaciones/getTiposResiduos/${this.sucursal.id}`, {params:{
                esValorizable : valorizable,
                anio: this.anioConsulta
            }}).then(response=>{
                this.tiposDeResiduos = response.data.tipos;
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
              }
            })
        },
        cambiarSeleccion(){
            this.seleccionado = true;
            this.getMesesModificar();
        },
        getMesesModificar(){
            axios.get("/instalaciones/getMesesModificar/"+this.tipoDeResiduo, {params:{
                instalacion : this.sucursal.id,
                anio: this.anioConsulta    
            }}).then(response=>{
                this.arr = response.data.meses;
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
              }
            })
        },
    },
}
</script>