<template>
  <div class="pb-5">
      <v-card class="pa-3" color="#0B3F67">
          <v-card>
              <v-card-text>
                <v-row align="center" align-content="center">
                    <v-col cols="3" sm="2" md="1">
                        <div class="pl-3">
                            <v-img contain height="85" min-width="85" max-width="50" :src="logo"></v-img>
                        </div>
                    </v-col>
                    <v-col cols="4" sm="7" md="9">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title v-if="dashboardAdmin" class="headline mb-1">Administrador</v-list-item-title>
                                <v-list-item-title v-else class="headline mb-1">{{organizacion.nombre}}</v-list-item-title>
                                <v-list-item-subtitle
                                v-if="this.$route.path !== '/main/dashboard'"
                                >{{ sucursal.nombre }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-else>Dashboard</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="5" sm="3" md="2" align-self="center">
                        <span class="headline text-center font-weight-bold">{{ anioConsulta }}</span><AnioSelect @reload="cambiaAnio"/>
                    </v-col>
                </v-row> 
              </v-card-text>
          </v-card>
      </v-card>
    </div>
</template>
<script>

import AnioSelect from '@/components/utils/MenuAnioComponent'

export default {
    components: {
        AnioSelect
    },
    computed: {
        organizacion() {
            return this.$store.getters.organizacion;
        },
        sucursal() {
            return this.$store.getters.sucursal;  
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        logo() {
            let logo = this.$store.getters.logo;
            if(logo != null && logo != '') {
                return logo;
            }
            return require('@/assets/logo_3.png');
        },
        dashboardAdmin() {
            let admin = this.$store.getters.usuario.rol === 'ADMIN' ? true : false; 
            let dashboard = this.$route.path === '/main/dashboard' ? true : false;
            if(admin && dashboard) {
                return true;
            }
            return false;
        }
    },
    methods: {
        cambiaAnio() {
            this.loadingModal(true);
            this.$emit('cambiaAnio');
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>