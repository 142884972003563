<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-1"
    item-key="id"
    :search="search"
    no-data-text="No hay Actividades para este periodo"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon:'mdi-page-first',
      lastIcon: 'mdi-page-last',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      itemsPerPageText: 'Registros por página',
      itemsPerPageAllText: 'Todos',
      showCurrentPage: true,
      showFirstLastPage: true
    }">
    <template v-slot:item.action="{ item }">
      <v-layout justify-center>
      <Modificar-accion 
        @reload="reloadData" 
        :idAccion="item.id"/>
      <ListadosDocs 
        :id="item.id" 
        :titulo="'Evidencias'"
        :origen="'prevencion'" 
      ></ListadosDocs>
      <Agregar-evidencia 
        :id="item.id"
        titulo="Agregar Evidencias"
        tooltipText="Agregar Evidencias"
        :tipo="1"
        icon="mdi-upload"
      />
      </v-layout>
    </template>
    <template v-slot:item.fechaActividad="{ item }">
      {{ dateFormat(item.fechaActividad) }}
    </template>
  </v-data-table>
</template>
<script>
import ModificarAccion from '@/components/parametros/Agua/ModifAccPrevenComponent'
import AgregarEvidencia from '@/components/documentos/AgregarEvidenciaComponent'
import ListadosDocs from '@/components/documentos/ListadoDocumentosComponent'
import moment from "moment"
export default {
  props: [
    'items',
    'search'
  ],
  components: {
    ModificarAccion,
    ListadosDocs,
    AgregarEvidencia
  },
  data: () => ({
    headers: [
      {
        text: "Acciones",
        value: "action",
        align: "center",
      },
      {
        text: "Acción realizada",
        value: "tipoActividad",
        align: "center",
      },
      {
        text: "Descripción",
        value: "descripcion",
        align: "start",
      },
      {
        text: "Cantidad de acciones",
        value: "cantidadActividades",
        align: "center",
      },
      {
        text: "Fecha de ejecución",
        value: "fechaActividad",
        align: "center",
      },
    ]
  }),
  methods: {
    reloadData() {
      this.$emit('reload')
    },
    dateFormat(fecha) {
      return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
    },
  }
}
</script>