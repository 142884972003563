<template>
    <v-dialog
        v-model="dialog" 
        persistent
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" class="white--text" v-on="on">Agregar Acción<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Acción de Limpieza" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                      <v-text-field
                        label="Nombre Actividad"
                        counter="100"
                        v-model="accion.tipoActividad"
                        :error-messages="tipoErrors"             
                        @change="$v.accion.tipoActividad.$touch()"
                        @blur="$v.accion.tipoActividad.$touch()"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                      <v-textarea
                        rows="3"
                        label="Descripción"
                        counter="500"
                        v-model="accion.descripcion"
                        :error-messages="descErrors"             
                        @change="$v.accion.descripcion.$touch()"
                        @blur="$v.accion.descripcion.$touch()"
                        required
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                        type="number"
                        label="Cantidad de Actividades"
                        v-model="accion.cantidadActividades"
                        :error-messages="cantErrors"             
                        @change="$v.accion.cantidadActividades.$touch()"
                        @blur="$v.accion.cantidadActividades.$touch()"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="fechaMoment"
                                label="Fecha de la Actividad"
                                prepend-inner-icon="mdi-calendar-month"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="accion.fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                </v-row>
                <v-file-input 
                  accept=".png, .jpeg, .jpg" 
                  show-size counter 
                  multiple 
                  v-model="archivos"
                  :error-messages="docErrors"
                  @input="$v.archivos.$touch()"
                  @blur="$v.archivos.$touch()" 
                  label="Agregar Evidencias">
                </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarAccion">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import moment from "moment"
import { required, maxLength, minValue } from "vuelidate/lib/validators"
import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    }, 
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
          return this.archivos != null ? this.archivos.length : 0;
        },
        fechaMoment () {
          return this.accion.fechaActividad ? moment(this.accion.fechaActividad).format('DD/MM/YYYY') : '';
        },
        tipoErrors () {
          const errors = []
          if (!this.$v.accion.tipoActividad.$dirty) return errors
          !this.$v.accion.tipoActividad.required && errors.push('Campo requerido')
          !this.$v.accion.tipoActividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
          return errors
        },
        descErrors () {
          const errors = []
          if (!this.$v.accion.descripcion.$dirty) return errors
          !this.$v.accion.descripcion.required && errors.push('Campo requerido')
          !this.$v.accion.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
          return errors
        },
        cantErrors () {
          const errors = []
          if (!this.$v.accion.cantidadActividades.$dirty) return errors
          !this.$v.accion.cantidadActividades.minValue && errors.push('Minimo 1 actividad')
          return errors
        },
        docErrors () {
          const errors = []
          if (!this.$v.archivos.$dirty) return errors            
          !this.$v.archivos.required && errors.push('La evidencia es requerida.')
          !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
          return errors
        },
        indicadorActual() {
          return this.$store.getters.indicadorActual;
        },
        usuario(){
          return this.$store.getters.usuario;
        },
        instalacion() {
          return this. $store.getters.sucursal;
        },
        anioConsulta() {
          return this.$store.getters.anioConsulta;
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        mask: '####',
        accion: {
            tipoActividad: '',
            descripcion: '',
            cantidadActividades: 0,
            fechaActividad: new Date().toISOString().substr(0, 10),
        },
        archivos:[],
    }),
    validations: {
        accion: {
            tipoActividad: {
                maxLength: maxLength(200),
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            cantidadActividades: {
                minValue: minValue(1)
            },
            fechaActividad: {
                required
            },
        },
        archivos: {
            required,
            maxFileSize
        }
    },
    methods: {
        agregarAccion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.archivos.forEach(file => {
                  formData.append('archivos', file);
                });
                formData.append("tipoActividad", this.accion.tipoActividad);
                formData.append("descripcion", this.accion.descripcion);
                formData.append("cantidad", this.accion.cantidadActividades);
                formData.append("fechaActividad", this.accion.fechaActividad);    
                formData.append('instalacion', this.instalacion.id);
                formData.append('usuario', this.usuario.id);
                formData.append('anioConsulta', this.anioConsulta);
                axios.post("/limpieza/agregarMedidaLimpieza", formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Acción de prevención agregada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la acción de prevención', color:'error'});  
                      this.cancelar();     
                    }
                  }       
                })
            }
        },
        cancelar() {
            this.accion = {};
            this.accion.fechaActividad = new Date().toISOString().substr(0, 10);
            this.accion.cantidadActividades = 0;
            this.archivos=[];
            this.$v.accion.$reset();    
            this.$v.archivos.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>