<template>
    <v-dialog
        v-model="dialog"
        persistent 
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Cadena de Suministros" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Nombre"
                            v-model="cadenas.nombre"
                            :error-messages="nameErrors"
                            @input="$v.cadenas.nombre.$touch()"
                            @blur="$v.cadenas.nombre.$touch()"
                            required
                        ></v-text-field>
                    </v-col>                    
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarCadenas">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, email } from "vuelidate/lib/validators";

export default {
    mounted() {
        this.getDataAgregarUsuario();
    },
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },    
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.cadenas.nombre.$dirty) return errors            
            !this.$v.cadenas.nombre.required && errors.push('El nombre es requerido.')            
            return errors
        },       
    },
    data: () => ({
        dialog: false,
        loading: false,
        cadenas: {
            nombre: '',                     
        },       
    }),
    validations: {
        cadenas: {
            nombre: {
                required,               
            },
        }            
    },
    methods: {
        
        cancelar() {
            this.cadenas = {};
            this.loading = false;
            this.dialog = !this.dialog;            
            this.$v.cadenas.$reset();            
        },
        agregarCadenas() {
            this.$v.$touch();
            if(!this.$v.$invalid) {                
                this.loading = true;
                axios.post("/cadenas/agregarCadenas", this.cadenas, { params: {                    
                }}).then(response => {            
                    this.$emit('recargaTabla');
                    this.$store.dispatch('noti',{mensaje: 'Cadena de suministros agregada', color:'success'});                
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar la Cadena de Suministros: " + error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la cadena de suministros', color:'error'});
                    this.cancelar();
                  }
                })
            }
        },
    },
}
</script>