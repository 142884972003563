var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","lg":"4","xl":"4"}},[_c('v-select',{attrs:{"items":_vm.hogares,"item-text":"nombre","return-object":"","label":"Hogares"},on:{"change":_vm.obtieneHistorial},model:{value:(_vm.hogar),callback:function ($$v) {_vm.hogar=$$v},expression:"hogar"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[(_vm.hogar && _vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarHistorial',{attrs:{"hogar":_vm.hogar,"indicadores":_vm.indicadores,"notas":_vm.notas},on:{"recargaTabla":_vm.obtieneHistorial}}):_vm._e()],1)],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-sheet',{staticClass:"v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center",attrs:{"height":_vm.alturaHoja(),"color":"#0B3F67","elevation":"12","max-width":"calc(100% - 32px)"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_vm._v("Notas de Parámetros")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.cargando,"multi-sort":"","search":_vm.search,"headers":_vm.headers,"items":_vm.notas,"no-data-text":"Sin datos","no-results-text":"Dato no encontrado","footer-props":{
                        showFirstLastPage: true,
                        firstIcon:'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageAllText: 'Todos',
                        showCurrentPage: true,
                        showFirstLastPage: true
                    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && 
                                  _vm.rolUsuario != 'GERENTE' && 
                                  _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarHistorialHogar',{attrs:{"id":item.id,"hogar":_vm.hogar},on:{"recargaTabla":_vm.obtieneHistorial}}):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }