<template>
    <v-container fluid>        
        <SuministrosTabla></SuministrosTabla>      
    </v-container>
</template>
<script>
import SuministrosTabla from "@/components/Suministros/SuministrosTablaComponent";
export default {
    components: {
        SuministrosTabla,       
    },    
}
</script>