<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="50%"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="white--text" color="#0B3F67" prepend-icon="mdi-plus">Agregar Evidencia</v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Planta de Tratamiento" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="5" align-self="center" class="text-right subtitle-1">
                        <span>Año de Particpación</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                        <v-select
                            :items="anios"
                            v-model="anio"
                            @change="$v.anio.$touch()"
                            @blur="$v.anio.$touch()"
                            label="Año de Participación"
                            :error-messages="anioErrors"  
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" offset-md="5" class="pa-0">
                        <v-alert dense type="error" v-model="alert">
                            Ya existe una evidencia para este periodo.
                        </v-alert>     
                    </v-col>
                </v-row>
                <v-row v-if="(plantaTrat || tanqueSep) && anio < 2022">
                    <v-col cols="5" align-self="center" class="text-right subtitle-1">
                        <span v-if="plantaTrat">Descripción del Sistema de Tratamiento</span>
                        <span v-if="tanqueSep">Descripción de operación y mantenimiento</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                        <v-textarea
                            label="Descripción"
                            outlined
                            v-model="tipoSistema.descripcion"
                            :error-messages="descripErrors"     
                            counter="500"        
                            @change="$v.tipoSistema.descripcion.$touch()"
                            @blur="$v.tipoSistema.descripcion.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>
                 <!-- Para cuando hay Planta de Tratamiento < 2022-->
                <div v-if="plantaTrat && anio < 2022">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Cumple con la Legislación</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-radio-group v-model="tipoSistema.legislacion" 
                                hint="Indique si cumple la legislacion">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Certificado de Calidad de Agua</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Certificación"
                                accept=".png, .jpeg, .jpg"
                                v-model="certificacion"
                                show-size                                
                                clearable
                                :error-messages="certificacionErrors"             
                                @change="$v.certificacion.$touch()"
                                @blur="$v.certificacion.$touch()">
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
                <div v-if="tanqueSep && anio < 2022">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Se realizó limpieza de lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-radio-group v-model="tipoSistema.limpiezaLodos">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Croquis Tanque Séptico</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Croquis"
                                accept=".png, .jpg, .jpeg"
                                v-model="croquisTanque"
                                show-size                                
                                clearable
                                :error-messages="croquisErrors"             
                                @change="$v.croquisTanque.$touch()"
                                @blur="$v.croquisTanque.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Diagnostico del Tanque</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Diagnostico"
                                accept=".png, .jpg, .jpeg"
                                v-model="diagnostico"
                                show-size                                
                                clearable
                                :error-messages="diagnosticoErrors"             
                                @change="$v.diagnostico.$touch()"
                                @blur="$v.diagnostico.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Plan de Mantenimiento</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Plan"
                                accept=".png, .jpg, .jpeg"
                                v-model="plan"
                                show-size                                
                                clearable
                                :error-messages="planErrors"             
                                @change="$v.plan.$touch()"
                                @blur="$v.plan.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row v-if="tipoSistema.limpiezaLodos">
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Evidencia de gestión de Lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Evidencia"
                                accept=".png, .jpg, .jpeg"
                                v-model="evidenciaLodos"
                                show-size                                
                                clearable
                                :error-messages="evidenciaLodosErrors"             
                                @change="$v.evidenciaLodos.$touch()"
                                @blur="$v.evidenciaLodos.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
                <div v-if="tanqueSep && anio >= 2022">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Se realizó limpieza de lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-radio-group v-model="tipoSistema.limpiezaLodos">
                                <v-radio label="Si" :value="true"></v-radio>
                                <v-radio label="No" :value="false"></v-radio>
                            </v-radio-group> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Permiso de funcionamiento del proveedo</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Diagnostico"
                                accept=".png, .jpg, .jpeg"
                                v-model="diagnostico"
                                show-size                                
                                clearable
                                :error-messages="diagnosticoErrors"             
                                @change="$v.diagnostico.$touch()"
                                @blur="$v.diagnostico.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Permiso sanitario de funcionamiento del proveedor</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Plan"
                                accept=".png, .jpg, .jpeg"
                                v-model="plan"
                                show-size                                
                                clearable
                                :error-messages="planErrors"             
                                @change="$v.plan.$touch()"
                                @blur="$v.plan.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row v-if="tipoSistema.limpiezaLodos">
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Constancia del proveedor que trató los lodos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Adjuntar Evidencia"
                                accept=".png, .jpg, .jpeg"
                                v-model="evidenciaLodos"
                                show-size                                
                                clearable
                                :error-messages="evidenciaLodosErrors"             
                                @change="$v.evidenciaLodos.$touch()"
                                @blur="$v.evidenciaLodos.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
                <v-row v-if="alcantarillado">
                    <v-col cols="5" align-self="center" class="text-right subtitle-1">
                        <span>Tratamiento del Agua Residual</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                        <v-file-input label="Adjuntar Evidencia"
                            accept=".png, .jpg, .jpeg"
                            v-model="tratamientoAgua"
                            show-size                                
                            clearable
                            :error-messages="tratamientoAguaErrors"             
                            @change="$v.tratamientoAgua.$touch()"
                            @blur="$v.tratamientoAgua.$touch()">
                        </v-file-input>   
                    </v-col>
                </v-row>
                <!-- todo despues de 2022 -->
                <div v-if="plantaTrat && anio >= 2022">
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Permiso para vertido de aguas residuales</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Permiso para vertido"
                                accept=".png, .jpg, .jpeg"
                                v-model="permisoVertido"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="permisoErrors"             
                                @change="$v.permisoVertido.$touch()"
                                @blur="$v.permisoVertido.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Reporte operacional</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Reporte operacional"
                                accept=".png, .jpg, .jpeg"
                                v-model="reporteOper"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="reporteOperErrors"             
                                @change="$v.reporteOper.$touch()"
                                @blur="$v.reporteOper.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Reporte de datos</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Reporte de datos"
                                accept=".png, .jpg, .jpeg"
                                v-model="reporteDatos"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="reporteDatosErrors"             
                                @change="$v.reporteDatos.$touch()"
                                @blur="$v.reporteDatos.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" align-self="center" class="text-right subtitle-1">
                            <span>Comprobante de pago del canon</span>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="6">
                            <v-file-input label="Comprobante de pago del canon"
                                accept=".png, .jpg, .jpeg"
                                v-model="comprobantePago"
                                show-size                                
                                clearable
                                multiple
                                :error-messages="comprobanteErrors"             
                                @change="$v.comprobantePago.$touch()"
                                @blur="$v.comprobantePago.$touch()">>
                            </v-file-input>   
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn :disabled="alert" :loading="loading" color="#0B3F67" text @click="agregarParametro">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>  
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import axios from "@/axios.js";
//Custom validations
const maxMultiFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return true;
}
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}
export default {
    mounted() {
        this.getAnioParticipacion();
    },
    components: {
      ModalTitle
    },
    props: [
        'sistemaActual'
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        plantaTrat() {
            return this.sistemaActual.sistema === 1 ? true : false;
        },
        tanqueSep() {
            return this.sistemaActual.sistema === 2 ? true : false;
        },
        alcantarillado() {
            return this.sistemaActual.sistema === 3 ? true : false;
        },
        descripErrors () {
            const errors = []
            if (!this.$v.tipoSistema.descripcion.$dirty) return errors            
            !this.$v.tipoSistema.descripcion.required && errors.push('La descripción es requerida.')      
            !this.$v.tipoSistema.descripcion.maxLength && errors.push('Este dato excede el tamaño permitido.')            
            return errors
        },
        certificacionErrors () {
            const errors = []
            if (!this.$v.certificacion.$dirty) return errors            
            !this.$v.certificacion.required && errors.push('Evidencia requerida.')
            !this.$v.certificacion.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        croquisErrors () {
            const errors = []
            if (!this.$v.croquisTanque.$dirty) return errors            
            !this.$v.croquisTanque.required && errors.push('Evidencia requerida.') 
            !this.$v.croquisTanque.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        diagnosticoErrors () {
            const errors = []
            if (!this.$v.diagnostico.$dirty) return errors            
            !this.$v.diagnostico.required && errors.push('Evidencia requerida.')  
            !this.$v.diagnostico.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')       
            return errors
        },
        planErrors () {
            const errors = []
            if (!this.$v.plan.$dirty) return errors            
            !this.$v.plan.required && errors.push('Evidencia requerida.')
            !this.$v.plan.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        evidenciaLodosErrors () {
            const errors = []
            if (!this.$v.evidenciaLodos.$dirty) return errors            
            !this.$v.evidenciaLodos.required && errors.push('Evidencia requerida.')
            !this.$v.evidenciaLodos.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        tratamientoAguaErrors () {
            const errors = []
            if (!this.$v.tratamientoAgua.$dirty) return errors            
            !this.$v.tratamientoAgua.required && errors.push('Evidencia requerida.')
            !this.$v.tratamientoAgua.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
        anioErrors () {
            const errors = []
            if (!this.$v.anio.$dirty) return errors            
            !this.$v.anio.required && errors.push('Dato requerido.')
            !this.$v.anio.uniqueAnio && errors.push('Ya existe un Sistema de Tratamiento para este año')
            return errors
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        permisoErrors () {
            const errors = []
            if (!this.$v.permisoVertido.$dirty) return errors            
            !this.$v.permisoVertido.required && errors.push('Evidencia requerida.')  
            !this.$v.permisoVertido.maxMultiFileSize && errors.push('El tamaño no debe exceder 1MB.')       
            return errors
        },
        reporteOperErrors () {
            const errors = []
            if (!this.$v.reporteOper.$dirty) return errors            
            !this.$v.reporteOper.required && errors.push('Evidencia requerida.')  
            !this.$v.reporteOper.maxMultiFileSize && errors.push('El tamaño no debe exceder 1MB.')       
            return errors
        },
        reporteDatosErrors () {
            const errors = []
            if (!this.$v.reporteDatos.$dirty) return errors            
            !this.$v.reporteDatos.required && errors.push('Evidencia requerida.')  
            !this.$v.reporteDatos.maxMultiFileSize && errors.push('El tamaño no debe exceder 1MB.')       
            return errors
        },
        comprobanteErrors () {
            const errors = []
            if (!this.$v.comprobantePago.$dirty) return errors            
            !this.$v.comprobantePago.required && errors.push('Evidencia requerida.')  
            !this.$v.comprobantePago.maxMultiFileSize && errors.push('El tamaño no debe exceder 1MB.')       
            return errors
        },
    },
    data: () => ({
        dialog: false,
        tipoSistema: {
            descripcion: null,
            legislacion: false,
            limpiezaLodos: false
        },
        certificacion: null,
        anios: [],
        croquisTanque: null,
        evidenciaLodos: null,
        plan: null,
        diagnostico: null,
        tratamientoAgua: null,
        alert: false,
        loading: false,
        anio: null,
        permisoVertido: [],
        reporteOper: [],
        reporteDatos: [],
        comprobantePago: [],

        permisoFunc: [],
        permisoSani: [],
        constProvee: []
    }),
    validations: {
        tipoSistema: {
            descripcion: {
                maxLength: maxLength(500),
                required: requiredIf(function(){
                    return (!this.alcantarillado  && this.anio < 2022) 
                })               
            },
        },
        anio: {
            required,
            uniqueAnio(anio) {
                if (anio === '' || anio === null) return true
                return new Promise((resolve, reject) => {
                    axios.get("/instalaciones/verificaPlantaTrat/" + anio + '/' +this.instalacion.id).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        reject(error);
                      }
                    })
                })
            }
        },
        certificacion: {
            required: requiredIf(function(){
                return (this.plantaTrat && this.anio < 2022) 
            }),
            maxFileSize
        },
        croquisTanque: {
            required: requiredIf(function(){
                return this.tanqueSep
            }),
            maxFileSize
        },
        diagnostico: {
            required: requiredIf(function(){
                return this.tanqueSep
            }),
            maxFileSize
        },
        plan: {
            required: requiredIf(function(){
                return this.tanqueSep
            }),
            maxFileSize
        },
        evidenciaLodos: {
            required: requiredIf(function(){
                return (this.tanqueSep && this.tipoSistema.limpiezaLodos)
            }),
            maxFileSize
        },
        tratamientoAgua: {
            required: requiredIf(function(){
                return this.alcantarillado
            }),
            maxFileSize
        },
        permisoVertido: {
            required: requiredIf(function(){
                return this.tanqueSep && this.anio >= 2022
            }),
            maxMultiFileSize
        },
        reporteOper: {
            required: requiredIf(function(){
                return this.tanqueSep && this.anio >= 2022
            }),
            maxMultiFileSize
        },
        reporteDatos: {
            required: requiredIf(function(){
                return this.tanqueSep && this.anio >= 2022
            }),
            maxMultiFileSize
        },
        comprobantePago: {
            required: requiredIf(function(){
                return this.tanqueSep && this.anio >= 2022
            }),
            maxMultiFileSize
        }
    },
    methods: {
        agregarParametro() {
            this.$v.$touch();
            if(!this.$v.$invalid && !this.alert) {
                this.loading = true;
                let formData = this.agregarEvidencias();
                axios.post("/instalaciones/agregarTipoSistema", formData) 
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Evidencia agregada', color: 'success'});
                    this.cancelar();
                }).catch(error => { 
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$emit('reload');
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la evidencia', color: 'error'});
                      this.cancelar();
                    }
                  }
                })
            }
        },
        cancelar() {
            this.tipoSistema = {
                descripcion: '',
                legislacion: false,
                limpiezaLodos: false
            };
            this.certificacion = null;
            this.croquisTanque = null;
            this.evidenciaLodos = null;
            this.diagnostico = null;
            this.anio = null;
            this.plan = null;
            this.alert = false;
            this.tratamientoAgua =  null;
            this.$v.tipoSistema.$reset();
            this.$v.certificacion.$reset();
            this.$v.evidenciaLodos.$reset();
            this.$v.croquisTanque.$reset();
            this.$v.diagnostico.$reset();
            this.$v.plan.$reset();
            this.$v.anio.$reset();
            this.$v.tratamientoAgua.$reset();
            this.loading = false; 
            this.dialog = false;
            this.permisoVertido = [];
            this.reporteOper = [];
            this.reporteDatos = [];
            this.comprobantePago = [];
            this.$v.reporteOper.$reset();
            this.$v.reporteDatos.$reset();
            this.$v.comprobantePago.$reset();
        },
        getAnioParticipacion() {
            this.anios = [];
            this.anios.push(this.anioConsulta);                
        },
        agregarEvidencias() {
            let formData = new FormData();
            
            if(this.plantaTrat) {
                if(this.anio < 2022) {
                    formData.append('archivos', this.certificacion);
                 } else {
                    this.comprobantePago.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.COMPROBANTE_PAGO]);
                    });
                     this.reporteDatos.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.REPORTE_DATOS]);
                    });
                     this.reporteOper.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.REPORTE_OPERACIONAL]);
                    });
                     this.permisoVertido.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.PERMISO_VERTIDO]);
                    });
                }
            }
            if(this.tanqueSep) {
                if(this.anio < 2022) {
                    formData.append('archivos', this.evidenciaLodos);
                    formData.append('archivos', this.diagnostico);
                    formData.append('archivos', this.plan);
                } else {
                    this.evidenciaLodos.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.COMPROBANTE_PAGO]);
                    });
                     this.diagnostico.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.REPORTE_DATOS]);
                    });
                     this.plan.forEach(file => {
                        formData.append('archivos', file);
                        formData.append('tipoDoc', [file.name, this.constantes.REPORTE_OPERACIONAL]);
                    });
                }
            }
            if(this.alcantarillado) {
                formData.append('archivos', this.tratamientoAgua);
            }
            formData.append('sistema', JSON.stringify(this.tipoSistema));
            formData.append('idInstalacion', this.instalacion.id);
            formData.append('idUsuario', this.$store.getters.usuario.id);
            formData.append('anio', this.anio);
            return formData;
        },
    }
}
</script>