<template>
  <v-card flat class="text-center pt-5 pb-10">
    <div class="titulo">LOGRE QUE SU ORGANIZACIÓN SEA MÁS</div>
    <div class="pb-10 tituloMax ">EFICIENTE, RESISTENTE Y SOSTENIBLE</div>
    <v-row justify="center">
      <v-img
        max-height="250"
        max-width="250" 
        src="@/assets/iconos/Iconos_detalles/PBAE.png"/>
    </v-row>
    <div class="pt-5 subtitulo">ACTIVE SU</div>
    <div class="subtitulo">PROGRAMA BANDERA AZUL</div>
  </v-card>
</template>
<script>
export default {
  
}
</script>
<style scoped>
  @font-face {
    font-family: "futura";
    src: url("~@/assets/fonts/Futura Condensed ExtraBold/Futura Condensed ExtraBold.ttf");
  }
  .titulo {
    color: #52B299;
    font-size: 26px;
    font-family: 'futura';
  }
  .tituloMax {
    color: #52B299;
    font-size: 36px;
    font-family: 'futura';
  }
  .subtitulo {
    color: #5491BD;
    font-size: 26px;
    font-family: 'futura';
  }
</style>