<template>
    <v-container fluid>
        <logo @cambiaAnio="getDataAccPlan" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Seguimiento Plan de Acción para Medida de Adaptación"/>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">            
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">            
                <v-card>
                    <tituloSimple titulo="Seguimiento Plan de Acción"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search"
                            fixed-header
                            no-data-text="No hay planes de acción registrados"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon:'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageText: 'Registros por página',
                                itemsPerPageAllText: 'Todos',                            
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }">
                            <template v-slot:item.action="{ item }">
                                <AgregarEvidencias 
                                    titulo="Agregar Evidencias" 
                                    tooltipText="Agregar Evidencias" 
                                    :anioActual="anioConsulta"
                                    origen="planAccion"
                                    :data="item">
                                </AgregarEvidencias>
                                <ModificarEstado
                                    @reload="getDataAccPlan"
                                    :idPlan="item.idPlan"
                                    :instalacion="instalacion"
                                    :estadoAct="item.estado"
                                    :usuario="usuario">
                                </ModificarEstado>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <estadoPlan :estado="item.estado"/>
                            </template>
                            <template v-slot:item.idPlan="{ item }">
                                <evidencias  
                                    origen="planAccion"
                                    titulo="Evidencias Plan de Acción"
                                    :id="item.idPlan">
                                </evidencias>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import EstadoPlan from '@/components/utils/DisplayEstadoComponent'
import AgregarEvidencias from '@/components/utils/AgregarEvidenciaComponent'
import Evidencias from '@/components/documentos/ListadoDocumentosComponent'
import ModificarEstado from '@/components/parametros/Adaptacion/ModificarEstadoComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'

export default {
    components: {
        TituloPrincipal,
        TituloSimple,
        NavParameters,
        AgregarEvidencias,
        ModificarEstado,
        Evidencias,
        EstadoPlan,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        }
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getDataAccPlan();
    },
    data: () => ({
        search: null,
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Plan de Acción',
                value: 'descripcion',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Plazo de Ejecución',
                value: 'plazo',
                align: 'center',
                width: '15%',
                sortable: false
            },            
            {
                text: 'Fecha Registro Plan',
                value: 'fechaRegistro',
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: 'Evidencias',
                value: 'idPlan',
                align: 'center',
                width: '10%',
                sortable: false
            },
        ],
        items: [],
    }),
    methods: {
        getDataAccPlan() {
            axios.get("/adaptacion/getDataPlanAccion/" + this.instalacion.id + "/" + this.anioConsulta)
            .then(response => {
                this.items = response.data.planes;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>