<template>
    <v-container fluid> 
        <v-row>
            <v-col cols="12" md="5" lg="4" xl="4">
                <v-select
                    :items="hogares"
                    item-text="nombre"
                    return-object
                    label="Hogares"
                    v-model="hogar"
                    @change="obtieneHistorial"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
                <AgregarHistorial 
                    :hogar="hogar" 
                    :indicadores="indicadores"
                    :notas="notas"
                    v-if="hogar && rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                    @recargaTabla="obtieneHistorial"/>
            </v-col>
        </v-row>
        <br> 
        <v-row>
            <v-col cols="12">
                <v-card>       
                    <v-sheet
                        :height="alturaHoja()"
                        class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                        color="#0B3F67"
                        elevation="12"
                        max-width="calc(100% - 32px)">
                        <v-layout fill-height align-center justify-center>Notas de Parámetros</v-layout>
                    </v-sheet>                              
                    <v-data-table
                        :loading="cargando"
                        multi-sort    
                        :search="search"        
                        :headers="headers"
                        :items="notas"
                        class="elevation-1"       
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }">
                        <template 
                            v-slot:item.action="{ item }">
                            <ModificarHistorialHogar
                                :id="item.id"
                                :hogar="hogar"
                                v-if="rolUsuario != 'CEO' && 
                                      rolUsuario != 'GERENTE' && 
                                      rolUsuario != 'SUPERVISOR'"
                                      @recargaTabla="obtieneHistorial"/>
                        </template>
                    </v-data-table>                       
                </v-card>
            </v-col>
        </v-row>       
    </v-container>
</template>

<script>
import ModificarHistorialHogar from "@/components/Estrellas/ModificarHistorialHogarComponent";
import AgregarHistorial from "@/components/Estrellas/AgregarHistorialHogarComponent";
import axios from "@/axios.js";

export default {
    components: {
        ModificarHistorialHogar,
        AgregarHistorial,
    },
    props: [
        'hogares'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    data: () => ({
        search: '',
        data: [],
        cargando: false,
        hogar: '',
        notas: [],
        indicadores: [],
        headers: [
            { text: 'Acciones', value: 'action', align: 'center', sortable: false, filterable: false,},
            { text: 'Parámetro', value: 'parametro', align: 'center', sortable: true, filterable: true, },
            { text: 'Nota Obtenida', value: 'notaObtenida', align: 'center', sortable: true, filterable: true, },
        ]
    }),
    computed: {
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
    },
    methods: {
        alturaHoja() {
            return this.$mq === "sm" ? 120 : 80;
        },
        obtieneHistorial() {
            this.initIndicadores();
            this.cargando = true;
            axios.get("/estrellas/getNotas/" + this.hogar.id)
            .then(response => {
                this.notas = response.data.notas;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        initIndicadores() {
            this.indicadores = []
            this.indicadores.push(
                {
                    id: this.$store.getters.constantes.indicadorEnergia, 
                    nombre: 'Energía eléctrica'
                },
                {
                    id: this.$store.getters.constantes.indicadorAgua,
                    nombre: 'Agua'
                },
                {
                    id: this.$store.getters.constantes.indicadorCombustibles, 
                    nombre: 'Combustibles fósiles'
                },
                {
                    id: this.$store.getters.constantes.indicadorCumpliLegal, 
                    nombre: 'Cumplimiento legal'
                },
                {
                    id: this.$store.getters.constantes.indicadorAguasResiduales, 
                    nombre: 'Tratamiento aguas residuales'
                },
                {
                    id: this.$store.getters.constantes.indicadorResiduos, 
                    nombre: 'Residuos'
                },
                {
                    id: this.$store.getters.constantes.indicadorContamiAdmosf, 
                    nombre: 'Gases Refrigerantes'
                },
                {
                    id: this.$store.getters.constantes.indicadorCompSostenibles, 
                    nombre: 'Compras sostenibles'
                },
                {
                    id: this.$store.getters.constantes.indicadorAdaptacion, 
                    nombre: 'Adaptación'
                },
                {
                    id: this.$store.getters.constantes.indicadorCompensacion,
                    nombre: 'Compensación'
                },
            )
        },
    }
}
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>

