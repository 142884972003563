<template>
    <v-dialog
        v-model="dialog"  
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon v-on="{ ...dialog, ...tooltip }">mdi-inbox-full-outline</v-icon>
                </template>
                Mostrar Detalle
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Detalle del Equipo" @cancelar="dialog = !dialog"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6" class="pb-0">
                        <v-text-field
                            disabled
                            label="Tipo de Equipo"
                            v-model="equipo.tipoConsumidor"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pb-0">
                        <v-text-field
                            disabled
                            label="Cilindrada"
                            v-model="equipo.cilindrada"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            disabled
                            label="Descripción"
                            v-model="equipo.descripcion"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Placa"
                            v-model="equipo.numPlacaActivo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Num. Activo"
                            v-model="equipo.numActivo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Marca"
                            v-model="equipo.marca"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Modelo"
                            v-model="equipo.modelo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Año"
                            v-model="equipo.anio"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Capacidad"
                            v-model="equipo.capacidad"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Tipo Combustible"
                            v-model="equipo.tipoCombustible"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Responsable"
                            v-model="equipo.responsableUso"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Unidad de Consumo"
                            v-model="equipo.unidadMedidaConsumoString"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            disabled
                            label="Costo Consumo"
                            v-model="equipo.unidadCostoReciboString"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = !dialog" color="error">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'equipo'
    ],
    components: {
      ModalTitle
    },
    data: () => ({
        dialog: false
    })
}
</script>