import { InformeService } from '../informe-api'
import diccionario from '@/imageDictionary.js'

export function informe2022(constantes, anio, anioAnterior, instalacion, organizacion, datos) {
    const informe = new InformeService(constantes)
    let content = [
        {
            table: {
                widths: ['71%','29%'],
                body: [
                    [
                        {
                            text: 'VI Categoría:\n Cambio Climático',
                            fontSize: 26,
                            bold: true,
                            alignment: 'right'
                        },
                        {
                            rowSpan: 3,
                            text: anio,
                            fontSize: 26,
                            bold: true,
                            alignment: 'center',
                            fillColor: '#9bbb59',
                            color: '#ffffff',
                        },
                    ],
                    [
                        {
                            text: 'Programa Bandera Azul Ecológica',
                            fontSize: 26,
                            bold: true,
                            alignment: 'right',
                            fillColor: '#4f81bd',
                            color: '#ffffff',
                        }
                    ],
                    [
                        {
                            image: diccionario.logoBandera,
                            width: 440,
                            height: 600,
                        }
                    ],
                ]
            },
            layout: {
                defaultBorder: false,
            },
        },
        {
            table: {
                widths: ['50%', '50%'],
                body: [
                    [
                        {
                            image: diccionario.logoBanderaAzulUno,
                            alignment: 'right',
                            fit: [100, 100]
                        },
                        {
                            image: diccionario.logoBanderaAzulDos,
                            alignment: 'left',
                            fit: [124, 124]
                        }
                    ]
                ]
            },
            layout: {
                defaultBorder: false,
            },
        },
        {
            text: 'Informe Final Año ' + anio,
            alignment: 'center',
            fontSize: 26,
            bold: true,
            pageBreak: 'after'
        },
        //Fin de la Portada del informe
        {
            text: 'Generalidades de la Organización',
            alignment: 'left',
            bold: true,
            margin: [0, 10, 0, 0]
        },
        {
            ul: [
                { text: '', margin: [70, 10, 60, 0], },
            ]
        },
        {
            table: {
                widths: ['20%', '30%', '50%'],
                body: [
                    [
                        informe.informeLogo(datos.orgaLogo),
                        {
                            text: 'Nombre de la Organización',
                            alignment: 'center',
                            fillColor: '#8db3e2'
                        },
                        { text: organizacion.nombre }
                    ],
                    [
                        '',
                        { text: 'Nombre de la Sede', alignment: 'center', fillColor: '#8db3e2' },
                        { text: instalacion.nombre }
                    ],
                    [
                        '',
                        { text: 'Código de la Comité', alignment: 'center', fillColor: '#8db3e2' },
                        { text: instalacion.codigoPbae }
                    ],
                    [
                        '',
                        { text: 'Ubicación de la Sede', alignment: 'center', fillColor: '#8db3e2' },
                        { text: instalacion.ubicacion }
                    ],
                    [
                        '',
                        { text: 'Cantidad de colaboradores/as', alignment: 'center', fillColor: '#8db3e2' },
                        { text: instalacion.cantidadPersonal }
                    ],
                    [
                        '',
                        { text: 'Años en que ha participado', alignment: 'center', fillColor: '#8db3e2' },
                        { text: instalacion.tiempoEnPrograma }
                    ],
                    [
                        { text: 'Indicar año y estrellas ganadas en años anteriores al que están participando', alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                        '',
                        { text: datos.estrellasInforme }
                    ],
                    [
                        { text: 'Mostrar foto (s), que demuestre, la iza o exhibición de la bandera, en el caso que tuvieran ese galardón del PBAE', alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                        '',
                        {
                            text: 'N/A',
                            alignment: 'left',
                        }
                    ]
                ]
            }
        },
        {
            margin: [20, 50, 20, 40, 20],
            text: [
                { text: '1. Resumen Ejecutivo: ', bold: true }
            ]
        },
        {
            table: {
                widths: ['10%', '20%', '70%'],
                body: [
                    [
                        { text: 'Indique el alcance de la implementación del PBAE (Edificio, sedes, otros)', alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                        '',
                        { text: instalacion.alcance }
                    ],
                    [
                        { text: 'Describa la actividad sustantiva que desarrolla la organización', alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                        '',
                        { text: datos.descripcionOrg }
                    ],
                    [
                        { text: 'Describa detalles que consideren pertinentes de indicar que permitan ubicar al evaluador del informe', alignment: 'center', fillColor: '#8db3e2', colSpan: 2 },
                        '',
                        { text: instalacion.detalle }
                    ],
                ]
            }
        },
        {
            text: '2. Integrantes Comité de Sede Participante', bold: true, margin: [20, 50, 20, 40, 20],
        },
        informe.agregaTablaComite(datos.comiteAmbiental, instalacion),
        {
            text: '3. Información requerida por parámetro', bold: true, margin: [20, 50, 20, 40, 20],
        },
        ///////////////////////////////////////////Cumplimiento Legal/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '1. Cumplimiento Legal', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            ul: [
                { text: '', margin: [70, 10, 60, 0], },
            ]
        },
        {
            text: '1.1 Declaración jurada y Cuestionario para autoevaluar el parámetro de cumplimiento legal', alignment: 'left', bold: true, margin: [50, 0, 40, 0]
        },
        informe.envidenciaSimple(datos.inscripcionLegal),
        ///////////////////////////////////////////Combustibles fósiles/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '2. Combustibles fósiles', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        informe.tipoCombustibles(datos, instalacion, anio, anioAnterior),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '3. Agua', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '3.1 Verificación de la calidad de agua potable', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.tablaEvidenciasAgua(datos.potabilidadAgua),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.tablaEvidenciasAguaF(datos.potabilidadAguaPropia),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justPotabilidadAgua, '#8db3e2', 'Justificación por ausencia de datos y acciones realizadas para mejorar la situación:'),
        informe.tablaEvidencias(datos.docsJustCalidadAgua),
        {
            pageBreak: 'before',
            text: '3.2 Determinación del consumo de agua para uso humano y el proceso productivo.', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°8', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Consumo Mensual de Agua del año anterior al de participación ${anioAnterior}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaConsumo2022(datos.consumoAguaAnterior, constantes.indicadorAgua, anioAnterior, anio, '#fac090'),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justAguaConsAnte, '#fac090', 'Justificación por ausencia de datos o igual a cero:'),
        {
            pageBreak: 'before',
            text: 'Cuadro N°9', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Consumo mensual de agua del año de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaConsumo2022(datos.consumoAguaActual, constantes.indicadorAgua, anio, anio, '#8db3e2'),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justAguaCons, '#8db3e2', 'Justificación por ausencia de datos o igual a cero:'),
        informe.tablaEvidencias(datos.docsJustConsumoAgua),
        {
            pageBreak: 'before',
            text: '3.3 Reducción en el consumo del agua para uso humano y el proceso productivo mayor a un 1%', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°10', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Comparación de consumos de agua año ${anioAnterior} con respecto al año ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },

        informe.reduccionConsumo2022(datos.reduccionAgua, constantes.indicadorAgua, anio, anioAnterior),
        {
            text: 'Siglas, abreviaturas y símbolos', alignment: 'left', bold: true, decoration: 'underline', italics: true, margin: [50, 20, 40, 0]
        },
        {
            text: '*: Multiplicación.', alignment: 'left', italics: true, margin: [50, 0, 40, 0]
        },
        {
            text: '/: División.', alignment: 'left', italics: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'L: Litros.', alignment: 'left', italics: true, margin: [50, 0, 40, 20]
        },
        {
            text: 'Nota: Los factores de conversión incluidos en este cuadro, para obtener las equivalencias, son un valor de referencia. ', alignment: 'left', italics: true, margin: [50, 0, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justAgua, '#8db3e2', 'Justificación en caso de aumento del consumo de agua, ausencia de datos o igual a cero:'),
        informe.tablaEvidencias(datos.docsJustReducciongua),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.evidenciaJustificacion(datos.justAnioAgua),
        {
            pageBreak: 'before',
            text: '3.4 Educación ambiental', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°11', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación Ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educacionAgua),
        {
            pageBreak: 'before',
            text: '3.5 Acciones de reducción y control', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°12', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Acción de reducción y control', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaAccionesReduccion2022(datos.accionReducAgua),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
        {
            pageBreak: 'before',
            text: '4. Tratamiento Aguas Residuales', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '4.1 Identificar e indicar el tipo de sistema de tratamiento de aguas residuales (STAR) utilizados en la organización', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°13', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Tipo de STAR', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablatipostar(datos.tipoSistemaTrat),
        informe.evidcroquis(datos.croquisSist),
        informe.tablaAccionesTrat(datos.tipoSistemaTrat),
        {
            pageBreak: 'before',
            text: '4.2 Demostración del correcto funcionamiento del STAR', alignment: 'left', bold: true, margin: [50, 0, 40, 20]
        },
        {
            text: 'En los casos que la organización cuente con dos sistemas de tratamiento de aguas residuales, solamente debe completar la información de uno de los STAR.', alignment: 'justify', italics: true, margin: [50, 0, 40, 20]
        },
        //Planta de tratamiento 
        informe.sistemaTratamientoAguas(datos),
        {
            pageBreak: 'before',
            text: '4.3 Educación ambiental', alignment: 'left', bold: true, margin: [50, 20, 40, 20],
        },
        {
            text: 'Cuadro N°15', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación Ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educcacionAguasRes),
        {
            pageBreak: 'before',
            text: '4.5 Acciones realizadas', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°16', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Acción de reducción y control', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaAccionesReduccion2022(datos.accionReducAguResid),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '5. Energía Eléctrica', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '5.1 Determinación del consumo de energía eléctrica', alignment: 'left', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°17', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Consumo mensual de electricidad del año anterior al de participación (${anioAnterior})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]

        },
        informe.tablaConsumo2022(datos.consumoEnergiaAnterior, constantes.indicadorEnergia, anioAnterior, anio, '#fac090'),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justEnerConsAnte, '#fac090', 'Justificación y evidencias por ausencia de datos:'),

        {
            pageBreak: 'before',
            text: 'Cuadro N°18', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Consumo mensual de electricidad del año anterior al de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]

        },
        informe.tablaConsumo2022(datos.consumoEnergiaActual, constantes.indicadorEnergia, anio, anio, '#8db3e2'),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justEnerCons, '#8db3e2', 'Justificación y evidencias por ausencia de datos:'),
        informe.tablaEvidencias(datos.docsJustConsumoEnergia),
        {
            pageBreak: 'before',
            text: '5.2 Reducción en el consumo de energía eléctrica mayor al 1% respecto al año anterior', alignment: 'left', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°19', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Comparación de consumos de energía eléctrica año ${anioAnterior} con respecto año ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]

        },
        informe.reduccionConsumo2022(datos.reduccionEnergia, constantes.indicadorEnergia, anio, anioAnterior),

        {
            text: 'Siglas, abreviaturas y símbolos', alignment: 'left', bold: true, decoration: 'underline', italics: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Multiplicación', alignment: 'left', bold: true, italics: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'KW/h: Kilowatt hora', alignment: 'left', italics: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Ton CO2 eq: Toneladas de dióxido de carbono equivalente' + anio, alignment: 'left', italics: true, margin: [50, 0, 40, 20]
        },
        {
            text: 'Nota: Los factores de conversión incluidos en este cuadro para obtener las equivalencias son un valor de referencia' + anio, alignment: 'left', italics: true, margin: [50, 0, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justEnergia, '#8db3e2', 'Justificación en caso de aumento del consumo de electricidad, ausencia de datos o igual a cero:'),
        informe.tablaEvidencias(datos.docsJustReduccionEnergia),
        {
            pageBreak: 'before',
            text: '5.3 Educación ambiental', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°20', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación Ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educacionEnergia),
        {
            pageBreak: 'before',
            text: '5.3 Acciones de reducción y control', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°21', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Acción de reducción y control', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaAccionesReduccion2022(datos.accionReduccionEnergia),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '6. Gestión de Residuos', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '6.1 Determinación del consumo de papel', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°22', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Consumo mensual de papel de oficina del año anterior al de participación (${anioAnterior})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaConsumo2022(datos.consumoResiduosAnterior, constantes.indicadorResiduos, anioAnterior, anio, '#fac090'),
        {
            text: 'Nota: Una resma equivale a 500 hojas', alignment: 'left', margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justResiduConsAnte, '#fac090', 'Justificación por ausencia de datos o igual a cero:'),
        {
            pageBreak: 'before',
            text: 'Cuadro N°23', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Consumo mensual de papel de oficina del año anterior al de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaConsumo2022(datos.consumoResiduosActual, constantes.indicadorResiduos, anio, anio, '#8db3e2'),
        {
            text: 'Nota: Una resma equivale a 500 hojas', alignment: 'left', margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justConsPapel, '#8db3e2', 'Justificación por ausencia de datos o igual a cero:'),
        informe.tablaEvidencias(datos.docsJustConsumoPapel),
        {
            pageBreak: 'before',
            text: '6.2. Reducción del uso de papel en al menos un 1% con respecto al año anterior', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°24', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Comparación del consumo de papel año ${anioAnterior} con respecto año ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.reduccionConsumo2022(datos.reduccionPapel, constantes.indicadorResiduos, anio, anioAnterior, instalacion),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justResPapel, '#8db3e2', 'Justificación en caso de aumento del consumo de papel, ausencia de datos o datos igual a cero:'),
        informe.tablaEvidencias(datos.docsJustReduccionPapel),
        {
            pageBreak: 'before',
            text: '6.3. Medición y comparación de la generación de Residuos Sólidos Valorizables', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°25', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Medición de la generación de residuos sólidos valorizables del año anterior de participación (${anioAnterior})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaResidSoli(datos.consumoValoAnterior, constantes.indicadorResiduos, '#fac090', instalacion),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justConsValoAnte, '#fac090', 'Justificación por ausencia de datos o igual a cero:'),
        {
            pageBreak: 'before',
            text: 'Cuadro N°26', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Medición de la generación de residuos sólidos valorizables del año de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaResidSoli(datos.consumoValoActual, constantes.indicadorResiduos, anio, '#8db3e2', instalacion),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justConsValo, '#8db3e2', 'Justificación por ausencia de datos o igual a cero:'),
        informe.tablaEvidencias(datos.docsJustConsumoValor),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.evidenciaJustificacion(datos.justAnioResiduos),
        {
            pageBreak: 'before',
            text: '6.4. Reducción de residuos valorizables en al menos un 1%, con respecto al año anterior de participación', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°27', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Comparación de generación residuos valorizables del año ${anioAnterior} con respecto al año ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.comparResid(datos.resValorizales, true, anio, anioAnterior, instalacion, 'Tipo de residuo valorizable'),
        {
            text: 'Nota: A excepción del tipo de residuo valorizable Chatarra y metales, esta clasificación de residuos valorizables responde a la Estrategia Nacional de Separación, Recuperación y Valorización de Residuos.', alignment: 'left', margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justResiduos, '#8db3e2', 'Justificación en caso de aumento del consumo de papel, ausencia de datos o datos igual a cero:'),
        informe.tablaEvidencias(datos.docsJustReduccionValor),
        {
            pageBreak: 'before',
            text: '6.5. Medición y comparación de la generación de residuos sólidos no valorizables', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°28', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Medición de la generación de residuos sólidos no valorizables del año anterior de participación (${anioAnterior})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaResidSoli(datos.consumoNoValoAnterior, constantes.indicadorResiduos, anioAnterior, '#fac090', instalacion),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justConsNoValoAnte, '#fac090', 'Justificación por ausencia de datos o igual a cero:'),
        {
            pageBreak: 'before',
            text: 'Cuadro N°29', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Medición de la generación de residuos sólidos no valorizables del año anterior al de participación (${anio})`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaResidSoli(datos.consumoNoValolActual, constantes.indicadorResiduos, anio, '#8db3e2', instalacion),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justConsNoValo, '#8db3e2', 'Justificación por ausencia de datos o igual a cero:'),
        informe.tablaEvidencias(datos.docsJustConsumoNoValor),
        {
            pageBreak: 'before',
            text: '6.6. Reducción de residuos no valorizables en al menos un 1%, con respecto al año anterior de participación', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°30', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: `Comparación de generación residuos no valorizables del año ${anioAnterior} con respecto al año ${anio}`, color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.comparResidNoVa(datos.resNoValorizales, true, anio, anioAnterior, 'Tipo de residuo No valorizable'),
        {
            text: 'Siglas' + anio, alignment: 'left', decoration: 'underline', italics: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Multiplicación' + anio, alignment: 'left', italics: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Nota: Los factores de conversión incluidos en este cuadro para obtener las equivalencias son un valor de referencia.' + anio, alignment: 'left', italics: true, margin: [50, 0, 40, 20]
        },
        informe.justificacionReduccion2022(datos.justResiduosNo, '#8db3e2', 'Justificación por aumento en generación de residuos, ausencia de datos o datos igual a cero:'),
        informe.tablaEvidencias(datos.docsJustReduccionNoValor),
        {
            pageBreak: 'before',
            text: '6.7. Trazabilidad de la gestión de residuos de manejo especial', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°31', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Trazabilidad de la gestión de residuos de manejo especial', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.residuosEspecialesTraz22(datos.residuosEspeciales, 'Descripción de la trazabilidad del residuo'),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        //informe.nombreimagen(datos.residuosEspeciales, 'Nombre de Evidencia'),
        informe.evidEspecialesTraz22(datos.residuosEspecialesEvid),
        {
            pageBreak: 'before',
            text: '6.8. Trazabilidad de la gestión de residuos peligrosos', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°32', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Trazabilidad de la gestión de residuos peligrosos', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.residuosEspecialesTraz22(datos.residuosPeligrosos, 'Descripción de la trazabilidad de la gestión'),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
      //  informe.nombreimagen(datos.residuosPeligrosos, 'Nombre de Evidencia'),
        informe.evidEspecialesTraz22(datos.residuosPeligrososEvid),
        {
            pageBreak: 'before',
            text: '6.9. Educación Ambiental', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°33', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación Ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educacionResiduos),
        {
            pageBreak: 'before',
            text: '6.10. Acciones de reducción y control', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°34', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Acción de reducción y control', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]

        },
        informe.tablaAccionesReduccion2022(datos.accionReduccionResiduos),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '7. Gases refrigerantes', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '7.1. Inventarios de equipos que consumen gases refrigerantes', alignment: 'left', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°35', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Inventarios de equipos que consumen gases refrigerantes', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.inventarioContam22(datos.inventarioContAtmos, anio, anioAnterior),
        {
            pageBreak: 'before',
            text: '7.2. Educación Ambiental', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°36', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación Ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educaGases),
        {
            pageBreak: 'before',
            text: '7.3. Acciones de reducción y control', alignment: 'justify', bold: true, margin: [50, 0, 40, 0]
        },
        {
            text: 'Cuadro N°37', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Acción de reducción y control', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaAccionesReduccion2022(datos.accionReduccionContAtmos),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '8. Compras Sostenibles', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '8.1 Criterios de compras sostenibles en ejecución, que permita a la empresa un consumo ambiental y socialmente responsable', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: '', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        informe.criteSotenibles22(datos.programaCompSostEvid),
        {
            text: '', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        informe.comprasSotenibles22(datos.programaCompSost),
        {
            pageBreak: 'before',
            text: '8.2. Productos adquiridos aplicando los criterios de compras sostenibles establecidos en la organización', alignment: 'justify', bold: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Cuadro N°38', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Productos adquiridos aplicando los criterios de compras sostenibles', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.inventCompSost22(datos.productosSost),
        {
            text: '', alignment: 'left', bold: true, margin: [50, 20, 40, 20]
        },
        //informe.nombreimagen(datos.productosSost, 'Nombre de Evidencia'),
        informe.evidCompSost22(datos.producSostEvi),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '9. Adaptación al Cambio Climático', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '9.1 Identificación del impacto directo e indirecto del cambio climático', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°39', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Identificación del impacto directo e indirecto del cambio climático', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.eventosClimaticostab22(datos.eventosClimaticos),
        {
            pageBreak: 'before',
            text: '9.2 Descripción de las acciones ya realizadas por la sede para la adaptación ante el cambio climático.', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°40', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Acciones en adaptación ante al cambio climático', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.accionesEventosClim22(datos.eventosClimaticos),
        {
            pageBreak: 'before',
            text: '9.3 Elaborar un plan de acción de adaptación por el impacto futuro del Cambio Climático', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°41', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Plan de acción de adaptación por el impacto futuro del Cambio Climático', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.planDeAccionTab22(datos.planesDeAccion),

        {
            pageBreak: 'before',
            text: '', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
    //    informe.nombreimagen(datos.evidPlanDeAccion, 'Nombre de Evidencia'),
        informe.evidPlanAccion(datos.evidPlanDeAccion),
        {
            pageBreak: 'before',
            text: '9.4 Educación ambiental', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°42', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educacionAdaptacion),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            text: '10. Compensación Ambiental', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            text: '10.1 Actividad de compensación, con relación al impacto generado por la sede, en alguno de los parámetros antes citados.', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°43', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Actividad de compensación', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.activComponsacion22(datos.actividadesComp),
        {
            pageBreak: 'before',
            text: '', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        //informe.nombreimagen(datos.actividadesComp, 'Nombre de Evidencia'),
        informe.evidencia22(datos.actividadesCompEvid, 'Evidencias de la actividad'),
        {
            pageBreak: 'before',
            text: '10.2 Educación ambiental', alignment: 'justify', bold: true, margin: [50, 20, 40, 0]
        },
        {
            text: 'Cuadro N°44', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Educación ambiental', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.educacionAmbiental2022(datos.educacionCompensacion),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
        {
            pageBreak: 'before',
            text: 'Gradación de Estrellas', alignment: 'center', bold: true, margin: [50, 20, 40, 0],
        },
        {
            ul: [
                { text: 'UNA ESTRELLA:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'El comité local que logre un porcentaje anual del 90 al 100% de los parámetros obligatorios (apartados anteriores de este documento).', alignment: 'justify', italics: true, margin: [50, 0, 40, 20]
        },
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            ul: [
                { text: 'DOS ESTRELLAS:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°45', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Actividades o reconocimiento para optar por Dos estrellas blancas', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaDos(datos.programasGest),
        {
            text: ' ', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        informe.evidencia22(datos.programasGestEvid, 'Evidencias (constancias, certificados, fotografías u otros) del Programa de gestión interno o reconocimiento externo o bien iniciativa para promover la salud integral y la felicidad'),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'TRES ESTRELLAS:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°46', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Actividades para incentivar y acompañar en PBAE (dentro cadena valor) para optar por Tres estrellas blancas', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaTres22(datos.promoPbaeCadVal),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'CUATRO ESTRELLAS:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°47', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Actividades para incentivar y acompañar en PBAE (no vinculadas con cadena valor) para optar por Cuatro estrellas blancas', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaCuatro22(datos.promoPbaeComu),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'CINCO ESTRELLAS:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°48', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Inventario emisiones y remociones de GEI para optar por Cinco estrellas blancas', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaCinco22(datos.inventarioGei),
        {
            text: ' ', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        informe.evideEstrella22(datos.inventarioGeiEvid, 'Evidencia de la cuantificación', 'Fotografías del reporte o gráficos del inventario'),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'SEIS ESTRELLAS:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°49', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Reconocimientos para optar por Seis estrellas blancas', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaSeis22(datos.certCarbNeut),
        {
            text: ' ', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        informe.evideEstrella22(datos.certCarbNeutEvid, 'Evidencia del Reconocimiento', 'Fotografías del reconocimiento'),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'ESTRELLA VERDE:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°50', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Hogares Sostenibles Inscritos', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaVerde22(datos.notaHogares),
        {
            text: ' ', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        informe.evideEstrella22(datos.hogaresEvid, 'Evidencia de la asesoría, acompañamiento y seguimiento', 'Fotografías'),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'ESTRELLA PLATEADA:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°51', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Opción 1 para optar por estrella plateada', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.evideHistEstrella22(datos.progEcolones, 'Certificación de Ecoins indicando que la sede es parte del programa',12),
        informe.evideentrEstrella22(datos.progEcolones, 'Evidencias del tratamiento adecuado:',12),
        informe.evideevidEstrella22(datos.progEcolones, 'Evidencias de la disposición final:',12),

        {
            pageBreak: 'before',
            text: 'Cuadro N°52', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        
        {
            text: 'Opción 2 para optar por estrella plateada', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.estellaPlateada(datos.progEcolones),
        {
            text: ' ', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        informe.evideHistEstrella22(datos.progEcolones, 'Evidencia: Historial de entrega de material de la cuenta de la sede (obligatorio)',13),
        informe.evideentrEstrella22(datos.progEcolones, 'Evidencias de la entrega',13),
        informe.evideevidEstrella22(datos.progEcolones, 'Otras evidencias',13),
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            pageBreak: 'before',
            ul: [
                { text: 'ESTRELLA ANARANJADA:', bold: true, decoration: 'underline' }
            ]
        },
        {
            text: 'Cuadro N°53', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 20, 40, 20]
        },
        {
            text: 'Datos de actividades para optar por estrella anaranjada', color: '#4f81bd', alignment: 'center', fillColor: '#4f81bd', italics: true, margin: [50, 0, 40, 0]
        },
        informe.tablaEstrellaNaranja22(datos.actividadesNaranja),
    ]//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return content;
}