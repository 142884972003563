<template>
    <v-container fluid>
        <EstructuraLegalTabla></EstructuraLegalTabla>
    </v-container>
</template>

<script>
import EstructuraLegalTabla from "@/components/estructuraLegal/EstructuraLegalTablaComponent";
export default {
    components: {
        EstructuraLegalTabla
    }
    
}
</script>