<template>
    <v-container fluid>
        <instalacionesTabla></instalacionesTabla>
    </v-container>
</template>

<script>
import instalacionesTabla from "@/components/instalaciones/InstalacionesTablaComponent";

export default {
    components: {
        instalacionesTabla
    },
    
};
</script>