<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">                    
          <v-icon class="mr-2" color="#0B3F67" v-on="{ ...dialog, ... tooltip }">mdi-pencil</v-icon>
        </template>
        Modificar
      </v-tooltip>              
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Incripción Legal" @cancelar="cancelar"/>
      </v-card-title> 
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field          
          label="Instalación"
          v-model="inscripcion.instalacion.nombre"
          disabled
        ></v-text-field>
        <v-text-field          
          label="Código PBAE"
          v-model="codPbae"
          hint="Se modificará en todas las incripciones de esta instalación"
          :error-messages="codErrors"
          @blur="$v.codPbae.$touch()"
          @change="$v.codPbae.$touch()"
        ></v-text-field>
        <v-text-field          
          label="Año Participación"
          v-model="newAnio"
          :error-messages="anioErrors"
          @blur="$v.newAnio.$touch()"          
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
        <v-btn @click="modificar" text color="#0B3F67">Modificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/axios"
import { required } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  props: [
    'inscripcion'
  ],
  components: {
    ModalTitle
  },
  created() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
  }, 
  mounted() {
    this.newAnio = this.inscripcion.anio
    this.codPbae = this.inscripcion.instalacion.codigoPbae
  },
  computed: {
    usuario(){
      return this.$store.getters.usuario;
    },
    codErrors () {
      const errors = []
      if (!this.$v.codPbae.$dirty) return errors            
      !this.$v.codPbae.required && errors.push('El código PBAE es requerido.')      
      return errors
    },
    anioErrors () {
      const errors = []
      if (!this.$v.newAnio.$dirty) return errors            
      !this.$v.newAnio.required && errors.push('El año es requerido.')  
      !this.$v.newAnio.uniqueAnio && errors.push('Ya existe una inscripción para este año.')
      return errors
    },
  },
  data: () => ({
    dialog: false, 
    newAnio: null,
    codPbae: null
  }),
  validations:{ 
    codPbae: { required },
    newAnio: { 
      required,
      uniqueAnio(anio) {
        if (anio === '') return true
        return new Promise((resolve, reject) => {
          axios.get("/instalaciones/verificarAnioInscrip/" + this.inscripcion.instalacion.id + "/" + anio + "/" + this.inscripcion.id).then(response => {
            resolve(response.data);
          }).catch(error => {
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              reject(error);
            }
          })
        })
      }
    },
  },
  methods: {
    modificar() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.$store.dispatch('loadingPage', true);
        axios.post('/instalaciones/modificarInscripcion', null, {params: {
          anio: this.newAnio,
          idInscripcion: this.inscripcion.id,
          usuario: this.usuario.id,
          codPbae: this.codPbae
        }}).then(response => {
          this.$store.dispatch('loadingPage', false);
          this.$store.dispatch('noti',{mensaje: 'Inscripción modificada', color:'success'});
          this.$emit('reload');
          this.cancelar();
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            this.$store.dispatch('loadingPage', false);
            this.$store.dispatch('noti',{mensaje: 'Error al modificar la inscripción', color:'error'});          
            this.cancelar();
          }
        })
      }
    },
    cancelar() {
      this.dialog = !this.dialog      
    }
  }
}
</script>