<template>
    <v-container fluid>
        <logo @cambiaAnio="getProductos" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Inventario de Compras Sostenibles"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3" align-self="center">
                <agregarProducto @reload="getProductos" v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="2">
                <v-text-field    
                    prepend-inner-icon="mdi-magnify"                
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Inventario de Productos Sostenibles"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="productos"                                                                                
                            item-key="id"                    
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <ModificarProd @reload="getProductos" :productProp="item" v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && item.estadoAnio"/>
                                <AgregarEvidenciaAnio v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && item.estadoAnio" titulo="Agregar Evidencias" tooltipText="Agregar Evidencias" icon="mdi-cloud-upload" :id="item.id" :anio="anioConsulta" :tipo="2"/>
                            </template>
                            <template v-slot:item.estadoAnio="{ item }">
                            <v-layout justify-center>
                                <ActivarProducto 
                                    @reload="getProductos" 
                                    :estado="item.estadoAnio" 
                                    :id="item.id"/>
                            </v-layout>
                        </template>
                            <template v-slot:item.id="{ item }">                                   
                                <listadoDocs :id="item.id" origen="inventario" v-if="item.estadoAnio"/>
                            </template>             
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import AgregarEvidenciaAnio from '@/components/documentos/AgregarEvidenciaAnioComponent.vue'
import AgregarProducto from '@/components/parametros/ComprasSostenibles/AgregarProductoComponent'
import ListadoDocs from '@/components/parametros/ComprasSostenibles/EvidenciasProdSostComponent'
import ModificarProd from '@/components/parametros/ComprasSostenibles/ModificarProductoComponent'
import ActivarProducto from '@/components/parametros/ComprasSostenibles/ActivarProductoComponent'
import axios from '@/axios.js'

export default {
    components: {
    TituloPrincipal,
    TituloSimple,
    AgregarProducto,
    ListadoDocs,
    ModificarProd,
    ActivarProducto,
    NavParameters,
    AgregarEvidenciaAnio,
    Logo,
},
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    props: [
        'navParams'
    ],
    mounted() {
        this.getProductos();
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estadoAnio',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Producto',
                value: 'nombre',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Uso',
                value: 'uso',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Categoria',
                value: 'categoria',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Criterio de Sotenibilidad',
                value: 'criterio',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Evidencias',
                value: 'id',
                align: 'center',
                width: '10%',
                sortable: false
            },
        ],
        productos: [],
        search: null
    }),
    methods: {
        getProductos() {
            let instalacion = this.instalacion.id;
            axios.get(`/comprasSostenibles/getProductosSostenibles/${instalacion}/${this.anioConsulta}`)
            .then(response => {
                this.productos = response.data.productos;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>