<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Informe Anual PBAE"/>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" sm="12" md="6">
               <informeFinal />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import InformeFinal from '@/components/informe/InformeFinalComponent'
import axios from '@/axios.js'

export default {
    components: {
        TituloPrincipal,
        InformeFinal
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
}
</script>