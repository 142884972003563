<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="600px"
    transition="dialog-transition">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" block text color="#0B3F67">Seleccionar Logo</v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Seleccionar Logo" @cancelar="cancelar"/>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="(item, i) in logos" :key="i" cols="12" md="4">
            <v-card flat @click="selectLogo(item)">
              <v-img
                contain
                :src="logoPath(item)"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
  components: {
    ModalTitle
  },
  data: () => ({
    dialog: false,
    logos: [
      'agropecuaria.png', 'bienestar-animal.jpeg', 'biodiversidad.png', 'cambio-climatico.png',
      'Carbono Neutral.png', 'centros-educativos.png', 'comunidad-clima-neutral.png', 'comunidades.png',
      'construccion-sostenible.png', 'eclesial-ecologica.png', 'ecodiplomatica.png', 'espacios-naturales-protegidos.png',
      'eventos-especiales.jpeg', 'hogares-sostenibles.png', 'microcuencas.png', 'movilidad-sostenible.png',
      'municipalidades.png', 'playas.png', 'salud-comunitaria.png'
    ]
  }),
  methods: {
    selectLogo(logo) {
      this.$emit('cambiarLogo', logo)
      this.cancelar();
    },
    logoPath(logo) {
      return require('@/assets/' + logo);
    },
    cancelar() {
      this.dialog = !this.dialog;
    }
  },
}
</script>