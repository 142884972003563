<template>   
  <v-dialog
    v-model="dialog"
    scrollable 
    persistent
    max-width="500px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-icon large v-on="{...dialog, ...tooltip}" @click="aniosInscripcion" color="#0B3F67">mdi-gas-station</v-icon>
        </template>
        Detalle Combustibles
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Modificar Combustibles" @cancelar="cancelar"/>
      </v-card-title> 
      <v-divider></v-divider>
      <v-card-text>
        <div class="title pt-2 pb-2">
          <strong class="title">¡Advertencia!</strong><br>
          <div class="subtitle-1">Se modificarán los parámetros para <strong>Combustibles fósiles
            </strong> para la instalación <strong>{{ instalacion }}</strong>
            en el año seleccionado.
          </div>
        </div>
        <v-divider></v-divider>
        <v-list 
          subheader
          two-line>
            <v-subheader class="text-md-h6">Parámetros Combustibles por año</v-subheader>
            <v-list-item
              v-for="(anio, i) in anios"
              :key="i"
            >
              <v-list-item-avatar>
                <v-icon
                  large
                  :color="iconColor(anio[1])"
                  dark
                >
                  {{ fuelIcon(anio[1]) }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <strong class="text-md-h6">{{ anio[0] }}</strong> 
                  <div class="text-md-subtitle-1">{{ textInfo(anio[1]) }}</div>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip right>
                  <template v-slot:activator="{ on:tooltipDos }">
                      <v-btn icon v-on="tooltipDos"
                        @click="activarInstalacion(anio[0], anio[1])">
                        <v-icon large :color="powerBtn(anio[1])">mdi-power</v-icon>
                      </v-btn>
                    </template>
                  {{ textTooltip(anio[1]) }}
                </v-tooltip>
              </v-list-item-action>
              
            </v-list-item>
        </v-list>
      </v-card-text> 
    </v-card>
  </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'

export default {
    props: [
        'id',
        'estadoComb',
        'instalacion'
    ],
    components: {
      ModalTitle
    },
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
      usuario(){
        return this.$store.getters.usuario;
      },
      estadoActual: {
        get() {
            return this.estadoComb;
        },
        set(estadoComb) {
            this.nuevoEstado = estadoComb;
        }
      }
    },
    data: () => ({
        nuevoEstado: null,
        loading: false,
        dialog: false,
        anios: []
    }),
    methods: {
      aniosInscripcion() {
        axios.get(`/combustibles/combustiblesAnio/${this.id}`).then(response => {
          this.anios = Object.keys(response.data).map((key) => [Number(key), response.data[key]]);
        }).catch(error => {
          console.error(error);
        })
      },
      activarInstalacion(anio, estado) {
        this.loading = true;
        this.nuevoEstado = !estado
        axios.put("/instalaciones/activarCombustibles/"+this.id, null, { params: {
            anio: anio,
            consumeComb: this.nuevoEstado,
            usuario: this.usuario.id
        }}).then(response => {
            this.loading = false;
            this.$emit('reload');
            this.$store.dispatch('noti',{mensaje: 'Estado modificado', color:'success'});
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log(error);
            this.loading = false;
            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el estado', color:'error'});
          }
        }).finally(() => {
          this.cancelar()
        })
      },
      fuelIcon(estado) {
        if(estado) {
          return "mdi-gas-station"
        } else {
          return 'mdi-gas-station-off-outline'
        }
      },
      iconColor(estadoComb) {
        if(estadoComb) {
          return "#0B3F67"
        } else {
          return 'grey darken-1'
        }
      },
      textTooltip(estadoComb) {
        if(estadoComb) {
          return 'Inactivar Combustibles'
        } else {
          return 'Activar Combustibles'
        }
      },
      textInfo(estadoComb) {
        if(estadoComb) {
          return 'Combustibles Caso 1'
        } else {
          return 'Combustibles Caso 2'
        }
      },
      powerBtn(estadoComb) {
        if(estadoComb) {
          return 'green darken-2'
        } else {
          return 'error'
        }
      },
      cancelar() {
        this.dialog = !this.dialog
      }
    }
};
</script>
