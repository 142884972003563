<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" class="white--text" v-on="on">Agregar Actividad<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Actividad de Compesación" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" v-if="anioConsulta > 2021">
                        <v-select
                            :items="parametros"
                            label="Parámetro Asociado"
                            item-text="nombre"
                            item-value="id"
                            v-model="actividadComp.parametroAsociado"
                            :error-messages="paramErrors"             
                            @change="$v.actividadComp.parametroAsociado.$touch()"
                            @blur="$v.actividadComp.parametroAsociado.$touch()"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-select
                            :items="listActividades"
                            item-value="actividadComp.actividad"
                            label="Actividad Compesación"
                            v-model="actividadComp.actividad"
                            :error-messages="actErrors"             
                            @change="$v.actividadComp.actividad.$touch()"
                            @blur="$v.actividadComp.actividad.$touch()"
                            counter="500"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12 ">
                        <v-textarea
                            rows="3"
                            label="Logros"
                            counter="500"
                            v-model="actividadComp.logros"
                            :error-messages="logrErrors"             
                            @change="$v.actividadComp.logros.$touch()"
                            @blur="$v.actividadComp.logros.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12 ">
                        <v-textarea
                            rows="3"
                            label="Beneficiarios"
                            counter="400"
                            v-model="actividadComp.beneficiarios"
                            :error-messages="beneErrors"             
                            @change="$v.actividadComp.beneficiarios.$touch()"
                            @blur="$v.actividadComp.beneficiarios.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12 ">
                        <v-textarea
                            rows="2"
                            label="Ubicación"
                            counter="100"
                            v-model="actividadComp.ubicacion"
                            :error-messages="ubiErrors"             
                            @change="$v.actividadComp.ubicacion.$touch()"
                            @blur="$v.actividadComp.ubicacion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Cantidad de Participantes"
                            v-model="actividadComp.cant_participantes"
                            v-mask="mask"
                            hint="Ingresar solo números"
                            :error-messages="cantErrors"             
                            @change="$v.actividadComp.cant_participantes.$touch()"
                            @blur="$v.actividadComp.cant_participantes.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="actividadComp.fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="12" v-if="anioConsulta > 2021">
                        <v-text-field
                            label="Cantidad de Árboles"
                            v-model="actividadComp.cantArboles"
                            type="number"
                            hint="Ingresar solo números"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter 
                    multiple 
                    v-model="archivos" 
                    :error-messages="docErrors"
                    @input="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()"
                    label="Agregar Evidencias">
                </v-file-input>
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter 
                    multiple 
                    v-model="archivosDisp" 
                    :error-messages="docErrors"
                    @input="$v.archivosDisp.$touch()"
                    @blur="$v.archivosDisp.$touch()"
                    label="Evidencias Disposición Residuos">
                </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarActividadComp">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total <= maximo;
    }
    return true;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted() {
        this.getData();
    },
    components: {
      ModalTitle
    },
    directives: {
        mask,
    },
    computed: {
        fechaMoment () {
            return this.actividadComp.fechaActividad ? moment(this.actividadComp.fechaActividad).format('DD/MM/YYYY') : '';
        },
        actErrors () {
            const errors = []
            if (!this.$v.actividadComp.actividad.$dirty) return errors
            !this.$v.actividadComp.actividad.required && errors.push('Campo requerido')
            !this.$v.actividadComp.actividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        logrErrors () {
            const errors = []
            if (!this.$v.actividadComp.logros.$dirty) return errors
            !this.$v.actividadComp.logros.required && errors.push('Campo requerido')
            !this.$v.actividadComp.logros.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        beneErrors () {
            const errors = []
            if (!this.$v.actividadComp.beneficiarios.$dirty) return errors
            !this.$v.actividadComp.beneficiarios.required && errors.push('Campo requerido')
            !this.$v.actividadComp.beneficiarios.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        ubiErrors () {
            const errors = []
            if (!this.$v.actividadComp.ubicacion.$dirty) return errors
            !this.$v.actividadComp.ubicacion.required && errors.push('Campo requerido')
            !this.$v.actividadComp.ubicacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.actividadComp.cant_participantes.$dirty) return errors
            !this.$v.actividadComp.cant_participantes.required && errors.push('Campo requerido')
            return errors
        },
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        }, 
        archivosDispErrors () {
            const errors = []
            if (!this.$v.archivosDisp.$dirty) return errors            
            !this.$v.archivosDisp.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        paramErrors () {
            const errors = []
            if (!this.$v.actividadComp.parametroAsociado.$dirty) return errors
            !this.$v.actividadComp.parametroAsociado.required && errors.push('Campo requerido')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this. $store.getters.sucursal;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        mask: '####',
        listActividades: ['Protección de mantos acuíferos','Campaña de reciclaje','Reforestación','Jornada de limpieza','Educación ambiental externa'],
        actividadComp: {
            actividad: '',
            fechaActividad: new Date().toISOString().substr(0, 10),
            logros: '',
            beneficiarios: '',
            ubicacion: '',
            cant_participantes: 0,
            parametroAsociado: 0,
            cantArboles: 0
        },
        archivos:[],
        parametros: [],
        archivosDisp:[],
    }),
    validations: {
        actividadComp: {
            actividad: {
                maxLength: maxLength(500),
                required
            },
            logros: {
                maxLength: maxLength(500),
                required
            },
            beneficiarios: {
                maxLength: maxLength(400),
                required
            },
            ubicacion: {
                maxLength: maxLength(100),
                required
            },
            cant_participantes: {
                required
            },
            fechaActividad: {
                required
            },
            parametroAsociado: {
                required: requiredIf(function() { return this.anioConsulta > 2021 }), 
            }
        },
        archivos: {
            maxFileSize
        },
        archivosDisp: {
            maxFileSize
        },
    },
    methods: {
        agregarActividadComp() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                this.archivosDisp.forEach(file => {
                    formData.append('archivosDisp', file);
                });
                formData.append('actividad', this.actividadComp.actividad);
                formData.append('logros', this.actividadComp.logros);
                formData.append('beneficiarios', this.actividadComp.beneficiarios);
                formData.append('ubicacion', this.actividadComp.ubicacion);
                formData.append('cant_participantes', this.actividadComp.cant_participantes);
                formData.append('fechaActividad', this.actividadComp.fechaActividad);
                formData.append('idInstalacion', this.instalacion.id);
                formData.append('idUsuario', this.usuario.id);
                formData.append('parametroAsociado', this.actividadComp.parametroAsociado != undefined ? this.actividadComp.parametroAsociado : 0);
                formData.append('cantArboles', this.actividadComp.cantArboles != undefined ? this.actividadComp.cantArboles : 0);
                axios.post("/actividadCompesacion/agregar", formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Actividad de Compesación agregada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de compesación', color:'error'});  
                      this.cancelar();   
                    }
                  }         
                })
            }
        },
        getData() {
            if(this.anioConsulta > 2021) {
                this.loadingModal(true)
                axios.get('/actividadCompesacion/getDataAgregar')
                .then(response => {
                    this.parametros = response.data
                }).catch(err => {
                    console.error(err);
                }),
                this.loadingModal(false)
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        cancelar() {
            this.actividadComp = {};
            this.actividadComp.fechaActividad = new Date().toISOString().substr(0, 10);
            this.archivos=[];
            this.$v.actividadComp.$reset();       
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>