<template>
    <v-dialog
        v-model="dialog"
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <a v-on="on">Olvidé mi contraseña</a>
        </template> 
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Recuperar Contraseña" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field
                    label="Usuario"
                    hint="Ingrese su usuario"
                    v-model="usuario"
                    :error-messages="usuarioErrors"
                    @blur="$v.usuario.$touch()"
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cerrar</v-btn>
                <v-btn v-if="!emailEnviado" :loading="loading" color="#0B3F67" @click="generarPassword" text>Recuperar</v-btn>
            </v-card-actions>
            <v-alert class="" icon="mdi-alert-decagram" :color="type" v-model="alert" clearable>
                {{ mensaje }}
            </v-alert>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "axios"
import { required } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'

export default {
    computed: {
        usuarioErrors() {
            const errors = []
            if (!this.$v.usuario.$dirty) return errors
            !this.$v.usuario.required && errors.push('Ingrese el nombre de usuario.')
            !this.$v.usuario.existsUser && errors.push('Nombre de usuario invalido.')
            return errors
        }
    },
    components: {
      ModalTitle
    },
    data: () => ({
        usuario: "",
        dialog: false,
        loading: false,
        alert: false,
        type: "",
        emailEnviado: false,
        mensaje: ""
    }),
    validations: {
        usuario: {
            required,
            existsUser(usuario) {
                if (usuario === '') return true
                return new Promise((resolve, reject) => {
                    axios.get(process.env.BASE_URL+"api/user/auth/verificarUsuario/" + usuario).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        reject(error);
                      }
                    })
                })
            }
        }
    },
    methods: {
        generarPassword() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                axios.get(process.env.BASE_URL+"api/user/auth/recuperarPassword/" + this.usuario)
                .then(response => {
                    this.showAlert("Se envió un correo con su nueva contraseña.", "success", true, true);
                    this.loading = false;
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.showAlert("No se pudo generar un password nuevo, contacte al administrador.", "error", true, false);
                    this.loading = false;
                  }
                })
            }
        },
        cancelar() {
            this.usuario = "";
            this.loading = false;
            this.showAlert("", "", false, false);
            this.$v.usuario.$reset();
            this.dialog = false;
        },
        showAlert(mensaje, tipo, show, enviado) {
            this.type = tipo;
            this.mensaje = mensaje;
            this.alert = show;
            this.emailEnviado = enviado;
        }
    }
}
</script>