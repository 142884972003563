<template>
    <v-dialog
        v-model="dialog"          
        persistent 
        scrollable
        max-width="700px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                                
                    <v-icon @click="getDataModificar" :color="iconColor" v-on="{...tooltip, ...dialog}">{{ icon }}</v-icon>
                </template>
                {{tipoModal}} Sustancia
            </v-tooltip>                            
        </template>        
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle :titulo="tipoModal" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field                            
                            label="Nombre"
                            v-model="sustancia.nombreSustancia"
                            :error-messages="nameErrors"
                            @input="$v.sustancia.nombreSustancia.$touch()"
                            @blur="$v.sustancia.nombreSustancia.$touch()"
                            counter="100"
                            hint="Nombre de la Sustnacia"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-select
                            :items="clasificaciones"
                            v-model="clasificacionSga"
                            :error-messages="clasificacionErrors"             
                            @change="$v.clasificacionSga.$touch()"
                            @blur="$v.clasificacionSga.$touch()"
                            required
                            label="Clasificación SGA"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field                            
                            label="Cantidad Máxima Almacenada"
                            hint="Cantidad Máxima Almacenada"
                            v-model="sustancia.cantMaxalmacenamiento"
                            :error-messages="cantErrors"
                            @input="$v.sustancia.cantMaxalmacenamiento.$touch()"
                            @blur="$v.sustancia.cantMaxalmacenamiento.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-select
                            :items="tipos"
                            v-model="tipoSustancia"
                            label="Tipo de  Residuo"
                            :error-messages="tipoErrors"             
                            @change="$v.tipoSustancia.$touch()"
                            @blur="$v.tipoSustancia.$touch()"
                            counter="250"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="">
                        <v-select
                            :items="tipoTratamiento"
                            v-model="tratamiento"
                            label="Tipo de Tratamiento"
                            :error-messages="tratamientoErrors"             
                            @change="$v.tratamiento.$touch()"
                            @blur="$v.tratamiento.$touch()"
                            counter="500"
                            required
                        ></v-select>
                    </v-col>                    
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field                            
                            label="Gestor Autorizado"
                            hint="Gestor Autorizado"
                            counter="200"
                            v-model="sustancia.gestor"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field                            
                            label="Lugar de Almacenamiento"
                            hint="Lugar de Almacenamiento"
                            v-model="sustancia.almacenamiento"
                            :error-messages="almacenaErrors"
                            counter="250"
                            @input="$v.sustancia.almacenamiento.$touch()"
                            @blur="$v.sustancia.almacenamiento.$touch()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" text @click="agregarSustancia">{{ tipoModal }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import { required, decimal, maxLength } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    mounted() {
        this.getData();
    },
    components: {
      ModalTitle
    },
    props: [
        'tipoModal',
        'sustanciaModif',
        'icon',
        'iconColor'
    ],
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        nameErrors () {
            const errors = []
            if (!this.$v.sustancia.nombreSustancia.$dirty) return errors            
            !this.$v.sustancia.nombreSustancia.required && errors.push('Ingrese el nombre de la sustancia.')  
            !this.$v.sustancia.nombreSustancia.maxLength && errors.push('Este dato excede el tamaño permitido.')          
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.sustancia.cantMaxalmacenamiento.$dirty) return errors            
            !this.$v.sustancia.cantMaxalmacenamiento.required && errors.push('Dato requerido.')     
            !this.$v.sustancia.cantMaxalmacenamiento.decimal && errors.push('Deben ser solo números.')              
            return errors
        },
        almacenaErrors () {
            const errors = []
            if (!this.$v.sustancia.almacenamiento.$dirty) return errors            
            !this.$v.sustancia.almacenamiento.required && errors.push('Dato requerido.')    
            !this.$v.sustancia.almacenamiento.maxLength && errors.push('Este dato excede el tamaño permitido.')          
            return errors
        },
        clasificacionErrors () {
            const errors = []
            if (!this.$v.clasificacionSga.$dirty) return errors            
            !this.$v.clasificacionSga.required && errors.push('Seleccione un clasificación.')           
            return errors
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.tipoSustancia.$dirty) return errors            
            !this.$v.tipoSustancia.required && errors.push('Seleccione un tipo.')    
            !this.$v.tipoSustancia.maxLength && errors.push('Este dato excede el tamaño permitido.')           
            return errors
        },
        tratamientoErrors () {
            const errors = []
            if (!this.$v.tratamiento.$dirty) return errors            
            !this.$v.tratamiento.required && errors.push('Seleccione un tipo de tratamiento.')        
            !this.$v.tratamiento.maxLength && errors.push('Este dato excede el tamaño permitido.')      
            return errors
        },
    },
    data: () => ({
        dialog: false,
        clasificaciones: [],
        tipos: [],
        tipoTratamiento:[],
        sustancia: {
            nombreSustancia: '',			                                   
            cantMaxalmacenamiento: '',                       
            almacenamiento: '',                                              
            gestor: '',            
        },
        clasificacionSga: '',
        tratamiento: '',
        tipoSustancia: ''
    }),
    validations: {
        sustancia: {
            nombreSustancia: {
                maxLength: maxLength(100),
                required
            },			                                   
            cantMaxalmacenamiento: {
                required,
                decimal
            },                       
            almacenamiento: {
                maxLength: maxLength(250),
                required
            },                                                                    
        },
        clasificacionSga: {
            required
        },
        tipoSustancia: {
            maxLength: maxLength(250),
            required
        },
        tratamiento: {
            maxLength: maxLength(500),
            required
        },        
    },
    methods: {
        getData() {
            axios.get("/tratamientoAgua/getDataSustancias")
            .then(response => {
                this.clasificaciones = response.data.clasificaciones;
                this.tipos = response.data.tipos;
                this.tipoTratamiento = response.data.tipoTratamiento;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);    
              }            
            })
        },
        agregarSustancia() {
            this.$v.$touch();
            if(!this.$v.$invalid) {     
                this.loadingModal(true);           
                if(this.tipoModal === 'Agregar') {                    
                    axios.post('/tratamientoAgua/agregarSustancia', this.sustancia, {params: {
                        inst: this.instalacion.id,
                        user: this.usuario.id,
                        clasificacionSga: this.clasificacionSga,
                        tratamiento: this.tratamiento,
                        tipoSustancia: this.tipoSustancia
                    }})
                    .then(response => {
                        this.$emit('reload');
                        this.$store.dispatch('noti', {mensaje: "Sustancia peligrosa agregada", color: "success"});
                        this.cancelar();
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.$store.dispatch('noti', {mensaje: "Ocurrió un error no se agregó la sustancia peligrosa", color: "error"});
                        this.cancelar();
                      }
                    })
                } else {                    
                    axios.put('/tratamientoAgua/modificarSustancia', this.sustancia, {params: {
                        inst: this.instalacion.id,
                        user: this.usuario.id,
                        clasificacionSga: this.clasificacionSga,
                        tratamiento: this.tratamiento,
                        tipoSustancia: this.tipoSustancia
                    }})
                    .then(response => {
                        this.$emit('reload');
                        this.$store.dispatch('noti', {mensaje: "Sustancia peligrosa modificada", color: "success"});
                        this.cancelar();
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        this.cancelar();
                        this.$store.dispatch('noti', {mensaje: "Ocurrió un error no se modificó la sustancia peligrosa", color: "error"});
                      }
                    })
                }                
            }
        },
        cancelar() {
            this.sustancia = {};
            this.clasificacionSga = '';
            this.tratamiento = '';
            this.tipoSustancia = '';
            this.$v.$reset();
            this.dialog = false;
        },
        getDataModificar() {
            if(this.tipoModal === 'Modificar') {
                this.sustancia = this.sustanciaModif;
                this.clasificacionSga = this.sustanciaModif.clasificacionSgaName;
                this.tratamiento = this.sustanciaModif.tratamientoName;
                this.tipoSustancia = this.sustanciaModif.tipoSustanciaName;
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);            
        },
    }
}
</script>