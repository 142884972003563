<template>    
    <v-switch :loading="loading" inset @change="activarContaminante" color="#0B3F67" v-model="estadoActual"></v-switch>    
</template>

<script>
import axios from "@/axios";


export default {
    props: [
        'estado',
        'id'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    computed: {
        estadoActual: {
            get() {
                return this.estado;
            },
            set(estado) {
                this.nuevoEstado = estado;
            }
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        nuevoEstado: null,
        loading: false
    }),
    methods: {
        activarContaminante() {
            this.loading = true;
            axios.put("/productoContaAtmosf/cambiaEstado", null, { params: {
                invState: this.nuevoEstado,
                idContaminante: this.id,
                idUsuario: this.usuario.id,
                anio: this.anioConsulta
            }}).then(response => {
                this.loading = false;
                this.$emit('reload');
                this.$store.dispatch('noti',{mensaje: `Producto agregado al año ${this.anioConsulta}` , color:'success'});
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error.response);
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el gas refrigerante', color:'error'});
                this.loading = false;
              }
            })
        }
    }
}
</script>