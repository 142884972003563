<template>
    <v-dialog v-model="dialog" persistent max-width="1000px" transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
        <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">
            <v-btn @click="getDocumentos" large dark icon v-on="{ ...tooltip, ...dialog }">
                <v-icon color="#0B3F67">mdi-file-multiple</v-icon>
            </v-btn>
            </template>
            Ver Archivos Adjuntos
        </v-tooltip>
        </template>
        <v-card>
        <v-card-title class="pa-0">
            <ModalTitle :titulo="titulo" @cancelar="cerrar"/>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                item-key="id"
                no-data-text="No hay documentos adjuntos"
                :footer-props="footer">
                <template v-slot:item.action="{ item }">
                    <ModicafDoc 
                        class="mr-2" 
                        :documento="item.id"
                        :inst="instalacion.id"
                        @reload="getDocumentos"
                    />
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="#0B3F67" @click="borrarDocumentoJustificacion(item.documentoId)">mdi-trash-can-outline</v-icon>
                        </template>
                        Eliminar Evidencia
                    </v-tooltip>
                </template>
                <template v-slot:item.fechaRegistro="{ item }">
                    {{ dateFormat(item.fechaRegistro) }}
                </template>
                <template v-slot:item.documentoId="{ item }">      
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="#0B3F67" @click="descargarDocumento(item.documentoId)">mdi-cloud-download-outline</v-icon>
                        </template>
                        Descargar
                    </v-tooltip>      
                </template>
            </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cerrar" color="error">Cerrar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from '@/axios'
import moment from "moment"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import ModicafDoc from "@/components/documentos/ModificarDocComponent"
export default {
    components: {
        ModalTitle,
        ModicafDoc
    },
    props: {
        tipoDocumento: {
            type: Number,
            default: 0,
            required: false
        },
        titulo: {
            type: String,
            default: 'Evidencias Justificación',
            required: false
        },
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
    },
    data: () => ({
        dialog: false,
        headers: [
        { 
            text: "Acciones",
            value: "action",
            align: "center",
            align: 'center',
            width: '20%',
            sortable: false
        },
        {
            text: "Nombre",
            value: "nombre",
            align: "center",
            sortable: true
        },
        {
            text: "Origen",
            value: "origen",
            align: "center",
            sortable: true
        },
        {
            text: "Fecha Registro",
            value: "fechaRegistro",
            align: "center",
            sortable: true
        },
        {
            text: "Archivo",
            align: "center",
            sortable: false,
            value: "documentoId"
        }
        ],
        footer: {
            disableItemsPerPage: true,
            showFirstLastPage: true,
            firstIcon: "mdi-page-first",
            lastIcon: "mdi-page-last",
            prevIcon: "mdi-chevron-left",
            nextIcon: "mdi-chevron-right",
            itemsPerPageText: "Registros por página",
            itemsPerPageAllText: "Todos",
            showCurrentPage: true
        },
        items: []
    }),
    methods: {
        getDocumentos() {
            this.loadingModal(true)
            let instalacion = this.instalacion.id;
            axios.get(`/instalaciones/getDocsJustificacion/${instalacion}/${this.anioConsulta}/${this.indicadorActual}/${this.tipoDocumento}`).then(response => {
                this.items = response.data;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.loadingModal(false)
            })
        },
        descargarDocumento(documentoId) {
            axios({
                url: '/documento/descargar/' + documentoId,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
        dateFormat(fecha) {
            return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
        },
        borrarDocumentoJustificacion(documentoId) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar el documento?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.loadingModal(true)
                    axios.post("/instalaciones/borrarDocumentoJustificacion", null, {params:{
                        instalacion: this.instalacion.id,
                        anio: this.anioConsulta,
                        indicador: this.indicadorActual,
                        tipoDocumento: this.tipoDocumento,
                        usuario: this.usuario.id,
                        doc: documentoId
                    }}).then(response=>{
                        this.$store.dispatch('noti',{mensaje: 'Evidencia eliminada', color:'success'});
                        this.loadingModal(false)
                        this.getDocumentos();
                        this.$emit('reload');
                    }).catch(error=>{
                        if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                        } else {
                            console.log(error);
                            this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se eliminó la evidencia', color:'error'});
                            this.$store.dispatch('loadingPage', false);
                        }
                    })
                }
            });
        },
        cerrar() {
            this.dialog = false
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        }
    },
}
</script>