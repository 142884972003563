<template>
    <v-dialog
        v-model="dialog" 
        persistent
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip top v-if="tipoAccion === 'Agregar'">
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="#0B3F67" dark v-on="{ ...tooltip, ...on }">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
                </template>
                <span>Agregar Nuevo Evento</span>
            </v-tooltip>            
            <v-tooltip top v-else>
                <template v-slot:activator="{ on: tooltip }"> 
                    <v-icon
                        color="#FFAB00"                                                
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getDataEvento()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar Evento</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle :titulo="tipoAccion" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            :label="eventoLabel"
                            v-model="evento.evento"
                            counter="200"
                            :error-messages="eventErrors"             
                            @change="$v.evento.evento.$touch()"
                            @blur="$v.evento.evento.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            :label="impactoLabel"
                            v-model="evento.impacto"
                            counter="200"
                            :error-messages="impactErrors"             
                            @change="$v.evento.impacto.$touch()"
                            @blur="$v.evento.impacto.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" v-if="anioConsulta > 2021">
                        <v-textarea
                            outlined
                            label="Impacto a la Sede o parte interesada"
                            v-model="evento.ubicacionImpacto"
                            counter="500"
                            :error-messages="impactErrors"             
                            @change="$v.evento.impacto.$touch()"
                            @blur="$v.evento.impacto.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn v-if="tipoAccion === 'Agregar'" color="#0B3F67" @click="agregar" text>{{ tipoAccion }}</v-btn>
                <v-btn v-else color="#0B3F67" @click="modificar" text>{{ tipoAccion }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import axios from '@/axios.js'

export default {
    props: [
      'tipoAccion',
      'eventoProp',
      'instalacion',
      'usuario'
    ],
    components: {
      ModalTitle
    },
    computed: {
      anioConsulta() {
        return this. $store.getters.anioConsulta;
      },
      eventoLabel() {
        return this.anioConsulta > 2021 ? 'Evento hidrometeorológico' : 'Evento Climático';
      },
      impactoLabel() {
        return this,this.anioConsulta > 2021 ? 'Impacto directo e indirecto' : 'Impacto Ocasionado' 
      },
      eventErrors() {
        const errors = []
        if (!this.$v.evento.evento.$dirty) return errors            
        !this.$v.evento.evento.required && errors.push('El evento es requerido.')
        !this.$v.evento.evento.maxLength && errors.push('Este dato excede el tamaño permitido.')
        return errors
      },
      impactErrors() {
        const errors = []
        if (!this.$v.evento.impacto.$dirty) return errors            
        !this.$v.evento.impacto.required && errors.push('El impacto es requerido.')
        !this.$v.evento.impacto.maxLength && errors.push('Este dato excede el tamaño permitido.')
        return errors
      },
      ubicacionErrors() {
        const errors = []
        if (!this.$v.evento.ubicacionImpacto.$dirty) return errors            
        !this.$v.evento.ubicacionImpacto.required && errors.push('El dato es requerido.')
        !this.$v.evento.ubicacionImpacto.maxLength && errors.push('Este dato excede el tamaño permitido.')
        return errors
      }
    },
    data: () => ({
        dialog: false,
        evento: {
            evento: null,
            impacto: null,
            ubicacionImpacto: null
        }
    }),
    validations: {
        evento: {
            evento: {
                required,
                maxLength: maxLength(200)       
            },
            impacto: {
                required,
                maxLength: maxLength(200)     
            },
            ubicacionImpacto: {
                required: requiredIf(function() { 
                    return this.anioConsulta >= 2022;
                }),
                maxLength: maxLength(500)     
            },
        }        
    },
    methods: {
        getDataEvento() {
            this.evento.evento = this.eventoProp.evento;
            this.evento.impacto = this.eventoProp.impacto;
            this.evento.id = this.eventoProp.id;
            this.evento.ubicacionImpacto = this.eventoProp.ubicacionImpacto;
    },
        agregar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.post('/adaptacion/agregarEvento', this.evento, {params: {
                    inst: this.instalacion.id,
                    usuario: this.usuario.id,
                    anio: this.anioConsulta
                }}).then(response => {
                    this.$store.dispatch('noti', {mensaje: 'Evento climático agregado', color: 'success'});
                    this.loadingModal(false);
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo agregar el evento climático', color: 'error'});
                    this.loadingModal(false);
                  }
                })
            }
        },
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.put('/adaptacion/modificarEvento', this.evento, {params: {
                    eventoModId: this.evento.id,
                    inst: this.instalacion.id,
                    usuario: this.usuario.id
                }}).then(response => {
                    this.$store.dispatch('noti', {mensaje: 'Evento climático modificado', color: 'success'});
                    this.loadingModal(false);
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo modificar el evento climático', color: 'error'});
                    this.loadingModal(false);
                    this.cancelar();
                  }
                })
            }
        },
        cancelar() {
            this.evento = {};
            this.$v.evento.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>