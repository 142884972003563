<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Inscribir Hogares" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="hogarInscrito.nombre"
                            label="Nombre del Hogar"
                            :error-messages="nombreErrors"
                            @input="$v.hogarInscrito.nombre.$touch()"
                            @blur="$v.hogarInscrito.nombre.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Comité Encargado de la Revisión del Informe"
                            v-model="hogarInscrito.comiteRevision"
                            :error-messages="comiteErrors"             
                            @change="$v.hogarInscrito.comiteRevision.$touch()"
                            @blur="$v.hogarInscrito.comiteRevision.$touch()"
                            counter="100"
                            required>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Persona de Contacto"
                            v-model="hogarInscrito.personaContacto"
                            :error-messages="personaErrors"             
                            @change="$v.hogarInscrito.personaContacto.$touch()"
                            @blur="$v.hogarInscrito.personaContacto.$touch()"
                            counter="200"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Teléfono"
                            v-model="hogarInscrito.telefono"
                            v-mask="telMask"
                            :error-messages="telefonoErrors"             
                            @change="$v.hogarInscrito.telefono.$touch()"
                            @blur="$v.hogarInscrito.telefono.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Correo"
                            v-model="hogarInscrito.correo"
                            :error-messages="correoErrors"             
                            @change="$v.hogarInscrito.correo.$touch()"
                            @blur="$v.hogarInscrito.correo.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Estrellas Ganadas"
                            v-model="hogarInscrito.estrellasGanadas"
                            :error-messages="estrellasErrors"             
                            @change="$v.hogarInscrito.estrellasGanadas.$touch()"
                            @blur="$v.hogarInscrito.estrellasGanadas.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Calificación Final"
                            v-model="hogarInscrito.calificacionFinal"
                            :error-messages="calificacionErrors"             
                            @change="$v.hogarInscrito.calificacionFinal.$touch()"
                            @blur="$v.hogarInscrito.calificacionFinal.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Informes Recibidos"
                            v-model="hogarInscrito.informesRecibidos"
                            :error-messages="recibidosErrors"             
                            @change="$v.hogarInscrito.informesRecibidos.$touch()"
                            @blur="$v.hogarInscrito.informesRecibidos.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Informes Aprobados"
                            v-model="hogarInscrito.informesAprobados"
                            :error-messages="aprobadosErrors"             
                            @change="$v.hogarInscrito.informesAprobados.$touch()"
                            @blur="$v.hogarInscrito.informesAprobados.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Años Acompañando Hogar"
                            v-model="hogarInscrito.aniosAcomp"
                            :error-messages="aniosAcompErrors"             
                            @change="$v.hogarInscrito.aniosAcomp.$touch()"
                            @blur="$v.hogarInscrito.aniosAcomp.$touch()"
                            type="number"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-checkbox 
                            label="Galardon anterior"                     
                            v-model="hogarInscrito.galardonAnterior"
                            color="#0B3F67"
                            :value="true">                    
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha que presentaron el formulario"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaFormulario" locale="es-CR" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input label="Adjuntar Evidencias"
                            accept=".png, .jpeg, .jpg"
                            v-model="evidencias"
                            show-size     
                            multiple                           
                            clearable
                            :error-messages="evidenciasErrors"             
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarEstrella">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import { mask } from "vue-the-mask";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, email, integer, decimal, minValue, maxValue, maxLength } from "vuelidate/lib/validators";
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    computed: {
        fechaMoment () {
            return this.fechaFormulario ? moment(this.fechaFormulario).format('DD/MM/YYYY') : '';
        },
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        nombreErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.nombre.$dirty) return errors            
            !this.$v.hogarInscrito.nombre.required && errors.push('El nombre del hogar es requerido.')
            !this.$v.hogarInscrito.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')    
            return errors
        },
        comiteErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.comiteRevision.$dirty) return errors            
            !this.$v.hogarInscrito.comiteRevision.required && errors.push('El nombre del comité es requerido.')
            !this.$v.hogarInscrito.comiteRevision.maxLength && errors.push('No debe exceder el tamaño permitido.')               
            return errors
        },
        personaErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.personaContacto.$dirty) return errors            
            !this.$v.hogarInscrito.personaContacto.required && errors.push('El nombre de la persona de contacto es requerido.')            
            !this.$v.hogarInscrito.personaContacto.maxLength && errors.push('No debe exceder el tamaño permitido.')  
            return errors
        },
        telefonoErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.telefono.$dirty) return errors            
            !this.$v.hogarInscrito.telefono.required && errors.push('El teléfono es requerido.')            
            return errors
        },
        correoErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.correo.$dirty) return errors            
            !this.$v.hogarInscrito.correo.required && errors.push('El correo es requerido.')            
            !this.$v.hogarInscrito.correo.email && errors.push('El formato del correo es inválido.')            
            return errors
        },
        estrellasErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.estrellasGanadas.$dirty) return errors            
            !this.$v.hogarInscrito.estrellasGanadas.required && errors.push('Las estrellas ganadas son requeridas.')            
            !this.$v.hogarInscrito.estrellasGanadas.integer && errors.push('Solo debe digitar números.')            
            return errors
        },
        calificacionErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.calificacionFinal.$dirty) return errors            
            !this.$v.hogarInscrito.calificacionFinal.required && errors.push('La calificación final es requerida.')            
            !this.$v.hogarInscrito.calificacionFinal.decimal && errors.push('Solo debe digitar números.')
            !this.$v.hogarInscrito.calificacionFinal.minValue && errors.push('La calificación debe ser mayor a 0.')
            !this.$v.hogarInscrito.calificacionFinal.maxValue && errors.push('La calificación debe ser menor a 100.')
            return errors
        },
        evidenciasErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('La evidencia es requerida.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        recibidosErrors () {
            const errors = []
            if (!this.$v.hogarInscrito.informesRecibidos.$dirty) return errors            
            !this.$v.hogarInscrito.informesRecibidos.required && errors.push('La cantidad de informes recibidos es requerida.')            
            !this.$v.hogarInscrito.informesRecibidos.decimal && errors.push('Solo debe digitar números.')            
            return errors
        },
        aprobadosErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.informesAprobados.$dirty) return errors            
            !this.$v.hogarInscrito.informesAprobados.required && errors.push('La cantidad de informes aprobados es requerida.')            
            !this.$v.hogarInscrito.informesAprobados.decimal && errors.push('Solo debe digitar números.')            
            return errors
        },
        aniosAcompErrors () {
            const errors = []
            if (!this.$v.hogarInscrito.aniosAcomp.$dirty) return errors            
            !this.$v.hogarInscrito.aniosAcomp.required && errors.push('Dato es requerido.')
            !this.$v.hogarInscrito.aniosAcomp.integer && errors.push('Solo debe digitar números.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        telMask: '####-####',
        hogarInscrito: {
            nombre: '',
            comiteRevision: '',
            personaContacto: '',
            telefono: '',
            correo: '',
            estrellasGanadas: '',
            calificacionFinal: '',
            informesAprobados: '',
            informesRecibidos: '',
            aniosAcomp: 0,
            galardonAnterior: null
        },
        evidencias: [],
        fechaFormulario: new Date().toISOString().substr(0, 10),
    }),
    validations: {
        hogarInscrito: {
            nombre: { required, maxLength: maxLength(100) },
            comiteRevision: { required, maxLength: maxLength(100) },
            personaContacto: { required, maxLength: maxLength(200) },
            telefono: { required },
            correo: { required, email },
            estrellasGanadas: { required, integer },
            calificacionFinal: { required, decimal, minValue: minValue(0), maxValue: maxValue(100) },
            informesAprobados: { required, decimal },
            informesRecibidos: { required, decimal },
            aniosAcomp: { required, integer },
        },
        evidencias: { required, maxFileSize, }
    },
    methods: {
        cancelar() {
            this.hogarInscrito = {};
            this.evidencias = [];
            this.dialog = false;
            this.loading = false;
            this.$v.hogarInscrito.$reset();
            this.$v.evidencias.$reset();
            this.fechaFormulario = new Date().toISOString().substr(0, 10);
        },
        agregarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.evidencias.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append("idInstalacion", this.idInstalacion);
                formData.append("idUsuario", this.idUsuario);
                formData.append("hogarInscritoS", JSON.stringify(this.hogarInscrito));
                formData.append("anio", this.$store.getters.anioConsulta);
                formData.append("fechaFormulario", this.fechaFormulario);
                axios.post("/estrellas/agregaHogarInscrito", formData)
                .then(response => {
                    this.$emit("agregaHogares");
                    this.$store.dispatch("noti", { mensaje: "Hogar agregado", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch("noti", { 
                          mensaje: "Ocurrió un error no se agregó el hogar",
                          color: "error"
                      });
                      this.cancelar();
                    }
                  }
                });
            }
        }
    }
};
</script>
