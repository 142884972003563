<template>
    <v-container fluid>        
        <EficienciasTabla></EficienciasTabla>
    </v-container>
</template>

<script>

import EficienciasTabla from "@/components/eficiencia/EficienciasTablaComponent";

export default {
    components: {
        EficienciasTabla
    },
};
</script>