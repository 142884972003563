<template>
        <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition"
    >
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                small
                exact-active-class="indigo"
                class="ma-2"
                v-on="{ ...tooltip, ...on }"
                @click="getDataModificar()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Residuo" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                    <v-text-field
                        label="Nombre de Residuo"
                        v-model="residuo.nombreResiduo"
                        :error-messages="nombreErrors"             
                        @change="$v.residuo.nombreResiduo.$touch()"
                        @blur="$v.residuo.nombreResiduo.$touch()"
                        counter="50"
                        required                        
                    ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Descripcion de Trazabilidad"
                            v-model="residuo.descripcionTrazabilidad"
                            :error-messages="descripcionErrors"             
                            @change="$v.residuo.descripcionTrazabilidad.$touch()"
                            @blur="$v.residuo.descripcionTrazabilidad.$touch()"
                            counter="500"
                            required                            
                            >
                        </v-text-field>    
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Gestor Autorizado"
                            counter="100"
                            :error-messages="gestorErrors"             
                            @change="$v.residuo.gestorAutorizado.$touch()"
                            @blur="$v.residuo.gestorAutorizado.$touch()"
                            v-model="residuo.gestorAutorizado"  
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-select
                            :items="tipoTratamiento"
                            item-text="nombre"
                            item-value="valor"
                            v-model="residuo.tipoTratamiento"
                            :error-messages="tratErrors"             
                            @change="$v.residuo.tipoTratamiento.$touch()"
                            @blur="$v.residuo.tipoTratamiento.$touch()"
                            required                             
                            label="Tipo de Tratamiento"
                        ></v-select> 
                    </v-col> 
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="12" v-if="anioConsulta > 2021">
                        <v-text-field
                            label="Disposición Final"
                            v-model="residuo.disposicionFinal"
                            :error-messages="disposicionErrors"             
                            @change="$v.residuo.disposicionFinal.$touch()"
                            @blur="$v.residuo.disposicionFinal.$touch()"
                            counter="500"
                            required                            
                            >
                        </v-text-field>    
                    </v-col> 
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarResiduo">Modificar</v-btn>
            </v-card-actions>          
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
    components: {
      ModalTitle
    },
    computed:{
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        tipoResiduo(){
            switch(this.origen){
                case 0:
                    return 0;
                    break;
                case 1:
                    return 1;
                    break;
            }
        },
        nombreErrors(){
            const errors = []
            if (!this.$v.residuo.nombreResiduo.$dirty) return errors
            !this.$v.residuo.nombreResiduo.required && errors.push('Campo requerido')
            !this.$v.residuo.nombreResiduo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descripcionErrors(){
            const errors = []
            if (!this.$v.residuo.descripcionTrazabilidad.$dirty) return errors
            !this.$v.residuo.descripcionTrazabilidad.required && errors.push('Campo requerido')
            !this.$v.residuo.descripcionTrazabilidad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors            
        },
        tratErrors(){
            const errors = []
            if (!this.$v.residuo.tipoTratamiento.$dirty) return errors
            !this.$v.residuo.tipoTratamiento.required && errors.push('Campo requerido')
            return errors            
        },        
        gestorErrors() {
            const errors = []
            if (!this.$v.residuo.gestorAutorizado.$dirty) return errors
            !this.$v.residuo.gestorAutorizado.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors 
        },
        disposicionErrors(){
            const errors = []
            if (!this.$v.residuo.disposicionFinal.$dirty) return errors
            !this.$v.residuo.disposicionFinal.required && errors.push('Campo requerido')
            !this.$v.residuo.disposicionFinal.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors            
        },
    },
    props:["id"],
    validations: {
        residuo:{
            nombreResiduo:{
                maxLength: maxLength(50),
                required
            },
            descripcionTrazabilidad:{
                maxLength: maxLength(500),
                required
            },
            tipoResiduo:{
                required
            },
            tipoTratamiento:{
                required
            },
            gestorAutorizado:{
                maxLength: maxLength(100),
            },
            disposicionFinal: {
                required: requiredIf(function(){
                    return (this.anioConsulta > 2021) 
                }),
                maxLength: maxLength(300)
            }
        }
    },
    data:()=>({
        menu:false,
        dialog:false,
        loading:false,
        fechaActividad: null,
        residuo:{
            nombreResiduo:'',
            descripcionTrazabilidad:'',
            gestorAutorizado:'',
            tipoResiduo:'',
            tipoTratamiento:'',
            fechaRegistro:'',
            disposicionFinal: ''
        },
        tipoTratamiento: [
            {nombre:'RECICLAJE', valor: 1},              
            {nombre:'ESTERILIZACION', valor: 2},
            {nombre:'INCINERACION', valor: 3},
            {nombre:'REUTILIZACIÓN', valor: 4},
            {nombre:'RELLENO_SANITARIO', valor: 5},
            {nombre:'PLANTA DE TRATAMIENTO', valor: 6},
            {nombre:'DESINFECCIÓN', valor: 7},
            {nombre:'NEUTRALIZACION', valor: 8},
            {nombre:'GASIFICACION', valor: 9},
            {nombre:'ENCAPSULAMIENTO', valor: 10},
            {nombre:'REUTILIZACION', valor: 11},
            {nombre:'RECUPERACION', valor: 12},
            {nombre:'VERTEDERO', valor: 13}
        ], 
    }),
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    methods:{
        modificarResiduo(){
            this.$v.$touch()
            delete this.residuo.tratamiento;
            if(!this.$v.$invalid){
                this.loading = true;
                axios.put("/gestionResiduos/modificar/", this.residuo, {params:{
                    idUsuario: this.usuario.id,
                    fechaActividad: this.fechaActividad,
                }}).then(response=>{
                    this.$emit("reload");
                    this.cancelar();
                    this.$store.dispatch("noti", {mensaje: "Residuo modificado",color: "success"});
                }).catch(error=>{
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.cancelar()
                    this.$store.dispatch("noti", {mensaje: "Ocurrió un error no se modificó el residuo",color: "error"});                
                  }
             })
            }
        },
        getDataModificar(){
            axios.get("/gestionResiduos/getDataModificar/"+this.id).then(response=>{
                this.residuo = response.data.residuo;
                this.fechaActividad = this.dateFormat(this.residuo.fechaRegistro);
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
              }
            })
        },
        dateFormat(fecha) {
            return new Date(fecha).toISOString().substr(0, 10);
        },
        cancelar(){
            this.fechaActividad='';
            this.residuo={};
            this.dialog = false;
            this.$v.residuo.$reset(); 
            this.loading = false;
        }
    }
}
</script>