<template>
    <v-container fluid> 
        <v-row 
            v-if="rolUsuario != 'CEO' && 
                  rolUsuario != 'GERENTE' && 
                  rolUsuario != 'SUPERVISOR' &&
                  tipo <= 9">
            <v-col>
                <component 
                    v-if="!estrellaVerde && 
                          rolUsuario != 'CEO' && 
                          rolUsuario != 'GERENTE' && 
                          rolUsuario != 'SUPERVISOR' &&
                          tipo <= 9"
                    :is="botonAgregar"
                    :titulo="'Agregar ' + titulo"
                    :tipo="tipo"
                    @recargaTabla="listaEstrellas" />
            </v-col>
        </v-row>
        <br> 
        <v-row>
            <v-col cols="12">
                <v-card>       
                    <v-sheet
                        v-if="estrellaVerde"
                        :height="alturaHoja()"
                        class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center"
                        color="#0B3F67"
                        elevation="12"
                        max-width="calc(100% - 32px)">
                        <v-layout fill-height align-center justify-center>{{tituloTabla}}</v-layout>
                    </v-sheet>                              
                    <v-data-table
                        :loading="cargando"
                        multi-sort    
                        :search="search"        
                        :headers="headers"
                        :items="items"
                        class="elevation-1"       
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }">
                        <template 
                            v-slot:item.action="{ item }">
                            <component 
                                v-if="rolUsuario != 'CEO' && 
                                      rolUsuario != 'GERENTE' && 
                                      rolUsuario != 'SUPERVISOR'"
                                :is="botonModificar" 
                                :titulo="titulo" 
                                :tipo="tipo"
                                :id="item.id"
                                @recargaTabla="listaEstrellas"/>
                            <AgregarEvidencia
                                v-if="rolUsuario != 'CEO' &&
                                      rolUsuario != 'GERENTE' &&
                                      rolUsuario != 'SUPERVISOR' &&
                                      tipo != 9"
                                :idActividad="item.id"
                                :origen="'estrellas'"
                                :tipo="tipo"
                                tooltipText="Agregar Evidencias"
                                :titulo="'Agregar Evidencias'">
                            </AgregarEvidencia>
                            <Eliminar v-if="tipo <= 9"
                              v-bind:requestData="requestDataFormat(item)"
                              :requestUrl="urlEliminar"
                              @reload="reloadData"
                            />
                        </template>
                        <template v-slot:item.categoria="{ item }">
                            {{ tipo === 3 ? item.categoriaCadena : item.categoriaComunidad }}
                        </template>
                        <template v-slot:item.fecha="{ item }">
                            {{
                                tipo === 2 ? dateFormat(item.fechaEjecucion) : 
                                tipo === 3 || tipo === 4 ? dateFormat(item.fechaActividad) : 
                                tipo === 5 || tipo === 6 ? dateFormat(item.fechaCuantificacion) : 
                                dateFormat(item.fecha)
                            }}
                        </template>
                        <template 
                            v-slot:item.evidencias="{ item }">
                            <ListadosDocs 
                                :idActividad="item.id" 
                                :medidorId="tipo"
                                :titulo="'Evidencias'"
                                :origen="'estrellas'" 
                            ></ListadosDocs>
                        </template>
                    </v-data-table>                       
                </v-card>
            </v-col>
        </v-row>       
    </v-container>
</template>

<script>
import moment from "moment"
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import axios from "@/axios.js";
import Eliminar from "@/components/utils/EliminarComponent";

export default {
    components: {
        ListadosDocs,
        AgregarEvidencia,
        AgregarSeguEstrella: () => import("@/components/Estrellas/AgregarSegEstrellaComponent"),
        AgregarTerCuarEstrella: () => import("@/components/Estrellas/AgregarTerCuarEstrellaComponent"),
        AgregarQuintSextEstrella: () => import("@/components/Estrellas/AgregarQuinSextEstrellaComponent"),
        AgregarEstrellaPlateada: () => import("@/components/Estrellas/AgregarEstrellaPlateadaComponent"),
        ModificarSeguEstrella: () => import("@/components/Estrellas/ModificarSegEstrellaComponent"),
        ModificarTerCuarEstrella: () => import("@/components/Estrellas/ModificarTerCuarEstrellaComponent"),
        ModificarQuintSextEstrella: () => import("@/components/Estrellas/ModificarQuinSextEstrellaComponent"),
        ModificarEstrellaVerde: () => import("@/components/Estrellas/ModificarEstrellaVerdeComponent"),
        ModificarEstrellaPlateada: () => import("@/components/Estrellas/ModificarEstrellaPlateadaComponent"),
        Eliminar,
        AgregarActEstrellaNaranja: () => import("@/components/Estrellas/AgregarActEstrellaNaranjaComponent"),
        //ModificarEstrellaNaranja: () => import("@/components/Estrellas/ModificarActEstrellaNaranjaComponent"),
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    mounted () {
        this.listaEstrellas();
    },
    props: [
        'titulo',
        'tipo',
        'headers',
        'tituloTabla',
        'hogares',
    ],
    data: () => ({
        search: '',
        data: [],
        cargando: false
    }),
    computed: {
        items() {
            return this.tipo != 7 ? this.data : this.hogares;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        botonAgregar() {
            return this.tipo === 2 ? 'AgregarSeguEstrella' :
                this.tipo === 3 ? 'AgregarTerCuarEstrella' :
                this.tipo === 4 ? 'AgregarTerCuarEstrella' :
                this.tipo === 5 ? 'AgregarQuintSextEstrella' :
                this.tipo === 6 ? 'AgregarQuintSextEstrella' : 
                this.tipo === 9 ? 'AgregarActEstrellaNaranja' :
                'AgregarEstrellaPlateada';
        },
        botonModificar() {
            return this.tipo === 2 ? 'ModificarSeguEstrella' :
                this.tipo === 3 ? 'ModificarTerCuarEstrella' :
                this.tipo === 4 ? 'ModificarTerCuarEstrella' :
                this.tipo === 5 ? 'ModificarQuintSextEstrella' :
                this.tipo === 6 ? 'ModificarQuintSextEstrella' : 
                this.tipo === 7 ? 'ModificarEstrellaVerde' : 
                this.tipo === 8 ? 'ModificarEstrellaPlateada' :
                //this.tipo === 9 ? 'ModificarEstrellaNaranja' :
                'ModificarHistorialHogar';
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        estrellaVerde() {
            return this.tipo === 7;
        },
        urlEliminar() {
            switch (this.tipo) {
                case 2:
                    return '/estrellas/historialEstrellas/eliminarPrograma';
                case 3:
                    return '/estrellas/eliminarPromocadenaValor'
                case 4:
                    return '/estrellas/eliminarPromoComunidad'
                case 5:
                    return '/estrellas/eliminarInventarioGei'
                case 6:
                    return '/estrellas/eliminarCertificadoCarbono'
                case 7:
                    return '/estrellas/eliminarHogares'
                case 8:
                    return '/estrellas/eliminarIniciativaEcoColon'
                case 9:
                    return '/estrellas/eliminarActividadEstrellaNaranja'
                default:
                    return ''
            }
        }
    },
    methods: {
        alturaHoja() {
            return this.$mq === "sm" ? 120 : 80;
        },
        listaEstrellas() {
            let anio = this.anioConsulta;
            if (this.tipo === 2)
                this.listaProgramas(anio);
            else if (this.tipo === 3)
                this.listaPromocionCadena(anio);
            else if (this.tipo === 4)
                this.listaPromocionComunidad(anio);
            else if (this.tipo === 5)
                this.listarInventarioEmision(anio);
            else if (this.tipo === 6)
                this.listarCertificacionCarbono(anio);
            else if (this.tipo === 7)
                this.$emit("modificaHogar");
            else if (this.tipo === 8)
                this.listarIniciativasEcolones(anio);
            else if (this.tipo === 9)
                this.listarActividadesNaranja(anio)
        },
        dateFormat(fecha) {
            return fecha ? moment(fecha).format("DD/MM/YYYY") : "";
        },
        listaProgramas(anio) {
            this.cargando = true;
            axios.get("/estrellas/getProgramaGestionInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.programas;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        listaPromocionCadena(anio) {
            this.cargando = true;
            axios.get("/estrellas/getPromoCadenaValorInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.promociones;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        listaPromocionComunidad(anio) {
            this.cargando = true;
            axios.get("/estrellas/getPromoComunidadInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.promociones;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        listarInventarioEmision(anio) {
            this.cargando = true;
            axios.get("/estrellas/getInventarioGeiInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.inventarios;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        listarCertificacionCarbono(anio) {
            this.cargando = true;
            axios.get("/estrellas/getCertificadoCarbonoInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.certificados;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        listarIniciativasEcolones(anio) {
            this.cargando = true;
            axios.get("/estrellas/getIniciativaEcolonInst/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.iniciativas;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        listarActividadesNaranja(anio) {
            this.cargando = true;
            axios.get("/estrellas/getActividadesEstreNaranja/" + this.idInstalacion + '/' + anio)
            .then(response => {
                this.data = response.data.actividades;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            });
        },
        requestDataFormat(item) {
            const idUsuario = this.$store.getters.usuario.id;
            const fd = new FormData();
            fd.set('usuario', idUsuario);
            switch (this.tipo) {
                case 2:
                    fd.set('programa', item.id);
                    break;
                case 3:
                case 4:
                    fd.set('promocionS', item.id);
                    break;
                case 5:
                    fd.set('inventarioS', item.id);
                    break;
                case 6:
                    fd.set('certificadoS', item.id);
                case 7:
                    fd.set('hogarInscrito', item.id);
                case 8:
                    fd.set('iniciativaEcoColon', item.id);
                    break;
                case 9:
                    fd.set('actividad', item.id);
                    break;
                default:
                    break;
            }
            return fd;
        },
        reloadData() {
            this.listaEstrellas();
        }
    }

}
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>

