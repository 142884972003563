<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Identificación de Amenazas Climáticas"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <IdentificarAmenazas @reload="getData" :amenazas="items" :escala="escala"/>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Amenazas Climáticas"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VueSticky from 'vue-sticky'
    import Logo from '@/components/utils/CompanyComponent'
    import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
    import TituloSimple from '@/components/utils/TituloSimpleComponent'
    import NavParameters from '@/components/utils/NavParameterComponent'
    import IdentificarAmenazas from '@/components/parametros/Adaptacion/IdentificarAmenazasComponent'
    import axios from '@/axios.js'

    export default {
        components: {
            TituloPrincipal,
            TituloSimple,
            NavParameters,
            Logo,
            IdentificarAmenazas,
        },
        props: [
            'navParams'
        ],
        directives: {
            'sticky': VueSticky,
        },
        computed: {
            usuario() {
                return this.$store.getters.usuario;
            },
            instalacion() {
                return this.$store.getters.sucursal;
            },
            rolUsuario(){
                return this.$store.getters.usuario.rol;
            },
            anioConsulta() {
                return this. $store.getters.anioConsulta;
            },
            parametroActual() {
                return this.$store.getters.parametroActual;
            }
        },
        created() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
        },
        mounted() {
            this.getData()
        },
        data: () => ({
            search: null,
            items:[],
            escala: [],
            headers: [
                { 
                    text: "Amenaza climática",
                    value: "amenazaNombre",
                    align: "center",
                    width: '20%',
                    sortable: false
                },
                { 
                    text: "Definición",
                    value: "amenazaDefinicion",
                    align: "left",
                    width: '60%',
                    sortable: false
                },
                { 
                    text: "Nivel de exposición ",
                    value: "exposicionNombre",
                    align: "center",
                    width: '40%',
                    sortable: false
                },
            ]
        }),
        methods: {
            getData() {
                axios.get("/adaptacion/getDataAmenazasClimaticas/" + this.instalacion.id + "/" +this.anioConsulta)
                .then(response => {
                    this.items = response.data.amenazas;
                    this.escala = response.data.escala;
                    this.loadingModal(false);
                }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                } else {
                    this.loadingModal(false);
                }
                })
            },

            loadingModal(modal) {                        
                this.$store.dispatch('loadingPage', modal);
            }
        },
    }
</script>

<style scoped>

</style>