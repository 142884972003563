<template>
  <v-card flat class="transparent">
    <v-card-text>
      <v-skeleton-loader
        v-bind="attrs"
        height="50px"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        height="50px"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        height="50px"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        height="50px"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        height="50px"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        height="50px"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        height="100px"       
        type="card"
      ></v-skeleton-loader>
    </v-card-text>
    <v-card-actions>
      <v-skeleton-loader
        v-bind="attrs"
        height="32px"
        width="100%"
        type="card"
      ></v-skeleton-loader>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
    },
  })
}
</script>