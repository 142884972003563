<template>
     <v-card>
        <v-card-text>
          <v-select
                v-if="isAdmin"
                :items="orgs"
                v-model="idOrganizacion"
                item-text="nombre"
                item-value="id"
                label="Seleccione una Organización"
                :error-messages="orgErrors"             
                @change="instalacionesPorUsuario"
                @blur="$v.idOrganizacion.$touch()"
            ></v-select>
            <v-select
                :items="instalaciones"
                v-model="idInstalacion"
                item-text="nombre"
                item-value="id"
                label="Seleccione una Instalación"
                :error-messages="instalErrors"             
                @change="getDataAnios"
                @blur="$v.idInstalacion.$touch()"
            ></v-select>
            <v-select
                :items="anios"
                v-model="anio"
                no-data-text="Seleccione primero la instalación"
                label="Año de Participación"
                :error-messages="anioErrors"             
                @change="$v.anio.$touch()"
                @blur="$v.anio.$touch()"
            ></v-select>            
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn @click="getDataInformeFinal" class="white--text" block color="#0B3F67">Generar Informe</v-btn>
        </v-card-actions>
        <v-alert type="error" :value="alert" clearable>
            {{ mensaje }}
        </v-alert>
    </v-card>
</template>
<script>
import { informe2024 } from '@/informes/Categoria_IV/informe-2024'
import { informe2023 } from '@/informes/Categoria_IV/informe-2023'
import { informe2022 } from '@/informes/Categoria_IV/informe-2022'
import { informe2021 } from '@/informes/Categoria_IV/informe-2021'
import { informe2020 } from '@/informes/Categoria_IV/informe-2020'
import { required, requiredIf } from "vuelidate/lib/validators";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import diccionario from '@/imageDictionary.js';
import axios from '@/axios.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    mounted() {        
      if(this.isAdmin) {
        this.getDataAdmin();
      } else {
        this.instalacionesPorUsuario();
      }
    },
    computed: {
        organizacion() {
            return this.$store.getters.organizacion;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        anioAnterior() {
            let anterior = this.anio;
            return anterior - 1;
        },
        anioErrors () {
            const errors = []
            if (!this.$v.anio.$dirty) return errors            
            !this.$v.anio.required && errors.push('Dato requerido.')            
            return errors
        },
        instalErrors () {
            const errors = []
            if (!this.$v.idInstalacion.$dirty) return errors            
            !this.$v.idInstalacion.required && errors.push('Dato requerido.')            
            return errors
        },
        orgErrors () {
            const errors = []
            if (!this.$v.idOrganizacion.$dirty) return errors            
            !this.$v.idOrganizacion.required && errors.push('Dato requerido.')            
            return errors
        },
        isAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN'
        },
    },
    data: () => ({
        anio: null,
        alert: false,
        mensaje: null,
        anios: [],
        instalaciones: [],
        instalacion: null,
        idInstalacion: null,
        orgs: [],
        idOrganizacion: null,
        datos: {
          organizacionNombre: '',
          orgaLogo: '', //logo organizacion
          inscripcionLegal: '', //incripcion legal
          flotaCasoUno: [],
          potabilidadAgua: [],
          comiteAmbiental: [],
          coordinador: '',
          tipoSistemaTrat: '',
          croquisSist: '',
          sistTratEvidencias: [],
          sustanPeligrosasEvi: [],
          equiposContaminantes: [],
          evideRefrigerantes: [],
          programaCompSost: '',
          programaCompSostEvid: '',
          //decripcion Organizacion
          descripcionOrg: '',
          //estrellas informe
          estrellasInforme: '',
          //Adaptacion
          eventosClimaticos: [], 
          evidPlanDeAccion: [],
          planesDeAccion: [],
          //Compensacion
          actividadesComp: [],
          actCompEvid: [],
          educAmbCompensacion: [],
          actividadesCompEvid: [],
          educAmbCompensacionEvid: [],
          //productos sostenibles
          productosSost: [],
          producSostEvi: [],
          //Productos sustituidos
          prdSustituidos: [],
          evidPrdSustituido: [],
          //consumos
          consumoCombutibles: [],
          consumoCombAnterior: [],
          consumoEnergiaActual: [],
          consumoEnergiaAnterior: [],
          consumoAguaActual: [],
          consumoAguaAnterior: [],
          consumoResiduosAnterior: [],
          consumoResiduosActual: [],
          residuosEspeciales: [],
          residuosPeligrosos: [],
          residuosPeligrososEvid: [],
          residuosEspecialesEvid: [],
          inventarioContAtmos: [],
          //Justificaciones de reduccion
          justAgua: '',
          justCombus: '',
          justEnergia: '',
          justContam: '',
          justResPapel: '',
          justResiduos: '',
          justPotabilidadAgua: '',
          //Justificaciones de combustibles
          justCombusInve: '',
          justCombusCons: '',
          justCombConsAnte: '',
          //Justificaciones de energia
          justEnerCons: '',
          justEnerConsAnte: '',
          //Justificaciones de agua
          justAguaCons: '',
          justAguaConsAnte: '',
          //Justificaciones de residuos
          justResiduCons: '',
          justResiduConsAnte: '',
          justResiCons: '',
          justConsPapel: '',
          justConsValo:'',
          justConsNoValo: '',
          justConsValoAnte:'',
          justConsNoValoAnte: '',
          justResiduosNo: '',
          //Justificaciones de contaminantes
          justContaConsAnte: '',
          //Evidencias justificaciones de reduccion
          justAnioAgua: '',
          justAnioComb: '',
          justAnioEnergia: '',
          justAnioConta: '',
          justAnioResiduosPapel: '',
          justAnioResiduos: '',
          justifResidPeligrosos: '',
          //datos de reduccion comparacion de reduccion de consumos
          reducCombust: [],
          reduccionAgua: [],
          reduccionEnergia: [],
          reduccionPapel: [],
          resValorizales: [],
          resNoValorizales: [],
          //Educacion Ambiental
          educaCombustibles: [],
          educEvidenCombust: [],
          educacionAgua: [],
          educEvidenAgua: [],
          educacionEnergia: [],
          educEvideEnergia: [],
          educcacionAguasRes: [],
          educEvidenAguResid: [],
          educacionResiduos: [],
          educacionResiduosEvid: [],
          educacionAdaptacion: [],
          educacionAdaptacionEvid: [],
          educacionCompensacion: [],
          educacionCompensacionEvid: [],
          educaGases: [],
          //acciones de reduccion
          accionReducComb: [],
          accReducEvidenCombust: [],
          accionReducAgua: [],
          accReducEvidenAgua: [],
          accionReducAguResid: [],
          accionReducEvidAguResid: [],
          accionReduccionEnergia: [],
          accionReduccionEnergiaEvid: [],
          accionReduccionResiduos: [],
          accionReduccionResiduosEvid: [],
          accionReduccionContAtmos: [],
          accionReducEvidContAtmos: [],
          //combustibles caso 2
          accionPrimerSegOrden: [],
          eviAccionPrimerSegOrden: [],
          //Estrellas adicionales
          //Estrella 2
          programasGest: [],
          programasGestEvid: [],
          //Estrella 3
          promoPbaeCadVal: [],
          promoPbaeCadValEvid: [],
          //Estrella 4
          promoPbaeComu: [],
          promoPbaeComuEvid: [],
          //Estrella 5
          inventarioGei: [],
          inventarioGeiEvid: [], 
          //Estrella 6
          certCarbNeut: [],
          certCarbNeutEvid: [],
          //Estrella verde
          hogaresSostData: [],
          notaHogares: [],
          hogaresEvid: [],
          //Estrella Plateada
          progEcolones: [],
          progEcolonesEvid: [],
          //acciones de prevencion
          accionesPrevencion: [],
          evidAccionesPrevencion: [],
          accionesLimpieza: [],
          evidaccionesLimpieza: [],
          permisoVertido: [],
          reporteOperacional: [],
          comprobantePago: [],
          reporteDatos: [],
          permisoProveedor: [],
          permisoSanitario: [],
          constanciaProveedor: [],
          constanciaDescarga: [],
          constanciaTragua: [],
          programaCompras: [],
          fuenteAbastecimiento:-1,
          potabilidadAguaPropia: [],
          actividadesNaranja: [],
          consumoValoActual: [],
          consumoValoAnterior: [],
          consumoNoValolActual: [],
          consumoNoValoAnterior: [],
          //evidencias justificacion
          docsJustCalidadAgua: [],
          docsJustConsumoAgua: [],
          docsJustReducciongua: [],
          docsJustConsumoEnergia: [],
          docsJustReduccionEnergia: [],
          docsJustInventarioComb: [],
          docsJustConsumoComb: [],
          docsJustReduccionComb: [],
          docsJustConsumoPapel: [],
          docsJustConsumoValor: [],
          docsJustConsumoNoValor: [],
          docsJustReduccionPapel: [],
          docsJustReduccionValor: [],
          docsJustReduccionNoValor: [],
          docsJustSistemaTratami: [],
          educacionAmbientalCaso2: [],
          accionesReduccionCaso2: [],
          usoProducSostEvi: [],
          amenazas: [],
          escalas:[],
          impactos: [],
          actCompDispEvid: [],
          accionesAdaptacion:[]
        },
        //definicion del documento
        docDefinition: {
            footer: function(currentPage, pageCount) { 
                if(currentPage !== 1)
                    return {columns: [
                        { width: '10%', image: diccionario.logoBanderaAzulUno, fit: [50, 50], alignment:'center' },
                        { width: '90%', text: 'Programa Bandera Azul Ecológica', alignment: 'left', margin: 12 }
                    ]}; 
            },   
            pageSize: 'B4',
            content: [],
            styles: {
                table: {
                  margin: [0, 0, 0, 15]
                },
            },
        },
    }),
    validations: {
        anio: { required },
        idInstalacion: { required },
        idOrganizacion: { 
          required: requiredIf(function(){
            return this.isAdmin
          })  
        }
    },
    methods: {
        generarReporte() {
          let orga  = this.isAdmin ? this.orgs.find( organizacion => organizacion.id === this.idOrganizacion ) : this.organizacion;
          if(this.anio >= 2024) {
            this.docDefinition.content = informe2024(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2023) {
            this.docDefinition.content = informe2023(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2022) {
            this.docDefinition.content = informe2022(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2021) {
            this.docDefinition.content = informe2021(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2020) {
            this.docDefinition.content = informe2020(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          
            pdfMake.createPdf(this.docDefinition).download(`Informe final PBAE-CCC ${this.anio} ${this.instalacion.codigoPbae} ${this.instalacion.nombreOrganizacion} ${this.instalacion.nombre}.pdf`);
            this.loadingModal(false);
        },
        aniosParticipacion() {
            this.loadingModal(true);
            axios.get("/instalaciones/getAniosParticipacionInforme/" + this.idInstalacion).then(response => {  
                    this.anios = response.data.anios;   
                    this.loadingModal(false);           
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los años de participacion: " + error);
                this.loadingModal(false);   
              }
            })
        },
        getDataAnios() {
            this.aniosParticipacion();
            this.$v.idInstalacion.$touch();
        },
        instalacionesPorUsuario() {
            let orga = this.isAdmin ? this.idOrganizacion : this.organizacion.id;
            axios.get("/organizacion//listarInstalacionesInforme/"+ orga).then(response => {  
                    this.instalaciones = response.data.instalaciones;              
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log( error);
              }
            })
        },
        getDataAdmin() {
          this.loadingModal(true)
          axios.get("/organizacion//listarOrganizaciones/").then(response => {  
                  this.orgs = response.data.organizaciones;
                  this.loadingModal(false)              
          }).catch(error => {
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
              this.loadingModal(false)
            } else {
              console.log( error);
              this.loadingModal(false)
            }
          })
        },
        getDataInformeFinal() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.instalacion  = this.instalaciones.find( instalacion => instalacion.id === this.idInstalacion );
                this.loadingModal(true);
                axios.get("/informe/getDataInformeFinal/" + this.instalacion.id + "/" + this.anio)
                .then(response => {
                    this.datos.potabilidadAgua = response.data.potabilidad;
                    this.datos.potabilidadAguaPropia = response.data.potabilidadPropia;
                    this.datos.inscripcionLegal = response.data.inscripcion;
                    this.datos.comiteAmbiental = response.data.comiteLocal;
                    this.datos.coordinador = response.data.coordinador;
                    this.datos.orgaLogo = response.data.logoOrg;
                    this.datos.flotaCasoUno = response.data.flotaCasoUno;
                    this.datos.consumoCombutibles = response.data.consumoComb;
                    this.datos.reducCombust = response.data.reducCombust;
                    this.datos.consumoCombAnterior = response.data.consumoCombAnterior;
                    this.datos.educaCombustibles = response.data.educaCombustibles;
                    this.datos.educEvidenCombust = response.data.eviEducCombust;
                    this.datos.accionReducComb = response.data.accionesReducComb;
                    this.datos.accReducEvidenCombust = response.data.accReducEvidenCombust;
                    this.datos.accionPrimerSegOrden = response.data.flotaCasoDos;
                    this.datos.eviAccionPrimerSegOrden = response.data.eviAccionPrimerSegOrden;
                    this.datos.consumoAguaAnterior = response.data.consumoAguaAnterior;
                    this.datos.consumoAguaActual = response.data.consumoAguaActual;
                    this.datos.reduccionAgua = response.data.reducAgua;
                    this.datos.educacionAgua = response.data.educacionAgua;
                    this.datos.educEvidenAgua = response.data.educEvidenAgua;
                    this.datos.accionReducAgua = response.data.accionReducAgua;
                    this.datos.accReducEvidenAgua = response.data.accReducEvidenAgua;
                    this.datos.educcacionAguasRes = response.data.educcacionAguasRes;
                    this.datos.educEvidenAguResid = response.data.educEvidenAguResid;
                    this.datos.accionReducAguResid = response.data.accionReducAguResid;
                    this.datos.accionReducEvidAguResid = response.data.accionReducEvidAguResid;
                    this.datos.tipoSistemaTrat = response.data.tipoSistemaTrat;
                    this.datos.croquisSist = response.data.croqiosSist;
                    this.datos.sistTratEvidencias = response.data.sistTratEvidencias;
                    this.datos.sustanPeligrosasEvi = response.data.sustanPeligrosasEvi;
                    this.datos.consumoEnergiaActual = response.data.consumoEnergiaActual;
                    this.datos.consumoEnergiaAnterior = response.data.consumoEnergiaAnterior;
                    this.datos.reduccionEnergia = response.data.reduccionEnergia;
                    this.datos.educacionEnergia = response.data.educacionEnergia;
                    this.datos.educEvideEnergia = response.data.educEvideEnergia;
                    this.datos.accionReduccionEnergia = response.data.accionReduccionEnergia;
                    this.datos.accionReduccionEnergiaEvid = response.data.accionReduccionEnergiaEvid;
                    this.datos.consumoResiduosActual = response.data.consumoResiduosActual;
                    this.datos.consumoResiduosAnterior = response.data.consumoResiduosAnterior;
                    this.datos.consumoValoAnterior = response.data.consumoValoAnterior;
                    this.datos.consumoValoActual = response.data.consumoValoActual;
                    this.datos.reduccionPapel = response.data.reducPapel;
                    this.datos.resValorizales = response.data.resValorizales;
                    this.datos.resNoValorizales = response.data.resNoValorizales;
                    this.datos.residuosEspeciales = response.data.residuosEspeciales;
                    this.datos.residuosPeligrosos = response.data.residuosPeligrosos;
                    this.datos.educacionResiduos = response.data.educacionResiduos;
                    this.datos.educacionResiduosEvid = response.data.educacionResiduosEvid;
                    this.datos.educacionAdaptacion = response.data.educacionAdaptacion;
                    this.datos.educacionAdaptacionEvid = response.data.educacionAdaptacionEvid;
                    this.datos.educacionCompensacion= response.data.educacionCompensacion;
                    this.datos.educacionCompensacionEvid = response.data.educacionCompensacionEvid;
                    this.datos.accionReduccionResiduos = response.data.accionReduccionResiduos,
                    this.datos.accionReduccionResiduosEvid = response.data.accionReduccionResiduosEvid;
                    this.datos.inventarioContAtmos = response.data.inventarioContAtmos;
                    this.datos.equiposContaminantes = response.data.equiposContaminantes;
                    this.datos.evideRefrigerantes = response.data.evideRefrigerantes;
                    this.datos.accionReduccionContAtmos = response.data.reduccionContAtmos;
                    this.datos.accionReducEvidContAtmos = response.data.eviReducContAtmos;
                    this.datos.programaCompSost = response.data.programaCompSost;
                    this.datos.programaCompSostEvid = response.data.programaCompSostEvid;
                    this.datos.productosSost = response.data.productosSost;
                    this.datos.producSostEvi = response.data.producSostEvi;
                    this.datos.prdSustituidos = response.data.prdSustituidos;
                    this.datos.evidPrdSustituido = response.data.evidPrdSustituido;
                    this.datos.eventosClimaticos = response.data.eventosClimaticos;
                    this.datos.planesDeAccion = response.data.planesDeAccion;
                    this.datos.evidPlanDeAccion = response.data.evidPlanDeAccion;
                    this.datos.actividadesComp = response.data.actividadesComp;
                    this.datos.actCompEvid = response.data.actCompEvid;
                    this.datos.educAmbCompensacion = response.data.educAmbCompensacion;
                    this.datos.actividadesCompEvid = response.data.actCompEvid;
                    this.datos.educAmbCompensacionEvid = response.data.educAmbCompensacionEvid;
                    this.datos.programasGest = response.data.programasGest;
                    this.datos.programasGestEvid = response.data.programasGestEvid;
                    this.datos.promoPbaeCadVal = response.data.promoPbaeCadVal;
                    this.datos.promoPbaeCadValEvid = response.data.promoPbaeCadValEvid;
                    this.datos.promoPbaeComu = response.data.promoPbaeComu;
                    this.datos.promoPbaeComuEvid = response.data.promoPbaeComuEvid;
                    this.datos.inventarioGei = response.data.inventarioGei;
                    this.datos.inventarioGeiEvid = response.data.inventarioGeiEvid;
                    this.datos.residuosPeligrososEvid = response.data.residuosPeligrososEvid;
                    this.datos.residuosEspecialesEvid = response.data.residuosEspecialesEvid;
                    this.datos.certCarbNeut = response.data.certCarbNeut;
                    this.datos.certCarbNeutEvid = response.data.certCarbNeutEvid;
                    this.datos.hogaresSostData = response.data.hogaresSost;
                    this.datos.hogaresEvid = response.data.hogaresEvid;
                    this.datos.notaHogares = response.data.hogaresCommand;
                    this.datos.progEcolones = response.data.progEcolones;
                    this.datos.progEcolonesEvid = response.data.progEcolonesEvid;
                    this.datos.justAgua = response.data.justAgua;
                    this.datos.justCombus = response.data.justCombus;
                    this.datos.justEnergia = response.data.justEnergia;
                    this.datos.justContam = response.data.justContam;
                    this.datos.justResPapel = response.data.justResPapel;
                    this.datos.justResiduos = response.data.justResiduos;
                    this.datos.justAnioAgua = response.data.justAnioAgua;
                    this.datos.justAnioComb = response.data.justAnioComb;
                    this.datos.justAnioEnergia = response.data.justAnioEnergia;
                    this.datos.justAnioConta = response.data.justAnioConta;
                    this.datos.justAnioResiduosPapel = response.data.justAnioResiduosPapel;
                    this.datos.justAnioResiduos = response.data.justAnioResiduos;
                    this.datos.justCombusInve = response.data.justCombusInve;
                    this.datos.justCombusCons = response.data.justCombusCons;
                    this.datos.justCombConsAnte = response.data.justCombConsAnte;
                    this.datos.justEnerCons = response.data.justEnerCons;
                    this.datos.justEnerConsAnte = response.data.justEnerConsAnte;
                    this.datos.justResiduosNo = response.data.justResiduosNo;
                    this.datos.justContaConsAnte = response.data.justContaConsAnte;
                    this.datos.justResiduConsAnte = response.data.justResiduConsAnte;
                    this.datos.justResiCons = response.data.justResiCons;
                    this.datos.justConsPapel = response.data.justConsPapel;
                    this.datos.justConsNoValo = response.data.justConsNoValo;
                    this.datos.justConsValo = response.data.justConsValo;
                    this.datos.justConsNoValoAnte = response.data.justConsNoValoAnte;
                    this.datos.justConsValoAnte = response.data.justConsValoAnte;
                    this.datos.justAguaCons = response.data.justAguaCons;
                    this.datos.justAguaConsAnte = response.data.justAguaConsAnte;
                    this.datos.descripcionOrg = response.data.descripcionOrg;
                    this.datos.estrellasInforme = response.data.estrellasInforme;
                    this.datos.accionesPrevencion = response.data.accionesPrevencion;
                    this.datos.evidAccionesPrevencion = response.data.evidAccionesPrevencion;
                    this.datos.accionesLimpieza = response.data.accionesLimpieza;
                    this.datos.evidaccionesLimpieza = response.data.evidaccionesLimpieza;
                    this.datos.permisoVertido = response.data.permisoVertido;
                    this.datos.reporteOperacional = response.data.reporteOperacional;
                    this.datos.comprobantePago = response.data.comprobantePago;
                    this.datos.reporteDatos = response.data.reporteDatos;
                    this.datos.permisoProveedor = response.data.permisoProveedor;
                    this.datos.permisoSanitario = response.data.permisoSanitario;
                    this.datos.constanciaProveedor = response.data.constanciaProveedor;
                    this.datos.constanciaDescarga = response.data.constanciaDescarga;
                    this.datos.constanciaTragua = response.data.constanciaTragua;
                    this.datos.programaCompras = response.data.programaCompras;
                    this.datos.justPotabilidadAgua = response.data.justPotabilidadAgua;
                    this.datos.fuenteAbastecimiento = response.data.fuenteAbastecimiento;
                    this.datos.actividadesNaranja = response.data.actividadesNaranja;
                    this.datos.consumoNoValolActual = response.data.consumoNoValoActual;
                    this.datos.consumoNoValoAnterior = response.data.consumoNoValoAnterior;
                    this.datos.docsJustCalidadAgua = response.data.docsJustCalidadAgua;
                    this.datos.docsJustConsumoAgua = response.data.docsJustConsumoAgua;
                    this.datos.docsJustReducciongua = response.data.docsJustReducciongua;
                    this.datos.docsJustConsumoEnergia = response.data.docsJustConsumoEnergia;
                    this.datos.docsJustReduccionEnergia = response.data.docsJustReduccionEnergia;
                    this.datos.docsJustInventarioComb = response.data.docsJustInventarioComb;
                    this.datos.docsJustConsumoComb = response.data.docsJustConsumoComb;
                    this.datos.docsJustReduccionComb = response.data.docsJustReduccionComb;
                    this.datos.docsJustConsumoPapel = response.data.docsJustConsumoPapel;
                    this.datos.docsJustConsumoValor = response.data.docsJustConsumoValor;
                    this.datos.docsJustConsumoNoValor = response.data.docsJustConsumoNoValor;
                    this.datos.docsJustReduccionPapel = response.data.docsJustReduccionPapel;
                    this.datos.docsJustReduccionValor = response.data.docsJustReduccionValor;
                    this.datos.docsJustReduccionNoValor = response.data.docsJustReduccionNoValor;
                    this.datos.docsJustSistemaTratami = response.data.docsJustSistemaTratami;
                    this.datos.educaGases = response.data.educaGases;
                    this.datos.educacionAmbientalCaso2 = response.data.educaGasesCasoDos;
                    this.datos.accionesReduccionCaso2 = response.data.reduccionContAtmosDos;
                    this.datos.usoProducSostEvi = response.data.usoProducSostEvi;
                    this.datos.amenazas = response.data.amenazas;
                    this.datos.escalas = response.data.escalas;
                    this.datos.impactos = response.data.impactos;
                    this.datos.actCompDispEvid = response.data.actCompDispEvid;
                    this.datos.ecolonesCasoUno = response.data.ecolonesCasoUno;
                    this.datos.ecolonesCasoDos = response.data.ecolonesCasoDos;
                    this.datos.ecolonesCasoTres = response.data.ecolonesCasoTres;
                    this.datos.organizacionNombre = response.data.organizacionNombre;
                    this.datos.justifResidPeligrosos = response.data.justifResidPeligrosos;
                    this.datos.galardon = response.data.galardon;
                    this.datos.accionesAdaptacion = response.data.accionesAdaptacion;
                    this.generarReporte();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.mensaje = 'Error al generar el informe final. Favor antes de contactar al administrador asegurarse que la inscripción legal está registrada y medidores en estado activo.)';
                    this.alert = true;
                    this.loadingModal(false);
                  }
                })
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>