<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn class="white--text" color="#0B3F67" v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Actividad" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                        label="Tipo de Actividad"
                        v-model="accion.tipoActividad"
                        :error-messages="tipoErrors"             
                        @change="$v.accion.tipoActividad.$touch()"
                        @blur="$v.accion.tipoActividad.$touch()"
                        counter="100"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                        label="Descripcion"
                        v-model="accion.descripcion"
                        :error-messages="descErrors"
                        @change="$v.accion.descripcion.$touch()"
                        @blur="$v.accion.descripcion.$touch()"
                        counter="500"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col  cols="12" md="12">
                        <v-text-field
                        label="Cantidad de Actividades"
                        v-model="accion.cantidadActividades"
                        :error-messages="cantErrors"
                        @change="$v.accion.cantidadActividades.$touch()"
                        @blur="$v.accion.cantidadActividades.$touch()"
                        required 
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-select
                            :items="tipoAccion"
                            item-text="nombre"
                            item-value="valor"
                            v-model="accion.tipoAccion"
                            :error-messages="tipoAccionErrors"
                            @change="$v.accion.tipoAccion.$touch()"
                            @blur="$v.accion.tipoAccion.$touch()"
                            required
                            label="Tipo de Accion"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input 
                            accept=".png, .jpeg, .jpg" 
                            show-size counter 
                            multiple 
                            v-model="archivos"
                            :error-messages="docErrors"
                            @input="$v.archivos.$touch()"
                            @blur="$v.archivos.$touch()" 
                            label="Agregar Evidencias">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarAccion">Agregar</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>

<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
import moment from "moment"
import { mask } from "vue-the-mask"
import { required, integer, maxLength } from "vuelidate/lib/validators"
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    computed:{
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.accion.tipoActividad.$dirty) return errors
            !this.$v.accion.tipoActividad.required && errors.push('Campo requerido')
            !this.$v.accion.tipoActividad.maxLength && errors.push('No debe exceder el tamaño permitido.') 
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.accion.descripcion.$dirty) return errors
            !this.$v.accion.descripcion.required && errors.push('Campo requerido')
            !this.$v.accion.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.accion.cantidadActividades.$dirty) return errors
            !this.$v.accion.cantidadActividades.required && errors.push('Campo requerido')
            !this.$v.accion.cantidadActividades.integer && errors.push('Debe digitar solo números')
            return errors
        },
        tipoAccionErrors (){
            const errors = []
            if(!this.$v.accion.tipoAccion.$dirty) return errors
            !this.$v.accion.tipoAccion.required && errors.push('Campo requerido')
            return errors
        },    
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('La evidencia es requerida.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },    
        tipoOrden() {
            return this.accion.tipoAccion === 1 ? 'primer' : 'segundo'
        }
    },
    data: ()=>({
        loading: false,
        dialog: false,
        menu: false, 
        accion: {
            tipoActividad:'',
            descripcion:'',
            cantidadActividades:'',
            tipoAccion:''
        },
        archivos: null,
        fechaActividad: new Date().toISOString().substr(0, 10),
        tipoAccion: [
            {nombre:'PRIMER', valor: 1},              
            {nombre:'SEGUNDO', valor: 2},
        ], 
    }),
    validations: {
        accion: {
            tipoActividad: {
                maxLength: maxLength(100),
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            cantidadActividades: {
                required,
                integer
            },
            tipoAccion:{
                required
            }
        },
        archivos: {
            required,
            maxFileSize
        }
    },
    methods:{
        agregarAccion(){
            this.$v.$touch();
            if(!this.$v.$invalid){
                this.loading = true;
                var formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append("accion", JSON.stringify(this.accion));
                formData.append('idInstalacion',this.sucursal.id);
                formData.append("idUsuario", this.usuario.id);
                formData.append("fechaActividad", this.fechaActividad);            
                axios.post("/accionesPoS/agregar", formData)
                    .then(response=>{
                        this.$store.dispatch('noti',{
                            mensaje: 'Acción de ' + this.tipoOrden + ' orden agregada', 
                            color:'success'
                        });   
                        this.$emit('reload');
                        this.$emit('reloadAn');
                        this.cancelar();
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                          this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la acción', color:'error'});
                          this.cancelar();
                        }
                      }
                    })
            }           
        },
        cancelar(){
            this.loading = false;
            this.accion = {};
            this.fechaActividad = new Date().toISOString().substr(0, 10);
            this.$v.accion.$reset();
            this.$v.archivos.$reset();
            this.dialog = false;
            this.archivos=[];
        }
    },

    
}
</script>