<template>
    <v-dialog
        v-model="dialog" 
        persistent       
        scrollable  
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                    
                    <v-icon class="mr-2" v-on="{...tooltip, ...dialog}" @click="getProducto" dark color="#ffb605">mdi-pencil</v-icon>
                </template>
                Modificar Producto
            </v-tooltip>            
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Producto" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field                            
                            label="Producto"
                            v-model="producto.nombre"
                            :error-messages="nomErrors"
                            @change="$v.producto.nombre.$touch()"
                            @blur="$v.producto.nombre.$touch()"
                            counter="200"
                        ></v-text-field>
                        <v-text-field                            
                            label="Uso"
                            v-model="producto.uso"
                            :error-messages="usoErrors"
                            @change="$v.producto.uso.$touch()"
                            @blur="$v.producto.uso.$touch()"
                            counter="200"
                        ></v-text-field>
                        <v-select
                            :items="categorias"
                            v-model="producto.categoria"
                            label="Categoria"
                            :error-messages="catErrors"
                            @change="$v.producto.categoria.$touch()"
                            @blur="$v.producto.categoria.$touch()"
                        ></v-select>
                        <v-select
                            :items="criterios"
                            v-model="producto.criterio"
                            :error-messages="critErrors"
                            hint="Elegir criterio con mayor prioridad para la instalación"
                            label="Criterio de Sostenibilidad"
                            @change="$v.producto.criterio.$touch()"
                            @blur="$v.producto.criterio.$touch()"
                        ></v-select>                       
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" text @click="modificar">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import { required } from 'vuelidate/lib/validators'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'productProp'
    ],
    components: {
      ModalTitle
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        nomErrors() {
            const errors = []
            if (!this.$v.producto.nombre.$dirty) return errors            
            !this.$v.producto.nombre.required && errors.push('Dato requerido.')   
            !this.$v.producto.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')         
            return errors
        },
        usoErrors() {
            const errors = []
            if (!this.$v.producto.uso.$dirty) return errors            
            !this.$v.producto.uso.required && errors.push('Dato requerido.')   
            !this.$v.producto.uso.maxLength && errors.push('No debe exceder el tamaño permitido.')           
            return errors
        },
        catErrors() {
            const errors = []
            if (!this.$v.producto.categoria.$dirty) return errors            
            !this.$v.producto.categoria.required && errors.push('Dato requerido.')            
            return errors
        },
        critErrors() {
            const errors = []
            if (!this.$v.producto.criterio.$dirty) return errors            
            !this.$v.producto.criterio.required && errors.push('Dato requerido.')            
            return errors
        },
    },    
    data: () => ({
        producto: {
            nombre: '',
            uso: '',
            categoria: '',
            criterio: ''
        },
        categorias: [
            'Artículos de oficina',
            'Artículos de limpieza',
            'Artículos de mantenimiento'
        ],
        criterios: [
            'Bajo consumo de agua',
            'Bajo consumo de energía',
            'Biodegradable',
            'Proveniente de fuentes renovables',
            'Control de químicos',
            'NO GMO',
            'No erosión del suelo',
            'RSE'
        ],
        dialog: false
    }),
    validations: {
        producto: {
            nombre: {
                required
            },
            uso: {
                required
            },
            categoria: {
                required
            },
            criterio: {
                required
            }
        },
    },
    methods: {
        getProducto() {
            this.producto = this.productProp;
        },
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.put("/comprasSostenibles/modificarProducto", null, {params: {
                    productoNuevo: JSON.stringify(this.producto),
                    producto: this.producto.id,
                    usuario: this.usuario.id
                }}).then(response => {
                    this.$emit('reload');
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Producto modificado', color: 'success'});
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el producto', color: 'error'});
                  }
                })
            }
        },
        cancelar() {
            this.producto = {};            
            this.$v.producto.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>