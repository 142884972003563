var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('logo',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 4, stickyTop: 65}),expression:"{ zIndex: 4, stickyTop: 65}"}],on:{"cambiaAnio":_vm.getDataAccPlan}}),_c('navParameters',{attrs:{"navParams":_vm.navParams}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tituloPrincipal',{attrs:{"titulo":"Plan de Acción para Medida de Adaptación"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('tituloSimple',{attrs:{"titulo":"Plan de Acción por Evento Climático"}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"fixed-header":"","no-data-text":"No acciones de adaptación registradas","footer-props":{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',                            
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('div',[((item.idPlan === 0 || item.idPlan === null || item.idPlan === undefined) && (_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR'))?_c('agreModfPlan',{attrs:{"detalle":false,"tipoAccion":"Agregar","icono":"mdi-plus-circle-outline","planProp":item,"instalacion":_vm.instalacion,"usuario":_vm.usuario},on:{"reload":_vm.getDataAccPlan}}):_vm._e()],1),(item.idPlan != 0 && item.idPlan != null && item.idPlan != undefined)?_c('div',[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('agreModfPlan',{attrs:{"detalle":false,"tipoAccion":"Modificar","icono":"mdi-pencil","planProp":item,"instalacion":_vm.instalacion,"usuario":_vm.usuario},on:{"reload":_vm.getDataAccPlan}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('agreModfPlan',{attrs:{"detalle":true,"tipoAccion":"Detalle","icono":"mdi-text-box-multiple-outline","planProp":item,"instalacion":_vm.instalacion,"usuario":_vm.usuario},on:{"reload":_vm.getDataAccPlan}}):_vm._e(),_c('Eliminar',{attrs:{"requestUrl":"/adaptacion/eliminarPlan","requestData":_vm.eliminarDataFormat(item)},on:{"reload":_vm.getDataAccPlan}})],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }