<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
        transition="dialog-transition">  
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"     
                        @click="getDataInstalacion()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>          
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Tipo de Instalacion" @cancelar="cancelar"/>
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" >
                            <v-text-field                              
                                label="Nombre"
                                v-model="instalacion.nombre"
                                :error-messages="nameErrors"                                
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-textarea                                                              
                                v-model="instalacion.descripcion"                                                               
                                required
                                label="Descripción" outlined
                                shaped
                                counter="100"                               
                                hint="Campo Opcional"   
                            ></v-textarea>
                        </v-col> -->                     
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarInstalacion" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>
import axios from "@/axios.js";
import {required} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: 
    [      
        'idInstalacion'    
    ],
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.instalacion.nombre.$dirty) return errors            
            !this.$v.instalacion.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        instalacion: {
            nombre: '',
            descripcion: '',
            estado: 0,
        },
        loading: false
    }),
    validations: {
        instalacion: {
            nombre: {
                required,               
            },
        }
    },
    methods: {          
         getDataInstalacion() {
            this.loading = true;
            axios.get("/instalacion/getDataInstalacion/"+this.idInstalacion).then(response => {
                this.instalacion = response.data.tipoIns;                       
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarInstalacion() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/instalacion/modificarInstalacion/"+this.idInstalacion, this.instalacion)                
                .then(response => {
                    this.cancelar();
                    this.$emit('reloadEdit');
                    this.$store.dispatch('noti',{mensaje: 'Tipo de instalación modificado', color:'success'});
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el tipo de instalación', color:'error'});
                    this.cancelar();
                  }
                })
            }
        },             
        cancelar() {
            this.instalacion = {};
            this.dialog = false;
            this.loading = false;           
            this.$v.instalacion.$reset();            
        },
    },
}
</script>