<template>
    <v-container fluid>
        <logo @cambiaAnio="getConstancias" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="tituloPrincipal"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <AgregarConstancia :anios="anios" :aniosConstancia="aniosConstancia" @reloadTable="getConstancias"/>
            </v-col>
            <v-col cols="4" sm="6" md="3" align-self="center" >
                <Justificacion titulo="Justificación por falta de constancia" tooltipText="Justificación por falta de constancia"
                  :reqEvidencias="true" :tipoDocumento="constantes.JUSTIF_CALIDAD_AGUA"/>
            </v-col>
        </v-row>
        <v-row align-center>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <titulo :titulo="tituloTabla"/>
                        <v-row class="pt-2">
                            <v-col cols="12">
                              <v-data-table
                                :headers="headers"
                                :items="constancias"
                                item-key="id"
                                :search="search"
                                :footer-props="options"
                                :no-data-text="noData">
                                <template v-slot:item.action="{ item }">
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="mr-2" color="#0B3F67" @click="borrarPotabilidad(item.id)">mdi-trash-can-outline</v-icon>
                                        <ModificarPotabllidadAgua :anios="anios" :aniosConstancia="aniosConstancia" :idPotabilidad="item.id" @reloadTable="getConstancias"/>
                                      </template>
                                      Eliminar
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.evidencia="{ item }">
                                  <TablaEvidencias :url="evidencias(item.id)"/>
                                </template>
                              </v-data-table>
                            </v-col>                            
                        </v-row>                        
                    </v-card-text>
                </v-card>                 
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from "@/axios.js"
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import Titulo from "@/components/utils/TituloSimpleComponent"
import NavParameters from '@/components/utils/NavParameterComponent'
import Justificacion from '@/components/utils/JustificacionComponent'
import TituloPrincipal from "@/components/utils/TituloPrincipalComponent"
import TablaEvidencias from "@/components/documentos/EvidenciasComponent"
import AgregarConstancia from "@/components/parametros/AgreConstPotabAguaComponent"
import ModificarPotabllidadAgua from "@/components/parametros/Agua/ModificarPotabllidadAguaComponent"

export default {
    components: {
    Titulo,
    TituloPrincipal,
    TablaEvidencias,
    AgregarConstancia,
    NavParameters,
    Logo,
    Justificacion,
    ModificarPotabllidadAgua
},
    computed: {
      anioConsulta() {
        return this. $store.getters.anioConsulta;
      },
      usuario(){
        return this.$store.getters.usuario;
      },
      constantes() {
        return this.$store.getters.constantes;
      }
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    mounted() {
        this.anios = this.getAnioParticipacion();  
        this.getConstancias();      
    },
    data: () => ({                              
        tituloPrincipal: 'Verificación de la Calidad del Agua Potable',
        tituloTabla: 'Historial de Constancias',
        noData: "No hay constancias registradas",
        anios: [],
        aniosConstancia: [],
        search: null,
        headers: [
          {
              text: 'Acciones',
              value: 'action',
              align: 'center',
              width: '10%',
              sortable: false
          },
          {
              text: 'Año',
              align: 'center',
              sortable: true,
              value: 'anio',
          },
          {
              text: 'Fuente de Abastecimiento',
              align: 'center',
              sortable: true,
              value: 'fuenteName',
          },
          {
              text: 'Agua Potable',
              align: 'center',
              sortable: true,
              value: 'potableName',
          },
          {
              text: 'Ente Emisor',
              align: 'center',
              sortable: true,
              value: 'emisor',
          },
          {
              text: 'Evidencias',
              align: 'center',
              sortable: false,
              value: 'evidencia',
          },
        ],
        constancias: [],
        options: {
            showFirstLastPage: true,
            firstIcon:'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageText: 'Registros por página',
            itemsPerPageAllText: 'Todos',
            showCurrentPage: true,
            showFirstLastPage: true
        },
        existeConstancia: false
    }),
    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getConstancias() {
            let idInstalacion = this.$store.getters.sucursal.id;
            axios.get("/indicadorAgua/getConstanciasAgua/" + idInstalacion + '/' + this.anioConsulta)
            .then(response => {
                this.constancias = response.data.constancias;
                this.aniosConstancia = response.data.aniosConsta;
                if (this.constancias.length > 0){
                  this.existeConstancia = true;
                }else{
                  this.existeConstancia = false;
                }
                setTimeout(function () {this.loadingModal(false)}.bind(this), 2000);                
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al consultar las constancias " + error);
                setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);   
              }             
            });
        },
        getAnioParticipacion() {
            let idInstalacion = this.$store.getters.sucursal.id;
            axios.get("/instalaciones/getAniosParticipacion/" + idInstalacion
            ).then(response => {
                this.anios = response.data.anios;                    
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los años de participacion: " + error);
              }
            })
        },
        evidencias(idPotabilidad) {
          return `/indicadorAgua/getEvidenciasPotabilidad/${idPotabilidad}/${this.anioConsulta}`
        },
        borrarPotabilidad(id) {
          this.$store.dispatch('loadingPage', true);
          axios.post(`/indicadorAgua/eliminarPotabilidad/`, null, {params: {
            potabilidad: id,
            usuario: this.usuario.id
          }}
            ).then(() => {
              this.getConstancias();
              this.$store.dispatch('noti',{mensaje: 'Registro eliminado', color:'success'});
              this.$store.dispatch('loadingPage', false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al eliminar la potabilidad " + error);
                this.$store.dispatch('loadingPage', false);
              }
            })
        }
    },
}
</script>