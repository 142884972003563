<template>
    <v-container>
        <v-row class="mb-3">
            <v-col cols="6" sm="5" md="5" lg="3" xl="3"><agregarProducto @reload="listarProductos" /></v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="4" md="4" lg="3" xl="3">            
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búsqueda"
                    single-line
                    hide-details>
                </v-text-field>
            </v-col>  
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-sheet height="80" class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">                   
                        <v-layout fill-height align-center justify-center>                            
                            Catálogo Producto
                        </v-layout>                                  
                    </v-sheet>
                    <v-data-table
                        multi-sort
                        :search="search"        
                        :headers="headers"
                        :items="productos"
                        class="elevation-1"
                        :loading="cargando"
                        loading-text="Cargando..."
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }">
                        <template v-slot:item.action="{ item }">
                            <modificarProducto @reload="listarProductos" v-bind:id="item.id"/>
                        </template>
                        <template v-slot:item.estado="{ item }">
                            <v-layout justify-center>
                                <activarProducto @reload="listarProductos" v-bind:estado="item.estado" v-bind:id="item.id"/>
                            </v-layout>
                        </template>                  
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import agregarProducto from "@/components/productos/ProductosAgregarComponent";
import activarProducto from "@/components/productos/ActivarProductoComponent";
import modificarProducto from "@/components/productos/ModificarProductoComponent";
import axios from "@/axios.js";

export default {
    components: {
        agregarProducto,
        activarProducto,
        modificarProducto,
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    mounted () {
        this.listarProductos();
    },
    data: () => ({
        headers:[
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Producto',
                value: 'nombre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Etiqueta Ambiental',
                value: 'nombreEtiqueta',
                align: 'center',
                sortable: true,
                filterable: true,
            }
        ],
        search: '',
        productos: [],
        cargando: false
    }),
    methods: {
        listarProductos() {
            this.cargando = true;
            axios.get("/productos/listarProductos").then(response => {
                this.productos = response.data.productos;   
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            })
        }
    }
};
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>