import moment from "moment";

export function informeBitacora(bitacoras, instalacion, indicador) {
    let content = [
        tablaBitacoras(bitacoras, instalacion, indicador)
    ]
    return content;
}

function tablaBitacoras(bitacoras, instalacion, indicador) {
    let tablaBitacora = {
        table: {
            body: [
                [
                    { text: `Informe de Bitacoras ${instalacion.nombre}, Indicador: ${indicador.nombre}`, alignment: 'center', bold: true, colSpan: 4, fillColor: '#8db3e2' }, '', '', ''
                ],
                [
                    { text: 'Acción', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Descripción', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Registrado por', alignment: 'center', bold: true, fillColor: '#8db3e2' },
                    { text: 'Fecha de Registro', alignment: 'center', bold: true, fillColor: '#8db3e2' }
                ],
            ]
        }
    }
    bitacoras.forEach(b => {
        let row = [
            { text: b.accion.nombre, alignment: 'center' },
            { text: b.descripcion, alignment: 'left' },
            { text: b.usuarioRegistra, alignment: 'center' },
            { text: formatoFecha(b.fechaRegistro), alignment: 'center' }
        ]
        tablaBitacora.table.body.push(row);
    })
    return tablaBitacora;
}

function formatoFecha(fecha) {
    return moment(fecha).format('DD/MM/YYYY')
}