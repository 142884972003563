<template>
    <v-dialog
        v-model="dialog" 
        scrollable
        persistent
        max-width="550px"
        transition="dialog-transition">

        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" class="white--text" v-on="on">Agregar Inscripción<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Inscripción" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="instalacionSelect"
                            :items="instalaciones"
                            item-value="id"
                            item-text="nombre"
                            :error-messages="instalacionErrors"
                            @change="$v.instalacionSelect.$touch()"
                            @blur="$v.instalacionSelect.$touch()"
                            label="Nombre de Instalación">
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="codigopbae"
                            :error-messages="codigoErrors"
                            @change="$v.codigopbae.$touch()"
                            @blur="$v.codigopbae.$touch()"
                            label="Código PBAE">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="inscripcion.anioInscripcion"
                            label="Año de Inscripción"
                            v-mask="mask"
                            prepend-inner-icon="mdi-calendar-month"
                            :error-messages="anioErrors"
                            @change="$v.inscripcion.anioInscripcion.$touch()"
                            @blur="$v.inscripcion.anioInscripcion.$touch()"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-file-input
                    accept=".png, .jpeg, .jpg" 
                    show-size 
                    counter 
                    v-model="archivos" 
                    label="Agregar Documento de Formalización"
                    :error-messages="evidErrors"
                    @change="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()">
                </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarInscripcion">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mask } from "vue-the-mask"
import { required, minLength } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (file) => {
    if(file != null) {
        let maximo = 1024 * 1024;
        return file.size < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    directives: {
        mask,
    },
    props: [
        'instalaciones'
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        evidErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        anioErrors() {
            const errors = []
            if (!this.$v.inscripcion.anioInscripcion.$dirty) return errors            
            !this.$v.inscripcion.anioInscripcion.required && errors.push('Por favor digite el año de participación.')
            !this.$v.inscripcion.anioInscripcion.minLength && errors.push('Formato de año incorrecto.')
            return errors
        },
        instalacionErrors() {
            const errors = []
            if (!this.$v.instalacionSelect.$dirty) return errors            
            !this.$v.instalacionSelect.required && errors.push('El nombre de la instalación es requerido.')
            return errors
        },
        codigoErrors() {
            const errors = []
            if (!this.$v.codigopbae.$dirty) return errors            
            !this.$v.codigopbae.required && errors.push('El código de participación es requerido.')
            return errors
        },
        usuario(){
            return this.$store.getters.usuario;
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        mask: '####',
        codigopbae:'',
        instalacionSelect: 0,
        inscripcion: {
            anioInscripcion: new Date().toISOString().substr(0, 4),
            documento: {},
            instalacion: {}
        },
        archivos:[],
    }),
    validations: {
        inscripcion: {
            anioInscripcion: {
                required,
                minLength: minLength(4)
            },
        },
        instalacionSelect: {
            required 
        },
        archivos: {
            required,
            maxFileSize
        },
        codigopbae: { required },
    },
    methods: {
        agregarInscripcion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('archivo', this.archivos);
                formData.append('anioInscripcion', this.inscripcion.anioInscripcion);
                formData.append('codigopbae', this.codigopbae);
                formData.append('idInstalacion', this.instalacionSelect);
                formData.append('idUsuario', this.usuario.id);
                axios.post("/instalaciones/agregarInscripcion", formData)
                .then(response => {
                    this.verificaAnios(response.data.anios);
                    this.$store.dispatch('noti',{mensaje: 'Inscripción legal agregada', color:'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la inscripción legal', color:'error'});  
                      this.cancelar();
                    }
                  }    
                })
            }
        },
        cancelar() {
            this.inscripcion = {};
            this.inscripcion.anioInscripcion = new Date().toISOString().substr(0, 4),
            this.archivos=[];
            this.instalacionSelect = [];
            this.archivos = [];
            this.codigopbae = '';
            this.$v.inscripcion.$reset();    
            this.$v.archivos.$reset();  
            this.$v.codigopbae.$reset();
            this.$v.instalacionSelect.$reset();
            this.dialog = false;
            this.loading = false;
        },
        verificaAnios(aniosInscripcion) {
            let aniosActual = this.$store.getters.aniosConsulta;
            if(aniosInscripcion.length > aniosActual.length) {
                this.$store.commit('aniosConsulta', aniosInscripcion);  
            }
        }
    },
}
</script>