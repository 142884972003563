<template>
    <v-card hover class="pt-3">
        <v-sheet class="v-sheet--offset mx-auto pt-" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title
                        class="white--text headline text-center">
                        {{ titleText() }}
                    </v-list-item-title>
                    <!-- <v-list-item-title 
                        v-if="indicadorElectricidad"
                        class="white--text headline text-center">
                        Consumo Mensual de Electricidad
                    </v-list-item-title>
                    <v-list-item-title 
                        v-if="data.consumoHumano" 
                        class="white--text headline text-center">
                        {{ data.medidor }} - Medida: {{ unidadConsumo(data.unidadConsumo) }} - Costo: {{ unidadCosto(data.unidadCosto) }}
                    </v-list-item-title>
                    <v-list-item-title 
                        v-if="indicCombustibles && !indicResiduos" 
                        class="white--text headline text-center">
                        Combustible: {{ data.medidor }} - Consumo: {{ unidadConsumo(data.unidadConsumo) }} - Costo: {{ unidadCosto(data.unidadCosto) }}
                    </v-list-item-title>
                    <v-list-item-title 
                        v-if="!indicCombustibles && !data.consumoHumano && !indicResiduos && !indicadorElectricidad" 
                        class="white--text headline text-center">
                        Medidor: {{ data.medidor }} - NIS: {{ data.nis }} - Consumo: {{ unidadConsumo(data.unidadConsumo) }} - Costo: {{ unidadCosto(data.unidadCosto) }}
                    </v-list-item-title> -->
                    <v-list-item-subtitle
                        v-if="indicadorElectricidad || indicadorAgua && !data.consumoHumano"
                        class="white--text subtitle text-center">
                        Medidor: {{data.medidor}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle 
                        v-if="!data.consumoHumano && !indicResiduos && !indicadorElectricidad && !indicadorAgua" 
                        class="white--text subtitle text-center">
                        {{ data.proveedor }}
                    </v-list-item-subtitle>
                    <!--<v-list-item-subtitle 
                        v-if="indicResiduos && !valorizable" 
                        class="white--text headline text-center"> 
                        CONSUMO PAPEL - Consumo: {{ unidadConsumo(data.unidadConsumo) }} - Costo: {{ unidadCosto(data.unidadCosto) }} 
                    </v-list-item-subtitle> 
                    <v-list-item-subtitle 
                        v-if="indicResiduos && valorizable == true" 
                        class="white--text headline text-center"> 
                        CONSUMO RESIDUOS - {{data.medidor}} - Consumo:  KG  -  
                    </v-list-item-subtitle> -->
                    <v-list-item-subtitle 
                        class="white--text subtitle text-center">
                        {{ anio }}
                    </v-list-item-subtitle> 
                </v-list-item-content>
            </v-list-item>
        </v-sheet> 
        <v-card-text>
            <v-data-table v-if="estado"
                :headers="headers"
                :items="data.historial"
                calculate-widths
                disable-filtering
                disable-pagination
                hide-default-footer
                fixed-header>
                <template v-slot:item.detalle="props">
                    <div class="text-center">{{ obtDetalle(props.item.detalle) }}</div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>

export default {
    props: [
        'data',        
        'anio',
        'valorizable',
        'estado'
    ],
    computed: {
        indicCombustibles() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            return indicador === constantes.indicadorCombustibles ? true : false;
        },
        indicResiduos() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            return indicador === constantes.indicadorResiduos ? true : false;
        },
        indicadorElectricidad() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            return indicador === constantes.indicadorEnergia ? true : false;
        },  
        indicadorAgua() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            return indicador === constantes.indicadorAgua ? true : false;
        },   
    },
    data: () => ({
        headers:[
            {
                text: 'Detalle',
                value: 'detalle',
                align: 'center',
                sortable: false
            },
            {
                text: 'Enero',
                value: 'enero',
                align: 'center',
                sortable: false
            },
            {
                text: 'Febrero',
                value: 'febrero',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Marzo',
                value: 'marzo',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Abril',
                value: 'abril',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Mayo',
                value: 'mayo',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Junio',
                value: 'junio',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Julio',
                value: 'julio',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Agosto',
                value: 'agosto',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Septiembre',
                value: 'septiembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Octubre',
                value: 'octubre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Noviembre',
                value: 'noviembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Diciembre',
                value: 'diciembre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            {
                text: 'Total',
                value: 'total',
                align: 'center',
                sortable: true,
                filterable: true,
            },
        ],
    }),
    methods: {
        titleText() {
            return this.indicadorElectricidad ? 'Consumo Mensual de Electricidad' : 
                this.indicadorAgua && !this.data.consumoHumano ? 'Consumo Mensual de Agua' :
                this.data.consumoHumano ? this.data.medidor : 
                this.indicCombustibles ? 'Combustible: ' + this.data.medidor + ' - Consumo: ' + this.unidadConsumo(this.data.unidadConsumo) + ' - Costo: ' + this.unidadCosto(this.data.unidadCosto) :
                this.indicResiduos && !this.valorizable ? 'CONSUMO PAPEL - Consumo: ' + this.unidadConsumo(this.data.unidadConsumo) + ' - Costo: ' + this.unidadCosto(this.data.unidadCosto) :
                this.indicResiduos && this.valorizable ? 'CONSUMO RESIDUOS - ' + this.data.medidor + ' - Consumo:  KG  -' :
                'Medidor: ' + this.data.medidor + ' - NIS: ' + this.data.nis + ' - Consumo: ' + this.unidadConsumo(this.data.unidadConsumo) + ' - Costo: ' + this.unidadCosto(this.data.unidadCosto);
        },
        obtDetalle(medidor) {
            return this.indicadorElectricidad ? 
                medidor.includes('consumo') ? medidor + ' (' + this.unidadConsumo(this.data.unidadConsumo) +')' :
                medidor + ' (' + this.unidadCosto(this.data.unidadCosto) +')' :
                this.indicadorAgua? medidor.includes('consumo') ? medidor + ' (' + this.unidadConsumo(this.data.unidadConsumo) +')' :
                medidor + ' (' + this.unidadCosto(this.data.unidadCosto) +')' : medidor;
        },
        totalMedidor(nombreMedidor) {            
            let datos = this.data.historial;
            let total = 0;
            datos.forEach((medidor) => {
                let medidorData = Object.values(medidor);
                if(medidorData.includes(nombreMedidor)) {
                    medidorData = this.filter_list(medidorData);
                    medidorData.forEach(suma);
                    function suma(item, index){
                        if(index > 0 && index < 13){
                            total += item;
                        }
                    };
                }                
            })                        
            return total.toFixed(2);
        },
        unidadConsumo(unidad) {  
            switch(unidad) {
                case 1:
                    return 'KWh';
                    break;
                case 2:
                    return 'L';
                    break;
                case 3:
                    return 'Gal';
                    break;
                case 4:
                    return 'm³';
                    break;
                case 6:
                    return 'RESMAS';
                    break;
            }            
        },
        unidadCosto(unidad) {
            return unidad == 1 ? '₡' : '$';
        },
        filter_list(l) {
            return l.filter(x => typeof x === "number");
        }
    },
}
</script>
<style scoped>
    thead th {
      font-size: 15px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>