<template>
    <v-container fluid>
        <v-row>
            <!-- aqui va el agregar-->
            <v-col cols="6" sm="6" md="3" align-self="center">
                <AgregarAccion v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" @reloadAn="aniosConsulta" @reload="getDataPorAnio"></AgregarAccion>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-data-table
                        :headers="headers"
                        :items="acciones"
                        calculate-widths
                        item-key="id"
                        :loading="loading"
                        no-data-text="No hay Acciones para este periodo"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                    >
                    <template v-slot:item.fechaActividad="{ item }">{{ dateFormat(item.fechaActividad) }}</template>
                    <template v-slot:item.action="{ item }">
                    <AgregarEvidencia
                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                        @reload="getDataPorAnio"
                        :idActividad="item.id"
                        :origen="origen"
                        :titulo="titulo"
                        tooltipText="Agregar Evidencias"
                    ></AgregarEvidencia>
                    <ModificarAccion 
                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                        @reloadAn="aniosConsulta" 
                        @reload="getDataPorAnio" 
                        v-bind:id="item.id">
                    </ModificarAccion>
                    </template>                    
                    <template v-slot:item.evidencia="{ item }">
                        <ListadosDocs :idActividad="item.id" origen="accionesPoS" :titulo="titulo"></ListadosDocs>
                    </template>
                    </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    
</template>

<script>

import axios from "@/axios.js";
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import AgregarAccion from "@/components/parametros/AccionesPrimerSegundo/AgregarAccionPoSComponent";
import ModificarAccion from "@/components/parametros/AccionesPrimerSegundo/ModificarAccionPoSComponent";
import moment from "moment";

export default {
    mounted(){
        this.getDataPorAnio();
    },
    components:{
        AgregarAccion,
        ListadosDocs,
        AgregarEvidencia,
        ModificarAccion
    },
    props: ["headers"],
    data :()=>({
        loading: false,
        acciones: [],
        items:[],
        titulo:"Evidencia de Acciones",
        origen:"accionesPoS"
    }),
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    computed: {  
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        aniosConsulta() {
            return this. $store.getters.aniosConsulta;
        }
    },
    methods:{
        getDataPorAnio(){
            this.loading = true;
            let anio = this.anioConsulta;
            let idInstalacion = this.instalacion.id;
            axios.get("/accionesPoS/listarAcciones/"+anio+"/"+idInstalacion
            ).then(response=>{
                this.acciones = response.data.acciones;
                this.loading = false;
                this.loadingModal(false);
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
                this.loadingModal(false);
              }
            })
        },
        dateFormat(fecha) {
            return fecha ? moment(fecha).format("DD/MM/YYYY") : "";
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }   
}
</script>