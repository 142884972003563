<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card>
          <v-list-item>
            <v-list-item-avatar tile left size="10%">
              <v-img src="@/assets/logo_3.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="mqClass(3)"
                >Error 401</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-text>
            <v-row align="stretch" justify="space-around">
              <v-col cols="8">
                <v-row>
                  <v-col cols="12">
                    <p :class="mqClass(1)">Acceso no autorizado.</p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="fullHeigth" align="center">
                  <v-col cols="12">
                    <p :class="mqClass(2)">
                      Lo sentimos, su usuario no posee acceso a este módulo, por
                      favor contácte al administrador.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider class="pa-0" vertical></v-divider>
              <v-col cols="3">
                <v-img src="@/assets/not_found.png"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  methods: {
    mqClass(tipo) {
        this.$store.dispatch('loadingPage', false);
      let mq = this.$mq;
      switch (tipo) {
        case 1:
          switch (mq) {
            case "sm":
            case "xs":
              return "body-2";
              break;
            default:
              return "display-1";
              break;
          }
          break;
        case 2:
          switch (mq) {
            case "sm":
            case "xs":
              return "caption";
              break;
            default:
              return "headline";
              break;
          }
          break;
        case 3:
          switch (mq) {
            case "sm":
            case "xs":
              return "caption";
              break;
            default:
              return "display-1";
              break;
          }
          break;
      }
    }
  }
};
</script>
<style scoped>
.fullHeigh {
  height: 100%;
}
</style>
