<template>
    <v-dialog v-model="dialog"
            persistent 
            scrollable
            max-width="500px"
            transition="dialog-transition">
            <template v-slot:activator="{ on }">
                <v-btn @click="getDataAgregarInstalacion" color="#0B3F67" dark v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
            </template>
            <v-card :loading="loading">
                <v-card-title class="pa-0">
                  <ModalTitle titulo="Agregar Instalación" @cancelar="cancelar"/>
                </v-card-title>  
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-text-field 
                                label="Nombre"
                                :error-messages="nameErrors"
                                v-model="instalacion.nombre"
                                @input="$v.instalacion.nombre.$touch()"
                                @blur="$v.instalacion.nombre.$touch()"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Ubicación"
                                v-model="instalacion.ubicacion">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Provincia"
                                v-model="provincia">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Cantón"
                                v-model="canton">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Distrito"
                                v-model="distrito">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Latitud"
                                :error-messages="latitudErrors"
                                @change="$v.instalacion.latitud.$touch()"
                                @blur="$v.instalacion.latitud.$touch()"
                                v-model="instalacion.latitud" 
                                required
                                decimal>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Longitud"
                                :error-messages="longitudErrors"
                                v-model="instalacion.longitud"
                                @change="$v.instalacion.longitud.$touch()"
                                @blur="$v.instalacion.longitud.$touch()"
                                required
                                decimal>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Cantidad de Personas"
                                :error-messages="cantidadErrors"
                                v-model="instalacion.cantidadPersonal" 
                                @change="$v.instalacion.cantidadPersonal.$touch()"
                                @blur="$v.instalacion.cantidadPersonal.$touch()"
                                required
                                numeric>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Teléfono"
                                v-mask="telMask"
                                v-model="instalacion.telefono" >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select
                                :items="tipos"
                                item-text="nombre"
                                item-value="id"
                                v-model="tipoInstalacion"
                                :error-messages="tiposErrors"
                                label="Tipo Instalación"             
                                @change="$v.tipoInstalacion.$touch()"
                                @blur="$v.tipoInstalacion.$touch()"
                                required
                            ></v-select>
                        </v-col>                                                
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                label="Correo"
                                v-model="instalacion.correo"
                                :error-messages="emailErrors"
                                @input="$v.instalacion.correo.$touch()"
                                @blur="$v.instalacion.correo.$touch()"
                                required
                                email>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-textarea
                                label="Alcance de Implementación"
                                counter
                                rows="3"
                                no-resize
                                :error-messages="alcanceErrors"
                                v-model="instalacion.alcance" 
                                @change="$v.instalacion.alcance.$touch()"
                                @blur="$v.instalacion.alcance.$touch()"
                                required>
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-textarea
                                counter
                                rows="3"
                                no-resize
                                label="Detalle Instalación"
                                :error-messages="detalleErrors"
                                v-model="instalacion.detalle" 
                                @change="$v.instalacion.detalle.$touch()"
                                @blur="$v.instalacion.detalle.$touch()"
                                required>
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-select
                            v-if="isAdmin"
                                :items="organizaciones"
                                item-text="nombre"
                                item-value="id"
                                v-model="organizacion"
                                :error-messages="orgErrors"
                                label="Organización"             
                                @change="$v.organizacion.$touch(), getOrganizacion()"
                                @blur="$v.organizacion.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0" v-if="combustibles">
                            <v-switch 
                                label="Equipos que consumen Combustibles Fósiles"                                 
                                inset 
                                color="#0B3F67" 
                                v-model="instalacion.tieneCombustibles">
                            </v-switch>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0" v-if="cluster">
                            <v-file-input
                                accept=".png, .jpeg, .jpg"
                                label="Agregue el Logo"
                                show-size             
                                :error-messages="logoErrors"
                                @input="$v.logo.$touch()"
                                @blur="$v.logo.$touch()"
                                required                   
                                clearable
                                v-model="logo">
                            </v-file-input>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <select-categoria ref="selectCateg" @enviarCategoria="setCategoria"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn> 
                    <v-btn text :loading="loading" color="#0B3F67" @click="agregarInstalacion"> Agregar </v-btn>
                    <Limite ref="limite"/>
                </v-card-actions>
        </v-card>              
    </v-dialog>    
</template>

<script>

import axios from "@/axios.js";
import { mask } from "vue-the-mask";
import { required, email, requiredIf, numeric, decimal, maxLength } from "vuelidate/lib/validators";
import SelectCategoria from '@/components/categorias/SelectCategoriaComponent';
import ModalTitle from '@/components/utils/ModalTitleComponent'
import Limite from '@/components/utils/LimiteInstComponent';
//Custom validations
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}

export default {
  components: { SelectCategoria, Limite, ModalTitle },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },        
        isAdmin() {
            return this.$store.getters.usuario.rol === "ADMIN" ? true : false;
        },
        orgId() {
            return this.isAdmin ? this.organizacion : this.$store.getters.organizacion.id;
        },
        nameErrors () {
            const errors = []
            if (!this.$v.instalacion.nombre.$dirty) return errors            
            !this.$v.instalacion.nombre.required && errors.push('El nombre es requerido.')            
            return errors
        },
        latitudErrors () {
            const errors = []
            if (!this.$v.instalacion.latitud.$dirty) return errors            
            !this.$v.instalacion.latitud.required && errors.push('La latitud es requerida.') 
            !this.$v.instalacion.latitud.decimal && errors.push('Deben ser solo números.')           
            return errors
        },
        longitudErrors () {
            const errors = []
            if (!this.$v.instalacion.longitud.$dirty) return errors            
            !this.$v.instalacion.longitud.required && errors.push('La longitud es requerida.')  
            !this.$v.instalacion.longitud.decimal && errors.push('Deben ser solo números.')   
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.instalacion.correo.$dirty) return errors
            !this.$v.instalacion.correo.required && errors.push('El correo es requerido.')
            !this.$v.instalacion.correo.email && errors.push('Debe ser un correo valido.')
            return errors
        },
        tiposErrors () {
            const errors = []
            if (!this.$v.tipoInstalacion.$dirty) return errors
            !this.$v.tipoInstalacion.required && errors.push('El tipo es requerido.')
            return errors
        },
        orgErrors () {
            const errors = []
            if (!this.$v.organizacion.$dirty) return errors
            !this.$v.organizacion.required && errors.push('La Organización es requerida.')
            return errors
        },
        cantidadErrors () {
            const errors = []
            if (!this.$v.instalacion.cantidadPersonal.$dirty) return errors
            !this.$v.instalacion.cantidadPersonal.required && errors.push('La cantidad de personas es requerida.')
            !this.$v.instalacion.cantidadPersonal.numeric && errors.push('Deben ser solo números.')
            return errors
        },
        alcanceErrors() {
            const errors = []
            if (!this.$v.instalacion.alcance.$dirty) return errors
            !this.$v.instalacion.alcance.required && errors.push('El alcance es requerido')
            !this.$v.instalacion.alcance.maxLength && errors.push('Dato excede tamaño máximo')
            return errors
        },
        detalleErrors() {
            const errors = []
            if (!this.$v.instalacion.detalle.$dirty) return errors
            !this.$v.instalacion.detalle.required && errors.push('El detalle es requerido')
            !this.$v.instalacion.detalle.maxLength && errors.push('Dato excede tamaño máximo')
            return errors
        },
        logoErrors () {
            const errors = []
            if (!this.$v.logo.$dirty) return errors
            !this.$v.logo.required && errors.push('Logo requerido.')
            !this.$v.logo.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        }, 
        limiteInsta: {
          get () {
            return this.limiteOrg;
          },
          set (limite) {
            this.limiteOrg = limite;
          }
        },
        cantidadInst: {
          get() {
            return this.cantInsta;
          },
          set (cantidad) {
            this.cantInsta = cantidad;
          }
        }
    },
    directives: {
        mask,
    },
    data: () => ({
        dialog: false,
        limiteOrg: null,
        cantInsta: null,
        instalacion: {
            nombre: '',
            ubicacion: '',
            latitud: '',
            longitud: '',
            telefono: '',
            correo: '',
            sitioWeb: '',
            descripcion: '',
            cantidadPersonal: '',
            tieneCombustibles: false,      
            direccion: ''    
        },
        provincia: '',
        canton:'',
        distrito: '',
        loading: false,
        organizacion: null,                        
        organizaciones: [],                        
        telMask: '####-####',
        tipos: [],
        tipoInstalacion: '',
        categoria: null,
        combustibles: false,
        organizacionAdmin: null,
        logo: null,
        cluster: false
    }),
    validations: {
        instalacion: {
          nombre: {
            required,               
          },
          longitud: {
            required,
            decimal
          },
          latitud:{
            required,
            decimal
          },
          correo: {
            required,
            email
          },
          cantidadPersonal: {
            required,
            numeric
          },
          alcance: {
            required,
            maxLength: maxLength(250)
          },
          detalle: {
            required,
            maxLength: maxLength(250)
          }
        },
        tipoInstalacion:{
          required
        },
        organizacion: { 
          required: requiredIf(function(){
            return this.isAdmin
          }) 
        },
        categoria: {
          required
        },
        logo: {
          required: requiredIf(function(){
            return this.cluster
          }),
          maxFileSize
        },
    },
    methods: {
        getDataAgregarInstalacion(){
            this.loading = true;
            axios.get("/instalaciones/getDataAgregarInstalacion/" + this.usuario.id).then(response => {
                this.tipos = response.data.Tipos;
                this.organizaciones = response.data.organizaciones;
                this.limiteInsta = response.data.limiteOrg;
                this.cantidadInst = response.data.cantInsta;
                this.loading = false;
             }).catch(error => {
               if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                 console.log(error);  
                 this.loading = false;   
              }           
             })
        },
        cancelar() {
            this.instalacion = {};
            this.instalacion.tieneCombustibles = false
            this.loading = false;
            this.organizacion = null;
            this.tipoInstalacion = '';
            this.dialog = !this.dialog;                   
            this.$v.instalacion.$reset();
            this.$v.tipoInstalacion.$reset();
            this.$v.organizacion.$reset();
            this.categoria = null;
            this.$refs.selectCateg.cancelar()
            this.combustibles = false;
        }, 
        agregarInstalacion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
              if(this.cantidadInst < this.limiteInsta) {
                this.loading = true;
                let formData = new FormData();
                formData.append('logo', this.logo);
                formData.append('idOrganizacion', this.orgId);
                formData.append('idTipoInstalacion', this.tipoInstalacion);
                formData.append('idUsuario', this.usuario.id);
                formData.append('idCategoria', this.categoria.id);
                this.instalacion.direccion = `${this.provincia}, ${this.canton}, ${this.distrito}`
                formData.append('instal', JSON.stringify(this.instalacion));
                axios.post("/instalaciones/agregar", formData).then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Instalación agregada', color:'success'});
                    // this.$store.commit('inscripcion', response.data.inscripcion); 
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.cancelar();
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la instalación', color:'error'});              
                  }
                })
              } else {
                this.$refs.limite.show = true
              }
            } else {
              if(this.$v.categoria.$invalid) {
                this.$store.dispatch('noti', {mensaje: 'Debe seleccionar una categoria', color:'error'});              
              }
            }
        },       
        setCategoria(categoria) {
          this.categoria = categoria;
          this.requiereCombustibles(categoria);
        },
        requiereCombustibles(categoria) {
          switch(categoria.id){
            case 1:
              this.combustibles = true;
              break;
          }
        },
        getOrganizacion() {
          this.loadingModal(true)
          axios.get(`/organizacion/getOrganizacion/${this.organizacion}`).
          then(response => {
            this.limiteInsta = response.data.limiteOrg;
            this.cantidadInst = response.data.cantInsta;
            this.cluster = response.data.cluster;
          }).catch(err => {
            console.error(err)
          }).finally(() => {
            this.loadingModal(false)
          })
        },
        loadingModal(modal) {                        
          this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>