<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                    
                    <v-icon class="mr-2" v-on="{...tooltip, ...dialog}" @click="getProductosSost" dark color="#ffb605">mdi-pencil</v-icon>
                </template>
                Modificar Sustitución
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Sustitución" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-select
                            :items="anios"
                            v-model="productoSust.anio"
                            :error-messages="anioErrors"
                            label="Año que aplica la sustitución"   
                            @change="$v.productoSust.anio.$touch()"       
                            @blur="$v.productoSust.anio.$touch()"                  
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field                            
                            label="Producto para Sustituir"
                            v-model="productoSust.nombre"
                            :error-messages="nomErrors"
                            @change="$v.productoSust.nombre.$touch()"       
                            @blur="$v.productoSust.nombre.$touch()" 
                            counter="200"
                            hint="Nombre del producto que va a ser sustituido"                   
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-select
                            :items="prodSostenibles"
                            v-model="produtoSost"
                            :error-messages="produtoSostErrors"
                            item-text="nombre"
                            item-value="id"
                            label="Producto Sostenible"         
                            @change="$v.produtoSost.$touch()"
                            @blur="$v.produtoSost.$touch()"                                               
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="modificarProductoSust" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'producto',
        'anios'
    ],
    components: {
      ModalTitle
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        nomErrors() {
            const errors = []
            if (!this.$v.productoSust.nombre.$dirty) return errors            
            !this.$v.productoSust.nombre.required && errors.push('Dato requerido.')    
            !this.$v.productoSust.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')         
            return errors
        },
        anioErrors() {
            const errors = []
            if (!this.$v.productoSust.anio.$dirty) return errors            
            !this.$v.productoSust.anio.required && errors.push('El año de la evidencia es requerido.')            
            return errors
        },
        produtoSostErrors() {
            const errors = []
            if (!this.$v.produtoSost.$dirty) return errors            
            !this.$v.produtoSost.required && errors.push('Seleccionar un producto sustituto.')            
            return errors
        }
    },
    data: () => ({
        dialog: false,
        productoSust: {
            nombre: '',
            anio: '',
        },
        produtoSost: null,
        prodSostenibles: null
    }),
    validations: {
        productoSust: {
            nombre: {
                maxLength: maxLength(200),
                required
            },
            anio: {
                required
            }
        },
        produtoSost: {
            required
        }
    },
    methods: {
        getProductosSost() {
            let instalacion = this.instalacion.id;
            axios.get("/comprasSostenibles/getProductosSustituido/" + this.producto.id + "/" + instalacion)
            .then(response => {
                this.productoSust = response.data.producto;
                this.produtoSost = response.data.prodSostenible;
                this.prodSostenibles = response.data.productosSostenibles;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              }
            })
        },
        modificarProductoSust() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                let sustitucion = this.productoSust;
                delete sustitucion.productSost;
                axios.put("/comprasSostenibles/modificarProdSustituido", null, {params: {
                    producto: JSON.stringify(sustitucion),
                    productoSoste: this.produtoSost,
                    usuario: this.usuario.id
                }}).then(response => {
                    this.$emit('reload');
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Sustitucion modificada', color: 'success'});
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.loadingModal(false);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó la sustitución', color: 'error'});
                  }
                })
            }
        },
        cancelar() {
            this.productoSust = {};
            this.produtoSost = {};
            this.$v.productoSust.$reset();
            this.$v.produtoSost.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>