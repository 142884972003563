<template>
    <div class="mapCR" id="mapDiv"></div>
</template> 

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import costaricaHigh from "@amcharts/amcharts4-geodata/costaRicaHigh";
export default {
  props: [
    'dataMarkers',
    'tipo'
  ],
  mounted() {
    let map = am4core.createFromConfig(this.config, "mapDiv", am4maps.MapChart);
    map.geodata = costaricaHigh;
    map.projection = new am4maps.projections.Miller();
    let polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    map.series.push(polygonSeries);

    //configuracion de los marcadores y tooltips en el mapa
    let imageSeries = map.series.push(new am4maps.MapImageSeries());
    // imageSeries.tooltip.label.interactionsEnabled = true;

    let imageSeriesTemplate = imageSeries.mapImages.template;
    if (this.tipo === 1) {
        //organizaciones
        imageSeriesTemplate.tooltipHTML = this.infoTooltipOrganizacion;
    } else {
        //Instalaciones
        imageSeriesTemplate.tooltipHTML = this.infoTooltip;
    }
    let marker = imageSeriesTemplate.createChild(am4core.Image);
    marker.href = require("@/assets/pin.png");
    marker.width = 25;
    marker.height = 25;
    marker.nonScaling = true;
    marker.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    marker.horizontalCenter = "middle";
    marker.verticalCenter = "bottom";
    imageSeriesTemplate.propertyFields.latitude = "latitud";
    imageSeriesTemplate.propertyFields.longitude = "longitud";

    // Configuracion de las series (tooltip y color de las provincias)
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#74B266");

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#367B25");

    //evento click del marker   
    if (this.tipo == 1) {
        //organizaciones
        marker.events.on("hit", ev => {
            this.detalleOrganizacion(ev.target.dataItem.dataContext);
        });
    } else {
        //instalaciones
        marker.events.on("hit", ev => {
            this.detalleInstalacion(ev.target.dataItem.dataContext);      
        });
    }
    //evento zoom a la provincia
    polygonTemplate.events.on("hit", function(ev) {
      ev.target.series.chart.zoomToMapObject(ev.target);        
    });

    //map.maxZoomLevel = 1;
    //marcadores
    imageSeries.data = this.dataMarkers;
  },
  data: () => ({
    config: {
      "seriesContainer": {
        "draggable": true,
        "resizable": true,
        "maxZoomLevel": 10
      }
    },
    infoTooltipOrganizacion: '<b>{nombre}</b><br><ul><li>Ubicacion:{ubicacion}</li><li>Cantidad de instalaciones: {cantInstalaciones}</li><li>Teléfono: {telefono}</li>',
    infoTooltip: '<b>{nombre}</b><br><ul><li>Ubicacion:{ubicacion}</li><li>Código PBAE: {codigoPbae}</li><li>Teléfono: {telefono}</li>'
  }),
  methods: {
    detalleInstalacion(sucursal){
      this.$store.commit('changeCategoria', `, ${sucursal.nombreCategoria}`);
      this.$store.commit('sucursal', sucursal);
      this.$router.push({name: 'BanderaAzulDashboard', params: {sucursal: sucursal}});
    },
    detalleOrganizacion(organizacion) {
        this.$store.commit('organizacion', organizacion);
        this.$emit('organizacionInfo');
    }
  }
}
</script>

<style scoped>
.mapCR {
  width: 100%;
  height: 550px;
}
</style>