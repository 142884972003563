<template>
  <div>
    <v-row class="pb-1 pt-2">
      <v-col cols="6" offset="3">
        <v-select
          @change="getData"
          :items="anios"
          v-model="anio"
          label="Seleccionar Año"
        ></v-select>
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="350">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Acción</th>
            <th class="text-left">Nombre</th>
            <th class="text-center">Puntos</th>
            <th class="text-center">Cantidad Minima</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in parametros" :key="item.id">
            <td><edit-parametro :item="item"/></td>
            <td>{{ item.nombre }} ( {{ item.indicador.nombre }} )</td>
            <td class="text-center">{{ item.puntos }} pts</td>
            <td class="text-center">{{ item.cantidadMinima }} </td>
          </tr>
        </tbody>
      
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import EditParametro from '@/components/categorias/EditParametroComponent'
export default {
  props: [
    'anios',
    'parametros'
  ],
  components: {
    EditParametro
  },
  data: () => ({
    anio: null
  }),
  methods: {
    getData() {
      this.$emit('getData', this.anio)
    }
  },
}
</script>