<template>
  <v-container fluid>
    <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
    <navParameters :navParams="navParams"/>
    <v-row>
      <v-col cols="12">
        <tituloPrincipal titulo="Medidas Para Evitar Propagación de Enfermedades"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" align-self="center">
        <Agregar v-if="anioConsulta > 2020" @reload="getData"/>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="3">
        <v-text-field 
          label="Buscar"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <tituloSimple titulo="Acciones para Evitar Propagación de Enfermedades"/>
          <v-card-text>
            <Tabla-prevencion @reload="getData" :items="actividades" :search="search"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TablaPrevencion from '@/components/parametros/Agua/PrevencionTablaComponent'
import Agregar from '@/components/parametros/Agua/AgregarAccionPrevComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from '@/axios.js'
export default {
  components: {
    TablaPrevencion,
    TituloPrincipal,
    TituloSimple,
    NavParameters,
    Logo,
    Agregar
  },
  directives: {
    'sticky': VueSticky,
  },
  props: [
      'navParams'
  ],
  mounted() {
    this.getData();
  },
  computed: {
    instalacion() {
      return this.$store.getters.sucursal;
    },
    usuario() {
      return this.$store.getters.usuario;
    },
    anioConsulta() {
      return this. $store.getters.anioConsulta;
    }
  },
  data: () => ({
    search: null,
    actividades: []
  }),
  methods: {
    getData() {
      axios.get(`/prevencion/listarInstalacionAnio/${this.instalacion.id}/${this.anioConsulta}`)
      .then(response => {
        this.actividades = response.data;
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.log(error);
          this.$store.dispatch('noti', {mensaje: 'Ocurrió un error', color:'error'});  
        }
      }).finally(() => {
        this.loadingModal(false);
      })
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  }
}
</script>