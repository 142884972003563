<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn class="white--text" color="#0B3F67" v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Actividad" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-select
                            :items="actividades"
                            v-model="actividad.tipoActividad"
                            :error-messages="tipoErrors"             
                            @change="$v.actividad.tipoActividad.$touch()"
                            @blur="$v.actividad.tipoActividad.$touch()"
                            required                            
                            label="Tipo de Actividad"
                        ></v-select>
                    </v-col>
                    <v-col v-if="isOtro" cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Nombre Actividad"
                            counter="100"
                            v-model="otro"
                            :error-messages="otroErrors"             
                            @change="$v.otro.$touch()"
                            @blur="$v.otro.$touch()"
                            required
                        >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción"
                            counter="500"
                            v-model="actividad.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.actividad.descripcion.$touch()"
                            @blur="$v.actividad.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Temas Tratados"
                            counter="500"
                            v-model="actividad.temasTratados"
                            :error-messages="temasErrors"             
                            @change="$v.actividad.temasTratados.$touch()"
                            @blur="$v.actividad.temasTratados.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Cantidad de Personas"
                            v-model="actividad.cantidadPersonas"
                            v-mask="mask"
                            hint="Ingresar solo números"
                            :error-messages="cantErrors"             
                            @change="$v.actividad.cantidadPersonas.$touch()"
                            @blur="$v.actividad.cantidadPersonas.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Cantidad de Actividades"
                            v-model="actividad.cantidadActividades"
                            v-mask="mask"
                            hint="Ingresar solo números"
                            :error-messages="cantAcErrors"             
                             @change="$v.actividad.cantidadActividades.$touch()"
                             @blur="$v.actividad.cantidadActividades.$touch()"
                            required                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-select
                            label="Participantes"
                            :items="participantes"
                            item-text="nombre"
                            item-value="id"
                            multiple
                            chips
                            v-model="listParticipantes"
                            :error-messages="ParticipanteErrors"             
                            @change="$v.listParticipantes.$touch()"
                            @blur="$v.listParticipantes.$touch()"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                    :error-messages="fechaErrors"
                                    @change="$v.fechaActividad.$touch()"
                                    @blur="$v.fechaActividad.$touch()"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false" :min="fechaMinima" :max="fechaMaxima"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-radio-group 
                            v-model="actividad.modalidad"
                            row
                            mandatory>
                            <template v-slot:label>
                                <div class="text-md-h6">Modalidad</div>
                            </template>
                            <v-radio
                                v-for="m in modalidad"
                                :key="m.id"
                                :label="m.nombre"
                                :value="m.id"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="anioConsulta > 2021">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter  
                            v-model="archivoAct" 
                            label="Fotografias de la Actividad"
                            :error-messages="evidActErrors"
                            @change="$v.archivoAct.$touch()"
                            @blur="$v.archivoAct.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="anioConsulta > 2021">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter  
                            v-model="archivoPart" 
                            label="Certificación de participación o Lista de asistencia"
                            :error-messages="evidPartErrors"
                            @change="$v.archivoPart.$touch()"
                            @blur="$v.archivoPart.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivos" 
                            :label="labelEvid"
                            :error-messages="evidErrors"
                            @change="$v.archivos.$touch()"
                            @blur="$v.archivos.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarActividad">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}
const maxSingleFileSize = (file) => {
    if(file != null) {
        let maximo = 1024 * 1024;
        return file.size < maximo;
    }
    return false;
}
export default {
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    mounted() {
        this.getDataAgregar();
    },
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.actividad.tipoActividad.$dirty) return errors
            !this.$v.actividad.tipoActividad.required && errors.push('Campo requerido')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.actividad.descripcion.$dirty) return errors
            !this.$v.actividad.descripcion.required && errors.push('Campo requerido')
            !this.$v.actividad.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        otroErrors(){
            const errors = []
            if(!this.$v.otro.$dirty) return errors
            !this.$v.otro.required && errors.push('Dato requerido')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.actividad.cantidadPersonas.$dirty) return errors
            !this.$v.actividad.cantidadPersonas.required && errors.push('Campo requerido')
            return errors
        },
        cantAcErrors () {
           const errors = []
           if (!this.$v.actividad.cantidadActividades.$dirty) return errors
           !this.$v.actividad.cantidadActividades.required && errors.push('Campo requerido')
           return errors
        },
        evidErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        ParticipanteErrors () {
            const errors = []
            if (!this.$v.listParticipantes.$dirty) return errors
            !this.$v.listParticipantes.required && errors.push('Campo requerido')
            return errors
        },
        evidActErrors() {
            const errors = []
            if (!this.$v.archivoAct.$dirty) return errors            
            !this.$v.archivoAct.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivoAct.maxSingleFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        evidPartErrors() {
            const errors = []
            if (!this.$v.archivoPart.$dirty) return errors            
            !this.$v.archivoPart.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivoPart.maxSingleFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        fechaErrors () {
            const errors = []
            if (!this.$v.fechaActividad.$dirty) return errors
            !this.$v.fechaActividad.required && errors.push('Campo requerido')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        temasErrors () {
            const errors = []
            if (!this.$v.actividad.temasTratados.$dirty) return errors
            !this.$v.actividad.temasTratados.required && errors.push('Campo requerido')
            !this.$v.actividad.temasTratados.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        isOtro(){
            return this.actividad.tipoActividad === "Otro" ? true : false;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        labelEvid() {
            return this.anioConsulta > 2021 ? 'Evidencias de temas tratados' : 'Agregar Evidencias';
        },
        fechaMaxima() {
            return `${this.anioConsulta}-12-31`;
        },
        fechaMinima() {
            return `${this.anioConsulta}-01-01`;
        },
    },
    data: () => ({
        loading: false,
        dialog: false,
        menu: false,
        mask: '####',
        actividad: {
            tipoActividad: '',
            descripcion: '',
            cantidadPersonas: '',
            cantidadActividades: '',
            indicador: '',
            temasTratados: '',
            modalidad: 0
        }, 
        archivos:[],
        fechaActividad: null,
        actividades:[
            "Capacitacion",
            "Charla",
            "Cine foro",
            "Comunicaciones",
            "Congreso",
            "Conversatorio",
            "Curso",
            "Feria de ambiente y salud",
            "Foro",
            "Información audiovisual y/o escrita complementada con preguntas generadoras, encuestas o debates",
            "Mesa redonda",
            "Taller",
            "Otro"
            ],
        otro : "",
        participantes: [],
        listParticipantes: [],
        archivoAct: null,
        archivoPart: null,
        modalidad: []
    }),
    validations: {
        actividad: {
            tipoActividad: {
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            cantidadPersonas: {
                required
            },
            cantidadActividades: {
                required
            },
            temasTratados:{
                maxLength: maxLength(500),
                required
            }
        },
        archivos: {
            required,
            maxFileSize
        },
        otro: {
            required: requiredIf(function(){
                return this.actividad.tipoActividad === "Otro" ? true : false;
            })
        },
        listParticipantes: {
            required
        },
        archivoAct: {
            required: requiredIf(function(){
                return this.anioConsulta > 2021 ? true : false;
            }),
            maxSingleFileSize
        },
        archivoPart: {
            required: requiredIf(function(){
                return this.anioConsulta > 2021 ? true : false;
            }),
            maxSingleFileSize
        },
        fechaActividad: {
            required
        }
    },
    methods: {
        getDataAgregar() {
            this.loading = true;
            axios.get('/educacionAmbiental/getDataAgregar')
            .then(response => {
                this.participantes = response.data.participantes;
                this.modalidad = response.data.modalidad;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.loading = false;
            })
        },
        agregarActividad() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                this.actividad.indicador = this.indicadorActual;
                var formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append("otro", this.otro);    
                formData.append("actividad", JSON.stringify(this.actividad));
                formData.append("fechaActividad", this.fechaActividad);
                formData.append('idInstalacion',this.sucursal.id);
                formData.append("idUsuario", this.usuario.id);
                formData.append("indicador", this.indicadorActual);
                formData.append("participantes", this.listParticipantes);
                formData.append("evidActividad", this.archivoAct);
                formData.append("evidParticipacion", this.archivoPart);
                axios.post("/educacionAmbiental/agregar", formData).then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Actividad de educación agregada', color:'success'});   
                    this.$emit('reload');
                    this.$emit('reloadAn');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de educación', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.actividad = {};
            this.fechaActividad = null
            this.archivos=[];
            this.otro = "";
            this.listParticipantes = [];
            this.$v.actividad.$reset();  
            this.$v.archivos.$reset();
            this.$v.archivoAct.$reset();
            this.$v.archivoPart.$reset();
            this.$v.fechaActividad.$reset();
            this.$v.otro.$reset();
            this.$v.listParticipantes.$reset();
            this.dialog = false;
            this.loading = false;
            this.archivoPart = null;
            this.archivoAct = null;
        }
    },
}
</script>