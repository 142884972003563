<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on:tooltip }">
                    <span id="pointer" class="pl-3" v-on="{...dialog, ...tooltip}">{{ userName }}</span>
                </template>
                Perfil de Usuario
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Perfil de Usuario" @cancelar="cerrar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Nombre"
                            v-model="usuario.nombre"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Apellido"
                            v-model="usuario.apellido"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Usuario en la Aplicación"
                            v-model="usuario.nombreUsuario"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Correo"
                            v-model="usuario.correo"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Teléfono"
                            v-model="usuario.telefono"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Cédula"
                            v-model="usuario.cedula"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Rol"
                            v-model="usuario.nombreRol"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0">
                        <v-text-field
                            label="Puesto dentro de la Organización"
                            v-model="usuario.puestoTrabajo"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            v-if="changePass"
                            label="Nuevo Password"
                            v-model="password"
                            type="password" 
                            :error-messages="passErrors"             
                            @change="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                            v-if="changePass"
                            label="Confirme el Password"
                            v-model="confirmPassword"
                            type="password" 
                            :error-messages="confPassErrors"             
                            @change="$v.confirmPassword.$touch()"
                            @blur="$v.confirmPassword.$touch()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-checkbox 
                            label="Cambiar Password" 
                            v-model="changePass">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cerrar" color="error" text>Cerrar</v-btn>
                <v-btn v-if="changePass" @click="changePassword" text color="#0B3F67">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { requiredIf, sameAs } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from '@/axios.js'

export default {
    props: [
        'userName'
    ],
    components: {
      ModalTitle
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario
        },
        passErrors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors            
            !this.$v.password.required && errors.push('Ingrese el nuevo password.')
            return errors
        },
        confPassErrors () {
            const errors = []
            if (!this.$v.confirmPassword.$dirty) return errors            
            !this.$v.confirmPassword.sameAsPassword && errors.push('Los datos ingresados no coinciden.') 
            return errors
        },
    },
    data: () => ({
        dialog: false,
        changePass: false,
        password: null,
        confirmPassword: null
    }),
    validations: {
        password: { 
            required: requiredIf(function(){
                return this.changePass
            })
        },
        confirmPassword: {
            sameAsPassword: sameAs('password')
        }
    },
    methods: {
        changePassword() {
            this.$v.$touch();
                if(!this.$v.$invalid) {
                axios.put("/usuario/modificarPassword", null, {params: {
                    usuario: this.usuario.id,
                    password: this.password
                }}).then(response => {
                    this.$store.dispatch('noti', {mensaje: 'Password moddificado', color: 'success'});
                    this.cerrar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti', {mensaje: 'Error no se modificó el password', color: 'error'});
                  }
                })
            }
        },
        cerrar() {
            this.changePass = false;
            this.password = null;
            this.confirmPassword = null;
            this.$v.password.$reset();
            this.$v.confirmPassword.$reset();
            this.dialog = false;
        }
    },
}
</script>
<style scoped>
    #pointer {
        cursor: pointer;
    }
</style>