<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon @click="getEstado" class="pl-1" color="#0B3F67" v-on="{ ...tooltip, ...dialog }">mdi-state-machine</v-icon>
                </template>
                Modificar Estado
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Estado Plan" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-select
                    :items="estados"
                    no-data-text="Sin Datos"
                    item-text="nombre"
                    item-value="estado"
                    v-model="estado"
                    label="Estado"
                ></v-select>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
                <v-btn @click="modificar" text color="#0B3F67">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    props: [
        'idPlan',
        'instalacion',
        'usuario',
        'estadoAct'
    ],
    components: {
      ModalTitle
    },
    data: () => ({
        estado: null,
        dialog: false,
        estados: [
            {
                estado: 0,
                nombre: 'Sin Iniciar'
            },
            {
                estado: 1,
                nombre: 'En Proceso'
            },
            {
                estado: 2,
                nombre: 'Finalizado'
            }
        ]
    }),
    methods: {
        modificar() {
            this.loadingModal(true);
            axios.put('/adaptacion/modificarEstadoPlan', null, {params: {
                plan: this.idPlan,
                estado: this.estado,
                inst: this.instalacion.id,
                usuario: this.usuario.id
            }}).then(response => {
                this.$emit('reload');
                this.loadingModal(false);
                this.$store.dispatch('noti', {mensaje: 'Estado modificado', color: 'success'});
                this.cancelar();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el estado', color: 'error'});
                this.loadingModal(false);
                this.cancelar();
              }
            })
        },
        getEstado() {
            this.estado = this.estadoAct;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        cancelar() {
            this.estado = null;
            this.dialog = false;
        }
    },
}
</script>