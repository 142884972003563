<template>
  <v-container>
    <v-row>
      <!-- Encabezados -->
      <v-col cols="12" class="pb-0">
        <v-card flat class="transparent pb-0">
          <v-card-title primary-title class="layout justify-center pb-0">
            <div class="headline"><h1 class="gris disp-3 font-weight-bold">CONTÁCTENOS</h1></div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card flat class="transparent pt-0">
          <v-card-title primary-title class="layout justify-center pt-0">
            <div class="display-2"><p class="gris disp-1">Gestor de Sostenibilidad</p></div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-divider></v-divider>
      <!-- columna informativa -->
      <v-col xs="12" sm="12" md="5" offset-md="1">
        <v-card flat class="transparent">
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12">
                  <v-row align-content="center" justify="center">
                    <v-col cols="2" align-self="center">
                      <v-icon size="70px" class="text text--lighten-2">mdi-web</v-icon>
                    </v-col>
                    <v-col cols="10" align-self="center">
                      <div class="title"><a href="https://www.epromcr.com/" target="_blank" rel="noopener noreferrer">https://www.epromcr.com/</a></div>
                    </v-col>
                  </v-row>
              </v-col>
              <v-col cols="12">
                  <v-row align-content="center" justify="center">
                    <v-col cols="2" align-self="center">
                      <v-icon size="70px" class="text text--lighten-2">mdi-phone</v-icon>
                    </v-col>
                    <v-col cols="10" align-self="center">
                      <div class="title">(506) 8314-6930</div>
                    </v-col>
                  </v-row>
              </v-col>
              <v-col cols="12">
                  <v-row align-content="center" justify="center">
                    <v-col cols="2" align-self="center">
                      <v-icon size="70px" class="text text--lighten-2">mdi-email</v-icon>
                    </v-col>
                    <v-col cols="10" align-self="center">
                      <div class="title"><a href="mailto:zurqui@epromcr.com">zurqui@epromcr.com</a></div>
                    </v-col>
                  </v-row>
              </v-col>
              <v-col cols="12">
                  <v-row align-content="center" justify="center">
                    <v-col cols="2" align-self="center">
                      <v-icon size="70px" class="text text--lighten-2">mdi-map</v-icon>
                    </v-col>
                    <v-col cols="10" align-self="center">
                      <a href="https://www.google.com/maps/dir/''/9.93656,-84.151621/@9.9365137,-84.1517329,20.33z/data=!4m8!4m7!1m5!1m1!1s0x8fa0fc79a6854773:0xe4d1cf61a0bd5c4b!2m2!1d-84.1516567!2d9.9365552!1m0" target="_blank" class="title">Centro Comercial Calle Real.  Local No. 10. San Rafael de Escazú, San José, Costa Rica.</a>
                    </v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1">
        <v-divider vertical></v-divider>
      </v-col>
      <!-- Formulario Contactenos -->
      <v-col cols="12" sm="12" md="5">
        <Contactenos-loader v-if="loading"/>
        <v-card flat class="transparent" v-else>
          <v-card-text>
            <v-text-field
              outlined
              label="Nombre"
              v-model="nombre"
              counter="50"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
              :error-messages="nombreErrors"
              required
            ></v-text-field>
            <v-text-field
              outlined
              label="Empresa"
              v-model="empresa"
              counter="50"
              @input="$v.empresa.$touch()"
              @blur="$v.empresa.$touch()"
              :error-messages="empresaErrors"
              required
            ></v-text-field>
             <v-select
              outlined
              :items="sectores"
              item-text="nombre"
              item-value="id"
              v-model="sector"
              label="Sector Productivo"
              :error-messages="sectorErrors"             
              @change="$v.sector.$touch()"
              @blur="$v.sector.$touch()"
              required
            ></v-select>
            <v-text-field
              outlined
              label="Puesto"
              v-model="puesto"
              counter="50"
              @input="$v.puesto.$touch()"
              @blur="$v.puesto.$touch()"
              :error-messages="puestoErrors"
              required
            ></v-text-field>
            <v-text-field
              outlined
              label="Correo"
              v-model="correo"
              counter="50"
              @input="$v.correo.$touch()"
              @blur="$v.correo.$touch()"
              :error-messages="correoErrors"
              required
            ></v-text-field>
            <v-text-field
              outlined
              label="Teléfono"
              v-model="telefono"
              v-mask="mask"
              counter="9"
              @input="$v.telefono.$touch()"
              @blur="$v.telefono.$touch()"
              :error-messages="telefonoErrors"
              required
            ></v-text-field>
            <v-textarea
              outlined
              label="Mensaje"
              v-model="mensaje"
              counter="100"
              @input="$v.mensaje.$touch()"
              @blur="$v.mensaje.$touch()"
              :error-messages="mensajeErrors"
              required
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <vue-recaptcha 
              :sitekey="recaptchaKey" 
              size="invisible"
              :loadRecaptchaScript="true"
              badge="bottomleft"
              ref="invisibleRecaptcha"
              @verify="enviarCorreo">
              <v-btn block outlined color="#0B3F67">Enviar</v-btn>
            </vue-recaptcha>
            <!-- <v-btn block outlined @click="enviarCorreo" color="#0B3F67">Enviar</v-btn> -->
          </v-card-actions>
        </v-card>
        <v-alert type="success" dismissible :value="enviado">
          Correo Enviado, Gracias por contactarnos
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import VueRecaptcha from 'vue-recaptcha';
import { required, email, maxLength } from "vuelidate/lib/validators";
import ContactenosLoader from '@/components/loaders/ContactenosLoader'
export default {
  directives: {
    mask,
  },
  components: {
    ContactenosLoader,
    VueRecaptcha
  },
  mounted() {
    this.getData()
  },
  computed: {
    nombreErrors () {
      const errors = []
      if (!this.$v.nombre.$dirty) return errors            
      !this.$v.nombre.required && errors.push('Campo obligatorio.')    
      !this.$v.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')        
      return errors
    },
    empresaErrors () {
      const errors = []
      if (!this.$v.empresa.$dirty) return errors            
      !this.$v.empresa.required && errors.push('Campo obligatorio.')
      !this.$v.empresa.maxLength && errors.push('No debe exceder el tamaño permitido.')            
      return errors
    },
    puestoErrors () {
      const errors = []
      if (!this.$v.puesto.$dirty) return errors            
      !this.$v.puesto.required && errors.push('Campo obligatorio.')
      !this.$v.puesto.maxLength && errors.push('No debe exceder el tamaño permitido.')            
      return errors
    },
    correoErrors () {
      const errors = []
      if (!this.$v.correo.$dirty) return errors            
      !this.$v.correo.required && errors.push('Campo obligatorio.')
      !this.$v.correo.email && errors.push('Debe ser un correo valido.')
      !this.$v.correo.maxLength && errors.push('No debe exceder el tamaño permitido.')
      return errors
    },
    telefonoErrors () {
      const errors = []
      if (!this.$v.telefono.$dirty) return errors            
      !this.$v.telefono.required && errors.push('Campo obligatorio.')
      !this.$v.telefono.maxLength && errors.push('No debe exceder el tamaño permitido.')            
      return errors
    },
    mensajeErrors () {
      const errors = []
      if (!this.$v.mensaje.$dirty) return errors            
      !this.$v.mensaje.required && errors.push('Campo obligatorio.')
      !this.$v.mensaje.maxLength && errors.push('No debe exceder el tamaño permitido.')            
      return errors
    },
    sectorErrors () {
      const errors = []
      if (!this.$v.sector.$dirty) return errors
      !this.$v.sector.required && errors.push('El sector es requerido.')
      return errors
    },
  },
  data: () => ({
    sectores: [],
    sector: '',
    nombre: null,
    empresa: null,
    puesto: null,
    correo: null,
    telefono: null,
    mensaje: null,
    mask: '####-####',
    loading: false,
    enviado: false,
    recaptchaKey: '6Lc6kTcdAAAAAIHXfTFFnIgf2h4dE1VGgN1lr7kK'
  }),
  validations: {
    nombre: { required, maxLength: maxLength(100) },
    empresa: { required, maxLength: maxLength(100) },
    puesto: { required, maxLength: maxLength(100) },
    correo: { required, email, maxLength: maxLength(50) },
    telefono: { required, maxLength: maxLength(9) },
    mensaje: { required, maxLength: maxLength(300) },
    sector: { required },
  },
  methods: {
    getData() {
      axios.get(process.env.BASE_URL+'api/user/unauthenticated/getSectoresProd/')
      .then(response => {
        this.sectores = response.data
      }).catch(error => {
        console.error(error);
      })
    },
    enviarCorreo(response) {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        this.loading = true
        axios.post(process.env.BASE_URL+"api/user/auth/contactenos/", null, { 
          params: {
            nombre: this.nombre,
            empresa: this.empresa,
            puesto: this.puesto,
            correo: this.correo,
            telefono: this.telefono,
            mensaje: this.mensaje,
            sector: this.sector,
            recaptchaResponse: response
          }
        }).then(() => {
          this.enviado = true
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.limpiarDatos()
          this.loading = false
        })
      }
    },
    limpiarDatos() {
      this.nombre = null
      this.empresa = null
      this.puesto = null
      this.correo = null
      this.telefono = null
      this.mensaje = null
      this.sector = ''
      this.$v.nombre.$reset()
      this.$v.empresa.$reset()
      this.$v.puesto.$reset()
      this.$v.correo.$reset()
      this.$v.telefono.$reset()
      this.$v.mensaje.$reset()
      this.$v.sector.$reset()
    }
  }
}
</script>
<style>
.grecaptcha-badge {
	z-index: 999;
}
</style>