<template>
    <v-container fluid>        
        <IndicadoresDesempenioTabla></IndicadoresDesempenioTabla>
    </v-container>
</template>

<script>

import IndicadoresDesempenioTabla from "@/components/eficiencia/configuracionDesempenio/IndicadorDesempenioTablaComponent";

export default {
    components: {
        IndicadoresDesempenioTabla
    },
};
</script>