<template>
    <v-container fluid>             
        <v-row class="mb-3">            
            <v-col cols="6" sm="5" md="5" lg="3" xl="3"><AgregarEnfoque @recargaTabla="buscarEnfoque" /></v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="4" md="4" lg="3" xl="3">            
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Búsqueda"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>  
        </v-row>  
        <v-row>             
            <v-col cols="12">
                <v-card>
                    <v-sheet :height="alturaHoja()" class="v-sheet--offset mx-auto white--text display-1 font-weight-medium text-center" color="#0B3F67" elevation="12" max-width="calc(100% - 32px)">                   
                        <v-layout fill-height align-center justify-center>                            
                            Catálogo Tipo de Enfoque
                        </v-layout>                                  
                    </v-sheet>                      
                    <v-data-table
                        multi-sort        
                        :search="search"        
                        :headers="headers"
                        :items="enfoque"
                        class="elevation-1"            
                        no-data-text="Sin datos"
                        no-results-text="Dato no encontrado"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"               
                    >
                    <template v-slot:item.action="{ item }">
                        <modificarEnfoque v-bind:idEnfoque="item.id" @reloadEdit="buscarEnfoque"/>
                    </template>      
                        <template v-slot:item.estado="{ item }">
                            <v-layout justify-center>
                            <activarEnfoque v-bind:id="item.id" v-bind:estado="item.estado" @reloadEdit="buscarEnfoque"/>
                            </v-layout>
                        </template>                    
                    </v-data-table>   
                </v-card>
            </v-col>
        </v-row>     
    </v-container>
</template>

<script>

import AgregarEnfoque from "@/components/Enfoque/TipoEnfoqueAgregarComponent";
import modificarEnfoque from "@/components/Enfoque/TipoEnfoqueModificarComponent";
import activarEnfoque from "@/components/Enfoque/TipoEnfoqueActivarComponent";
import axios from "@/axios.js";

export default {
    components: { 
        AgregarEnfoque,
        modificarEnfoque,
        activarEnfoque
    },
   created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },      
    mounted () {
        this.buscarEnfoque();
    },
     data: () => ({
        headers:[

            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                width: '10%',
                sortable: false
            },
             {
                text: 'Nombre',
                value: 'nombre',
                align: 'center',
                sortable: true,
                filterable: true,
            },
            /* {
                text: 'Descripción',
                value: 'descripcion',
                align: 'center',
                sortable: true,                
            }, */
        ],
        search: '',
        enfoque: [],
        cargando: true
    }),
    methods: 
    { 
        buscarEnfoque() {
            this.cargando = true;
            axios.get("/enfoque/listarEnfoque").then(response => {
                this.enfoque = response.data.enfoque;   
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.cargando = false;
              }
            })
        },
        alturaHoja() {
            return this.$mq === 'sm' ? 120 : 80;
        }        
    }    
}
</script>

<style scoped>
    table.v-table thead th {
      font-size: 20px !important;
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>
