<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Notas" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="hogar.nombre"
                            label="Hogar"
                            disabled
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            label="Parámetro"
                            :items="indicadores"
                            value="nombre"
                            item-text="nombre"
                            v-model="historial.parametro"
                            :error-messages="parametrosErrors"             
                            @change="$v.historial.parametro.$touch()"
                            @blur="$v.historial.parametro.$touch()"
                            required>
                        </v-select>  
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Nota Obtenida"
                            v-model="historial.notaObtenida"
                            :error-messages="notasErrors"             
                            @change="$v.historial.notaObtenida.$touch()"
                            @blur="$v.historial.notaObtenida.$touch()"
                            required>
                        </v-text-field>  
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarHistorial">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, decimal } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        parametrosErrors() {
            const errors = []
            if (!this.$v.historial.parametro.$dirty) return errors            
            !this.$v.historial.parametro.required && errors.push('El nombre del parámetro es requerido.')
            return errors
        },
        notasErrors() {
            const errors = []
            if (!this.$v.historial.notaObtenida.$dirty) return errors            
            !this.$v.historial.notaObtenida.required && errors.push('La nota es requerida.')
            !this.$v.historial.notaObtenida.decimal && errors.push('Solo debe digitar números.')
            return errors
        },
    },
    props: [
        'hogar',
        'disabled',
        'indicadores',
        'notas',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        historial: {
            parametro: '',
            notaObtenida: '',
        },
        repite: 0,
    }),
    validations: {
        historial: {
            parametro: { required },
            notaObtenida: { required, decimal },
        },
    },
    methods: {
        repiteParametro() {
            let param = this.historial.parametro;
            this.notas.forEach(i => {
                if (i.parametro.localeCompare(param) == 0)
                    this.repite++ 
            });
        },
        cancelar() {
            this.historial = {};
            this.repite = 0;
            this.dialog = false;
            this.loading = false;
            this.$v.historial.$reset();
        },
        agregarHistorial() {
            this.repite = 0
            this.$v.$touch();
            this.repiteParametro();
            if (this.repite > 0) {
                this.$store.dispatch("noti", { 
                    mensaje: "Ya se ha agregado el parámetro actual, por favor seleccione otro",
                    color: "error"
                });
            }
            if (!this.$v.$invalid && this.repite === 0) {
                this.loading = true;
                let formData = new FormData();
                formData.append("idHogar", this.hogar.id);
                formData.append("historialS", JSON.stringify(this.historial));
                axios.post("/estrellas/agregarNotaHogar", formData)
                .then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Nota agregada", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agregar la nota " + error);
                    this.$store.dispatch("noti", { 
                        mensaje: "Ocurrió un error no se agregó la nota",
                        color: "error"
                    });
                    this.cancelar();
                  }
                });
            }
        }
    }
};
</script>
