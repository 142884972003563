<template>
    <v-container fluid>
        <Legislaciones/>             
    </v-container>
</template>
<script>
import Legislaciones from "@/components/Informativo/LegislacionesComponent";

export default {
    components: {
        Legislaciones,       
    },    
}
</script>