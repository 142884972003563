<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getProducto()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Producto" @cancelar="cancelar"/>
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text>
                <v-container grid-list-xs>
                   <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Nombre"
                                v-model="producto.nombre"
                                :error-messages="nameErrors"
                                @input="$v.producto.nombre.$touch()"
                                @blur="$v.producto.nombre.$touch()"
                                required
                            ></v-text-field>
                        </v-col>                    
                        <v-col cols="6">
                            <v-select
                                :items="etiquetas"
                                item-text="nombre"
                                item-value="id"
                                v-model="etiqueta"
                                label="Etiqueta Ambiental"
                                :error-messages="etqErrors"             
                                @change="$v.etiqueta.$touch()"
                                @blur="$v.etiqueta.$touch()"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarProducto" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import {required} from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'

export default {
    props: [
        'id'
    ],
    components: {
      ModalTitle
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.producto.nombre.$dirty) return errors            
            !this.$v.producto.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
        etqErrors () {
            const errors = []
            if (!this.$v.etiqueta.$dirty) return errors
            !this.$v.etiqueta.required && errors.push('La etiqueta es requerido')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        producto: {
            nombre: ''
        },
        loading: false,
        etiquetas: [],
        etiqueta: ''
    }),
    validations: {
        producto: {
            nombre: {
                required               
            }
        },
        etiqueta: {
            required
        }
    },
    methods: {
        getProducto() {
            this.loading = true;
            axios.get("/productos/getDataModificar/"+this.id).then(response => {
                this.producto = response.data.producto;
                this.etiquetas = response.data.etiquetas;
                this.etiqueta = response.data.etiqueta;
                this.$emit('reload');
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },        
        modificarProducto() {
            this.$v.$touch();
            if(!this.$v.$invalid) { 
                this.loading = true;
                axios.put("/productos/modificarProducto/", this.producto,{ params: {
                    idEtiqueta: this.etiqueta
                }}).then(response => {
                    this.loading = false;
                    this.dialog = false;
                    this.$store.dispatch('noti',{mensaje: 'Producto modificado', color:'success'});      
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el producto', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        cancelar() {
            this.producto = {};
            this.loading = false;
            this.etiqueta = '';            
            this.$v.producto.$reset();
            this.$v.etiqueta.$reset();
            this.dialog = false;
        },
    },
}
</script>