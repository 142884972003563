<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{...tooltip, ...on}"
                        @click="getDataUsuario()">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Usuario" @cancelar="limpiar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-container grid-list-xs>
                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Nombre"
                                v-model="usuario.nombre"
                                :error-messages="nameErrors"
                                @input="$v.usuario.nombre.$touch()"
                                @blur="$v.usuario.nombre.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Apellido"
                                v-model="usuario.apellido"
                                :error-messages="apelErrors"
                                @input="$v.usuario.apellido.$touch()"
                                @blur="$v.usuario.apellido.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <v-text-field                                
                                label="Usuario"
                                v-model="usuario.nombreUsuario"
                                :error-messages="userNameErrors"
                                @blur="$v.usuario.nombreUsuario.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Teléfono"
                                v-model="usuario.telefono"
                                :error-messages="telefonoErrors"
                                @input="$v.usuario.telefono.$touch()"
                                @blur="$v.usuario.telefono.$touch()"
                                required
                                v-mask="telMask"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Cédula"
                                v-model="usuario.cedula"
                                v-mask="mask"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="!isAdmin" class="pt-0 pb-0">
                            <v-text-field
                                label="Puesto de Trabajo"
                                v-model="usuario.puestoTrabajo"
                            ></v-text-field>
                        </v-col>    
                        <v-col cols="12" v-if="isAdmin" class="pt-0 pb-0">
                            <v-select
                                :items="roles"
                                v-model="rol"
                                label="Rol"
                                :error-messages="rolErrors"             
                                @change="$v.rol.$touch()"
                                @blur="$v.rol.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <v-col :cols="colsSize" v-else class="pt-0 pb-0">
                            <v-select
                                :items="roles"
                                v-model="rol"
                                label="Rol"
                                :error-messages="rolErrors"             
                                @change="$v.rol.$touch()"
                                @blur="$v.rol.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="!isAdmin && superAdmin" class="pt-0 pb-0">
                            <v-select
                                :items="organizaciones"
                                item-text="nombre"
                                item-value="id"
                                v-model="organizacion"
                                label="Organización"
                                :error-messages="orgErrors"             
                                @change="$v.organizacion.$touch()"
                                @blur="$v.organizacion.$touch()"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field type="email"
                                label="Correo"
                                v-model="usuario.correo"
                                :error-messages="emailErrors"
                                @blur="$v.usuario.correo.$touch()"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="limpiar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="modificarUsuario" text>Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import { mask } from "vue-the-mask";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import {required, minLength, email, requiredIf} from "vuelidate/lib/validators";

export default {
    props: [
        'idUsuario'
    ],
    components: {
      ModalTitle
    },
    directives: {
        mask,
    },
    computed: {
        usuarioSol() {
          return this.$store.getters.usuario;
        },
        colsSize () {
            return this.superAdmin ? 6 : 12;
        },
        superAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN'
        },
        isAdmin() {
            return this.rol === 'Administrador' ? true : false;
        },
        nameErrors () {
            const errors = []
            if (!this.$v.usuario.nombre.$dirty) return errors            
            !this.$v.usuario.nombre.required && errors.push('El nombre es requerido.')
            return errors
        },
        apelErrors () {
            const errors = []
            if (!this.$v.usuario.apellido.$dirty) return errors            
            !this.$v.usuario.apellido.required && errors.push('El apellido es requerido.')            
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.usuario.correo.$dirty) return errors
            !this.$v.usuario.correo.email && errors.push('Debe ser un correo valido.')
            !this.$v.usuario.correo.required && errors.push('El correo es requerido.')
            !this.$v.usuario.correo.uniqueEmail && errors.push('Este correo eléctronico ya se encuentra en uso.')
            return errors
        },
        telefonoErrors () {
            const errors = []
            if (!this.$v.usuario.telefono.$dirty) return errors            
            !this.$v.usuario.telefono.required && errors.push('El teléfono es requerido.')
            return errors
        },
        rolErrors () {
            const errors = []
            if (!this.$v.rol.$dirty) return errors
            !this.$v.rol.required && errors.push('El rol es requerido')
            return errors
        },
        orgErrors () {
            const errors = []
            if (!this.$v.organizacion.$dirty) return errors
            !this.$v.organizacion.required && errors.push('La Organización es requerida')
            return errors
        },
        userNameErrors () {
            const errors = []
            if (!this.$v.usuario.nombreUsuario.$dirty) return errors            
            !this.$v.usuario.nombreUsuario.required && errors.push('El usuario es requerido.')
            !this.$v.usuario.nombreUsuario.minLength && errors.push('Deben ser al menos 5 carácteres.')
            !this.$v.usuario.nombreUsuario.uniqueUsername && errors.push('Este nombre de usuario no esta disponible.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        usuario: {
            nombre: '',
            apellido: '',
            correo: '',
            estado: 0,
            nombreUsuario: '',            
            cedula: '',
            puestoTrabajo: '',
            organizacionCompleta: false,
            telefono: '',
        },
        mask: '#-####-####',
        telMask: '####-####',
        rol: '',
        loading: false,
        organizaciones: [],
        roles: [],
        organizacion: null
    }),
    validations: {
        usuario: {
            nombre: {
                required,
                minLength: minLength(4)
            },
            apellido: {
                required,               
            },
            nombreUsuario: {
                required,
                minLength: minLength(5),
                uniqueUsername(username) {
                    if (email === '') return true
                    return new Promise((resolve, reject) => {
                        axios.get("/usuario/verificarUsuario/" + username + "/" + this.idUsuario).then(response => {
                            resolve(response.data);
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            reject(error);
                          }
                        })
                    })
                }
            },
            correo: {
                required,
                email,
                uniqueEmail(email) {
                    if (email === '') return true
                    return new Promise((resolve, reject) => {
                        axios.get("/usuario/verificarEmail/" + email + "/" + this.idUsuario).then(response => {
                            resolve(response.data);
                        }).catch(error => {
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            reject(error);
                          }
                        })
                    })
                }
            },
            telefono: { required }
        },
        rol: {
            required
        },
        organizacion: {
            required: requiredIf(function(){
                return !this.isAdmin
            }) 
        }
    },
    methods: {
        getDataUsuario() {
            this.loading = true;
            axios.get(`/usuario/getDataUsuario/${this.idUsuario}/${this.usuarioSol.id}` ).then(response => {
                this.usuario = response.data.usuario;
                this.organizaciones = response.data.organizaciones;
                this.roles = response.data.roles;
                this.rol = response.data.rol;
                this.organizacion = response.data.organizacion;
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        
        modificarUsuario() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                this.usuario.organizacionCompleta = false;
                axios.put("/usuario/modificarUsuario/", this.usuario, { params: {
                    orgId: this.organizacion, 
                    rol: this.rol,
                }}).then(response => {
                    this.loading = false;
                    this.$store.dispatch('noti',{mensaje: 'Usuario modificado', color:'success'});
                    this.$emit('reloadEdit');
                    this.limpiar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el usuario', color:'error'});
                    this.loading = false;
                  }
                })
            }
        },
        limpiar() {
            this.usuario = {};
            this.dialog = !this.dialog;
            this.organizacion = '';
            this.$v.usuario.$reset();
            this.$v.organizacion.$reset();
            this.$v.rol.$reset();
            this.loading = false;
        },
    },
}
</script>