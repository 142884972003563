<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="800px"
    transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn icon v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
            <v-icon>mdi-cog-sync-outline</v-icon>
          </v-btn>
        </template>
        Configurar Categoria
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle titulo="Configurar Categoria" @cancelar="cancelar"/>
      </v-card-title>
      <v-card-text>
          <v-card>
            <v-card-title class="text-center justify-center py-6">
              <h1 class="font-weight-bold text-h2 basil--text">
                {{ categoria.nombre }}
              </h1>
            </v-card-title>

            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#0B3F67"
              grow>
              <v-tab
                v-for="item in items"
                :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card
                  flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" offset-md="3">
                        <v-text-field
                          label="Nombre Categoria"
                          v-model="categoria.nombre"
                          @input="$v.categoria.nombre.$touch()"
                          @blur="$v.categoria.nombre.$touch()"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" cols="12" md="12">
                        <v-card class="text-center">
                          <img height="150" width="140" :src="logo" contain alt="zurqui"/>
                          <v-card-actions>
                            <seleccionar-logo @cambiarLogo="cambiarLogo"/>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <editable-table :parametros="parametros" :anios="anios" @getData="getDataCategoria"/>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" text color="error">Cancelar</v-btn>
        <v-btn @click="modificar" text color="#0B3F67">Modificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import SeleccionarLogo from './SeleccionarLogoComponent';
import EditableTable from './EditableTableComponent';
import { required } from "vuelidate/lib/validators";
import axios from '@/axios'
export default {
  components: { SeleccionarLogo, EditableTable, ModalTitle },
  props: [
    'anios',
    'categoria'
  ],
  computed: {
    logo() {
      return require('@/assets/' + this.categoria.logo)
    },
    usuario() {
      return this.$store.getters.usuario;
    },
    nameErrors () {
      const errors = []
      if (!this.$v.categoria.nombre.$dirty) return errors            
      !this.$v.categoria.nombre.required && errors.push('El nombre es requerido.')            
      return errors
    },
  },
  data: () => ({
    dialog: false,
    tab: null,
    items: [
      'Datos de Categoria', 'Personalizar Parámetros',
    ], 
    parametros: [],
    selectedAnio: null
  }),
  validations: {
    categoria: {
      nombre: { required }
    }
  },
  methods: {
    getDataCategoria(anio) {
      this.selectedAnio = anio
      this.loadingModal(true);
      axios.get(`/categoria/getDataCategoria/${this.categoria.id}/${anio}`)
      .then(response => {
        this.parametros = response.data.parametros;
        this.loadingModal(false);
      }).catch(error => {
        if (error.request && error.request.status === 401) {
          this.$store.commit('showSessionDialog', true);
        } else {
          console.error(error);
          this.loadingModal(false);
        }
      })
    },
    modificar() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.loadingModal(true);
        const modifiedParams = this.parametros.map(obj => {
          return { ...obj, indicador: obj.indicador.id };
        });
        console.log(modifiedParams);
        axios.post('/categoria/modificarCategoria', modifiedParams, {params: {
          usuario: this.usuario.id,
          categoria: this.categoria.id,
          nombreCat: this.categoria.nombre,
          logoCateg: this.categoria.logo,
          anio: this.selectedAnio
        }}).then(response => {
          this.$store.dispatch('noti',{mensaje: 'Categoria ' + this.categoria.nombre + ', configurada', color:'success'});  
          this.loadingModal(false); 
          this.cancelar();
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            this.$store.dispatch('noti',{mensaje: 'Error al configurar la categoria ' + this.categoria.nombre + '.', color:'error'});  
            this.loadingModal(false); 
          }
        })
      }
      
    },
    cambiarLogo(logo) {
      this.categoria.logo = logo;
    },
    cancelar() {
      this.dialog = !this.dialog;
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
  },
}
</script>