<template>
    <v-container fluid>  
        <v-row>
            <v-col cols="12">
                <tituloPrincipal :titulo="'Iniciativa Ecolones'"/>
            </v-col>
        </v-row>   
        <EstrellaTabla ref="tablaEstrella" :titulo="'Iniciativa Ecolones'" :headers="headers" :tipo="tipo"></EstrellaTabla>
    </v-container>
</template>

<script>
import EstrellaTabla from "@/components/Estrellas/EstrellaTablaComponent";
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'

export default {
    components: {
        EstrellaTabla,
        TituloPrincipal,
    },
    props: [
        'tipo',
    ],
    data: () => ({
        headers: [
            { text: 'Acciones', value: 'action', align: 'center', width: '10%', sortable: false, filterable: false,},
            { text: 'Nombre del Centro de Acopio', value: 'centroAcopio', align: 'center', sortable: true, filterable: true, },
            { text: 'Tipo de Residuo', value: 'tipoResiduo', align: 'center', sortable: true, filterable: true, },
            { text: 'Kilogramos Entregados', value: 'kilos', align: 'center', sortable: true, filterable: true, },
            { text: 'Fecha', value: 'fecha', align: 'center', sortable: true, filterable: true, },
            { text: 'Evidencias', value: 'evidencias', align: 'center', sortable: false, filterable: false,},   

        ]
    }),
    methods: {
        getData() {
            this.$refs.tablaEstrella.listaEstrellas();
        }
    }
};
</script>