var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.acciones,"search":_vm.buscar,"hide-default-footer":"","item-key":"id","no-data-text":"No hay Acciones para este periodo"},scopedSlots:_vm._u([{key:"item.fechaActividad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaActividad))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModificarAccionReduccion',{attrs:{"accion1":item},on:{"reload":_vm.listarAccionReduccion}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idAccion":item.id,"origen":_vm.origen,"tooltipText":"Agregar Evidencias","titulo":_vm.titulo},on:{"reload":_vm.listarAccionReduccion}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarAccion(item.id)}}},on),[_vm._v("mdi-trash-can-outline")])]}}:null],null,true)},[_vm._v(" Eliminar ")])]}},{key:"item.evidencia",fn:function(ref){
var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"idAccion":item.id,"origen":"accionReduccion","titulo":_vm.titulo}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }