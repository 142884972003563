var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"3","align-self":"center"}},[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('agregarActividad',{on:{"reload":_vm.getDataPorAnio}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","offset-md":"7"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.actividades,"calculate-widths":"","item-key":"id","loading":_vm.loading,"search":_vm.search,"no-data-text":"No hay Actividades para este periodo","footer-props":{
                          showFirstLastPage: true,
                          firstIcon:'mdi-page-first',
                          lastIcon: 'mdi-page-last',
                          prevIcon: 'mdi-chevron-left',
                          nextIcon: 'mdi-chevron-right',
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageAllText: 'Todos',
                          showCurrentPage: true,
                          showFirstLastPage: true
                      }},scopedSlots:_vm._u([{key:"item.fechaActividad",fn:function(ref){
                      var item = ref.item;
return [_vm._v(_vm._s(_vm.dateFormat(item.fechaActividad)))]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR' && item.tipoNum == 1)?_c('ModificarEducAmbiental',{attrs:{"id":item.id},on:{"reload":_vm.getDataPorAnio}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR' && item.tipoNum == 2)?_c('ModificarAccionReduccion',{attrs:{"accion1":item},on:{"reload":_vm.getDataPorAnio}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idActividad":item.id,"idAccion":item.id,"origen":_vm.getOrigenActividad(item.tipoNum),"tooltipText":"Agregar Evidencias","titulo":_vm.titulo},on:{"reload":_vm.getDataPorAnio}}):_vm._e(),_c('Eliminar',{attrs:{"requestData":_vm.requestDataFormat(item),"requestUrl":"/instalaciones/borrarActividadRedEdc/"},on:{"reload":_vm.reloadData}})]}},{key:"item.evidencia",fn:function(ref){
                      var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"idAccion":item.id,"origen":_vm.getOrigenActividad(item.tipoNum),"titulo":_vm.titulo}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }