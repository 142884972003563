<template>
    <v-container fluid class="pa-0">
        <v-tabs>
            <v-tab v-for="item in items" :key="item.title" :to="item.path">{{ item.title }}</v-tab>
        </v-tabs> 
        <router-view></router-view>
    </v-container>
</template>
<script>
    export default {
        data: () => ({            
            items: [
                {title: 'Bandera Azul', path: '/main/banderaAzul/dashboard'},
                {title: 'Electricidad', path: '/main/banderaAzul/electricidad'},
                {title: 'Agua', path: '/main/banderaAzul/agua'}
            ],
        }),
    }
</script>