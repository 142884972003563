<template>
    <v-dialog
        v-model="dialog"        
        persistent 
        max-width="70vw"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">                        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn @click="getEvidencias" large dark icon v-on="{ ...tooltip, ...dialog }">
                        <v-icon color="#0B3F67">mdi-file-multiple</v-icon>
                    </v-btn>
                </template>
                Adjuntos
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Historial Evidencias" @cancelar="cerrar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="5"
                    no-data-text="No hay evidencias"
                    :footerProps="footer"
                    item-key="id">
                    <template v-slot:item.action="{ item }">
                        <div class="d-flex justify-center">
                            <v-tooltip top v-if="origen == 'inventario'">
                                <template v-slot:activator="{ on }">
                                    <v-icon color="#0B3F67" @click="eliminarEvidencia(item.id)" v-on="on">mdi-trash-can-outline</v-icon>
                                </template>
                                Eliminar
                            </v-tooltip>
                            <modificarDoc @reload="getEvidencias" :documento="item.documentoId"/> 
                            <ImageViewer :documentoId="item.documentoId" />
                        </div>
                    </template>
                    <template v-slot:item.documentoId="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">                                                                
                                <v-icon color="#0B3F67" @click="descargar(item.documentoId)" v-on="on">mdi-download</v-icon>
                            </template>
                            Descargar
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#0B3F67" text @click="cerrar">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import ModificarDoc from '@/components/documentos/ModificarDocComponent'
import ModalTitle from '@/components/utils/ModalTitleComponent'
import ImageViewer from '@/components/documentos/ImageViewerComponent.vue'
export default {
    props: [
        'id',
        'origen'
    ],
    components: {
        ModificarDoc,
        ModalTitle,
        ImageViewer
    },
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        headers() {
            switch(this.origen) {
                case 'inventario':
                    return this.headersSost;
                    break;
                case 'sustituciones':
                    return this.headersProdSust;
                    break; 
            }
        }
    },
    data: () => ({
        dialog: false,
        headersSost: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: "Año",
                value: "anio",
                align: "center",
                width: '10%',
                sortable: true
            },
            {
                text: "Nombre",
                value: "docNombre",
                align: "center",
                width: '30%',
                sortable: true
            },
            {
                text: "Tipo",
                value: "documentoTipo",
                align: "center",
                width: '20%',
                sortable: true
            },
            {
                text: "Fecha Registro",
                value: "fechaRegistroDoc",
                align: "center",
                width: '15%',
                sortable: true
            },
            {
                text: "Archivo",
                align: "center",
                sortable: false,
                width: '10%',
                value: "documentoId"
            }
        ],
        headersProdSust: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: "Nombre",
                value: "nombre",
                align: "center",
                sortable: true
            },  
            {
                text: "Origen",
                value: "origen",
                align: "center",
                sortable: true
            },          
            {
                text: "Fecha Registro",
                value: "fechaRegistro",
                align: "center",
                sortable: true
            },
            {
                text: "Archivo",
                align: "center",
                sortable: false,
                value: "documentoId"
            }
        ],
        items: [],
        footer: {
            disableItemsPerPage: true,
            showFirstLastPage: true,
            firstIcon: "mdi-page-first",
            lastIcon: "mdi-page-last",
            prevIcon: "mdi-chevron-left",
            nextIcon: "mdi-chevron-right",
            itemsPerPageText: "Registros por página",
            itemsPerPageAllText: "Todos",
            showCurrentPage: true
        }
    }),
    methods: {
        getEvidencias() {
            let url;
            switch(this.origen) {
                case 'inventario':
                    url = "/comprasSostenibles/getDocsProductoSos/";
                    break;
                case 'sustituciones':
                    url = "/comprasSostenibles/getDocsProductoSustitu/";
                    break; 
            }
            axios.get(`${url}/${this.id}/${this.anioConsulta}`).then(response=>{                    
                this.items = response.data;   
                console.log(this.items);             
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error en la carga de documentos " + error);
                this.$store.dispatch("loadingPage", false); 
              }         
            })
        },
        descargar(documentoId) {
            axios({
                url: '/documento/descargar/' + documentoId,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
        eliminarEvidencia(id) {
            this.loadingModal(true);   
            axios.delete("/comprasSostenibles/eliminarEvidenciaProduc", {params: {
                evidencia: id,
                usuario: this.$store.getters.usuario.id
            }}).then(response => {
                this.getEvidencias();      
                this.loadingModal(false);     
                this.$store.dispatch('noti', {mensaje: "Evidencia eliminada", color: "success"});     
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
                this.$store.dispatch('noti', {mensaje: "Ocurrió un error no se eliminó la evidencia", color: "error"});
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        cerrar() {
            this.items = [];
            this.dialog = false;
        }
    },
}
</script>