<template>
  <v-dialog
        v-model="dialog"
        persistent 
        max-width="500px"       
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn rounded small color="#0B3F67" dark v-on="on">Ingresar</v-btn>
        </template>
      <v-card hover raised :loading="loading">
        <v-list-item>
          <v-list-item-content>        
            <v-list-item-title class="headline">Zurquí</v-list-item-title>
            <v-list-item-subtitle>Ingreso de usuario</v-list-item-subtitle>
          </v-list-item-content>
          <v-avatar height="40" width="105" tile>
            <v-img src="@/assets/logo_zurquipng.png"></v-img>  
          </v-avatar>
        </v-list-item>                
        <v-divider></v-divider>
        <v-card-text>
          <v-form>                      
            <v-text-field 
              v-model="usuario" 
              :error-messages="usuarioErrors"
              label="Usuario" 
              @input="$v.usuario.$touch()"
              @blur="$v.usuario.$touch()"
              required>
            </v-text-field>
            <v-text-field 
              :type="show ? 'text' : 'password'"
              v-model="password" 
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="passwordErrors"
              label="Contraseña" 
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              @click:append="show = !show"
              required>
            </v-text-field>
            <RecupPassword/>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions> 
          <v-spacer/>
          <v-btn text :disabled="loading" color="error" @click="cancelar" >Cancelar</v-btn>        
          <v-btn text :loading="loading" color="#0B3F67" @click="login()" >Ingresar</v-btn>          
        </v-card-actions>
      </v-card>
      <v-alert :color="tipo" icon="mdi-alert-decagram" dismissible v-model="alert">
        {{mensaje}}
      </v-alert>
  </v-dialog>
</template>

<script>

import axios from "axios";
import { required } from "vuelidate/lib/validators";
import RecupPassword from '@/components/usuarios/RecuperarPasswordComponent';

export default {
  computed: {
    usuarioErrors () {
        const errors = []
        if (!this.$v.usuario.$dirty) return errors            
        !this.$v.usuario.required && errors.push('El usuario es requerido.')            
        return errors
    },
    passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors            
        !this.$v.password.required && errors.push('La contraseña es requerida.')            
        return errors
    }
  },
  components: {
    RecupPassword
  },
  data: () => ({
    loading: false,
    usuario: "",
    password: "",
    //variables para el alert
    alert: false,
    mensaje: "",
    tipo: "",
    dialog:false,
    show: false,
  }),
  validations: {
    usuario: { required },
    password: { required }
  },
  methods: {
    cancelar() {            
      this.dialog = !this.dialog;     
      this.alert = false;
      this.password = '';
      this.usuario = '';
      this.$v.usuario.$reset();
      this.$v.password.$reset();
    },
    login() {
      this.loading = true;
      axios.post(process.env.BASE_URL+"api/user/auth/login/", null, { params: {
        usuario: this.usuario, 
        password: this.password
      }}).then(response => {
        this.$store.commit('usuario', response.data.usuarioLog);
        this.$store.commit('rolName', response.data.rolName);
        this.$store.commit('organizacion', response.data.organiacion);
        this.$store.commit('modificarToken', response.data.token);
        this.$store.commit('logo', response.data.logo);
        this.$router.push({ name: 'dashboard' });
      }).catch(error => {
        console.log(error);
        this.mensaje = "Usuario o contraseña incorrecta, intente de nuevo";
        this.tipo = "error";
        this.alert = true;
        this.loading = false;
      })
    }
  },
};
</script>
