<template>
    <v-container fluid>
        <cadenaTabla></cadenaTabla>
    </v-container>
</template>

<script>
import cadenaTabla from "@/components/cadenaSuministros/CadenaSuministroTablaComponent";

export default {
    components: {
        cadenaTabla
    },
    
};
</script>
