<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-chip :color="color" v-on="on"><v-icon color="white">{{ icono }}</v-icon></v-chip>
        </template>
        {{ estadoName }}
    </v-tooltip>
</template>
<script>
export default {
    props: [
        'estado'
    ],
    computed: {
        color() {
            switch(this.estado) {
                case 0:
                    return '#F44336'
                    break;
                case 1:
                    return '#009688'
                    break;
                case 2:
                    return '#2E7D32'
                    break;
            }
        },
        estadoName() {
            switch(this.estado) {
                case 0:
                    return 'Sin Iniciar'
                    break;
                case 1:
                    return 'En Proceso'
                    break;
                case 2:
                    return 'Finalizado'
                    break;
            }
        },
        icono() {
            switch(this.estado) {
                case 0:
                    return 'mdi-human-male'
                    break;
                case 1:
                    return 'mdi-run-fast'
                    break;
                case 2:
                    return 'mdi-human-handsup'
                    break;
            }
        }
    },
}
</script>