<template>        
    <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on:menu }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                    
                    <v-icon size="75" color="blue-grey darken-2" v-on="{...tooltip, ...menu}">mdi-calendar-month-outline</v-icon>            
                </template>
                <span>Cambiar año</span>
            </v-tooltip>
        </template> 
        <v-list>   
            <v-list-item v-for="(anio, index) in aniosConsulta" :key="index" @click="cambiarAnio(anio)">
                <v-list-item-title>{{ anio }}</v-list-item-title>
            </v-list-item>            
        </v-list>
    </v-menu>
</template>
<script>

export default {
    computed: {
        aniosConsulta() {
            return this.$store.getters.aniosConsulta;
        }
    },
    data: () => ({
        anioSelected: null,
        items: null
    }),
    methods: {
        cambiarAnio(anio) {
            this.$store.commit('anioConsulta', anio);
            this.$emit('reload');
        }
    },
}
</script>