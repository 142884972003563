<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="520px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon 
                        v-on="{ ...tooltip, ...dialog }" 
                        color="#0B3F67"
                        class="mr-2">
                        {{icon}}
                    </v-icon>                    
                </template>
                {{ tooltipText }}
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-file-input 
                    accept=".png, .jpeg, .jpg"
                    show-size 
                    counter
                    v-model="archivos"
                    :error-messages="docErrors"
                    @change="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()"
                    :label="'Agregar ' + tipo">
                </v-file-input> 
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="argregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import { required } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
//Custom validations
const maxFileSize = (file) => {
    if(file != null) {
        let maximo = 1024 * 1024;
        return file.size < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    props: [
        'titulo',
        'tooltipText',
        'tipo',
        'icon',
        'idInscripcion'
    ],
    components: {
      ModalTitle
    },
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('El ' + this.tipo + ' es requerido.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a 1 MB.')        
            return errors
        },
    },
    data: () => ({
        dialog : false,
        loading: false,
        archivos: null
    }),
    validations: {
        archivos: {
            required,
            maxFileSize
        }
    },
    methods: {
        argregarEvidencias() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('archivo', this.archivos);
                formData.append('idUsuario', this.usuario.id)
                formData.append('idInscripcion', this.idInscripcion);
                formData.append('tipo', this.tipo);
                axios.post("/instalaciones/agregaEvidenciaPbae", formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'El ' + this.tipo + ' se agregó', color:'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el' + this.tipo, color:'error'}); 
                      this.cancelar();
                    }
                  }
                });
            }
        },
        cancelar() {
            this.archivos = null;
            this.$v.archivos.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>