<template>
    <v-container fluid>
        <SectorProductivoTabla></SectorProductivoTabla>
    </v-container>
</template>

<script>

    import SectorProductivoTabla from "@/components/organizaciones/SectorProductivoTablaComponent";

    export default {
        components: {
            SectorProductivoTabla
        },
    };
</script>