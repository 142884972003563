<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="600px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" @click="getData" class="white--text"  color="#0B3F67">Impactos del cambio climático<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
                </template>
                Impactos del cambio climático
            </v-tooltip>  
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Impactos del cambio climático" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row v-for="(impactos, nivel) in dataImpactos" :key="nivel">
                    <v-col cols="12">
                        <v-subheader>
                            <div class="text-capitalize">A nivel de {{ nivel }}</div>
                        </v-subheader>
                    </v-col>
                    <v-col cols="12">
                        <v-list>
                            <template v-for="(item) in impactos">
                                <v-list-item>
                                    <v-list-item-content v-if="item.idImpacto === 24 && item.seleccionado">
                                        <v-list-item-title>{{ item.impacto }}</v-list-item-title>
                                        <v-text-field
                                            label="Especifique Valor para Otro"
                                            v-model="item.otro"
                                        ></v-text-field>
                                    </v-list-item-content>

                                    <v-list-item-content v-else>
                                        <v-list-item-title>{{ item.impacto }}</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-checkbox v-model="item.seleccionado" :value="true"></v-checkbox>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregar" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import ModalTitle from '@/components/utils/ModalTitleComponent'

export default {
    components: {
      ModalTitle
    },
    computed: {
      anioConsulta() {
        return this. $store.getters.anioConsulta;
      },
      instalacion() {
        return this.$store.getters.sucursal;
      },
      usuario() {
        return this.$store.getters.usuario;
      },
    },
    data: () => ({
        dataImpactos: [],
        dialog: false,
    }),
    methods: {
      getData() {
        this.dataImpactos = this.impactos
      },
      agregar() {
        this.loadingModal(true);
        axios.post('/adaptacion/agrImpactoInstalacion', this.dataImpactos, { params: {
            inst: this.instalacion.id,
            usuario: this.usuario.id,
            anio: this.anioConsulta
        }}).then(response => {
            this.$store.dispatch('noti', {mensaje: 'Impactos agregados', color: 'success'});
            this.loadingModal(false);
            this.$emit('reload');
            this.cancelar();
        }).catch(error => {
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo agregar impactos', color: 'error'});
            this.loadingModal(false);
          }
        })
      },
      getData() {
            axios.get(`/adaptacion/getDataImpactoClimatico/${this.instalacion.id}/${this.anioConsulta}`)
            .then(response => {
                this.dataImpactos = response.data.impactos;
                this.loadingModal(false);
            }).catch(error => {
            if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
            } else {
                this.loadingModal(false);
            }
            })
        },
      cancelar() {
          this.dataImpactos = this.impactos
          this.dialog = false;
      },
      loadingModal(modal) {                        
          this.$store.dispatch('loadingPage', modal);
      },
    },
}
</script>