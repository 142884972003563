<template>
    <v-data-table
        :headers="headers"
        :items="inscripciones"
        item-key="id"
        :search="buscar" 
        no-data-text="No hay inscripciones para esta Instalación"
        :footer-props="{
            showFirstLastPage: true,
            firstIcon:'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageText: 'Registros por página',
            itemsPerPageAllText: 'Todos',
            showCurrentPage: true,
            showFirstLastPage: true
        }">
        <template v-slot:item.action="{ item }">
            <Modificar 
                :inscripcion="item"
                @reload="listarInscripciones"
            ></Modificar>
<!--             <AgregarEvidencia
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && !item.certificado"
                @reload="listarInscripciones"
                :idInscripcion="item.id"
                :tipo="'certificado'"
                :titulo="'Agregar Certificado'"
                tooltipText="Agregar certificado"
                :icon="'mdi-clipboard-check-outline'"
            ></AgregarEvidencia> -->
            <AgregarEvidencia
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && !item.galardon"
                @reload="listarInscripciones"
                :idInscripcion="item.id"
                :tipo="'galardón'"
                :titulo="'Agregar Galardón'"
                tooltipText="Agregar galardón"
                :icon="'mdi-medal-outline'"
            ></AgregarEvidencia>
        </template>
        <template v-slot:item.documentos="{ item }">
            <ListadosDocs 
                :idActividad="item.id"
                origen="inscripcionLegal"
                :medidorId="item.instalacion.id"
                :titulo="'Documentos de Inscripción al Programa Bandera Azul'">
            </ListadosDocs>
        </template>
    </v-data-table>
</template>
<script>

import AgregarEvidencia from "@/components/parametros/InscripcionLegal/AgregarGalardonCertComponent";
import Modificar from "@/components/parametros/InscripcionLegal/ModificarInscripComponent";
import Eliminar from '@/components/parametros/InscripcionLegal/EliminarInscrComponent';
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import axios from "@/axios.js";

export default {
   created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
        AgregarEvidencia,
        ListadosDocs,
        Modificar,
        Eliminar
    },
    props: [
        'headers',
        'inscripciones',
        'buscar'
    ],
    mounted() {
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    computed: {
        instalacion() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
    },
    methods: {
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        borrarInscripcion(id) {
        this.cargando = true;
        axios.delete("/instalaciones/borrarInscripcion/" + id, {params: {idUsuario: this.usuario.id}})
        .then(response => {
            this.$store.dispatch("noti", {
                mensaje: "Inscripción borrada",
                color: "success"
            });
            this.cargando = false;
            this.$emit('reload');
            })
            .catch(error => {
            this.$store.dispatch("noti", {
                mensaje: "Ocurrió un error no se eliminó la inscripción legal",
                color: "error"
            });
            console.log(error);
            this.cargando = false;
            });
        },
        listarInscripciones(){
            this.$emit('reload');
        },
    },
}
</script>