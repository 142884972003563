<template>
    <v-dialog
        v-model="dialog" 
        persistent        
        scrollable 
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" class="white--text"  color="#0B3F67">Agregar Producto<v-icon right>mdi-plus-box-outline</v-icon></v-btn>                                        
                </template>
                Agregar Producto
            </v-tooltip>            
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Producto" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field                            
                            label="Producto"
                            v-model="producto.nombre"
                            :error-messages="nomErrors"
                            @change="$v.producto.nombre.$touch()"
                            @blur="$v.producto.nombre.$touch()"
                            counter="200"
                        ></v-text-field>
                        <v-text-field                            
                            label="Uso"
                            v-model="producto.uso"
                            :error-messages="usoErrors"
                            @change="$v.producto.uso.$touch()"
                            @blur="$v.producto.uso.$touch()"
                            counter="200"
                        ></v-text-field>
                        <v-select
                            :items="categorias"
                            v-model="producto.categoria"
                            label="Categoria"
                            :error-messages="catErrors"
                            @change="$v.producto.categoria.$touch()"
                            @blur="$v.producto.categoria.$touch()"
                        ></v-select>
                        <v-select
                            :items="criterios"
                            v-model="producto.criterio"
                            :error-messages="critErrors"
                            hint="Elegir criterio con mayor prioridad para la instalación"
                            label="Criterio de Sostenibilidad"
                            @change="$v.producto.criterio.$touch()"
                            @blur="$v.producto.criterio.$touch()"
                        ></v-select>
                        <v-select
                            :items="anios"
                            v-model="anio"
                            :error-messages="anioErrors"
                            label="Año que aplica la evidencia"
                            @change="$v.anio.$touch()"
                            @blur="$v.anio.$touch()"
                        ></v-select>
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="evidencias"
                            :error-messages="evidErrors"
                            label="Agregar Evidencias"
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input> 
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" text @click="agregar">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from '@/axios.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import ModalTitle from '@/components/utils/ModalTitleComponent'
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        nomErrors() {
            const errors = []
            if (!this.$v.producto.nombre.$dirty) return errors            
            !this.$v.producto.nombre.required && errors.push('Dato requerido.')  
            !this.$v.producto.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.') 
            return errors
        },
        usoErrors() {
            const errors = []
            if (!this.$v.producto.uso.$dirty) return errors            
            !this.$v.producto.uso.required && errors.push('Dato requerido.')  
            !this.$v.producto.uso.maxLength && errors.push('No debe exceder el tamaño permitido.')           
            return errors
        },
        catErrors() {
            const errors = []
            if (!this.$v.producto.categoria.$dirty) return errors            
            !this.$v.producto.categoria.required && errors.push('Dato requerido.')            
            return errors
        },
        critErrors() {
            const errors = []
            if (!this.$v.producto.criterio.$dirty) return errors            
            !this.$v.producto.criterio.required && errors.push('Dato requerido.')            
            return errors
        },
        anioErrors() {
            const errors = []
            if (!this.$v.anio.$dirty) return errors            
            !this.$v.anio.required && errors.push('El año de la evidencia es requerido.')            
            return errors
        },
        evidErrors() {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        }
    },
    mounted() {
        this.getAnioParticipacion();
    },
    data: () => ({
        producto: {
            nombre: '',
            uso: '',
            categoria: '',
            criterio: ''
        },
        anio: null,
        evidencias: [],
        categorias: [
            'Artículos de oficina',
            'Artículos de limpieza',
            'Artículos de mantenimiento'
        ],
        criterios: [
            'Bajo consumo de agua',
            'Bajo consumo de energía',
            'Biodegradable',
            'Proveniente de fuentes renovables',
            'Control de químicos',
            'NO GMO',
            'No erosión del suelo',
            'RSE'
        ],
        anios: [],
        dialog: false
    }),
    validations: {
        producto: {
            nombre: {
                maxLength: maxLength(200),
                required
            },
            uso: {
                maxLength: maxLength(200),
                required
            },
            categoria: {
                required
            },
            criterio: {
                required
            }
        },
        anio: {
            required
        },
        evidencias: {
            required,
            maxFileSize
        },
    },
    methods: {
        agregar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                let formData = new FormData();
                this.evidencias.forEach(file => {
                    formData.append('evidencias', file);
                });
                formData.append('producto', JSON.stringify(this.producto));
                formData.append('usuario', this.usuario.id);
                formData.append('instalacion', this.instalacion.id);
                formData.append('anio', this.anio);
                axios.post("/comprasSostenibles/agregarProductoSost", formData)
                .then(response => {
                    this.$emit('reload');
                    this.$store.dispatch('noti', {mensaje: 'Producto agregado', color: 'success'});
                    this.loadingModal(false);
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el producto', color: 'error'});
                      this.loadingModal(false);
                      this.cancelar();
                    }
                  }
                })
            }
        },
        getAnioParticipacion() {
            let id = this.instalacion.id;
            axios.get("/instalaciones/getAniosParticipacion/" + id
            ).then(response => {
                this.anios = response.data.anios;                
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los años de parrticipacion: " + error);
              }
            })
        },
        cancelar() {
            this.producto = {};
            this.evidencias = [];
            this.anio = null;
            this.$v.producto.$reset();
            this.$v.anio.$reset();
            this.$v.evidencias.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>