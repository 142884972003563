<template>
  <v-container fluid>
    <v-row>
        <!--Aqui va agregar residuo.-->
      <v-col cols="6" sm="6" md="3" align-self="center">
            <AgregaResiduo 
                @reload="getData" 
                :tipoResiduo="tipoResiduo" 
                :anioBusqueda="anioConsulta" 
                :origen="tipoResiduo"
                v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
      </v-col>
      <v-col cols="6" sm="6" md="3" align-self="center">
            <Justififcacion 
                v-if="shoWJustificacion" 
                titulo="Justificar Ausencia de Datos"
             />
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="residuos"
                    calculate-widths
                    item-key="id"
                    :loading="loading"
                    no-data-text="No hay Residuos para este periodo"
                    :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                >
                    <template v-slot:item.action="{item }">
                        <ModificarResiduo 
                            @reload="getData" 
                            v-bind:id="item.id"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                        <AgregarEvidencia
                            :origen="origenE"
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                            @reload="getData"
                            :idActividad="item.id"
                            tooltipText="Agregar Evidencias"
                            :titulo="titulo"
                        ></AgregarEvidencia>
                        <eliminar-residuo @reload="getData" :evidencia="item" :origen="tipoResiduo"/>
                    </template>
                    <template v-slot:item.evidencia="{ item }">
                        <ListadosDocs :idActividad="item.id" origen="residuosEspeciales" :titulo="titulo"></ListadosDocs>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   </v-container>
</template>
<script>

import axios from "@/axios.js";
import AgregaResiduo from '@/components/parametros/GestiondeResiduos/AgregarResiduosComponent';
import ModificarResiduo from '@/components/parametros/GestiondeResiduos/ModificarResiduosComponent';
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import EliminarResiduo from "@/components/parametros/GestiondeResiduos/EliminarResiduoComponent";
import Justififcacion from '@/components/utils/JustificacionComponent';

export default {
    created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    mounted(){
        this.getData();
    },
    components:{
        AgregaResiduo,
        ModificarResiduo,
        ListadosDocs,
        AgregarEvidencia,
        EliminarResiduo,
        Justififcacion
    }, 
    props: ["headers", "origen"],
    data:()=>({
        residuos:[],
        items:[],
        loading: false,
        titulo: "Evidencia Residuos Especiales",
        origenE: "residuosEspeciales"
    }),
    computed:{
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        tipoResiduo(){
            let constantes = this.$store.getters.constantes;
            switch(this.origen){
                case constantes.residuosEspeciales:
                    return 0;
                    break;
                case constantes.residuosPeligrosos:
                    return 1;
                    break;
            }
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        shoWJustificacion() {
            return !this.residuos.length
        }
    },
    methods:{
        getData(){
            this.loading = true;
            axios.get("/gestionResiduos/traerResiduosPorTipo/"+this.instalacion.id,{params:{
                tipoResiduo: this.tipoResiduo,
                anio: this.anioConsulta,
            }}).then(response=>{
                this.residuos = response.data.residuos;
                this.items = response.data.anios;
                this.loading = false;
                this.loadingModal(false);
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loading = false;
                console.log(error);
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        refreshTable() {
            this.$emit('reload');
        },
    }
}
</script>